import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import { ToastContainer, toast } from 'react-toastify';

import { multipleBrandsAlgorithmCalculation } from '../../../redux/actions/Firebase';
import { makeURL } from '../../../helperFunctions';
import useViewportWidth from '../../../myHooks/useViewportWidth';
import { getCompetitiveSetBrandsHeaderData, mapCompetitiveSetBrandsTableData } from './chartCalculations/helpers';

import PageContainer from '../../../myComponents/pageContainer';
import PageLoader from '../../../myComponents/loaders/pageLoader';

import RecentCardExtended from '../../../myComponents/recentView/recentCardExpanded';
import Filters from '../../../myComponents/filters/Filters';
import UserDropdownHeader from '../../../myComponents/userDropdownHeader';

const CompetitiveSetBrands = () => {
  const history = useHistory();
  const appData = useSelector(({ data }) => data);
  const viewportWidth = useViewportWidth();

  const [pageIsLoading, setPageIsLoading] = useState(true);

  //Calculation state
  const [calculations, setCalculations] = useState({
    loading: true,
    results: null,
  });

  useEffect(() => {
    if (!appData.loading) {
      if (appData.error) {
        showToastWarn();
      } else {
        multipleBrandsAlgorithmCalculation({ filters: null })
          .then(res => {
            setCalculations({ results: res, loading: false });
            setPageIsLoading(false);
          })
          .catch(res => {
            showToastWarn();
            setPageIsLoading(false);
            setCalculations({ results: null, loading: false });
          });
      }
    }
  }, [appData]);

  const calculateHandler = filters => {
    setCalculations(prevState => ({ ...prevState, loading: true }));
    multipleBrandsAlgorithmCalculation({ filters: filters })
      .then(res => {
        setCalculations({ results: res, loading: false });
      })
      .catch(err => {
        showToastWarn();
        setCalculations({ results: null, loading: false });
      });
  };

  const showToastWarn = () => {
    toast.warn('Something went wrong, please try again later.', {
      position: 'bottom-right',
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const viewSpecificSurveyHandler = id => {
    history.push(makeURL(`/competitive-set-brands/${id}`));
  };

  const competitiveSetBrandsHeaderData = getCompetitiveSetBrandsHeaderData(viewportWidth);

  const competitiveSetBrandsTableData = calculations.results
    ? mapCompetitiveSetBrandsTableData(Object.values(calculations.results), viewportWidth)
    : [];

  return (
    <>
      <UserDropdownHeader bgColor="#f4f4f7" />
      <PageContainer
        headingStyle={{
          fontWeight: 'bold',
          margin: '25px 0 0 25px',
        }}
        style={{
          position: 'relative',
        }}>
        {pageIsLoading || calculations.loading ? <PageLoader dashboardCalculation /> : null}
        {calculations.results ? (
          <Box width="100%" margin="30px auto">
            <Filters calculateHandler={calculateHandler} themeColor="green" csvExport={calculations.results} />
            <RecentCardExtended
              heading="Competitive Set Brands"
              type="competitive-brand"
              listSections={competitiveSetBrandsHeaderData}
              surveyData={competitiveSetBrandsTableData}
              viewSpecific={viewSpecificSurveyHandler}
              isLoading={appData.loading}
            />
          </Box>
        ) : null}
        <Box width="100%" margin="30px auto">
          <ToastContainer
            theme="colored"
            position="bottom-right"
            autoClose={4000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </Box>
      </PageContainer>
    </>
  );
};

export default CompetitiveSetBrands;
