import React from 'react';

import { useLocation } from 'react-router-dom';

import CmtVerticalLayout from '../layoutComponents';
import CmtSidebar from '../layoutComponents/Sidebar';
import CmtContent from '../layoutComponents/Content';
import SidebarHeader from '../layoutComponents/SidebarHeader';
import SidebarContent from '../layoutComponents/sidebarContent';
import ContentLoader from '../../../../myComponents/loaders/contentLoader';
import defaultContext from '../../../contexts/AppContext/defaultContext';
import { makeURL } from '../../../../helperFunctions';

const layoutOptions = {
  headerType: defaultContext.headerType,
  footerType: 'fixed',
  sidebarType: defaultContext.sidebarType,
  isSidebarFixed: defaultContext.isSidebarFixed,
  isSidebarOpen: false,
  showTourOpt: true,
  showFooterOpt: true,
  miniSidebarWidth: 80,
  layoutStyle: defaultContext.layoutType,
  drawerBreakPoint: defaultContext.drawerBreakPoint,
  sidebarWidth: defaultContext.sidebarWidth,
};

const VerticalLayout = ({ children }) => {
  const location = useLocation();
  return location.pathname !== makeURL('/signin') ? (
    <CmtVerticalLayout
      layoutOptions={layoutOptions}
      sidebar={
        <CmtSidebar>
          <SidebarHeader />
          <SidebarContent />
        </CmtSidebar>
      }>
      <CmtContent>
        {children}
        <ContentLoader />
      </CmtContent>
    </CmtVerticalLayout>
  ) : (
    <div style={{ minHeight: '100vh', width: '100%', display: 'flex', overflow: 'hidden' }}>{children}</div>
  );
};

export default VerticalLayout;
