import { USA_States } from '../config/questions';

export const filterSectionFields = [
  {
    id: 'Age',
    heading: 'AGE',
    type: 'multiple',
    sections: [
      {
        subHeading: 'AGE GROUPS',
        input: 'switch',
        fields: [
          { label: 'Young 18-34', value: '[18,34]' },
          { label: 'Middle 35-49', value: '[35,49]' },
          { label: 'Older 50+', value: '[50,100]' },
        ],
      },
      {
        subHeading: 'AGE RANGE (YEARS)',
        input: 'range',
        min: 0,
        max: 100,
      },
      {
        subHeading: 'GENERATIONS',
        input: 'switch',
        fields: [
          { label: 'Gen Z (10 - 25 Years)', value: '[10,25]' },
          { label: 'Gen Y (26 - 41 Years)', value: '[26,41]' },
          { label: 'Gen X (42 - 57 Years)', value: '[42,57]' },
          { label: 'Boomers (58 - 76 Years)', value: '[58,76]' },
          { label: 'Silent (77+ Years)', value: '[77,100]' },
        ],
      },
    ],
  },
  {
    id: 'Gender',
    heading: 'GENDER',
    type: 'single',
    input: 'switch',
    fields: [
      { label: 'Male', value: 1 },
      { label: 'Female', value: 2 },
      { label: 'Other', value: 3 },
    ],
  },
  {
    id: 'Ethnicity',
    heading: 'ETHNICITY',
    type: 'single',
    input: 'switch',
    fields: [
      { label: 'Caucasian', value: 1 },
      { label: 'Native American', value: 2 },
      { label: 'Hispanic', value: 3 },
      { label: 'African American', value: 4 },
      { label: 'Asian', value: 5 },
      { label: 'Pacific Islander', value: 6 },
      { label: 'Other', value: 7 },
    ],
  },
  {
    id: 'Education',
    heading: 'EDUCATION',
    type: 'single',
    input: 'switch',
    fields: [
      { label: 'High school or less', value: 1 },
      { label: 'Some collage/Associate Degree', value: 2 },
      { label: "Bachelor's Degree", value: 3 },
      { label: "Master's Degree / Some graduate school", value: 4 },
      {
        label: 'Doctoral and/or Professional Degree (e.g. Ph.D, JD, MD)',
        value: 5,
      },
    ],
  },
  {
    id: 'Income',
    heading: 'INCOME',
    type: 'multiple',
    sections: [
      {
        subHeading: 'INCOME GROUP',
        input: 'switch',
        fields: [
          { label: 'Low (under $49,999)', value: '[1,4]' }, //the value is range of values from data codes
          { label: 'Middle ($50,000 - $99,999)', value: '[5,6]' },
          { label: 'High ($100,000+)', value: '[7,9]' },
        ],
      },
      {
        subHeading: 'INCOME BRACKET',
        input: 'switch',
        fields: [
          { label: 'LESS THAN $15,000', value: 1 },
          { label: '$10,000 - $24,999', value: 2 },
          { label: '$25,000 - $34,999', value: 3 },
          { label: '$35,000 – $49,999', value: 4 },
          { label: '$50,000 - $74,999', value: 5 },
          { label: '$75,000 - $99,999', value: 6 },
          { label: '$100,000 - $149,999', value: 7 },
          { label: '$150,000 - $199,999', value: 8 },
          { label: '$200,000 +', value: 9 },
        ],
      },
    ],
  },
  {
    id: 'Location',
    heading: 'LOCATION',
    type: 'multiple',
    sections: [
      {
        subHeading: 'COUNTRY',
        input: 'dropdown',
        subId: 'Country',
        options: [
          {
            text: 'USA',
            value: 1,
          },
        ],
      },
      {
        subHeading: 'REGION',
        input: 'dropdown',
        subId: 'Region',
        options: [
          {
            text: 'USA',
            value: 1,
          },
        ],
      },
      {
        subHeading: 'STATE',
        input: 'dropdown',
        subId: 'State',
        options: [
          {
            text: 'Please Select',
            value: 0,
          },
          ...USA_States,
        ],
      },
      {
        subHeading: 'LOCATION TYPE',
        input: 'switch',
        subId: 'Location',
        fields: [
          { label: 'Urban', value: 1 },
          { label: 'Suburban', value: 2 },
          { label: 'Rural', value: 3 },
        ],
      },
    ],
  },
  {
    id: 'PersonalIdeology_1',
    heading: 'IDEOLOGY',
    type: 'single',
    input: 'switch',
    fields: [
      { label: 'Extremely liberal', value: 1 },
      { label: 'Somewhat liberal', value: 2 },
      { label: 'Centrist', value: 3 },
      { label: 'Somewhat Conservative', value: 4 },
      { label: 'Extremely Conservative', value: 5 },
    ],
  },
  {
    id: 'Homeownership',
    heading: 'HOME OWNERSHIP',
    type: 'single',
    input: 'switch',
    fields: [
      { label: 'Rent on my own/with related individuals', value: 1 },
      { label: 'Rent with unrelated individuals', value: 2 },
      { label: "Live with someone else and don't pay for housing", value: 3 },
      { label: "Own a home and don't have any debt on it", value: 4 },
      { label: 'Have a mortgage on my home', value: 5 },
      { label: 'Other', value: 6 },
    ],
  },
  {
    id: 'Marital_status',
    heading: 'MARITAL STATUS',
    type: 'single',
    input: 'switch',
    fields: [
      { label: 'Maried or living with a partner without children', value: 1 },
      { label: 'Maried or living with a partner with children', value: 2 },
      { label: 'Widowed', value: 3 },
      { label: 'Divorced', value: 4 },
      { label: 'Separated', value: 5 },
      { label: 'Never married', value: 6 },
    ],
  },
  // {
  //   id: 'batch_data',
  //   heading: 'BATCH DATA',
  //   type: 'multiple',
  //   sections: [
  //     {
  //       subHeading: 'YEAR DATA WAS COLLECTED',
  //       input: 'switch',
  //       fields: [
  //         { label: '2023', value: 2023 },
  //         { label: '2022', value: 2022 },
  //         { label: '2021', value: 2021 },
  //       ],
  //     },
  //     {
  //       subHeading: 'SELECT BATCH',
  //       input: 'switch',
  //       fields: [
  //         { label: 'Batch Q1 2022', value: '2022_1' },
  //         { label: 'Batch Q2 2022', value: '2022_2' },
  //         { label: 'Batch Q3 2022', value: '2022_3' },
  //       ],
  //     },
  //   ],
  // },
];

export const batchFilterFields = [
  {
    value: 'batch_1',
    label: 'Batch 2021',
    selected: false,
  },
  {
    value: 'batch_2',
    label: 'Batch 2022',
    selected: false,
  },
  {
    value: 'batch_3',
    label: 'Batch 2023',
    selected: true,
  },
];
