import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Typography,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    width: '100%',
    maxWidth: '700px',
    overflowX: 'auto',
    margin: '0 auto 0 auto',
    '@media (max-width:768px)': {
      maxWidth: '90%',
      overflow: 'auto',
    },
  },
  text: ({ isSummary }) => ({
    color: theme.palette.text.primary,
    fontWeight: 800,
    fontSize: '17px',
    margin: '0 0 20px 0',
    textAlign: 'left',
    '@media (max-width:768px)': {
      textAlign: 'center',
      margin: '0 0 15px 0',
      fontSize: '14px',
    },
  }),
  optionsText: {
    color: theme.palette.text.primary,
    fontWeight: 300,
    fontSize: '14px',
    margin: '0',
  },
  radioGroup: {
    display: 'flex',
    flexWrap: 'nowrap',
    overflowX: 'auto',
  },
}));

const RadioInputs = (props) => {
  const [selectedValue, setSelectedValue] = useState(
    props.value ? props.value : ''
  );
  const classes = useStyles();

  const handleChange = (event) => {
    const value = event.target.value;
    setSelectedValue(value);
    props.handleSurveyAnswers(value, props.id);
  };

  return (
    <Box className={classes.mainContainer}>
      <Typography component='div' variant='h3' className={classes.text}>
        {props.text}
      </Typography>
      <FormControl fullWidth>
        <RadioGroup
          aria-labelledby='demo-row-radio-buttons-group-label'
          name='row-radio-buttons-group'
          className={classes.radioGroup}
          value={selectedValue}
        >
          {props.options.map((radioElement, index) => {
            return (
              <FormControlLabel
                style={{ marginBottom: '3px' }}
                key={index}
                value={radioElement.value + ''}
                onChange={handleChange}
                control={
                  <Radio
                    style={{
                      color: '#19989B',
                    }}
                  />
                }
                label={
                  <Typography
                    component='div'
                    variant='body2'
                    className={classes.optionsText}
                  >
                    {radioElement.text}
                  </Typography>
                }
              />
            );
          })}
        </RadioGroup>
      </FormControl>
    </Box>
  );
};

export default RadioInputs;
