import React from 'react';
import { PieChart, ShoppingCart, Business, Poll, Chat, BlurCircular, Group } from '@material-ui/icons';
import { makeURL } from '../../../../../helperFunctions';
import { subscriptionTypeNames } from '../../../../../config/subscriptionsConfig';

export const sidebarItems = [
  {
    type: 'section',
    restrictedSubscriptions: [],
    children: [
      {
        name: 'Brand Personas',
        type: 'item',
        icon: <PieChart />,
        link: makeURL('/brand-personas/all'),
      },
    ],
  },
  {
    type: 'section',
    restrictedSubscriptions: [],
    children: [
      {
        name: 'Buyer Personas',
        type: 'item',
        icon: <ShoppingCart />,
        link: makeURL('/buyer-personas/all'),
      },
    ],
  },
  {
    type: 'section',
    restrictedSubscriptions: [],
    children: [
      {
        name: 'Social Media Dashboard',
        type: 'item',
        icon: <BlurCircular />,
        link: makeURL('/social-media/all'),
      },
    ],
  },
  {
    type: 'section',
    restrictedSubscriptions: [],
    children: [
      {
        name: 'Consumer Dashboard',
        type: 'item',
        icon: <Business />,
        link: makeURL('/consumer-dash'),
      },
    ],
  },
  {
    type: 'section',
    restrictedSubscriptions: [subscriptionTypeNames.demo],
    children: [
      {
        name: 'Competitive Set Brands',
        type: 'item',
        icon: <Poll />,
        link: makeURL('/competitive-set-brands/all'),
      },
    ],
  },
  {
    type: 'section',
    restrictedSubscriptions: [],
    children: [
      {
        name: 'Your Brand & Consumers',
        type: 'item',
        icon: <Group />,
        link: makeURL('/brand-consumers'),
      },
    ],
  },
  {
    type: 'section',
    restrictedSubscriptions: [],
    children: [
      {
        name: 'Contact Us',
        type: 'item',
        icon: <Chat />,
        link: makeURL('/contact-us'),
      },
    ],
  },
];
