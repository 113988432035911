import React from "react";

import { Box } from "@material-ui/core";

const DashSectionExplanation = ({ heading, headingColor, body, bodyColor }) => {
  return (
    <Box width="100%" textAlign="left">
      <h5
        style={{
          color: headingColor,
          fontSize: "16px",
          marginBottom: "10px"
        }}
      >
        {heading}
      </h5>
      <p
        style={{
          color: bodyColor,
          margin: 0,
          fontSize: "12px",
          fontWeight: 300,
        }}
      >
        {body}
      </p>
    </Box>
  );
};

export default DashSectionExplanation;
