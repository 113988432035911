import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography, Select, MenuItem, Slider } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

import FilterSwitchGroup from './FilterSwitchGroup';
import { uncheckAgeGroupFilters, uncheckGenGroupFilters } from '../../../helperFunctions';

const useStyles = makeStyles(theme => ({
  mainContainer: {
    backgroundColor: 'white',
    padding: '7px 12px 2px 30px',
    marginBottom: '20px',
    marginRight: '15px',
    borderRight: '1px solid lightgray',

    '@media (max-width: 1800px)': {
      maxWidth: '350px',
    },

    '@media (max-width: 1700px)': {
      maxWidth: '250px',
    },

    '@media (max-width: 1240px)': {
      maxWidth: '30%',
    },

    '@media (max-width: 991px)': {
      maxWidth: 'unset',
      width: '50%',
      borderRight: 'none',
    },

    '@media (max-width: 768px)': {
      maxWidth: 'unset',
      width: '350px',
      borderRight: 'none',
    },
  },
  heading: {
    color: 'black',
    fontWeight: 'bold',
    fontSize: '15px',
    paddingBottom: '8px',
    margin: '0 0 10px 0',
    minWidth: '150px',
  },
  subHeading: {
    color: '#303030',
    fontWeight: 'bold',
    fontSize: '12px',
    margin: '0 0 10px 0',
    letterSpacing: '0px',
  },
  dropdown: {
    marginBottom: '10px',
  },
  select: {
    padding: '7px 15px',
  },
}));

const themeColorConfig = {
  green: '#82c2c3',
  yellow: '#AB8628',
  red: 'rgb(228, 85, 137)',
};

const FilterInputGenerator = ({ section, setRangeWasTouched, themeColor }) => {
  const muiTheme = createTheme({
    overrides: {
      MuiSlider: {
        thumb: {
          color: themeColorConfig[themeColor],
          width: '15px',
          height: '15px',
          '&$disabled': {
            color: '#9F9F9F',
            width: '13px',
            height: '13px',
          },
        },
        track: {
          backgroundColor: themeColorConfig[themeColor],
          height: '5px',
        },
        rail: {
          color: '#9F9F9F',
          height: '5px',
        },
      },
    },
  });

  const [sliderValue, setSliderValue] = React.useState([0, 0]);
  const classes = useStyles();

  const onSliderChangeHandler = (e, newValue) => {
    const ageGroupSection = document.getElementById('AGEGROUPS');
    const genGroupSection = document.getElementById('GENERATIONS');
    const ageRangeSection = document.getElementById('AGERANGE(YEARS)');

    if (!ageGroupSection.classList.contains('opacity-sm')) {
      uncheckAgeGroupFilters();

      ageGroupSection.classList.add('opacity-sm');
    }

    if (!genGroupSection.classList.contains('opacity-sm')) {
      uncheckGenGroupFilters();

      genGroupSection.classList.add('opacity-sm');
    }

    ageRangeSection.classList.remove('opacity-sm-range');

    setSliderValue(newValue);
    setRangeWasTouched(true);
  };

  if (section.type === 'multiple') {
    return (
      <Box className={classes.mainContainer}>
        <Typography component="div" variant="body1" className={classes.heading}>
          {section.heading}
        </Typography>
        {section.sections.map((subSection, index) => {
          const elementId = subSection.subHeading.replace(/ /g, '');

          return (
            <Box key={index} id={elementId}>
              <Typography component="div" variant="body1" className={classes.subHeading}>
                {subSection.subHeading}
              </Typography>
              {subSection.input === 'switch' ? (
                <FilterSwitchGroup
                  switchElements={subSection.fields}
                  id={section.id}
                  themeColor={themeColor}
                  onSliderChangeHandler={onSliderChangeHandler}
                />
              ) : subSection.input === 'dropdown' ? (
                <Select
                  id={section.id}
                  variant="outlined"
                  defaultValue={subSection.options.length === 1 ? 1 : 0}
                  disabled={subSection.options.length === 1}
                  classes={{ select: classes.select }}
                  className={classes.dropdown}
                  name={subSection.subId}>
                  {subSection.options.map((e, index) => {
                    return (
                      <MenuItem key={index} value={e.value}>
                        {e.text}
                      </MenuItem>
                    );
                  })}
                </Select>
              ) : (
                <ThemeProvider theme={muiTheme}>
                  <Slider
                    style={{ maxWidth: '200px' }}
                    getAriaLabel={() => 'Age Range'}
                    name={section.id}
                    value={sliderValue}
                    onChange={(event, newValue) => {
                      onSliderChangeHandler(event, newValue);
                    }}
                    id="ageRange"
                    valueLabelDisplay="auto"
                    getAriaValueText={() => `${sliderValue}`}
                  />
                </ThemeProvider>
              )}
            </Box>
          );
        })}
      </Box>
    );
  } else {
    return (
      <Box className={classes.mainContainer}>
        <Typography component="div" variant="body1" className={classes.heading}>
          {section.heading}
        </Typography>
        <FilterSwitchGroup
          themeColor={themeColor}
          switchElements={section.fields}
          id={section.id}
          onSliderChangeHandler={onSliderChangeHandler}
        />
      </Box>
    );
  }
};

export default FilterInputGenerator;
