import {
  AUTH_SUCCESS,
  AUTH_FAIL,
  AUTH_SIGN_OUT,
  AUTH_SIGN_OUT_FAIL,
  AUTH_INITIAL_APP_LOAD,
} from "../actions/actionTypes.js";

const INIT_STATE = {
  initialAppLoadCheckingAuthState: true,
  user: null,
  fail: false,
  errorMessage: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case AUTH_SUCCESS: {
      return {
        user: action.user,
        fail: false,
        errorMessage: null,
        initialAppLoadCheckingAuthState: false,
      };
    }
    case AUTH_INITIAL_APP_LOAD: {
      return {
        ...state,
        initialAppLoadCheckingAuthState: true,
      };
    }

    case AUTH_FAIL: {
      return {
        ...INIT_STATE,
        fail: true,
        errorMessage: "An error has occured while trying to authenticate.",
      };
    }
    case AUTH_SIGN_OUT: {
      let resetState = {
        ...INIT_STATE,
        initialAppLoadCheckingAuthState: false,
      };
      return resetState;
    }
    case AUTH_SIGN_OUT_FAIL: {
      return {
        ...INIT_STATE,
        fail: true,
        errorMessage: "Couldn't sign out the user.",
      };
    }
    default:
      return state;
  }
};
