import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography, Switch } from '@material-ui/core';
import { ScatterChart, Scatter, XAxis, YAxis, CartesianGrid, Tooltip, ReferenceLine, ZAxis } from 'recharts';
import { getRealAndFakedCompetitiveValue } from '../../../routes/Pages/BrandPersonas/chartCalculations/helpers';
import { makeURL } from '../../../helperFunctions';
import './index.css';

const useStyles = makeStyles(theme => ({
  chartPartLabels: {
    color: '#95A2AC',
    fontSize: '15px',
    fontWeight: 400,
    margin: 0,
    position: 'absolute',
    textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  },
  chartYAxisLabel: {
    color: 'white',
    position: 'absolute',
    left: '-10px',
    textAlign: 'center',
    fontSize: '20px',
    writingMode: 'vertical-rl',
    height: '87%',
  },
  chartXAxisLabelContainer: {
    position: 'absolute',
    bottom: '-40px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  flyAwayContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  flyAwayText: {
    marginRight: "10px",
    color: '#90A0B0',
    fontSize: '15px',
  },
  chartXAxisLabel: {
    flex: 1,
    color: 'white',
    textAlign: 'center',
    fontSize: '20px',
  },
  chartPartLabelsTopLeft: {
    top: 30,
    left: 100,
  },
  chartPartLabelsBottomLeft: {
    bottom: 80,
    left: 100,
  },
  chartPartLabelsTopRight: {
    top: 30,
    right: 55,
  },
  chartPartLabelsCenterRight: {
    top: 220,
    right: 40,
  },
  chartPartLabelsBottomRight: {
    bottom: 80,
    right: 40,
  },
  switchRoot: {
    '& .MuiSwitch-track': {
      backgroundColor: 'lightgray !important',
    },
  },
  scatterChartLegendContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    paddingRight: '20px',
  },
  scatterLegend: {
    padding: 0,
    color: '#90A0B0',
    fontSize: '11px',
  },
  scatterChartLegendImageContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 7,
  },
}));

const configureState = matchesProp => {
  const targetMatchDataX = Math.round(matchesProp.targetMatch.values * 100);
  const targetMatchDataY = Math.round(matchesProp.targetMatch.product * 100);
  const targetMatchData = getRealAndFakedCompetitiveValue({
    x: targetMatchDataX,
    y: targetMatchDataY,
  });

  const otherCompaniesMatchData = matchesProp.otherCompaniesMatch.map(otherCompany => {
    const otherCompanyDataX = Math.round(otherCompany.match.values * 100);
    const otherCompanyDataY = Math.round(otherCompany.match.product * 100);
    const otherCompanyData = getRealAndFakedCompetitiveValue({
      x: otherCompanyDataX,
      y: otherCompanyDataY,
    });

    return {
      product: otherCompanyDataY,
      values: otherCompanyDataX,
      name: otherCompany.name,
      position: otherCompanyData,
    };
  });

  return {
    targetMatch: {
      product: targetMatchDataY,
      values: targetMatchDataX,
      name: matchesProp.targetMatch.name,
      position: targetMatchData,
    },
    otherCompaniesMatch: otherCompaniesMatchData,
  };
};

const maxMinCompare = (config, value) => {
  return value > config.max ? config.max : value < config.min ? config.min : value;
};

const SimpleScatterChart = ({ insideLabels, XYAxisLabels, chartHeightWidth, matches }) => {
  const classes = useStyles();
  const [scatterRecodedData, setScatterRecodedData] = useState(configureState(matches));
  const [flyAwayActivated, setFlyAwayActivated] = useState(false);
  const scatterX = {
    max: 80,
    min: 50,
  };
  const scatterY = {
    max: 70,
    min: 50,
  };

  useEffect(() => {
    setScatterRecodedData(configureState(matches));
    setFlyAwayActivated(false);
  }, [matches]);

  const handleFlyAway = event => {
    const activated = event.target.checked;
    setFlyAwayActivated(activated);
    if (!activated) {
      setScatterRecodedData(configureState(matches));
    } else {
      let shouldMoveOpposite = false;
      const moveTreshold = 3;
      const moveDistance = 1.6;
      const { position: centerPosition } = scatterRecodedData.otherCompaniesMatch.find(e => e.name === 'Set Average');
      const centerX = centerPosition.x;
      const centerY = centerPosition.y;
      const getMove = (value, center) => {
        if (Math.abs(value - center) >= moveTreshold) return 0;

        if (value === center) {
          const move = shouldMoveOpposite ? moveDistance : -moveDistance;
          shouldMoveOpposite = !shouldMoveOpposite;
          return move;
        }

        return value < center ? -moveDistance : moveDistance;
      };

      //Target company handling
      const targetCompanyPosition = { ...scatterRecodedData.targetMatch.position };
      const targetCompanyMoveX = getMove(targetCompanyPosition.x, centerX);
      const targetCompanyMoveY = targetCompanyMoveX === 0 ? 0 : getMove(targetCompanyPosition.y, centerY);
      targetCompanyPosition.x = maxMinCompare(scatterX, targetCompanyPosition.x + targetCompanyMoveX);
      targetCompanyPosition.y = maxMinCompare(scatterY, targetCompanyPosition.y + targetCompanyMoveY);

      //Other company handling
      const otherCompaniesWithRecodedPositions = scatterRecodedData.otherCompaniesMatch.map(otherCompany => {
        if (otherCompany.name === 'Set Average') return otherCompany;

        const otherCompanyPosition = { ...otherCompany.position };
        const otherCompanyMoveX = getMove(otherCompanyPosition.x, centerX);
        const otherCompanyMoveY = otherCompanyMoveX === 0 ? 0 : getMove(otherCompanyPosition.y, centerY);
        otherCompanyPosition.x = maxMinCompare(scatterX, otherCompanyPosition.x + otherCompanyMoveX);
        otherCompanyPosition.y = maxMinCompare(scatterY, otherCompanyPosition.y + otherCompanyMoveY);
        return {
          ...otherCompany,
          position: otherCompanyPosition,
        };
      });

      /*
        Logic which doesn check if the element is too far so it doesn't need to be moved
        I saved this snipet because 1 time i saw that this code behaves better
      */
      // //Target company handling
      // const targetCompanyPosition = { ...scatterRecodedData.targetMatch.position };
      // targetCompanyPosition.x = maxMinCompare(scatterX, targetCompanyPosition.x + getMove(targetCompanyPosition.x, centerX));
      // targetCompanyPosition.y = maxMinCompare(scatterY, targetCompanyPosition.y + getMove(targetCompanyPosition.y, centerY));

      // //Other company handling
      // const otherCompaniesWithRecodedPositions = scatterRecodedData.otherCompaniesMatch.map(otherCompany => {
      //   if (otherCompany.name === 'Set Average') return otherCompany;

      //   const otherCompanyPosition = { ...otherCompany.position };
      //   otherCompanyPosition.x = maxMinCompare(scatterX, otherCompanyPosition.x + getMove(otherCompanyPosition.x, centerX));
      //   otherCompanyPosition.y = maxMinCompare(scatterY, otherCompanyPosition.y + getMove(otherCompanyPosition.y, centerY));
      //   return {
      //     ...otherCompany,
      //     position: otherCompanyPosition,
      //   };
      // });

      setScatterRecodedData(prevState => ({
        targetMatch: {
          ...prevState.targetMatch,
          position: targetCompanyPosition,
        },
        otherCompaniesMatch: otherCompaniesWithRecodedPositions,
      }));
    }
  };

  const targetCompanyName = scatterRecodedData.targetMatch.name;
  const targetCompanyNameLimited =
    targetCompanyName.length > 30 ? targetCompanyName.substring(0, 30).concat('...') : targetCompanyName;

  const configuredMatches = configureState(matches);

  return (
    <>
      <div style={{ position: 'relative' }}>
        <div className="square" id="square1"></div>
        <div className="square" id="square2"></div>
        <div className="square" id="square3"></div>
        <div className="square" id="square4"></div>
        {insideLabels.map((label, index) => {
          const positionClass =
            index === 0
              ? 'chartPartLabelsTopLeft'
              : index === 1
              ? 'chartPartLabelsTopRight'
              : index === 2
              ? 'chartPartLabelsCenterRight'
              : index === 3
              ? 'chartPartLabelsBottomLeft'
              : index === 4
              ? 'chartPartLabelsBottomRight'
              : null;
          return (
            <Typography
              key={index}
              component="div"
              variant="body1"
              className={`${classes.chartPartLabels} ${classes[positionClass]}`}>
              {label}
            </Typography>
          );
        })}
        <Typography component="div" variant="body1" className={classes.chartYAxisLabel}>
          {XYAxisLabels.y}
        </Typography>
        <Box className={classes.chartXAxisLabelContainer}>
          <Box className={classes.flyAwayContainer}>
            <Typography component="span" variant="body1" className={classes.flyAwayText}>
              Spread Brands
            </Typography>
            <Switch
              size="small"
              color="secondary"
              onChange={handleFlyAway}
              checked={flyAwayActivated}
              classes={{
                root: classes.switchRoot,
              }}
              style={{
                color: flyAwayActivated ? '#19989B' : '#FFFFFF',
              }}
            />
          </Box>
          <Typography component="div" variant="body1" className={classes.chartXAxisLabel}>
            {XYAxisLabels.x}
          </Typography>
          <Box className={classes.scatterChartLegendContainer}>
            <Box className={classes.scatterChartLegendImageContainer} style={{ marginBottom: '10px' }}>
              <img
                style={{
                  width: '22px',
                  height: '20px',
                }}
                src={makeURL(`/images/selected_brand_scatter_indicator.png`)}
              />
              <Typography component="span" variant="body1" className={classes.scatterLegend}>
                {targetCompanyNameLimited}
              </Typography>
            </Box>
            <Box className={classes.scatterChartLegendImageContainer}>
              <img
                style={{
                  width: '17px',
                  height: '17px',
                  margin: '1.5px 2.5px',
                }}
                src={makeURL(`/images/set_average_scatter_indicator.png`)}
              />
              <Typography component="span" variant="body1" className={classes.scatterLegend}>
                Competitive Set Average
              </Typography>
            </Box>
          </Box>
        </Box>
        <ScatterChart
          width={chartHeightWidth.width}
          height={chartHeightWidth.height}
          margin={{
            top: 20,
            right: 20,
            bottom: 20,
            left: 20,
          }}>
          <CartesianGrid stroke="#666666" strokeOpacity={0.3} />
          <XAxis type="number" dataKey="x" name={XYAxisLabels.x} unit="%" domain={[50, 80]} tickCount={7} />
          <YAxis type="number" dataKey="y" name={XYAxisLabels.y} unit="%" domain={[50, 70]} tickCount={5} />
          <ZAxis dataKey="z" range={[200, 400]} />
          <Tooltip cursor={{ strokeDasharray: '3 3' }} content={props => <CustomTooltip {...props} />} />
          <Scatter
            name={targetCompanyNameLimited}
            data={[{ ...scatterRecodedData.targetMatch.position, targetName: targetCompanyNameLimited }]}
            dataKey={targetCompanyNameLimited}
            shape={props => (
              <CustomScatterShape
                {...props}
                defaultMatchPosition={configuredMatches.targetMatch.position}
                flyAwayActivated={flyAwayActivated}
                companyName={targetCompanyNameLimited}
                shape="star"
              />
            )}
          />
          {scatterRecodedData.otherCompaniesMatch.map((otherCompany, index) => {
            if(typeof configuredMatches.otherCompaniesMatch[index] === 'undefined') return null;
            
            const otherCompanyName = otherCompany.name;
            return (
              <Scatter
                key={index}
                name={otherCompanyName}
                data={[{ ...otherCompany.position, targetName: otherCompanyName }]}
                fill="#19989B"
                className="scatter-element-shadow"
                shape={props => (
                  <CustomScatterShape
                    {...props}
                    defaultMatchPosition={configuredMatches.otherCompaniesMatch[index].position}
                    flyAwayActivated={flyAwayActivated}
                    companyName={otherCompanyName}
                    shape="logo"
                  />
                )}
                filter="drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))"
              />
            );
          })}
          <ReferenceLine y={60} stroke="#666666" strokeWidth={2} strokeOpacity={0.5} />
          <ReferenceLine x={70} stroke="#666666" strokeWidth={2} strokeOpacity={0.5} />
          <ReferenceLine
            stroke="#666666"
            strokeOpacity={0.5}
            strokeWidth={2}
            segment={[
              { x: 70, y: 60 },
              { x: 80, y: 70 },
            ]}
          />
        </ScatterChart>
      </div>
    </>
  );
};

const CustomScatterShape = props => {
  let { x, y, xAxis, yAxis, companyName, flyAwayActivated, defaultMatchPosition, shape } = props;

  if (companyName === 'Set Average') shape = 'cross';

  const defaultMatchPositionX = xAxis.scale(defaultMatchPosition.x);
  const defaultMatchPositionY = yAxis.scale(defaultMatchPosition.y);
  const defaultMatchPositionIsDifferent =
    Math.abs(defaultMatchPositionX - x) > 20 || Math.abs(defaultMatchPositionY - y) > 20;
  const shouldMakePath = flyAwayActivated && defaultMatchPositionIsDifferent;

  switch (shape) {
    case 'logo':
      const imageSize = 30;
      const imageOffset = 5;
      const circleOffset = -10;
      const circleSizeOffset = 7;
      const circleRadius = imageSize / 2 + circleSizeOffset;
      const smallCircleRadius = 5;
      return (
        <g>
          {shouldMakePath ? (
            <>
              {/* Circle pointing to real location */}
              <circle cx={defaultMatchPositionX} cy={defaultMatchPositionY} r={smallCircleRadius} fill="lightgray" />

              {/* Dashed line between real location and fly away location */}
              <line
                x1={x + imageOffset}
                y1={y + imageOffset}
                x2={defaultMatchPositionX}
                y2={defaultMatchPositionY}
                stroke="lightgray"
                strokeWidth="1"
                strokeDasharray="5"
              />
            </>
          ) : null}

          <g className="scatter-shape-logo-container">
            <circle
              cx={x - circleOffset}
              cy={y - circleOffset}
              r={circleRadius}
              fill="lightgray"
              opacity="0.7"
              stroke="black"
              strokeWidth="1px"
              className="scatter-shape-circle scatter-element-shadow"
            />
            <image
              className="scatter-shape-image"
              x={x - imageOffset}
              y={y - imageOffset}
              xlinkHref={makeURL(`/images/company_logos/${companyName}.png`)}
              width={imageSize}
              height={imageSize}
            />
          </g>
        </g>
      );

    case 'star':
      const starImageSize = 30;
      const starImageOffset = 9;
      const starSmallCircleRadius = 5;
      return (
        <g>
          {shouldMakePath ? (
            <>
              {/* Circle pointing to real location */}
              <circle cx={defaultMatchPositionX} cy={defaultMatchPositionY} r={starSmallCircleRadius} fill="lightgray" />

              {/* Dashed line between real location and fly away location */}
              <line
                x1={x + starImageOffset}
                y1={y + starImageOffset}
                x2={defaultMatchPositionX}
                y2={defaultMatchPositionY}
                stroke="lightgray"
                strokeWidth="1"
                strokeDasharray="5"
              />
            </>
          ) : null}

          <g className="scatter-shape-logo-container scatter-element-shadow">
            <image
              className="scatter-shape-image"
              x={x - starImageOffset}
              y={y - starImageOffset}
              xlinkHref={makeURL(`/images/selected_brand_scatter_indicator.png`)}
              width={starImageSize}
              height={starImageSize}
            />
          </g>
        </g>
      );
    case 'cross':
      const crossImageSize = 30;
      const crossImageOffset = 5;
      return (
        <g className="scatter-shape-logo-container">
          <image
            className="scatter-shape-image"
            x={x - crossImageOffset}
            y={y - crossImageOffset}
            xlinkHref={makeURL(`/images/set_average_scatter_indicator.png`)}
            width={crossImageSize}
            height={crossImageSize}
          />
        </g>
      );

    default:
      return null;
  }
};

const CustomTooltip = props => {
  const { active, payload } = props;
  if (active && payload && payload.length) {
    const valuesStyle = {
      fontWeight: 300,
      fontSize: '15px',
    };
    return (
      <Box padding="15px" borderRadius="5px" style={{ backgroundColor: 'white' }}>
        <p style={{ ...valuesStyle, marginBottom: '5px' }}>
          <strong>{payload[0].payload.targetName}</strong>
        </p>
        <p style={valuesStyle}>
          {payload[0].name}: <strong>{payload[0].payload.realX}%</strong>
        </p>
        <p style={valuesStyle}>
          {payload[1].name}: <strong>{payload[1].payload.realY}%</strong>
        </p>
      </Box>
    );
  }

  return null;
};

export default SimpleScatterChart;
