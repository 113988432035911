import { USA_States } from '../config/questions';

export const dataCodeFields = {
  Country: [
    {
      text: 'Afghanistan',
      value: 1,
    },
    {
      text: 'Albania',
      value: 2,
    },
    {
      text: 'Algeria',
      value: 3,
    },
    {
      text: 'Andorra',
      value: 4,
    },
    {
      text: 'Angola',
      value: 5,
    },
    {
      text: 'Antigua and Barbuda',
      value: 6,
    },
    {
      text: 'Argentina',
      value: 7,
    },
    {
      text: 'Armenia',
      value: 8,
    },
    {
      text: 'Australia',
      value: 9,
    },
    {
      text: 'Austria',
      value: 10,
    },
    {
      text: 'Azerbaijan',
      value: 11,
    },
    {
      text: 'Bahamas',
      value: 12,
    },
    {
      text: 'Bahrain',
      value: 13,
    },
    {
      text: 'Bangladesh',
      value: 14,
    },
    {
      text: 'Barbados',
      value: 15,
    },
    {
      text: 'Belarus',
      value: 16,
    },
    {
      text: 'Belgium',
      value: 17,
    },
    {
      text: 'Belize',
      value: 18,
    },
    {
      text: 'Benin',
      value: 19,
    },
    {
      text: 'Bhutan',
      value: 20,
    },
    {
      text: 'Bolivia',
      value: 21,
    },
    {
      text: 'Bosnia and Herzegovina',
      value: 22,
    },
    {
      text: 'Botswana',
      value: 23,
    },
    {
      text: 'Brazil',
      value: 24,
    },
    {
      text: 'Brunei Darussalam',
      value: 25,
    },
    {
      text: 'Bulgaria',
      value: 26,
    },
    {
      text: 'Burkina Faso',
      value: 27,
    },
    {
      text: 'Burundi',
      value: 28,
    },
    {
      text: 'Cambodia',
      value: 29,
    },
    {
      text: 'Cameroon',
      value: 30,
    },
    {
      text: 'Canada',
      value: 31,
    },
    {
      text: 'Cape Verde',
      value: 32,
    },
    {
      text: 'Central African Republic',
      value: 33,
    },
    {
      text: 'Chad',
      value: 34,
    },
    {
      text: 'Chile',
      value: 35,
    },
    {
      text: 'China',
      value: 36,
    },
    {
      text: 'Colombia',
      value: 37,
    },
    {
      text: 'Comoros',
      value: 38,
    },
    {
      text: 'Congo, Republic of the...',
      value: 39,
    },
    {
      text: 'Costa Rica',
      value: 40,
    },
    {
      text: "Côte d'Ivoire",
      value: 41,
    },
    {
      text: 'Croatia',
      value: 42,
    },
    {
      text: 'Cuba',
      value: 43,
    },
    {
      text: 'Cyprus',
      value: 44,
    },
    {
      text: 'Czech Republic',
      value: 45,
    },
    {
      text: 'Democratic Republic of the Congo',
      value: 47,
    },
    {
      text: 'Denmark',
      value: 48,
    },
    {
      text: 'Djibouti',
      value: 49,
    },
    {
      text: 'Dominica',
      value: 50,
    },
    {
      text: 'Dominican Republic',
      value: 51,
    },
    {
      text: 'Ecuador',
      value: 52,
    },
    {
      text: 'Egypt',
      value: 53,
    },
    {
      text: 'El Salvador',
      value: 54,
    },
    {
      text: 'Equatorial Guinea',
      value: 55,
    },
    {
      text: 'Eritrea',
      value: 56,
    },
    {
      text: 'Estonia',
      value: 57,
    },
    {
      text: 'Ethiopia',
      value: 58,
    },
    {
      text: 'Fiji',
      value: 59,
    },
    {
      text: 'Finland',
      value: 60,
    },
    {
      text: 'France',
      value: 61,
    },
    {
      text: 'Gabon',
      value: 62,
    },
    {
      text: 'Gambia',
      value: 63,
    },
    {
      text: 'Georgia',
      value: 64,
    },
    {
      text: 'Germany',
      value: 65,
    },
    {
      text: 'Ghana',
      value: 66,
    },
    {
      text: 'Greece',
      value: 67,
    },
    {
      text: 'Grenada',
      value: 68,
    },
    {
      text: 'Guatemala',
      value: 69,
    },
    {
      text: 'Guinea',
      value: 70,
    },
    {
      text: 'Guinea-Bissau',
      value: 71,
    },
    {
      text: 'Guyana',
      value: 72,
    },
    {
      text: 'Haiti',
      value: 73,
    },
    {
      text: 'Honduras',
      value: 74,
    },
    {
      text: 'Hong Kong (S.A.R.)',
      value: 75,
    },
    {
      text: 'Hungary',
      value: 76,
    },
    {
      text: 'Iceland',
      value: 77,
    },
    {
      text: 'India',
      value: 78,
    },
    {
      text: 'Indonesia',
      value: 79,
    },
    {
      text: 'Iran',
      value: 80,
    },
    {
      text: 'Iraq',
      value: 81,
    },
    {
      text: 'Ireland',
      value: 82,
    },
    {
      text: 'Israel',
      value: 83,
    },
    {
      text: 'Italy',
      value: 84,
    },
    {
      text: 'Jamaica',
      value: 85,
    },
    {
      text: 'Japan',
      value: 86,
    },
    {
      text: 'Jordan',
      value: 87,
    },
    {
      text: 'Kazakhstan',
      value: 88,
    },
    {
      text: 'Kenya',
      value: 89,
    },
    {
      text: 'Kiribati',
      value: 90,
    },
    {
      text: 'Kuwait',
      value: 91,
    },
    {
      text: 'Kyrgyzstan',
      value: 92,
    },
    {
      text: "Lao People's Democratic Republic",
      value: 93,
    },
    {
      text: 'Latvia',
      value: 94,
    },
    {
      text: 'Lebanon',
      value: 95,
    },
    {
      text: 'Lesotho',
      value: 96,
    },
    {
      text: 'Liberia',
      value: 97,
    },
    {
      text: 'Libyan Arab Jamahiriya',
      value: 98,
    },
    {
      text: 'Liechtenstein',
      value: 99,
    },
    {
      text: 'Lithuania',
      value: 100,
    },
    {
      text: 'Luxembourg',
      value: 101,
    },
    {
      text: 'Madagascar',
      value: 102,
    },
    {
      text: 'Malawi',
      value: 103,
    },
    {
      text: 'Malaysia',
      value: 104,
    },
    {
      text: 'Maldives',
      value: 105,
    },
    {
      text: 'Mali',
      value: 106,
    },
    {
      text: 'Malta',
      value: 107,
    },
    {
      text: 'Marshall Islands',
      value: 108,
    },
    {
      text: 'Mauritania',
      value: 109,
    },
    {
      text: 'Mauritius',
      value: 110,
    },
    {
      text: 'Mexico',
      value: 111,
    },
    {
      text: 'Micronesia, Federated States of...',
      value: 112,
    },
    {
      text: 'Monaco',
      value: 113,
    },
    {
      text: 'Mongolia',
      value: 114,
    },
    {
      text: 'Montenegro',
      value: 115,
    },
    {
      text: 'Morocco',
      value: 116,
    },
    {
      text: 'Mozambique',
      value: 117,
    },
    {
      text: 'Myanmar',
      value: 118,
    },
    {
      text: 'Namibia',
      value: 119,
    },
    {
      text: 'Nauru',
      value: 120,
    },
    {
      text: 'Nepal',
      value: 121,
    },
    {
      text: 'Netherlands',
      value: 122,
    },
    {
      text: 'New Zealand',
      value: 123,
    },
    {
      text: 'Nicaragua',
      value: 124,
    },
    {
      text: 'Niger',
      value: 125,
    },
    {
      text: 'Nigeria',
      value: 126,
    },
    {
      text: 'North Korea',
      value: 127,
    },
    {
      text: 'Norway',
      value: 128,
    },
    {
      text: 'Oman',
      value: 129,
    },
    {
      text: 'Pakistan',
      value: 130,
    },
    {
      text: 'Palau',
      value: 131,
    },
    {
      text: 'Panama',
      value: 132,
    },
    {
      text: 'Papua New Guinea',
      value: 133,
    },
    {
      text: 'Paraguay',
      value: 134,
    },
    {
      text: 'Peru',
      value: 135,
    },
    {
      text: 'Philippines',
      value: 136,
    },
    {
      text: 'Poland',
      value: 137,
    },
    {
      text: 'Portugal',
      value: 138,
    },
    {
      text: 'Qatar',
      value: 139,
    },
    {
      text: 'Republic of Moldova',
      value: 141,
    },
    {
      text: 'Romania',
      value: 142,
    },
    {
      text: 'Russian Federation',
      value: 143,
    },
    {
      text: 'Rwanda',
      value: 144,
    },
    {
      text: 'Saint Kitts and Nevis',
      value: 145,
    },
    {
      text: 'Saint Lucia',
      value: 146,
    },
    {
      text: 'Saint Vincent and the Grenadines',
      value: 147,
    },
    {
      text: 'Samoa',
      value: 148,
    },
    {
      text: 'San Marino',
      value: 149,
    },
    {
      text: 'Sao Tome and Principe',
      value: 150,
    },
    {
      text: 'Saudi Arabia',
      value: 151,
    },
    {
      text: 'Senegal',
      value: 152,
    },
    {
      text: 'Serbia',
      value: 153,
    },
    {
      text: 'Seychelles',
      value: 154,
    },
    {
      text: 'Sierra Leone',
      value: 155,
    },
    {
      text: 'Singapore',
      value: 156,
    },
    {
      text: 'Slovakia',
      value: 157,
    },
    {
      text: 'Slovenia',
      value: 158,
    },
    {
      text: 'Solomon Islands',
      value: 159,
    },
    {
      text: 'Somalia',
      value: 160,
    },
    {
      text: 'South Africa',
      value: 161,
    },
    {
      text: 'South Korea',
      value: 162,
    },
    {
      text: 'Spain',
      value: 163,
    },
    {
      text: 'Sri Lanka',
      value: 164,
    },
    {
      text: 'Sudan',
      value: 165,
    },
    {
      text: 'Suriname',
      value: 166,
    },
    {
      text: 'Swaziland',
      value: 167,
    },
    {
      text: 'Sweden',
      value: 168,
    },
    {
      text: 'Switzerland',
      value: 169,
    },
    {
      text: 'Syrian Arab Republic',
      value: 170,
    },
    {
      text: 'Tajikistan',
      value: 171,
    },
    {
      text: 'Thailand',
      value: 172,
    },
    {
      text: 'The former Yugoslav Republic of Macedonia',
      value: 173,
    },
    {
      text: 'Timor-Leste',
      value: 174,
    },
    {
      text: 'Togo',
      value: 175,
    },
    {
      text: 'Tonga',
      value: 176,
    },
    {
      text: 'Trinidad and Tobago',
      value: 177,
    },
    {
      text: 'Tunisia',
      value: 178,
    },
    {
      text: 'Turkey',
      value: 179,
    },
    {
      text: 'Turkmenistan',
      value: 180,
    },
    {
      text: 'Tuvalu',
      value: 181,
    },
    {
      text: 'Uganda',
      value: 182,
    },
    {
      text: 'Ukraine',
      value: 183,
    },
    {
      text: 'United Arab Emirates',
      value: 184,
    },
    {
      text: 'United Kingdom of Great Britain and Northern Ireland',
      value: 185,
    },
    {
      text: 'United Republic of Tanzania',
      value: 186,
    },
    {
      text: 'United States of America',
      value: 187,
    },
    {
      text: 'Uruguay',
      value: 188,
    },
    {
      text: 'Uzbekistan',
      value: 189,
    },
    {
      text: 'Vanuatu',
      value: 190,
    },
    {
      text: 'Venezuela, Bolivarian Republic of...',
      value: 191,
    },
    {
      text: 'Viet Nam',
      value: 192,
    },
    {
      text: 'Yemen',
      value: 193,
    },
    {
      text: 'Zambia',
      value: 580,
    },
    {
      text: 'Zimbabwe',
      value: 1357,
    },
  ],
  City: null,
  State: USA_States,
  Region: null,
  ZIP: null,
  Location: [
    {
      text: 'Urban',
      value: 1,
    },
    {
      text: 'Suburban',
      value: 2,
    },
    {
      text: 'Rural',
      value: 3,
    },
  ],
  Age: null,
  Gender: [
    {
      text: 'Male',
      value: 1,
    },
    {
      text: 'Female',
      value: 2,
    },
    {
      text: 'Trans male',
      value: 3,
    },
    {
      text: 'Trans female',
      value: 4,
    },
    {
      text: 'Preferred not to state',
      value: 5,
    },
    {
      text: 'Preferred identity',
      value: 6,
    },
  ],
  Education: [
    {
      text: 'High school or less',
      value: 1,
    },
    {
      text: 'Some college/Associate Degree',
      value: 2,
    },
    {
      text: "Bachelor's Degree",
      value: 3,
    },
    {
      text: "Master's Degree/ Some graduate school",
      value: 4,
    },
    {
      text: 'Doctoral and/or Professional Degree (e.g. Ph.D., JD, MD)',
      value: 5,
    },
  ],
  Ethnicity: [
    {
      text: 'Caucasian',
      value: 1,
    },
    {
      text: 'Native American',
      value: 2,
    },
    {
      text: 'Hispanic',
      value: 3,
    },
    {
      text: 'African American',
      value: 4,
    },
    {
      text: 'Asian',
      value: 5,
    },
    {
      text: 'Pacific Islander',
      value: 6,
    },
    {
      text: 'Other',
      value: 7,
    },
  ],
  Marital_status: [
    {
      text: 'Married or living with a partner without children',
      value: 1,
    },
    {
      text: 'Married or living with a partner with children',
      value: 2,
    },
    {
      text: 'Widowed',
      value: 3,
    },
    {
      text: 'Divorced',
      value: 4,
    },
    {
      text: 'Separated',
      value: 5,
    },
    {
      text: 'Never married',
      value: 6,
    },
  ],
  Income: [
    {
      text: 'Less than $10,000',
      value: 1,
    },
    {
      text: '$10,000 - $24,999',
      value: 2,
    },
    {
      text: '$25,000 - $34,999',
      value: 3,
    },
    {
      text: '$35,000 – $49,999',
      value: 4,
    },
    {
      text: '$50,000 - $74,999',
      value: 5,
    },
    {
      text: '$75,000 - $99,999',
      value: 6,
    },
    {
      text: '$100,000 - $149,999',
      value: 7,
    },
    {
      text: '$150,000 - $199,999',
      value: 8,
    },
    {
      text: '$200,000 +',
      value: 9,
    },
  ],
  Homeownership: [
    {
      text: 'Rent on my own/with related individuals',
      value: 1,
    },
    {
      text: 'Rent with unrelated individuals',
      value: 2,
    },
    {
      text: 'Live with someone else and don’t pay for housing',
      value: 3,
    },
    {
      text: 'Own a home and don’t have any debt on it',
      value: 4,
    },
    {
      text: 'Have a mortgage on my home',
      value: 5,
    },
    {
      text: 'Other',
      value: 6,
    },
  ],

  Value: null,
  Interest: null,
  Goal: null,
  Problem: null,
  Brand: null,
  Influencers: null,
};

export const socialMedia = {
  platforms: [
    {
      value: 1,
      text: 'Facebook',
    },
    {
      value: 2,
      text: 'Instagram',
    },
    {
      text: 'Linkedin',
      value: 3,
    },
    {
      value: 4,
      text: 'Youtube',
    },
    {
      text: 'Twitter',
      value: 5,
    },
    {
      text: 'TikTok',
      value: 6,
    },
    {
      value: 7,
      text: 'Snapchat',
    },
    {
      value: 8,
      text: 'Other',
    },
  ],
};

export const industryDataCodes = [
  {
    name: "Consumer Services",
    id: 1
  },
  {
    name: "Retail",
    id: 2
  },
  {
    name: "Financial Services",
    id: 3
  },
  {
    name: "Technology",
    id: 4
  },
  {
    name: "Telecom",
    id: 5
  },
  {
    name: "Consumer Products",
    id: 6
  },
  {
    name: "Media And Entertainment",
    id: 7
  },
  {
    name: "Hospitality",
    id: 8
  },
  {
    name: "Apparel",
    id: 9
  },
  {
    name: "Food Service",
    id: 10
  },
  {
    name: "Automotive",
    id: 11
  },
  {
    name: "Luxury",
    id: 12
  },
  {
    name: "Other",
    id: 20
  }
];