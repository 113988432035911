import React, { useImperativeHandle } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';

const useStyles = (location) =>
  makeStyles((theme) => {
    let isDashboard =
      location.indexOf('/brand-personas') !== -1 ||
      location.indexOf('/social-media') !== -1 ||
      location.indexOf('/buyer-personas') !== -1 ||
      location.indexOf('/competitive-set-brands') !== -1 ||
      location.indexOf('/consumer-dash') !== -1 ||
      location.indexOf('/create-new') !== -1 ||
      location.indexOf('/home') !== -1;
    let paddingDefault = isDashboard ? 0 : '30px 15px';
    let paddingMD = isDashboard ? 0 : { paddingLeft: 30, paddingRight: 30 };
    let paddingLG = isDashboard ? 0 : { paddingLeft: 50, paddingRight: 50 };
    let paddingXL = isDashboard ? 0 : { paddingLeft: 65, paddingRight: 65 };
    let paddingSM = isDashboard ? 0 : { paddingLeft: 10, paddingRight: 10 };

    return {
      appMainContent: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        boxSizing: 'border-box',
        padding: paddingDefault,
        [theme.breakpoints.up('md')]: paddingMD,
        [theme.breakpoints.up('lg')]: paddingLG,
        [theme.breakpoints.up('xl')]: paddingXL,
        [theme.breakpoints.down('sm')]: paddingSM,
      },
    };
  });

const CmtContent = React.forwardRef(function LayoutContent(props, ref) {
  const { children } = props;

  useImperativeHandle(ref, () => ({}));

  const contentRef = React.createRef();

  const history = useHistory();
  const classes = useStyles(history.location.pathname)();

  return (
    <Box
      ref={contentRef}
      className={clsx(classes.appMainContent, 'Cmt-appMainContent')}
      bgcolor='background.main'
    >
      {children}
    </Box>
  );
});

export default CmtContent;
CmtContent.defaultProps = {
  name: 'LayoutContent',
};
