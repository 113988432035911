import React from 'react';
import PageContainer from '../../../myComponents/pageContainer';

const ContactUs = () => {
  return (
    <PageContainer heading="Contact us page">
      <h1>See your Contact us!</h1>
    </PageContainer>
  );
};

export default ContactUs;
