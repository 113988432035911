import { getUserInitials } from '../../helperFunctions';
import { Avatar, Menu, MenuItem, makeStyles } from '@material-ui/core';
import { useState } from 'react';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  container: {
    marginRight: '20px',
  },
  avatar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '8px',
    cursor: 'pointer',
    color: '#000',
  },
  avatarBadge: {
    width: '40px',
    height: '40px',
    fontSize: '18px',
    backgroundColor: '#82c2c3',
    '@media (max-width: 768px)': {
      width: '32px',
      height: '32px',
      fontSize: '15px',
    },
  },
  logoutLink: {
    fontWeight: 700,
    color: '#19989B !important',
  },
  menuItem: {
    '& a': {
      color: '#000',
    },
  },
}));

const UserDropdown = () => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const username = useSelector((state) => state?.auth?.user?.displayName);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const dropdownData = [
    {
      id: 1,
      text: 'Account Details',
      link: 'https://www.brandsocialvalue.com/my-account/',
    },
    {
      id: 2,
      text: 'Orders',
      link: 'https://www.brandsocialvalue.com/my-account/orders/',
    },
    {
      id: 3,
      text: 'Subscriptions',
      link: 'https://www.brandsocialvalue.com/my-account/subscriptions/',
    },
    {
      id: 4,
      text: 'Address',
      link: 'https://www.brandsocialvalue.com/my-account/edit-address/',
    },
    {
      id: 5,
      text: 'Payment Methods',
      link: 'https://www.brandsocialvalue.com/my-account/payment-methods/',
    },
    {
      id: 6,
      text: 'Log out',
      link: 'https://www.brandsocialvalue.com/my-account',
    },
  ];

  return (
    <div className={classes.container}>
      <div
        className={classes.avatar}
        id='basic-button'
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        {username}
        <Avatar className={classes.avatarBadge}>
          {getUserInitials(username)}
        </Avatar>
      </div>
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {dropdownData.map((item) => (
          <MenuItem
            key={item.id}
            onClick={handleClose}
            className={classes.menuItem}
          >
            <a
              href={item.link}
              target='_blank'
              className={item.id === 6 ? classes.logoutLink : ''}
            >
              {item.text}
            </a>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default UserDropdown;
