import React from 'react';

import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { surveyCardText } from '../../config/surveyCardText';

import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
  mainContainer: props => ({
    maxWidth: '380px',
    borderRadius: '5px',
    padding: '20px 30px',
    background:
      props.type === 'brandPersona'
        ? 'linear-gradient(202.05deg, #3EB6B9 14.41%, #334959 100%)'
        : props.type === 'buyerPersona'
        ? 'linear-gradient(202.05deg, #B8994A 14.41%, #1E2F3C 100%)'
        : 'linear-gradient(202.05deg, #DF3371 14.41%, #1E2F3C 100%)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    boxShadow: '0px 2px 2px 1px #B4B4B4',
    '@media (max-width: 1000px)': {
      maxWidth: '100% !important',
      margin: '0 auto',
    },
    '@media (max-width: 700px)': {
      maxWidth: '100% !important',
    },
  }),
  icon: {
    width: '40px',
    height: '40px',
    marginBottom: '20px',
  },
  primaryTitleText: {
    color: 'white',
    fontSize: '30px',
    fontWeight: 200,
    textAlign: 'center',
  },
  secondaryTitleText: {
    color: 'white',
    fontSize: '25px',
    fontWeight: 200,
    textAlign: 'center',
  },
  bodyText: {
    color: 'white',
    fontSize: '13px',
    fontWeight: 200,
    textAlign: 'center',
    marginBottom: '20px',
  },
  createNewButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    padding: '5px 15px 5px 15px',
    backgroundColor: 'white',
    borderRadius: '5px',
    margin: '0',
    '&:hover': {
      backgroundColor: '#ebe8e8',
    },
  },
  createNewButtonText: {
    fontSize: '18px',
    margin: '0',
    color: theme.palette.background.customPrimary,
    fontWeight: 800,
  },
}));

const CreateSurveyCard = ({ type, createNewHandler }) => {
  const classes = useStyles({ type });
  return (
    <Box className={classes.mainContainer}>
      <img alt="survey type tag" src={surveyCardText[type].icon} className={classes.icon} />

      <Typography component="div" variant="h2" className={classes.primaryTitleText}>
        {surveyCardText[type].titlePrimary}
      </Typography>

      {surveyCardText[type].titleSecondary && (
        <Typography component="div" variant="h3" className={classes.secondaryTitleText}>
          {surveyCardText[type].titleSecondary}
        </Typography>
      )}

      <Box marginBottom="20px"></Box>

      <Typography component="div" variant="body1" className={classes.bodyText}>
        {surveyCardText[type].bodyPrimary}
      </Typography>

      <Typography component="div" variant="body1" className={classes.bodyText}>
        {surveyCardText[type].bodySecondary}
      </Typography>

      <Button onClick={createNewHandler} className={classes.createNewButton}>
        <Typography component="div" variant="button" className={classes.createNewButtonText}>
          CREATE NEW
        </Typography>
      </Button>
    </Box>
  );
};

export default CreateSurveyCard;
