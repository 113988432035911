import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Typography,
  Radio,
  RadioGroup,
  FormControl,
} from '@material-ui/core';
import { addNameToText } from '../helperFunctions';
import {
  convert_normalize,
  convert_reverse_coded_normalize,
} from '../../../helperFunctions';
import useViewportWidth from '../../../myHooks/useViewportWidth';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    textAlign: 'center',
    width: '100%',
    margin: '0 auto',
    overflowX: 'auto',
    '@media (max-width:1025px)': {
      margin: 0,
    },
  },
  text: {
    color: theme.palette.text.primary,
    fontWeight: 300,
    fontSize: '18px',
    marginBottom: '40px',
    maxWidth: '800px',
    margin: '0 auto 40px auto',
    '@media (max-width:600px)': {
      fontSize: '16px',
      textAlign: "left"
    },
  },
  optionsText: {
    color: theme.palette.text.primary,
    fontWeight: 300,
    fontSize: '15px',
    padding: '11px 0',
    marginRight: '8px',
    flex: 1,
    textAlign: 'left',
  },
  overflowWrapper: {
    // overflowX: 'scroll',
    width: 'fit-content',
    margin: '0 auto',
  },
  formControlLabels: {
    color: theme.palette.text.primary,
    fontWeight: 300,
    fontSize: '14px',
    maxWidth: '95px',
  },
  formControl: {
    width: '400px',
  },
  radioGroup: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const RadioGroupFormElement = (props) => {
  const initialSateHandle = () => {
    let obj = {};
    for (let key of props.id) {
      const value =
        typeof props.value[key] !== undefined ? props.value[key] : '';

      if (value !== '') {
        if (
          key.indexOf('InterExter_1') !== -1 ||
          key.indexOf('InterExter_2') !== -1
        ) {
          obj[key] = convert_reverse_coded_normalize(value);
        } else {
          obj[key] = convert_normalize(value);
        }
      } else {
        obj[key] = value;
      }
    }
    return obj;
  };
  const [selectedValue, setSelectedValue] = useState(initialSateHandle());
  const classes = useStyles();
  const width = useViewportWidth();

  useEffect(() => {
    setSelectedValue(initialSateHandle());
  }, [props.id]);

  const handleChange = (event, id) => {
    setSelectedValue((prevState) => ({
      ...prevState,
      [id]: event.target.value,
    }));
    props.handleSurveyAnswers(event.target.value, id);
  };

  const isSocialInovat = props.id[0] === 'Brand_SocialInovation_1';
  const isLeftRightScale =
    typeof props.scale !== 'undefined' && props.scale[0] === 'Left';

  return (
    <Box className={classes.mainContainer}>
      <Box
        style={{
          minWidth: isSocialInovat ? "708px" : "632px"
        }}>
        <Typography component='div' variant='h2' className={classes.text}>
          {addNameToText(props.text, props.name)}
        </Typography>
        <Box>
          <Box
            width='100%'
            display='flex'
            justifyContent='center'
            marginBottom='10px'
          >
            <Box
              display='flex'
              justifyContent={isLeftRightScale ? 'space-between' : 'center'}
              alignItems='center'
              marginLeft={
                isSocialInovat ? '308px' : '198px'
              }
              width='460px'
            >
              {props.scale.map((scaleElement, index1) => {
                return (
                  <Typography
                    component='div'
                    variant='body2'
                    className={classes.formControlLabels}
                    style={{
                      margin: props.scale[0] === 'None at all' ? '0 24px' : '0 19px'
                    }}
                    key={index1}
                  >
                    {scaleElement}
                  </Typography>
                );
              })}
            </Box>
          </Box>
          <Box
            display='flex'
            flexDirection='column'
            width='fit-content'
            margin='0 auto'
          >
            {props.options.map((option, index1) => {
              return (
                <Box display='flex' key={index1} alignItems='center'>
                  <Typography
                    key={index1}
                    component='div'
                    variant='body2'
                    className={classes.optionsText}
                    style={{
                      width: isSocialInovat ? '300px' : '190px',
                    }}
                  >
                    {addNameToText(option, props.name)}
                  </Typography>
                  <FormControl className={classes.formControl}>
                    <RadioGroup
                      row
                      aria-labelledby='demo-row-radio-buttons-group-label'
                      name='row-radio-buttons-group'
                      className={classes.radioGroup}
                      value={
                        typeof selectedValue[props.id[index1]] !== 'undefined'
                          ? selectedValue[props.id[index1]] + ''
                          : ''
                      }
                    >
                      {[...new Array(props.scale.length)].map(
                        (radioElement, index2) => {
                          return (
                            <Radio
                              value={index2 + 1 + ''}
                              onChange={(event) =>
                                handleChange(event, props.id[index1])
                              }
                              name='radio-button'
                              key={index2}
                              style={{
                                color: '#19989B',
                                width: '42px',
                              }}
                            />
                          );
                        }
                      )}
                    </RadioGroup>
                  </FormControl>
                </Box>
              );
            })}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default RadioGroupFormElement;
