import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import { Box, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { addNameToText } from "../helperFunctions";
import { cos } from "@amcharts/amcharts4/.internal/core/utils/Math";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    width: "fit-content",
    margin: "0 auto",

    "@media (max-width: 768px)": {
      width: "100%",
    },
  },
  text: {
    color: theme.palette.text.primary,
    fontWeight: 300,
    fontSize: "18px",
    marginBottom: "15px",
    maxWidth: "800px",
    margin: "0 auto",
    textAlign: "center",
    "@media (max-width: 600px)": {
      fontSize: "16px",
      marginInline: 0,
    },
  },
  buttons: {
    padding: "10px 20px",
    marginBottom: "20px",
    border: "2px solid #A6A6A6",
    borderRadius: "5px",
    width: "100%",
    maxWidth: "380px",
  },

  buttonsWrapper: (props) => ({
    "@media (max-width: 1300px)": {
      maxHeight: props.isPlatformquestion ? "300px" : props.maxHeight,
      width: "100%",
    },
    "@media (max-width: 1000px)": {
      maxHeight: "unset",
      width: "100%",
    },
  }),
}));

const ButtonGroup = (props) => {
  const [selectedButton, setSelectedButton] = useState(props.value || null);
  const isProblematicWidthQuestion = (props.id === "Education" || props.id === "Income");
  const isIncomeQuestion = props.id === "Income";
  const isPlatformquestion = props.id === "Post_Social_Media";
  const classes = useStyles({ isProblematicWidthQuestion, isPlatformquestion});

  useEffect(() => {
    setSelectedButton(props.value || null);
  }, [props.id]);

  const onClickHandler = (value) => {
    setSelectedButton(value);
    props.handleSurveyAnswers(value, props.id);
  };

  //Used so that the container divides number of colmns correctly
  const optionsContainerMaxHeight =
    props.options.length % 2 === 1 ? "275px" : "260px";
    
  const buttonsWrapperMaxHeight = !isProblematicWidthQuestion ? optionsContainerMaxHeight : isIncomeQuestion ? "350px" : "unset";

  return (
    <Box className={classes.mainContainer}>
      <Typography component="div" variant="h2" className={classes.text}>
        {addNameToText(props.text, props.name)}
      </Typography>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        margin="40px auto 0 auto"
        width="fit-content"
        maxWidth="100%"
        maxHeight={buttonsWrapperMaxHeight}
        className={classes.buttonsWrapper}
        flexWrap="wrap"
        alignContent="center"
        style={{ columnGap: "25px" }}
      >
        {props.options.map((e, index) => {
          return (
            <Button
              key={index}
              onClick={() => onClickHandler(e.value)}
              className={classes.buttons}
              style={{
                backgroundColor:
                  e.value === selectedButton ? "#19989B" : "white",
                border: `2px solid ${
                  e.value === selectedButton ? "#19989B" : "#A6A6A6"
                }`,
              }}
            >
              <p
                style={{
                  margin: 0,
                  color: e.value === selectedButton ? "white" : "black",
                  textTransform: "none",
                  fontWeight: 300,
                  fontSize: "14px",
                  letterSpacing: "1px",
                }}
              >
                {e.text}
              </p>
            </Button>
          );
        })}
      </Box>
    </Box>
  );
};

export default ButtonGroup;
