import { socialMedia } from '../config/dataCodes';

export const getSurveysWithExtractedFields = (_DATA, dataExists, type, brandPersonas) => {
  switch (type) {
    case 'brand':
      return dataExists
        ? _DATA.currentData().map(survey => ({
            Name: survey.Name,
            lastEdited: survey.lastEdited,
            dateCreated: survey.dateCreated,
            socialValueMatch: survey.socialValueMatch,
            productBenefitsMatch: survey.productBenefitsMatch,
            totalMatch: survey.totalMatch,
            filter: survey.filter,
            id: survey.id,
          }))
        : null;
    case 'buyer':
      return dataExists
        ? _DATA.currentData().map(survey => ({
            Name: survey.Name,
            lastEdited: survey.lastEdited,
            dateCreated: survey.dateCreated,
            socialValueMatch: survey.socialValueMatch,
            productBenefitsMatch: survey.productBenefitsMatch,
            totalMatch: survey.totalMatch,
            filter: survey.filter,
            id: survey.id,
          }))
        : null;
    case 'social': {
      return dataExists
        ? _DATA.currentData().map(survey => {
            return {
              postTitle: survey.Post_Title,
              lastEdited: survey.lastEdited,
              scheduledDate: survey.Post_Date,
              persona: getPersonaName(survey, brandPersonas),
              platform: getSocialMediaPlatform(survey),
              impact: survey.impact,
              suggestion: survey.suggestion,
              filter: survey.filter,
              id: survey.id,
            };
          })
        : null;
    }
    case 'competitive-brand': {
      return dataExists
        ? _DATA.currentData().map(brandCalcResults => ({
            name: brandCalcResults.name,
            industry: brandCalcResults.industry,
            reputation: brandCalcResults.CReputation,
            relationship: brandCalcResults.CRelationship,
            brandChoice: brandCalcResults.CPurchaseChoice,
            loyalty: brandCalcResults.CLoyalty,
            IE_Focus: brandCalcResults.IE_Focus_Match,
            ideology: brandCalcResults.Ideology_Match,
            causes: brandCalcResults.Issues_Match,
            SV_Match: brandCalcResults.Values_Match,
            PB_Match: brandCalcResults.Product_Match,
            total_Match: brandCalcResults.Total_Match,
            id: brandCalcResults.id,
          }))
        : null;
    }
    default:
      return null;
  }
};

export const getCompetitiveSetBrandsSurveyWithExtractedFields = (_DATA, dataExists, type, brandPersonas, width) => {
  if (width < 991) {
    return dataExists
      ? _DATA.currentData().map(brandCalcResults => ({
          name: brandCalcResults.name,
          industry: brandCalcResults.industry,
          total_Match: brandCalcResults.Total_Match,
          id: brandCalcResults.id,
        }))
      : null;
  }

  if (width > 991 && width < 1600) {
    return dataExists
      ? _DATA.currentData().map(brandCalcResults => ({
          name: brandCalcResults.name,
          industry: brandCalcResults.industry,
          SV_Match: brandCalcResults.Values_Match,
          PB_Match: brandCalcResults.Product_Match,
          total_Match: brandCalcResults.Total_Match,
          id: brandCalcResults.id,
        }))
      : null;
  }

  return dataExists
    ? _DATA.currentData().map(brandCalcResults => ({
        name: brandCalcResults.name,
        industry: brandCalcResults.industry,
        reputation: brandCalcResults.CReputation,
        relationship: brandCalcResults.CRelationship,
        brandChoice: brandCalcResults.CPurchaseChoice,
        loyalty: brandCalcResults.CLoyalty,
        IE_Focus: brandCalcResults.IE_Focus_Match,
        ideology: brandCalcResults.Ideology_Match,
        causes: brandCalcResults.Issues_Match,
        SV_Match: brandCalcResults.Values_Match,
        PB_Match: brandCalcResults.Product_Match,
        total_Match: brandCalcResults.Total_Match,
        id: brandCalcResults.id,
      }))
    : null;
};

const getPersonaName = (survey, brandPersonas) => {
  const selectedPersona = brandPersonas.find(brand => brand.id == survey['Post_Brand_Persona']);

  return selectedPersona.Name;
};

export const getSocialMediaPlatform = ({ Post_Social_Media }) => {
  const { platforms } = socialMedia;

  const selectedPlatform = platforms.find(platform => platform.value == Post_Social_Media);

  return selectedPlatform.text;
};
