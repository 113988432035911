import axios from "axios";
import { getAuth } from "firebase/auth";

const unauthorizedRedirectURL = "https://www.brandsocialvalue.com/my-account/";

const getJwtTokenFromCookies = () => {
  const cookieValue = document.cookie
    .split("; ")
    .find((row) => row.startsWith("mo_firebase_jwt_cookie="))
    ?.split("=")[1];

  return cookieValue;
};

const axiosInstance = axios.create({
  baseURL: process.env.NODE_ENV !== 'production' ? 'http://localhost:8081/' : '',
});

// Set up a request interceptor
axiosInstance.interceptors.request.use(
  async (config) => {
    //If logged in user exists, send its token to backend
    const { currentUser } = getAuth();
    if (currentUser) {
      const idTokenResult = await currentUser.getIdTokenResult(
        /* forceRefresh */ true
      );

      if (idTokenResult.token) {
        //Check if user session is expired
        const authTime = idTokenResult.claims.auth_time * 1000;
        const sessionDuration = 1000 * 60 * 60 * 24 * 1 + 1000 * 30;
        const millisecondsUntilExpiration =
          sessionDuration - (Date.now() - authTime);

        if (millisecondsUntilExpiration > 0) {
          config.headers["Authorization"] = `Bearer ${idTokenResult.token}`;
        }
      }
    }

    const WPFirebaseToken = getJwtTokenFromCookies();
    if (WPFirebaseToken)
      config.headers["Custom-Header"] = `Bearer ${WPFirebaseToken}`;

    return config;
  },
  (error) => {
    // Handle request error here
    console.log("Catch error in axios interceptors", error);
    return Promise.reject(error);
  }
);

// Set up a response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error?.response && error?.response?.status === 401) {
      // User needs to login to WP, redirect to WP my account
      window.location.href = unauthorizedRedirectURL;
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
