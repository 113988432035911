import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, IconButton, Slide, Paper, Typography, TextField, CircularProgress } from '@material-ui/core';
import { Chat, Send, Close } from '@material-ui/icons';

import { AIChatBot } from '../../API/agent';
import {
  getIndustryName,
  getIssuesFromBrandPersona,
  getStateByCode,
  getCountryByCode,
  getEthnicityByCode,
  getGenderByCode,
  getEducationByCode,
  getMaritalStatusByCode,
  makeURL,
} from '../../helperFunctions';

const useStyles = makeStyles(theme => ({
  chatBoubble: {
    position: 'fixed',
    bottom: '20px',
    right: '20px',
    zIndex: '1000',
    width: '70px',
    height: '70px',
    borderRadius: '50%',
    backgroundColor: '#19989B',
    boxSizing: 'border-box',
    padding: '15px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  chatIconButton: {
    opacity: '0.8',
    color: 'white',
    transition: '0.3s ease-in-out',
    '&:hover': {
      opacity: '1',
    },
  },
  chatIcon: {
    fontSize: '30px',
  },
  chatWindow: {
    position: 'fixed',
    bottom: '20px',
    right: '20px',
    width: '90%',
    borderRadius: '16px',
    maxWidth: '500px',
    boxShadow: '1px 1px 3px 0px #737272',
    zIndex: 1000,
  },
  chatBox: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '80vh',
    border: '4px solid #19989B',
    borderRadius: '14px',
  },
  chatHeader: {
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    alignItems: 'center',
    padding: '8px 12px',
    backgroundColor: '#19989B',
    position: 'relative',
  },
  avatarContainer: {
    width: 56,
    height: 56,
    backgroundColor: '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
  },
  avatar: {
    width: '85%',
    height: '60%',
    borderRadius: '5px',
  },
  closeChatButton: {
    position: 'absolute',
    top: '5px',
    right: '5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    opacity: '0.8',
    transition: '0.3s ease-in-out',
    color: 'white',
    '&:hover': {
      opacity: '1',
    },
  },
  closeIcon: {
    fontSize: '30px',
  },
  chatContent: {
    height: '-webkit-fill-available',
    maxHeight: '-webkit-fill-available',
    overflowY: 'auto',
    borderLeft: '8px solid #19989B',
    borderRight: '8px solid #19989B',
    padding: '8px 12px',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  userMessage: {
    alignSelf: 'flex-end',
    backgroundColor: '#19989B',
    color: '#fff',
    padding: '4px 8px',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    maxWidth: '85%',
    boxShadow: '2px 2px 4px lightgrey',
  },
  systemMessage: {
    alignSelf: 'flex-start',
    backgroundColor: '#f5f5f5',
    padding: '4px 8px',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    maxWidth: '85%',
    boxShadow: '2px 2px 4px lightgrey',
  },
  chatFooter: {
    backgroundColor: '#19989B',
    padding: '6px 12px',
    display: 'flex',
    width: '100%',
  },
  chatForm: {
    backgroundColor: '#fff',
    padding: '4px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    gap: '4px',
    borderRadius: '8px',
  },
  chatInput: {
    border: 'none',
    outline: 'none',
    width: '100%',
  },
  sendButton: {
    minWidth: 40,
    minHeight: 40,
    padding: '2px',
  },
}));

const ChatBot = ({ pickedBrandPersona }) => {
  const classes = useStyles();

  const [messages, setMessages] = useState([]);
  const [isTyping, setIsTyping] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [chatOpened, setChatOpened] = useState(false);

  const parseToMessage = value => (value ? value : 'unknown');

  useEffect(() => {
    setMessages([
      {
        role: 'system',
        content: `
                    You are ${parseToMessage(pickedBrandPersona.Name)}, 
                    a persona representing a young, sociable, and entertainment-loving individual 
                    from the ${parseToMessage(getIndustryName(pickedBrandPersona.Industry))} industry.

                    Your values include ${parseToMessage(pickedBrandPersona.Value)}, 
                    and you aim for ${parseToMessage(pickedBrandPersona.Goal)}.

                    You are passionate about addressing ${parseToMessage(pickedBrandPersona.Problem)} 
                    and are inspired by ${parseToMessage(pickedBrandPersona.Influencers)}.

                    Your favorite brand is ${parseToMessage(pickedBrandPersona.Brand)}.

                    You have strong opinions on social issues like ${Object.keys(
                      getIssuesFromBrandPersona(pickedBrandPersona),
                    ).join(', ')}.

                    You live in ${parseToMessage(getCountryByCode(pickedBrandPersona.country))}, 
                    ${pickedBrandPersona.State ? parseToMessage(getStateByCode(pickedBrandPersona.State)) : ''}, 
                    and belong to the ${parseToMessage(getEthnicityByCode(pickedBrandPersona.Ethnicity))} ethnicity.
                    
                    You are ${parseToMessage(pickedBrandPersona.Age)} years old, 
                    ${parseToMessage(getGenderByCode(pickedBrandPersona.Gender))} gender, 
                    with a ${parseToMessage(getEducationByCode(pickedBrandPersona.Education))}, 
                    and are currently ${parseToMessage(getMaritalStatusByCode(pickedBrandPersona.Marital_status))}.
                `,
      },
    ]);
  }, [pickedBrandPersona]);

  const typeMessage = messageContent => {
    return new Promise(resolve => {
      let typedMessage = '';
      let index = 0;
      const typingInterval = setInterval(() => {
        typedMessage += messageContent[index++];
        setMessages(prevMessages => [
          ...prevMessages.slice(0, -1), // Remove the previous message (typing indicator)
          { role: 'assistant', content: typedMessage },
        ]);
        if (index === messageContent.length) {
          clearInterval(typingInterval);
          setIsTyping(false);
          resolve();
        }
      }, 25);
    });
  };

  const chatData = async userMessage => {
    AIChatBot([...messages, { role: 'user', content: userMessage }])
      .then(text => {
        setIsTyping(false);
        typeMessage(text);
      })
      .catch(err => {
        setIsTyping(false);
        showToastWarn();
      });
  };

  const showToastWarn = () => {
    toast.warn('Something went wrong while connecting to the chat assistant.', {
      position: 'bottom-right',
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const handleSendMessage = messageContent => {
    setMessages(prevMessages => [
      ...prevMessages,
      { role: 'user', content: messageContent },
      { role: 'assistant', content: 'Typing...' },
    ]);
    chatData(messageContent);
    setIsTyping(true);
  };

  return chatOpened ? (
    <Slide direction="up" in={chatOpened} mountOnEnter unmountOnExit>
      <Paper className={classes.chatWindow}>
        <Box className={classes.chatBox}>
          <Box className={classes.chatHeader}>
            <Box className={classes.avatarContainer}>
              <img src={makeURL('/images/logo_no_letters.png')} alt="Brand Social Value" className={classes.avatar} />
            </Box>
            <Box>
              <Typography variant="h6" style={{ color: '#fff' }}>
                Brand Persona
              </Typography>
              <Typography style={{ color: '#fff' }}>Available 24/7</Typography>
            </Box>
            <IconButton onClick={() => setChatOpened(false)} className={classes.closeChatButton}>
              <Close className={classes.closeIcon} />
            </IconButton>
          </Box>
          <Box className={classes.chatContent}>
            {messages.map((message, index) => {
              if (message.role === 'system') return null;

              return (
                <Box key={index} className={message.role === 'user' ? classes.userMessage : classes.systemMessage}>
                  {message.role !== 'system' && (
                    <>
                      {message.role === 'assistant' && (
                        <Typography variant="body2" fontWeight="bold">
                          {message.role}
                        </Typography>
                      )}
                      <Box display="flex" alignItems="center" justifyContent="center">
                        {message.content === 'Typing...' ? (
                          <CircularProgress style={{ marginTop: '7px', width: '25px', height: '25px', color: 'black' }} />
                        ) : (
                          <Typography variant="body2">{message.content}</Typography>
                        )}
                      </Box>
                    </>
                  )}
                </Box>
              );
            })}
          </Box>
          <Box className={classes.chatFooter}>
            <form
              className={classes.chatForm}
              onSubmit={e => {
                e.preventDefault();
                if (inputValue.trim() !== '') {
                  handleSendMessage(inputValue);
                  setInputValue('');
                }
              }}>
              <TextField
                value={inputValue}
                onChange={e => setInputValue(e.target.value)}
                placeholder="Enter Your Message"
                variant="outlined"
                size="small"
                className={classes.chatInput}
              />
              <Button type="submit" className={classes.sendButton} disabled={isTyping}>
                <Send />
              </Button>
            </form>
          </Box>
        </Box>
      </Paper>
    </Slide>
  ) : (
    <Box className={classes.chatBoubble}>
      <IconButton onClick={() => setChatOpened(true)} className={classes.chatIconButton}>
        <Chat className={classes.chatIcon} />
      </IconButton>
    </Box>
  );
};

export default ChatBot;
