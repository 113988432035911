import React from 'react';
import Hidden from '@material-ui/core/Hidden';
import { Box } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import Image from '../../../../myComponents/image';
import { makeURL } from '../../../../helperFunctions';

const SidebarLogo = ({ color, ...props }) => {
  const logoUrl = makeURL('/images/logos/BSV_light_no_background.png');
  const logoSymbolUrl = makeURL('/images/logos/BSV_light_no_background.png');

  return (
    <Box className='pointer' {...props} style={{ margin: 0 }}>
      <Hidden xsDown>
        <NavLink to={makeURL('/home')}>
          <Image
            src={logoUrl}
            alt='logo'
            style={{ width: '120px', maxWidth: '100%' }}
          />
        </NavLink>
      </Hidden>
      <Hidden smUp>
        <NavLink to={makeURL('/home')}>
          <Image
            src={logoSymbolUrl}
            alt='logo'
            style={{ width: '120px', maxWidth: '120px' }}
          />
        </NavLink>
      </Hidden>
    </Box>
  );
};

export default SidebarLogo;
