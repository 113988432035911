import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Slide from '@material-ui/core/Slide';
import CubeLoader from '../cubeLoader';
import Image from '../../image';
import { makeURL } from '../../../helperFunctions';

const useStyles = makeStyles(theme => ({
  mainContainer: {
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'white',
    zIndex: 1100,
    '&:before': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: 0,
      zIndex: 1000,
      width: '100%',
      height: '100%',
      backgroundColor: '',
    },
  },
  loader: {
    color: '#19989B',
  },
  contentContainer: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translateX(-50%) translateY(-50%) !important',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {
    width: '150px',
    marginBottom: '35px',
  },
}));

const AppLoader = () => {
  const classes = useStyles();

  return (
    <Box className={classes.mainContainer}>
      <Slide in={true} direction="up" mountOnEnter unmountOnExit>
        <Box className={classes.contentContainer}>
          <Image src={makeURL('/images/logos/BSV_Dark_no_background.png')} className={classes.logo} />
          <CubeLoader />
        </Box>
      </Slide>
    </Box>
  );
};

export default AppLoader;
