import { FETCH_DATA_START, FETCH_DATA_SUCCESS, FETCH_DATA_FAIL } from './actionTypes';
import axios from '../../API/axiosConfig';

export const fetchData = () => {
  return async dispatch => {
    dispatch({
      type: FETCH_DATA_START,
    });

    try {
      const url = '/fs/surveys';
      const {
        data: { result: allData },
      } = await axios.get(url);

      dispatch({
        type: FETCH_DATA_SUCCESS,
        ...allData,
      });
      return {
        err: false,
        result: allData,
      };
    } catch (err) {
      dispatch({
        type: FETCH_DATA_FAIL,
      });
      return {
        err: true,
        result: err.message,
      };
    }
  };
};
