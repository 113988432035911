import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography, Button } from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';
import _ from 'lodash';

import { getShouldCalculateAgeRange, makeURL, pushRangeOfNumbers } from '../../helperFunctions';
import { formatFilterValue, defaultFilterState, formatLabelValue } from './helpers';

import FilterInputs from './filterComponents/FilterInputs';
import FilterBadge from './filterComponents/FilterBadge';
import CSVExportButton from '../../myComponents/CSVExportButton';
import '../../myComponents/customSwitch/index.css';

const themeColorConfig = {
  green: {
    filterSectionBackground: 'rgba(130,194,195, 0.2) !important',
    filterBadgeBackground: '#97CCCD',
    resetFilterButtonColor: '#19989B',
    filterIconUrl: '/images/parameter-settings-icon-green.png',
  },
  yellow: {
    filterSectionBackground: 'rgba(171,134,40, 0.2) !important',
    filterBadgeBackground: '#AB8628',
    resetFilterButtonColor: '#AB8628',
    filterIconUrl: '/images/parameter-settings-icon-yellow.png',
  },
  red: {
    filterSectionBackground: 'rgba(223,51,113, 0.2) !important',
    filterBadgeBackground: '#E45589',
    resetFilterButtonColor: '#E45589',
    filterIconUrl: '/images/parameter-settings-icon-red.png',
  },
};

const useStyles = makeStyles(theme => ({
  filterSection: props => ({
    width: '100%',
    backgroundColor: themeColorConfig[props.themeColor].filterSectionBackground,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  }),
  filterSectionContent: {
    '@media (max-width: 768px)': {
      justifyContent: 'space-between',
    },
  },
  filtersButton: {
    display: 'flex',
    padding: '10px 15px',
    borderRadius: '10px',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  filtersButtonImage: {
    width: '25px',
    marginRight: '15px',
  },
  filtersButtonText: {
    color: theme.palette.text.primary,
    fontSize: '15px',
    margin: 0,
    textTransform: 'capitalize',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  filterSectionList: {
    height: '100%',
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '20px',
    '@media (max-width: 768px)': {
      display: 'none',
    },
  },
  filterSectionListScroll: {
    height: '100%',
    padding: '6px 0px',
    maxWidth: '500px',
    display: 'flex',
    alignItems: 'center',
    alignItems: 'flex-start',
    gap: '12px',
    overflow: 'hidden',
    overflowX: 'scroll',
    scrollbarWidth: 'none',
    paddingRight: '10px',
    ['-ms-overflow-style']: 'none',

    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  resetFiltersBtn: props => ({
    cursor: 'pointer',

    '& span': {
      color: themeColorConfig[props.themeColor].filterBadgeBackground,
      fontWeight: 700,
      fontStyle: 'italic',
    },
  }),
}));

const Filters = ({ calculateHandler, themeColor, csvExport }) => {
  //Styles
  const classes = useStyles({ themeColor });

  //Chosen filters state
  const [selectedFilters, setSelectedFilters] = useState(defaultFilterState());

  //Filters show/hide
  const [openFilters, setOpenFilters] = useState(false);

  //Input States
  const [rangeWasTouched, setRangeWasTouched] = useState(false);

  const onFiltersSubmit = event => {
    const shouldCalculateAgeRange = getShouldCalculateAgeRange();

    event.preventDefault();
    setOpenFilters(false);

    const data = new FormData(event.target);
    const preparedFilterData = defaultFilterState();
    const filterToSend = defaultFilterState();

    //Make sure that we lose the default batch_3 value if user picked something
    if (data.getAll('Batch').length) {
      preparedFilterData.Batch = [];
      filterToSend.Batch = [];
    }

    data.forEach((value, name) => {
      switch (name) {
        case 'Batch':
          preparedFilterData.Batch.push(value);
          filterToSend.Batch.push(value);
          break;
        case 'Age':
          if (value[0] !== '[') {
            //slider
            if (rangeWasTouched && shouldCalculateAgeRange) {
              let parsedValue = value.split(',');
              parsedValue = parsedValue.map(element => Number(element));
              preparedFilterData.Age.push(parsedValue);
              filterToSend.Age.push(parsedValue);
            }
          } else {
            //switches
            preparedFilterData.Age.push(JSON.parse(value));
            filterToSend.Age.push(JSON.parse(value));
          }
          break;
        case 'Gender':
          if (Number(value) === 3) {
            preparedFilterData.Gender.push(Number(value));
            filterToSend.Gender = pushRangeOfNumbers(filterToSend.Gender, 3, 6);
          } else {
            preparedFilterData.Gender.push(Number(value));
            filterToSend.Gender.push(Number(value));
          }
          break;
        case 'Income':
          if (value[0] === '[') {
            preparedFilterData.Income.push(value);
          } else {
            preparedFilterData.Income.push(Number(value));
          }
          if (isNaN(Number(value))) {
            let parsedValue = JSON.parse(value);
            filterToSend.Income = pushRangeOfNumbers(filterToSend.Income, parsedValue[0], parsedValue[1]);
          } else {
            filterToSend.Income.push(Number(value));
          }
          break;
        case 'State':
          if (Number(value) !== 0) {
            preparedFilterData.State.push(Number(value));
            filterToSend.State.push(Number(value));
          }
          break;
        default:
          //Fields that we do not support
          if (name === 'Region' || name === 'Country') break;

          preparedFilterData[name].push(Number(value));
          filterToSend[name].push(Number(value));
          break;
      }
    });

    setSelectedFilters(preparedFilterData);
    calculateHandler(filterToSend);
  };

  const onFilterRemoveHandler = (label, value) => {
    const mappedLabel = getLabelToRemove(label);
    let selectedFiltersTemp = { ...selectedFilters };

    Object.keys(selectedFiltersTemp).forEach(key => {
      if (key == mappedLabel) {
        selectedFiltersTemp[key] = selectedFiltersTemp[key].filter(filterValue => {
          let mappedFilterValue = mappedLabel == 'Age' ? `${filterValue}` : filterValue;
          const mappedValue = mappedLabel == 'Age' ? value.toString() : value;
          if (mappedFilterValue == mappedValue) {
            return false;
          }

          return true;
        });
      }
    });

    const filtersToSend = { ...selectedFiltersTemp };

    filtersToSend.Income = mapIncomeGroupToBracket(filtersToSend.Income);

    setSelectedFilters(selectedFiltersTemp);
    calculateHandler(filtersToSend);
  };

  const getLabelToRemove = label => {
    let mappedLabel = label;

    if (label === 'Ideology') {
      mappedLabel = 'PersonalIdeology_1';
    }

    return mappedLabel;
  };

  const mapIncomeGroupToBracket = incomeGroupArr => {
    let bracketValues = [];

    incomeGroupArr.forEach(incomeGroupItem => {
      const parsedItem = JSON.parse(incomeGroupItem);

      for (let i = parsedItem[0]; i <= parsedItem[1]; i++) {
        bracketValues.push(i);
      }
    });

    bracketValues.sort((a, b) => a - b);

    return bracketValues;
  };

  const onResetFiltersHandler = () => {
    const defaultState = defaultFilterState();
    defaultState.Batch = [...selectedFilters.Batch];
    setSelectedFilters({ ...defaultState });
    calculateHandler({ ...defaultState });
  };

  return (
    <Box>
      <Box className={classes.filterSection}>
        <Box
          className={classes.filterSectionContent}
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          flex={1}>
          <Button onClick={() => setOpenFilters(prevState => !prevState)} className={classes.filtersButton}>
            <img
              alt="filters-button"
              src={makeURL(themeColorConfig[themeColor].filterIconUrl)}
              className={classes.filtersButtonImage}
            />
            <Typography component="div" variant="button" className={classes.filtersButtonText}>
              Consumer Settings <ArrowDropDown />
            </Typography>
          </Button>

          <Box className={classes.filterSectionList}>
            <Box className={classes.filterSectionListScroll}>
              {Object.entries(selectedFilters || {}).map(
                ([key, values]) =>
                  key !== 'Batch' &&
                  values.map((filterValueToMap, idx) => {
                    return (
                      <FilterBadge
                        key={idx}
                        label={formatLabelValue(key)}
                        valueToShow={formatFilterValue(key, filterValueToMap)}
                        value={filterValueToMap}
                        onRemove={onFilterRemoveHandler}
                        bgColor={themeColorConfig[themeColor].resetFilterButtonColor}
                      />
                    );
                  }),
              )}
            </Box>
            {!_.isEqual(selectedFilters, defaultFilterState()) && (
              <Box className={classes.resetFiltersBtn} onClick={onResetFiltersHandler}>
                <span>Reset All</span>
              </Box>
            )}
          </Box>
        </Box>
        {csvExport && <CSVExportButton csvData={csvExport} containerStyle={{}} />}
      </Box>
      <FilterInputs
        themeColor={themeColor}
        show={openFilters}
        onFiltersSubmit={onFiltersSubmit}
        setRangeWasTouched={setRangeWasTouched}
      />
    </Box>
  );
};

export default Filters;
