import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

import { Pie, PieChart } from 'recharts';

import useViewportWidth from '../../../myHooks/useViewportWidth';
import {
  buyerDashboardRadarChartSubjects,
  generateConsumerGroupsData,
  generateConsumerSocialValuesData,
  generateRangeChartData_ConsumerDashboard,
  getProductBenefitsSubHeading,
} from '../BuyerPersonas/chartCalculations/helpers';
import { generateRadarChartData } from '../BuyerPersonas/chartCalculations/helpers';
import { getTop3SocialCauses_ConsumerDashboard } from '../BrandPersonas/chartCalculations/helpers';

import ConsumerGroup from '../../../myComponents/chart/ConsumerGroups/ConsumerGroup';
import Watermark from '../../../myComponents/watermark';
import DashDetailsWrapper from '../../../myComponents/dashDetailsWrapper';
import ConsumerDataInfoTable from '../../../myComponents/chart/dataInfoTable/consumerDataInfoTable';
import RangeChart from '../../../myComponents/chart/RangeChart';
import DashSectionExplanation from '../../../myComponents/dashSectionExplanation';
import RadarChart from '../../../myComponents/chart/RadarChart';

const useStyles = makeStyles(theme => ({
  textStyle: {
    fontSize: '18px',
    fontWeight: 'bold',
    color: theme.palette.text.primary,
    textAlign: 'center',
    marginTop: '10px',
  },
  circleChartWrapper: {
    width: 'fit-content',
  },
  productBenefitsWrapper: {
    '@media (max-width: 768px)': {
      width: '100%',
    },
  },
  rangeChartContainer: {
    marginTop: '50px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    width: '100%',

    '@media (max-width: 1260px)': {
      justifyContent: 'center',
    },
  },
  rangeChartWrapper: {
    maxWidth: '500px',
    margin: '0px 60px 100px 0px',
    width: '100%',
    '@media (max-width: 1240px)': {
      maxWidth: '600px',
    },
    '@media (max-width: 768px)': {
      maxWidth: '100%',
      width: '100%',
      margin: '0px 0px 60px 0px',
      padding: 0,
    },
  },
  circleChartContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',

    '@media (max-width: 768px)': {
      flexDirection: 'column',
    },
  },
  labels: {
    marginLeft: '24px',
    alignSelf: 'flex-start',
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '8px',
    marginBottom: '8px',

    '& div': {
      borderRadius: '0 !important',
      width: '16px',
      height: '16px',
      boxShadow: '0 4px 4px rgba(0,0,0,0.1)',
    },
    '& span': {
      fontSize: '14px',
      color: '#fff',
    },
  },
  pieChart: {
    width: '300px',
    height: '300px',
  },
  pieChartHeading: {
    fontSize: '24px',
    color: '#fff',
  },
  radarChartWrapper: {
    width: '700px',
    height: '500px',

    '@media (max-width: 768px)': {
      width: '100%',
      height: '300px',
    },
  },
  brandPerformanceResponsive: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: '30px',
    flexWrap: 'wrap',
    width: '100%',

    '@media (max-width: 1265px)': {
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  socialCausesRadarChartContent: {
    flex: 1,
    margin: '30px auto 0 auto',
    maxWidth: '650px',
    '@media (max-width: 620px)': {
      width: '100%',
    },
  },
  socialCausesRadarChartText: {
    marginLeft: '40px',
    marginBottom: '65px',
    color: 'lightgray',
    fontWeight: 300,
    fontSize: '16px',
    textAlign: 'left',
    '@media (max-width: 768px)': {
      maxWidth: '420px',
      margin: '0 auto',
    },
  },
  socialCausesRadarChartDashExp: {
    marginTop: '90px',
    marginLeft: '70px',

    '@media (max-width: 768px)': {
      maxWidth: '420px',
      margin: '40px auto 0px auto',
    },
  },
  consumerGroupsResponsive: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '40px',
    marginBottom: '30px',
    flexWrap: 'wrap',
    width: '100%',
    gridGap: '32px',
  },

  consumerGroupsWrapperResponsive: {
    '@media (max-width: 768px)': {
      maxWidth: '60%',
      justifyContent: 'center',
    },
  },
}));

const ChartCalculations = ({ data }) => {
  const classes = useStyles();

  const width = useViewportWidth();

  // Sending batch data Social values
  let generatedRangeChartSocial = generateRangeChartData_ConsumerDashboard(data.average.SocialValues);

  const multipleRadarChartDataLabels = ['SELECTED SAMPLE'];
  const multipleRadarChartData = generateRadarChartData(
    'consumerDashboard',
    'single',
    [data.average.PersonalIssues],
    buyerDashboardRadarChartSubjects.socialIssues,
    multipleRadarChartDataLabels,
  );

  const radarChartTemplate = [
    'State of the Economy',
    'Free Healthcare',
    'Violent Crime',
    'World Peace',
    'Gun Laws',
    'Race and Ethnic Inequality',
    'Gender Inequality',
    'Immigration',
    'Economic Inequality',
    'Climate Change',
    'Corporate Corruption',
  ];

  let orderedRadarChartData = [];

  radarChartTemplate.forEach(subject => {
    multipleRadarChartData.forEach(el => {
      if (el.subject == subject) {
        orderedRadarChartData = [...orderedRadarChartData, { ...el }];
        return;
      }
    });
  });

  const top3SocialCauses = getTop3SocialCauses_ConsumerDashboard(multipleRadarChartData);

  // Product benefits

  const productBeneitsSubHeading = getProductBenefitsSubHeading();

  let generatedRangeChartProduct = generateRangeChartData_ConsumerDashboard(data.average.PersonalProduct, 'product');

  // Consumer groups - Social Values

  const generatedConsumerSocialValuesData = generateConsumerSocialValuesData(data.average.SocialValues);

  // Consumer groups

  const generatedConsumerGroupsData = generateConsumerGroupsData(data.average.PersonalProduct);

  let circleChartData = [];

  generatedConsumerSocialValuesData.forEach(item => {
    const chart = [
      {
        name: item.leftText,
        value: item.highValue,
        fill: '#F06292',
      },
      {
        name: 'Balanced',
        value: item.mediumValue,
        fill: '#8E8B8B',
      },
      {
        name: item.rightText,
        value: item.lowValue,
        fill: '#BDE2FE',
      },
    ];

    circleChartData = [...circleChartData, chart];
  });

  return (
    <Box>
      {/* Consumer Demographics */}
      <DashDetailsWrapper
        backgroundColor="white"
        expandColor="black"
        heading1={{
          text: 'Consumer Demographics',
          color: '#CD225C',
        }}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          marginBottom="30px"
          flexWrap="wrap"
          width="100%">
          <ConsumerDataInfoTable data={data} />
        </Box>
      </DashDetailsWrapper>

      {/* Social Value & Social Causes */}
      <DashDetailsWrapper
        backgroundColor="#254157"
        expandColor="white"
        heading1={{
          text: 'Social Values & Social Causes',
          color: 'white',
        }}>
        <Box className={classes.brandPerformanceResponsive}>
          <Box display="flex" flexDirection="column" justifyContent="space-between" alignItems="flex-start">
            {generatedRangeChartSocial.map((socialRangeChartData, index) => (
              <Box marginBottom="20px" maxWidth="420px" key={index}>
                <RangeChart
                  mainLineColor="#667A89"
                  middleLineColor="#EFEFEF"
                  leftText={{
                    text: socialRangeChartData.leftText,
                    color: 'white',
                  }}
                  rightText={{
                    text: socialRangeChartData.rightText,
                    color: 'white',
                  }}
                  valueColor="lightgray"
                  heading={socialRangeChartData.heading}
                  headingColor="white"
                  body={{
                    text: socialRangeChartData.body,
                    color: 'lightgray',
                  }}
                  value1={socialRangeChartData.personaValue}
                  value2={socialRangeChartData.batchValue}
                  type="buyer"
                />
              </Box>
            ))}
          </Box>
          <Box className={classes.socialCausesRadarChartContent}>
            <p className={classes.socialCausesRadarChartText}>
              This chart compares consumers' and brands' positions regarding the most important 11 social causes that are
              commonly used in political research and social sciences.
            </p>
            <Box className={classes.radarChartWrapper}>
              <RadarChart
                personaType="consumerDash"
                type="single"
                data={orderedRadarChartData}
                size={width > 768 ? 200 : 100}
                AConfig={{
                  name: multipleRadarChartDataLabels[0],
                  color: '#CD225C',
                }}
                tickTextColor="#90A0B0"
              />
            </Box>
            <Box className={classes.socialCausesRadarChartDashExp}>
              <DashSectionExplanation
                heading={'Causes that consumers most care about:'}
                headingColor="white"
                body={
                  <span>
                    {top3SocialCauses.map((cause, index) => (
                      <p key={index} style={{ marginBottom: '1px', fontSize: '15px' }}>
                        - {cause.subject} -<strong> {cause['A']}%</strong>
                      </p>
                    ))}
                  </span>
                }
                bodyColor="lightgray"
              />
            </Box>
          </Box>
        </Box>
      </DashDetailsWrapper>

      {/* Poduct Benefits */}
      <DashDetailsWrapper
        backgroundColor="white"
        expandColor="black"
        heading1={{
          text: 'Product Benefits',
          color: '#CD225C',
        }}>
        <Box className={classes.productBenefitsWrapper}>
          <Box display="flex" alignItems="center" marginBottom="20px">
            <Box display="flex" alignItems="center" marginRight="20px">
              <Box
                style={{
                  backgroundColor: '#CE5378',
                  width: '15px',
                  height: '15px',
                  borderRadius: '50%',
                  boxShadow: '0px 4px 3px lightgray',
                  marginRight: '10px',
                }}></Box>
              <p
                style={{
                  color: 'black',
                  fontWeight: 300,
                  fontSize: '16px',
                  textAlign: 'left',
                  margin: '0',
                }}>
                SELECTED SAMPLE
              </p>
            </Box>
          </Box>
          <p
            style={{
              color: '#A5A5A5',
              fontWeight: 300,
              fontSize: '16px',
              textAlign: 'left',
              margin: '0',
              maxWidth: '700px',
            }}>
            {productBeneitsSubHeading}
          </p>
          <Box className={classes.rangeChartContainer}>
            {generatedRangeChartProduct.map((productRangeChartData, index) => (
              <Box className={classes.rangeChartWrapper} key={index}>
                <RangeChart
                  mainLineColor="#E5E6E6"
                  middleLineColor="#D9D9D9"
                  leftText={{
                    text: productRangeChartData.leftText,
                    color: '#6C757D',
                  }}
                  rightText={{
                    text: productRangeChartData.rightText,
                    color: '#6C757D',
                  }}
                  valueColor="gray"
                  body={{
                    text: productRangeChartData.body,
                    color: '#6C757D',
                  }}
                  value1={productRangeChartData.personaValue}
                  value2={productRangeChartData.batchValue}
                />
              </Box>
            ))}
          </Box>
        </Box>
      </DashDetailsWrapper>

      {/* Consumer Groups - Social Values */}
      <DashDetailsWrapper
        backgroundColor="#254157"
        expandColor="white"
        heading1={{
          text: 'Consumer Groups - Social Values ',
          color: '#fff',
        }}>
        <Box className={classes.consumerGroupsResponsive}>
          {circleChartData.map((chart, idx) => {
            return (
              <div
                className={classes.circleChartWrapper}
                key={idx}
                style={{ transform: width <= 480 ? 'scale(0.75)' : 'unset' }}>
                <h3 className={classes.pieChartHeading}>{generatedConsumerSocialValuesData[idx].heading}</h3>
                <div className={classes.circleChartContainer}>
                  <PieChart
                    key={idx}
                    width={450}
                    height={400}
                    style={{
                      filter: `drop-shadow(0px 0px 8px rgba(0,0,0,0.3))`,
                    }}>
                    <Pie
                      data={chart}
                      dataKey="value"
                      nameKey="name"
                      cx="50%"
                      cy="50%"
                      innerRadius={110}
                      outerRadius={150}
                      fill="#82ca9d"
                      label={({ value }) => `${value}%`}
                      stroke="none"
                    />
                  </PieChart>
                  <div className={classes.labels}>
                    <div className={classes.label}>
                      <div style={{ background: chart[0].fill }}></div>
                      <span>{generatedConsumerSocialValuesData[idx].leftText}</span>
                    </div>
                    <div className={classes.label}>
                      <div style={{ background: chart[1].fill }}></div>
                      <span>Balanced</span>
                    </div>
                    <div className={classes.label}>
                      <div style={{ background: chart[2].fill }}></div>
                      <span>{generatedConsumerSocialValuesData[idx].rightText}</span>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </Box>
      </DashDetailsWrapper>

      {/* Consumer Groups */}
      <DashDetailsWrapper
        backgroundColor="white"
        expandColor="black"
        heading1={{
          text: 'Consumer Groups - Product Benefits Preferences',
          color: '#CD225C',
        }}>
        <Box>
          <Box
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            marginTop="40px"
            marginBottom="30px"
            flexWrap="wrap"
            width="100%"
            gridGap="32px">
            {generatedConsumerGroupsData.map((item, index) => {
              return (
                <ConsumerGroup
                  key={index}
                  highText={item.leftText}
                  highPercentage={item.highValue}
                  highColor="#CD225C"
                  balancedText="Balanced"
                  balancedPercentage={item.mediumValue}
                  balancedColor="#979797"
                  lowText={item.rightText}
                  lowPercentage={item.lowValue}
                  lowColor="#254157"
                />
              );
            })}
          </Box>
          <Watermark />
        </Box>
      </DashDetailsWrapper>
    </Box>
  );
};

export default ChartCalculations;
