import React from 'react';
import { useSelector } from 'react-redux';
import PageLoader from '../pageLoader';

const ContentLoader = () => {
  const { loading } = useSelector(({ firebase }) => firebase);

  return <React.Fragment>{loading && <PageLoader />}</React.Fragment>;
};

export default ContentLoader;
