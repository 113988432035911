import React, { useState, useEffect } from 'react';

import { makeStyles, createTheme, ThemeProvider } from '@material-ui/core/styles';
import { Box, Button, Typography, FormControlLabel, Switch } from '@material-ui/core';

import { filterSectionFields, batchFilterFields } from '../../../config/filterFields';
import FilterInputGenerator from './FilterInputGenerator';

const useStyles = makeStyles(theme => ({
  modalContainer: {
    backgroundColor: 'rgba(4, 17, 38, 0.7)',
    position: 'absolute',
    top: '0',
    bottom: '0',
    left: '0',
    right: '0',
  },
  modalHeadingClassName: {
    alignSelf: 'flex-start',
    color: 'white',
    marginBottom: '40px',
    fontSize: '25px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  form: {
    width: '100%',
    height: '100%',
  },
  formMainContainer: {
    width: '100%',
    minHeight: 'unset',
    maxHeight: '74vh',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',

    '@media (min-width: 3440px)': {
      maxHeight: '30vh',
    },

    '@media (min-width: 1920px)': {
      maxHeight: '74vh',
    },

    '@media (max-width: 1920px)': {
      maxHeight: '78vh',
    },

    '@media (max-width: 1720px)': {
      maxHeight: '78vh',
    },

    '@media (max-width: 1400px)': {
      maxHeight: '100vh',
    },

    '@media (max-width: 1240px)': {
      maxHeight: '180vh',
    },

    '@media (max-width: 800px)': {
      maxHeight: '200vh',
    },

    '@media (max-width: 768px)': {
      maxHeight: 'unset',
      minHeight: '100vh',
      flexDirection: 'unset',
      justifyContent: 'center',
    },
  },
  heaingImage: {
    width: '30px',
    marginLeft: '20px',
  },
  filtersDropdownContainer: {
    width: '100%',
    background: '#fff',
    padding: '25px',
    borderBottom: '1px solid rgba(130,194,195, 0.5)',
  },
  batchDataContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    paddingLeft: '30px',
  },
  batchSectionHeading: {
    color: 'black',
    fontWeight: 'bold',
    fontSize: '15px',
    margin: '0 57px 0 0',
    whiteSpace: 'nowrap',
  },
  batchElementLabel: {
    color: theme.palette.text.primary,
    fontSize: '13px',
    marginLeft: '10px',
  },
  disabledBatchSwitchElement: {
    '&.Mui-checked': {
      color: 'rgb(130, 194, 195) !important',
    },
    '&.MuiSwitch-track': {
      backgroundColor: 'red !important',
    },
  },
  submitBtn: {
    '@media (max-width: 600px)': {
      padding: '8px 26px !important',
    },
    '@media (max-width: 400px)': {
      padding: '8px 30px !important',
    },
  },
}));

const FilterComponents = props => {
  const classes = useStyles();

  const themeColorConfig = {
    green: '#82c2c3',
    yellow: '#AB8628',
    red: 'rgb(228, 85, 137)',
  };

  const themeButtonColor = {
    green: 'rgb(25, 152, 155)',
    yellow: '#AB8628',
    red: 'rgb(228, 85, 137)',
  };

  const muiTheme = createTheme({
    overrides: {
      MuiSwitch: {
        switchBase: {
          // Controls default (unchecked) color for the thumb
          color: themeColorConfig[props.themeColor],
        },
        colorSecondary: {
          '&$checked': {
            // Controls checked color for the thumb
            color: themeColorConfig[props.themeColor],
          },
          '&$disabled': {
            color: 'rgba(130,194,195, 1)',
          },
        },
        track: {
          // Controls default (unchecked) color for the track
          backgroundColor: '#D0D0D0',
          '$checked$checked + &': {
            // Controls checked color for the track]
            opacity: 1,
            backgroundColor: themeColorConfig[props.themeColor],
          },
        },
      },
    },
  });

  const [show, setShow] = useState(props.show);
  const [batchesSelected, setBatchesSelected] = useState({
    batch_1: false,
    batch_2: false,
    batch_3: true,
  });

  useEffect(() => {
    setShow(props.show);
  }, [props.show]);

  const batchesSelectionHandler = (event, value) => {
    setBatchesSelected({
      ...batchesSelected,
      [value]: event.target.checked,
    });
  };

  return (
    <Box
      width="100%"
      display={show ? 'flex' : 'none'}
      justifyContent="center"
      alignItems="center"
      className={classes.filtersDropdownContainer}>
      <form onSubmit={props.onFiltersSubmit} className={classes.form}>
        <Box
          width="100%"
          marginTop="10px"
          display="flex"
          justifyContent="space-between"
          alignItems="flex-end"
          marginBottom="30px">
          <Box className={classes.batchDataContainer}>
            <Typography component="div" variant="body1" className={classes.batchSectionHeading}>
              Batch Data
            </Typography>
            {batchFilterFields.map((batchField, index) => {
              const selectedBatchesFromState = Object.entries(batchesSelected).filter(e => e[1]);
              const shouldElementBeDisabled =
                selectedBatchesFromState.length === 1 && selectedBatchesFromState[0][0] === batchField.value;
              return (
                <Box key={index} marginRight="20px">
                  <ThemeProvider theme={muiTheme}>
                    <FormControlLabel
                      value={batchField.value}
                      control={
                        <Switch
                          size="small"
                          color="secondary"
                          onChange={event => {
                            if (shouldElementBeDisabled) return;
                            batchesSelectionHandler(event, batchField.value);
                          }}
                          checked={batchesSelected[batchField.value]}
                        />
                      }
                      name="Batch"
                      label={batchField.label}
                      labelPlacement="start"
                      style={{ whiteSpace: 'nowrap', margin: 0 }}
                    />
                  </ThemeProvider>
                </Box>
              );
            })}
          </Box>
          <Button
            type="submit"
            variant="contained"
            className={classes.submitBtn}
            style={{
              background: themeButtonColor[props.themeColor],
              color: '#fff',
            }}>
            Submit
          </Button>
        </Box>
        <Box className={classes.formMainContainer}>
          {filterSectionFields.map((section, index) => {
            return (
              <FilterInputGenerator
                key={index}
                themeColor={props.themeColor}
                section={section}
                setRangeWasTouched={props.setRangeWasTouched}
              />
            );
          })}
        </Box>
      </form>
    </Box>
  );
};

export default FilterComponents;
