import React, { useEffect, useState } from 'react';
import {
  Button,
  MenuItem,
  Select,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  CircularProgress,
  Box,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { fetchData } from 'redux/actions/Data';
import { deleteSurvey, updateSurvey } from '../../API/agent';

import { makeStyles, styled } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    maxWidth: '50%',
    minWidth: '35%',
    minHeight: '20%',
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '20%',
    minHeight: '30%',
    paddingBottom: '20px',
    textAlign: 'left',
  },
  dialogTitle: {
    display: 'flex',
    minHeight: '5%',
    padding: '12px 20px 20px 20px',
    borderBottom: '1.5px solid lightgray',
    color: '#666666',
  },
  titleParagraph: {
    fontSize: '20px',
    fontWeight: 'bold',
    '& div': {
      fontSize: '16px',
    },
  },
  closeIconWrapper: {
    position: 'absolute',
    top: '5.5px',
    right: '0',
    opacity: '0.3',
    color: 'red',
    fontSize: '50px',
    '&:hover': {
      opacity: '1',
      backgroundColor: '#FFF',
    },
  },
  closeIcon: {
    fontSize: '30px',
  },
  brandListSelect: {
    fontSize: '20px',
    textAlign: 'center',
    margin: '15px 0 15px 0',
    minWidth: '40%',
  },
  initialButtons: {
    maxWidth: '50%',
    minWidth: '15%',
    fontSize: '14px',
    padding: '5px',
  },
  attachStyle: {
    color: '#19989B',
    fontWeight: 'bold',
  },
  deleteStyle: {
    color: 'red',
    fontWeight: 'bold',
  },
}));

const DeletionDialog = ({ open, setDialogOpen, type, deletionID }) => {
  const tbdType = type === 'brand' ? 'brandPersonas' : type === 'buyer' ? 'buyerPersonas' : 'socialPosts';
  const surveyType = type === 'brand' ? 'Brand Persona' : type === 'buyer' ? 'Buyer Persona' : 'Social Media Post';

  const classes = useStyles();
  const dispatch = useDispatch();
  const appData = useSelector(({ data }) => data);

  let brandsList = appData.brandPersonas.filter(brand => brand.id !== deletionID);
  const [brandPersonaPickedId, setBrandPersonaPickedId] = useState('');
  const [toBeDeletedSurveyName, setToBeDeletedSurveyName] = useState('');

  const [connectedSocialMediaPostsIDs, setConnectedSocialMediaPostsIDs] = useState(null);
  const [deletionProgressState, setDeletionProgressState] = useState('loading');
  const [submiting, setSubmiting] = useState(false);

  useEffect(() => {
    if (!appData.loading) {
      let socialMediaPostsIDs = appData.socialPosts
        .filter(post => post.Post_Brand_Persona === deletionID)
        .map(post => post.id);
      setConnectedSocialMediaPostsIDs(socialMediaPostsIDs);

      let tbdBrand = appData[tbdType].find(brand => brand.id === deletionID);
      if (typeof tbdBrand?.Name !== 'undefined') {
        setToBeDeletedSurveyName(tbdBrand?.Name);
      } else {
        setToBeDeletedSurveyName('');
      }

      if (open) {
        if (socialMediaPostsIDs.length > 0 && brandsList.length > 0) {
          setDeletionProgressState('initial');
        } else if(socialMediaPostsIDs.length > 0 && brandsList.length === 0) {
          setDeletionProgressState('deleting');
        } else {
          setDeletionProgressState('none-attached');
        }
      }
    }
  }, [deletionID, appData, open]);

  const closeDeletionDialog = () => {
    setDialogOpen(false);
  };

  const deleteSurveyHandler = (type, surveyId) => {
    setSubmiting(true);
    deleteSurvey(type, surveyId).then(result => {
      setSubmiting(false);
      if (result.err) {
        toast.warn('Something went wrong, please try again later.', {
          position: 'bottom-right',
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.success('Survey deleted successfully', {
          position: 'bottom-right',
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        closeDeletionDialog();
        dispatch(fetchData());
      }
    }).catch(err => {
      toast.warn('Something went wrong, please try again later.', {
        position: 'bottom-right',
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    })
  };

  const deleteSocMedPostAsync = async () => {
    for (let i = 0; i < connectedSocialMediaPostsIDs.length; i++) {
      await deleteSurvey('Social', connectedSocialMediaPostsIDs[i]);
    }
    await deleteSurvey('Brand', deletionID);
  };

  const deleteSocMediaPostHandler = () => {
    setSubmiting(true);
    try {
      deleteSocMedPostAsync().then(() => {
        setSubmiting(false);
        toast.success('Surveys deleted successfully', {
          position: 'bottom-right',
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        closeDeletionDialog();
        dispatch(fetchData());
      });
    } catch (err) {
      console.log('Error deleting survey', err);
      toast.warn('Something went wrong, please try again later.', {
        position: 'bottom-right',
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const attachSocMedPostAsync = async () => {
    for (let i = 0; i < connectedSocialMediaPostsIDs.length; i++) {
      const updatedSurveyData = {
        Post_Brand_Persona: brandPersonaPickedId ? brandPersonaPickedId : brandsList[0].id
      };

      await updateSurvey("social", connectedSocialMediaPostsIDs[i], updatedSurveyData);
    }
    await deleteSurvey('Brand', deletionID);
  };
  const updateSocMediaPostHandler = () => {
    setSubmiting(true);
    try {
      attachSocMedPostAsync().then(() => {
        setSubmiting(false);
        toast.success('Surveys updated successfully', {
          position: 'bottom-right',
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        closeDeletionDialog();
        dispatch(fetchData());
      });
    } catch (err) {
      console.log('Error deleting survey', err);
      toast.warn('Something went wrong, please try again later.', {
        position: 'bottom-right',
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  const setSelectedBrandId = e => {
    setBrandPersonaPickedId(e.target.value);
  };

  const CustomButton = styled(Button)(({ theme }) => ({
    color: '#FFF',
    backgroundColor: '#19989B',
    '&:hover': {
      backgroundColor: '#19878a',
    },
  }));

  const changeDeletionDialogContent = () => {
    switch (deletionProgressState) {
      case 'loading':
        return (
          <Typography component="div" variant="subtitle1">
            {`Please wait while we check if there are Social Media posts attached to ${surveyType} ...`}
          </Typography>
        );

      case 'none-attached':
        return (
          <>
            <Typography component="div" variant="subtitle1">
              {`${surveyType} ${toBeDeletedSurveyName} will be permanently deleted.`}
            </Typography>
            <Typography component="div" variant="subtitle1" className={classes.deleteStyle}>
              {`You cannot recover this data.`}
            </Typography>
          </>
        );

      case 'initial':
        return (
          <>
            <Typography component="div" variant="subtitle1">
              {`${surveyType} ${toBeDeletedSurveyName} has Social Media posts attached to it.`}
            </Typography>
            <Typography component="div" variant="subtitle1">
              You may<span className={classes.attachStyle}> attach </span>Social Media posts to another Brand Persona, or
              <span className={classes.deleteStyle}> delete </span> both the Brand Persona and all Social Media posts
              attached to it.
            </Typography>
          </>
        );

      case 'deleting':
        return (
          <Typography component="div" variant="subtitle1">
            {`This action will delete both the ${toBeDeletedSurveyName} Brand Persona and all Social Media posts attached to it. Proceed?`}
          </Typography>
        );

      case 'attaching':
        return (
          <>
            <Typography component="div" variant="subtitle1">
              {`${surveyType} ${toBeDeletedSurveyName} will be deleted after attachment.`}
            </Typography>
            <Typography component="div" variant="subtitle1">
              {`Please select a Brand Persona to attach the Social Media posts to:`}
            </Typography>
            <Select
              className={classes.brandListSelect}
              variant="outlined"
              value={brandPersonaPickedId ? brandPersonaPickedId : brandsList && brandsList.length ? brandsList[0].id : ''}
              onChange={e => setSelectedBrandId(e)}>
              {brandsList.map((brand, index) => {
                return (
                  <MenuItem key={index} value={brand.id}>
                    {brand.Name}
                  </MenuItem>
                );
              })}
            </Select>
          </>
        );
      default:
        return null;
    }
  };
  const dialogTextContent = changeDeletionDialogContent();

  const getActionButtons = () => {
    switch (deletionProgressState) {
      case 'loading':
        return null;

      case 'none-attached':
        return (
          <>
            <Button
              onClick={() => {
                closeDeletionDialog();
              }}>
              Close
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => deleteSurveyHandler(type, deletionID)}
              autoFocus>
              {submiting ? (
                <Box width="62.89px" height="22.75px" display="flex" justifyContent="center" alignItems="center">
                  <CircularProgress size={23} style={{ color: 'white' }} />
                </Box>
              ) : (
                'Delete'
              )}
            </Button>
          </>
        );

      case 'initial':
        return (
          <>
            <CustomButton
              variant="contained"
              color="primary"
              size="small"
              className={classes.initialButtons}
              onClick={() => setDeletionProgressState('attaching')}>
              Attach
            </CustomButton>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              className={classes.initialButtons}
              onClick={() => setDeletionProgressState('deleting')}
              autoFocus>
              Delete
            </Button>
          </>
        );

      case 'deleting':
        return (
          <>
            <Button variant="contained" color="secondary" onClick={deleteSocMediaPostHandler} autoFocus>
              {submiting ? (
                <Box width="98.67px" height="22.75px" display="flex" justifyContent="center" alignItems="center">
                  <CircularProgress size={23} style={{ color: 'white' }} />
                </Box>
              ) : (
                'Delete All'
              )}
            </Button>
          </>
        );

      case 'attaching':
        return (
          <CustomButton variant="contained" onClick={updateSocMediaPostHandler} autoFocus>
            {submiting ? (
              <Box width="59.2px" height="22.75px" display="flex" justifyContent="center" alignItems="center">
                <CircularProgress size={23} style={{ color: 'white' }} />
              </Box>
            ) : (
              'Attach'
            )}
          </CustomButton>
        );
      default:
        return null;
    }
  };
  const actionComponent = getActionButtons();

  if (type !== 'brand') {
    return (
      <Dialog open={open} classes={{ paper: classes.paper }}>
        <DialogTitle className={classes.dialogTitle}>
          <Typography component="div" variant="h2" className={classes.titleParagraph}>
            Permanently delete this survey?
          </Typography>
        </DialogTitle>
        <IconButton onClick={closeDeletionDialog} className={classes.closeIconWrapper}>
          <CloseIcon className={classes.closeIcon} />
        </IconButton>
        <DialogContent className={classes.dialogContent}>
          <>
            <Typography component="div" variant="subtitle1">
              {`${surveyType} ${toBeDeletedSurveyName} will be permanently deleted.`}
            </Typography>
            <Typography component="div" variant="subtitle1" className={classes.deleteStyle}>
              {`You cannot recover this data.`}
            </Typography>
          </>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button
            onClick={() => {
              closeDeletionDialog();
            }}>
            Close
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => deleteSurveyHandler(type, deletionID)}
            autoFocus>
            {submiting ? (
              <Box width="62.89px" height="22.75px" display="flex" justifyContent="center" alignItems="center">
                <CircularProgress size={23} style={{ color: 'white' }} />
              </Box>
            ) : (
              'Delete'
            )}
          </Button>
        </DialogActions>
      </Dialog>
    );
  } else {
    return (
      <Dialog open={open} classes={{ paper: classes.paper }}>
        <DialogTitle className={classes.dialogTitle}>
          <Typography component="div" variant="h1" className={classes.titleParagraph}>
            Permanently delete this survey?
          </Typography>
        </DialogTitle>
        <IconButton onClick={closeDeletionDialog} className={classes.closeIconWrapper}>
          <CloseIcon className={classes.closeIcon} />
        </IconButton>
        <DialogContent className={classes.dialogContent}>{dialogTextContent}</DialogContent>
        <DialogActions className={classes.dialogActions}>{actionComponent}</DialogActions>
      </Dialog>
    );
  }
};
export default DeletionDialog;
