import React from 'react';
import { makeStyles, Select, MenuItem } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { makeURL } from '../../helperFunctions';
import useViewportWidth from '../../myHooks/useViewportWidth';

const useStyles = makeStyles(theme => ({
  container: {},
  smallTitle: {
    fontSize: '18px',
    marginBottom: '5px',
    fontWeight: 300,
    color: '#585858',
  },
  dropdown: {
    '& svg': {
      top: '5px !important',
    },

    '& ul': {
      maxHeight: '100px',
      overflowY: 'scroll',
    },
  },
  selectBrand: {
    outline: 'none',
    border: 'none',
    fontSize: '32px',
    color: '#19989B',

    '@media (max-width: 768px)': {
      fontSize: '18px',
    },
  },
  selectCompetitiveBrand: {
    outline: 'none',
    border: 'none',
    fontSize: '32px',
    color: '#19989B',
  },
  selectBuyer: {
    color: '#AB8628',
    outline: 'none',
    border: 'none',
    fontSize: '32px',
  },
  selectSocialPost: {
    outline: 'none',
    border: 'none',
    fontSize: '32px',
    color: '#19989B',

    '@media (max-width: 768px)': {
      fontSize: '18px',
      marginBottom: '24px',
    },
  },
  menuItem: {
    minWidth: '200px',
    position: 'relative',
  },
  invisible: {
    opacity: 0,
    pointerEvents: 'none',
    visibility: 'none',
    height: '0px',
    padding: '0px',
  },
  viewAllBtn: {
    content: '',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'red',
    opacity: 0,
  },
}));

//screen-width: text-length
const titleTextLengthConfig = {
  wide: {
    width: 1250,
    textLength: 40,
  },
  medium: {
    width: 878,
    textLength: 25,
  },
  small: {
    width: 450,
    textLength: 25,
  },
  extraSmall: {
    width: 100,
    textLength: 15,
  },
};

const HeaderSelector = ({ selectedId, data, sameIndustryData, type, setPickedId, redirect, bgColor = '#19989B' }) => {
  const classes = useStyles();
  const history = useHistory();

  let titleText = 'Brand Persona';

  if (type == 'buyer') {
    titleText = 'Buyer Persona';
  } else if (type == 'social') {
    titleText = 'Social Media Post';
  } else if (type == 'competitive') {
    titleText = 'Select Brand';
  }

  let selectorOptions = [];

  if (type == 'brand') {
    selectorOptions = data.brandPersonas;
  } else if (type == 'buyer') {
    selectorOptions = data.buyerPersonas;
  } else if (type == 'social') {
    selectorOptions = data.socialPosts;
  } else if (type == 'competitive') {
    let sameIndustryDataNamesOnly = sameIndustryData.map(data => data.name);
    selectorOptions = data.batchBrands.filter(
      brands => sameIndustryDataNamesOnly.indexOf(brands.name) !== -1 || brands.id === selectedId,
    );
  }

  const onChangeHandler = e => {
    if (e.target.value) {
      setPickedId(e.target.value);
    }
  };

  const onLastItemClick = e => {
    if (redirect) {
      let redirectURL = '/brand-personas/all';
      if (type == 'buyer') {
        redirectURL = '/buyer-personas/all';
      } else if (type == 'social') {
        redirectURL = '/social-media/all';
      } else if (type == 'competitive') {
        redirectURL = '/competitive-set-brands/all';
      }
      history.push(makeURL(redirectURL));
      return;
    }
  };
  const screenWidth = useViewportWidth();

  const getFormattedSocMedPostTitle = title => {
    let screenType =
      screenWidth >= titleTextLengthConfig.wide.width
        ? 'wide'
        : screenWidth >= titleTextLengthConfig.medium.width
        ? 'medium'
        : screenWidth >= titleTextLengthConfig.small.width
        ? 'small'
        : 'extraSmall';

    const wantedTextLength = titleTextLengthConfig[screenType].textLength;
    return title.length > wantedTextLength ? title.substring(0, wantedTextLength).concat('...') : title;
  };
  return (
    <div className={classes.container}>
      <h5 className={classes.smallTitle}>{titleText}</h5>
      {selectedId && selectorOptions && (
        <Select
          id={'headerSelector'}
          variant="standard"
          value={selectedId ? selectedId : ''}
          disabled={selectorOptions.length === 1}
          classes={{
            select:
              type == 'brand'
                ? classes.selectBrand
                : type == 'social'
                ? classes.selectSocialPost
                : type == 'competitive'
                ? classes.selectCompetitiveBrand
                : classes.selectBuyer,
          }}
          className={classes.dropdown}
          name={'headerSelector'}
          onChange={onChangeHandler}
          disableUnderline
          MenuProps={{
            style: {
              maxHeight: 600,
            },
          }}>
          {selectorOptions.map((item, index) => {
            return (
              <MenuItem
                className={`${classes.menuItem} ${index > 4 && redirect ? classes.invisible : ''}`}
                key={item.id}
                value={item.id}>
                {type == 'social'
                  ? getFormattedSocMedPostTitle(item.Post_Title)
                  : type == 'competitive'
                  ? getFormattedSocMedPostTitle(item.name)
                  : getFormattedSocMedPostTitle(item.Name)}
              </MenuItem>
            );
          })}
          {redirect && (
            <MenuItem
              className={`${classes.menuItem}`}
              onClick={onLastItemClick}
              style={{ background: bgColor, color: '#fff' }}>
              <span className={classes.viewAllBtn}></span>
              View all
            </MenuItem>
          )}
        </Select>
      )}
    </div>
  );
};

export default HeaderSelector;
