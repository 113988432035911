import React from 'react';

import ButtonGroup from './buttonGroupFormElement';
import ButtonMultiple from './buttonMultiple';
import TextFormElement from './textFormElement';
import TextHeadingSubheading from './textHeadingSubheading';
import DropdownFormElement from './dropdownFormElement';
import RadioGroupMultipleFormElement from './radioGroupMultipleFormElement';
import RadioGroupFormElement from './radioGroupFormElement';
import InfoFormElement from './infoFormElement';
import NumberFormElement from './numberFormElement';
import TextAreaFormElement from './textAreaFormElement';
import MultiAiTextArea from './multiAiTextArea';
import PersonaDropdown from './personaDropdown';
import DatePicker from './datePicker';
import RadioInputs from './radioInputs';
import ShouldAiGenerate from './shouldAiGenerate';
import Wrapper from './wrapper';
import SurveySummary from './surveySummary';

const SurveyInputs = (props) => {
  const question = props.question || props.questions[props.activeQuestion];

  switch (question.type) {
    case 'button':
      return (
        <ButtonGroup
          id={question.id}
          name={props.name}
          value={props.surveyAnswersState[question.id]}
          text={question.text}
          options={question.options}
          handleSurveyAnswers={props.handleSurveyAnswers}
          isInGroup={props.isInGroup}
          isSummary={props.isSummary}
        />
      );
    case 'button-multiple':
      return (
        <ButtonMultiple
          id={question.id}
          dependancySelection={question.dependancySelection}
          value={props.surveyAnswersState}
          text={question.text}
          options={question.options}
          handleSurveyAnswers={props.handleSurveyAnswers}
          isInGroup={props.isInGroup}
          isSummary={props.isSummary}
        />
      );
    case 'text':
      return (
        <TextFormElement
          id={question.id}
          name={props.name}
          value={props.surveyAnswersState[question.id]}
          text={question.text}
          handleSurveyAnswers={props.handleSurveyAnswers}
          isInGroup={props.isInGroup}
          isSummary={props.isSummary}
          isDependent={props.isDependent}
        />
      );
    case 'text-heading-subHeading':
      return (
        <TextHeadingSubheading
          id={question.id}
          value={props.surveyAnswersState[question.id]}
          heading={question.heading}
          subHeading={question.subHeading}
          handleSurveyAnswers={props.handleSurveyAnswers}
          isInGroup={props.isInGroup}
          isSummary={props.isSummary}
        />
      );
    case 'text-area':
      return (
        <TextAreaFormElement
          id={question.id}
          name={props.name}
          value={props.surveyAnswersState[question.id]}
          text={question.text}
          handleSurveyAnswers={props.handleSurveyAnswers}
          isInGroup={props.isInGroup}
          isSummary={props.isSummary}
        />
      );
    case 'multi-ai-text-area':
      return (
        <MultiAiTextArea
          value={props.surveyAnswersState[question.id]}
          surveyAnswersState={props.surveyAnswersState}
          textAreas={question.textAreas}
          id={question.id}
          handleSurveyAnswers={props.handleSurveyAnswers}
        />
      );
    case 'date-picker':
      return (
        <DatePicker
          id={question.id}
          value={props.surveyAnswersState[question.id]}
          isInGroup={props.isInGroup}
          isSummary={props.isSummary}
          text={question.text}
          handleSurveyAnswers={props.handleSurveyAnswers}
        />
      );
    case 'radio':
      return (
        <RadioInputs
          id={question.id}
          value={props.surveyAnswersState[question.id]}
          isInGroup={props.isInGroup}
          isSummary={props.isSummary}
          text={question.text}
          handleSurveyAnswers={props.handleSurveyAnswers}
          options={question.options}
        />
      );
    case 'should-ai-generate':
      return (
        <ShouldAiGenerate
          id={question.id}
          value={props.surveyAnswersState[question.id]}
          text={question.text}
          handleSurveyAnswers={props.handleSurveyAnswers}
          options={question.options}
        />
      );
    case 'number':
      return (
        <NumberFormElement
          id={question.id}
          name={props.name}
          value={props.surveyAnswersState[question.id]}
          text={question.text}
          handleSurveyAnswers={props.handleSurveyAnswers}
          isInGroup={props.isInGroup}
          isSummary={props.isSummary}
          isDependent={props.isDependent}
        />
      );
    case 'dropdown':
      return question.dependency ? (
        <>
          <DropdownFormElement
            id={question.id}
            name={props.name}
            value={props.surveyAnswersState[question.id]}
            text={question.text}
            options={question.options}
            handleSurveyAnswers={props.handleSurveyAnswers}
            isInGroup={true}
            isSummary={props.isSummary}
          />
          {question.dependency.map((e, index) => {
            if (e.dependencyValue === props.surveyAnswersState[question.id]) {
              return (
                <SurveyInputs
                  key={index}
                  question={e}
                  name={props.name}
                  surveyAnswersState={props.surveyAnswersState}
                  handleSurveyAnswers={props.handleSurveyAnswers}
                  isInGroup={true}
                  isSummary={props.isSummary}
                  isDependent={true}
                />
              );
            } else {
              return null;
            }
          })}
        </>
      ) : (
        <DropdownFormElement
          id={question.id}
          name={props.name}
          value={props.surveyAnswersState[question.id]}
          text={question.text}
          options={question.options}
          handleSurveyAnswers={props.handleSurveyAnswers}
          isInGroup={props.isInGroup}
          isSummary={props.isSummary}
        />
      );
    case 'persona-dropdown':
      return (
        <PersonaDropdown
          id={question.id}
          value={props.surveyAnswersState[question.id]}
          text={question.text}
          handleSurveyAnswers={props.handleSurveyAnswers}
          isInGroup={props.isInGroup}
          isSummary={props.isSummary}
        />
      );
    case 'info':
      return (
        <InfoFormElement
          text={question.text}
          name={props.name}
          isSummary={props.isSummary}
        />
      );
    case 'radio-group-multiple':
      return (
        <RadioGroupMultipleFormElement
          id={question.id}
          dependancySelection={question.dependancySelection}
          name={props.name}
          text={question.text}
          left={question.left}
          right={question.right}
          value={props.surveyAnswersState}
          radioLength={question.radiosLength}
          handleSurveyAnswers={props.handleSurveyAnswers}
          isInGroup={props.isInGroup}
          isSummary={props.isSummary}
        />
      );
    case 'radio-group':
      return (
        <RadioGroupFormElement
          id={question.id}
          name={props.name}
          text={question.text}
          radioLength={question.radiosLength}
          scale={question.scale}
          value={props.surveyAnswersState}
          options={question.options}
          handleSurveyAnswers={props.handleSurveyAnswers}
          isInGroup={props.isInGroup}
          isSummary={props.isSummary}
        />
      );
    case 'wrapper':
      return (
        <Wrapper
          heading={question.heading}
          subHeading={question.subHeading}
          fields={question.fields}
          surveyAnswersState={props.surveyAnswersState}
          handleSurveyAnswers={props.handleSurveyAnswers}
          isSummary={false}
        />
      );
    case 'group':
      return (
        <>
          {question.fields.map((e, index) => {
            return (
              <SurveyInputs
                key={index}
                question={e}
                name={props.name}
                surveyAnswersState={props.surveyAnswersState}
                handleSurveyAnswers={props.handleSurveyAnswers}
                isInGroup={true}
                isSummary={props.isSummary}
              />
            );
          })}
        </>
      );
    case 'survey-summary':
      return (
        <SurveySummary
          questions={question}
          surveyAnswersState={props.surveyAnswersState}
          handleSurveyAnswers={props.handleSurveyAnswers}
        />
      );
    default:
      return null;
  }
};

export default SurveyInputs;
