import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography, Button } from "@material-ui/core";
import Image from "../../../myComponents/image";
import { makeURL } from "../../../helperFunctions";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "white",
    zIndex: 1100,
  },
  loader: {
    color: "#19989B",
  },
  logoContainer: {
    position: "absolute",
    top: "30px",
    left: "30px",
  },
  logo: {
    width: "150px",
    marginBottom: "40px",
  },
  sessionExpiredContainer: {
    borderRadius: "20px",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    flexDirection: "column",
    padding: "30px",
    backgroundColor: "#E6F3F3",
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translateX(-50%) translateY(-60%)",
  },
  sessionExpiredText: {
    fontSize: "18px",
    textTransform: "none",
    marginBottom: "40px",
    color: "#515151",
  },
  goToLoginButton: {
    padding: "8px 30px",
    color: "white",
    fontWeight: 300,
    order: 1,
    backgroundColor: "#19989B",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#82C2C3",
    },
  },
}));

const SessionExpired = () => {
  const classes = useStyles();
  const unauthorizedRedirectURL = "https://www.brandsocialvalue.com/my-account/";

  const buttonClick = () => {
    window.location.href = unauthorizedRedirectURL;
  };

  return (
    <Box className={classes.mainContainer}>
      <Box className={classes.logoContainer}>
        <Image
          src={makeURL("/images/logos/BSV_Grey_no_background.png")}
          className={classes.logo}
        />
      </Box>
      <Box className={classes.sessionExpiredContainer}>
        <Typography variant="body2" className={classes.sessionExpiredText}>
          Your login session has expired. Please login again.
        </Typography>
        <Button
          size="medium"
          onClick={buttonClick}
          className={classes.goToLoginButton}
        >
          Go to login
        </Button>
      </Box>
    </Box>
  );
};

export default SessionExpired;
