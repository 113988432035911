import axios from './axiosConfig';

export const submitSurvey = async (collectionType, surveyAnswers) => {
  const url = '/fs/surveys/create';
  const payload = {
    surveyAnswers,
    collectionType,
  };
  const { data } = await axios.post(url, payload);
  return data;
};

export const deleteSurvey = async (collectionType, surveyId) => {
  const url = '/fs/surveys/delete';
  const payload = {
    collectionType,
    surveyId,
  };

  const { data } = await axios.delete(url, { data: payload });

  return data;
};

export const updateSurvey = async (collectionType, surveyId, updatedSurveyData) => {
  const url = '/fs/surveys/edit';
  const payload = {
    collectionType,
    surveyId,
    updatedSurveyData,
  };

  const { data } = await axios.put(url, payload);

  return data;
};

export const AITextGenerator = (brandName, platform, title, keyword1, keyword2, keyword3) => {
  return new Promise((resolve, reject) => {
    const url = '/ai/social-media-content';
    const data = { brandName, platform, title, keyword1, keyword2, keyword3 };
    axios
      .post(url, data)
      .then(response => {
        if (!response.data.error) {
          resolve(response.data.result);
        } else {
          reject('An error occurred while calulcating the data');
        }
      })
      .catch(err => {
        console.log(err);
        reject('An error occurred while generating AI post');
      });
  });
};

export const AIProductSocialCausesAnswerGenerator = (onlyProduct, postText) => {
  return new Promise((resolve, reject) => {
    const url = onlyProduct ? '/ai/product-clasify' : '/ai/social-and-causes-clasify';
    axios
      .post(url, { postText })
      .then(response => {
        if (!response.data.error) {
          resolve(response.data.result);
        } else {
          reject('An error occurred while calulcating the data');
        }
      })
      .catch(err => {
        console.log(err);
        reject('An error occurred while generating AI post');
      });
  });
};

const updateSocialPostMarketingStrategy = async (socialPostID, brandPersonaID, newStrategy, oldStrategies) => {
  try {
    if (typeof oldStrategies === 'undefined') oldStrategies = {};
    const newData = {
      marketingStrategies: {
        ...oldStrategies,
        [brandPersonaID]: newStrategy,
      },
    };

    const response = await updateSurvey('social', socialPostID, newData);
    if (!response.err) {
      return {
        err: false,
        errMessage: '',
      };
    } else {
      return {
        err: true,
        errMessage: response.errMessage,
      };
    }
  } catch (err) {
    return {
      err: true,
      errMessage: err.message,
    };
  }
};

export const AISocialMediaStrategyGenerator = (
  postTitle,
  brandName,
  platform,
  postText,
  socialPostPickedId,
  brandPersonaPickedId,
  marketingStrategies,
) => {
  return new Promise((resolve, reject) => {
    const url = '/ai/social-media-strategy';
    const data = { postTitle, brandName, platform, postText };
    axios
      .post(url, data)
      .then(response => {
        if (!response.data.error) {
          updateSocialPostMarketingStrategy(
            socialPostPickedId,
            brandPersonaPickedId,
            response.data.result,
            marketingStrategies,
          )
            .then(res => {
              if (!res.err) {
                resolve(response.data.result);
              } else {
                console.log(res.errMessage);
                reject('An error occurred while trying to save marketing strategy.');
              }
            })
            .catch(err => {
              console.log(err.message);
              reject('An error occurred while trying to save marketing strategy.');
            });
        } else {
          reject('An error occurred while calulcating the data');
        }
      })
      .catch(err => {
        console.log(err);
        reject('An error occurred while generating AI strategy');
      });
  });
};

export const checkAuthState_API = async () => {
  const response = await axios.get('/auth/check');
  return response;
};

export const AIChatBot = messages => {
  return new Promise((resolve, reject) => {
    const url = '/ai/chat-ask';
    const data = { messages };
    axios
      .post(url, data)
      .then(response => {
        if (!response.data.error) {
          resolve(response.data.result);
        } else {
          reject('An error occurred while generating response');
        }
      })
      .catch(err => {
        reject('An error occurred while connecting to the chat assistant');
      });
  });
};
