import { convertRange } from '../../../../helperFunctions';

import { dataCodeFields } from '../../../../config/dataCodes';

export const generateSalesGaugeData = (type, data) => {
  switch (type) {
    case 'brand':
      let generatedData = {
        brandPerformance: [],
        socialValueDimensions: [],
      };
      //brandPerformance
      generatedData.brandPerformance.push({
        body1: {
          headingText: 'Social Values Match',
          bodyText:
            'Match between Brand Social Values and Consumer Social Values',
        },
        data: [
          {
            category: 'VALUES MATCH',
            value: Number((data.Values_Match * 100).toFixed(0)),
            full: 100,
          },
        ],
      });
      generatedData.brandPerformance.push({
        body1: {
          headingText: 'Total Match',
          bodyText:
            'An index based on the combination of Social Values Match and Product Benefits Match',
        },
        data: [
          {
            category: 'TOTAL MATCH',
            value: Number((data.Total_Match * 100).toFixed(0)) || 71,
            full: 100,
          },
        ],
      });
      generatedData.brandPerformance.push({
        body1: {
          headingText: 'Product Benefits Match',
          bodyText:
            'Match between Brand Product Benefits and Consumer Preferred Product Benefits Values',
        },
        data: [
          {
            category: 'BENEFITS MATCH',
            value: Number((data.Product_Match * 100).toFixed(0)),
            full: 100,
          },
        ],
      });

      //socialValueDimensions
      generatedData.socialValueDimensions.push({
        body1: {
          headingText: 'Internal vs External Focus Match',
          bodyText:
            'Measures if consumers and brands focus more on personal benefits or society around them.',
        },
        data: [
          {
            category: 'FOCUS MATCH',
            value: Number((data.Comp_IE_Match * 100).toFixed(0)),
            full: 100,
          },
        ],
      });
      generatedData.socialValueDimensions.push({
        body1: {
          headingText: 'Ideology Match',
          bodyText:
            'Positions consumers and brands take on a traditional liberal/conservative scale, considering the economic, social, and safety dimensions of ideology.',
        },
        data: [
          {
            category: 'IDEOLOGY MATCH',
            value: Number((data.Comp_Ideology_Match * 100).toFixed(0)),
            full: 100,
          },
        ],
      });
      generatedData.socialValueDimensions.push({
        body1: {
          headingText: 'Social Issues Match',
          bodyText:
            "Measures consumers' and brands' positions regarding the most important 11 social causes that are commonly used in political research., including gender inequality, state of the economy, climate change, etc",
        },
        data: [
          {
            category: 'SOCIAL ISSUES',
            value: Number((data.Issues_Match * 100).toFixed(0)),
            full: 100,
          },
        ],
      });

      return generatedData;
    default:
      return null;
  }
};

export const generateRangeChartData = (persona, batchData, type) => {
  return Object.keys(batchData).map((el, index) => {
    let personaText = el.replace('Personal', 'Brand_');
    if (personaText === 'Brand_SocialInovat')
      personaText = 'Brand_SocialInovation';
    let personaValue = Math.round(Number(persona[personaText]) * 100);
    let batchValue = Math.round(Number(batchData[el]) * 100);
    let displayData =
      type === 'product'
        ? productBenefitsRangeToDisplayData(
            index,
            convertRange(Number(persona[personaText]), [0, 1], [-1, 1]),
            convertRange(Number(batchData[el]), [0, 1], [-1, 1])
          )
        : socialRangeToDisplayData(index);
    return {
      ...displayData,
      personaValue: convertRange(personaValue, [0, 100], [-100, 100]),
      batchValue: convertRange(batchValue, [0, 100], [-100, 100]),
    };
  });
};

const productTextConfig = [
  {
    leftText: 'Price',
    rightText: 'Quality',
  },
  {
    leftText: 'Design',
    rightText: 'Functionality',
  },
  {
    leftText: 'Polite Service',
    rightText: 'Fast Service',
  },
  {
    leftText: 'Professionalism',
    rightText: 'Easy Going',
  },
  {
    leftText: 'Durability',
    rightText: 'Affordability',
  },
  {
    leftText: 'Uniqueness',
    rightText: 'Compatibility',
  },
  {
    leftText: 'Privacy',
    rightText: 'Personalization',
  },
  {
    leftText: 'Convenience',
    rightText: 'Exclusivity',
  },
  {
    leftText: 'Core Product',
    rightText: 'Customer Support',
  },
  {
    leftText: 'Experience',
    rightText: 'Ownership',
  },
];

const productBenefitsRangeToDisplayData = (
  index,
  brandValue,
  consumerValue
) => {
  const body = getProductBenefitsRangeText(index, brandValue, consumerValue);
  return { ...productTextConfig[index], body };
};

const getProductBenefitsRangeText = (index, brandValue, consumerValue) => {
  const leftText = productTextConfig[index].leftText.toLocaleLowerCase();
  const rightText = productTextConfig[index].rightText.toLocaleLowerCase();

  let consumerTextPart = '';
  let brandTextPart = '';
  if (consumerValue < -0.1) {
    consumerTextPart = `Consumers value ${leftText} over ${rightText}. `;
    if (brandValue - consumerValue < -0.1) {
      brandTextPart = `To improve Product Benefit Match, brand persona should place less value on ${leftText}.`;
    } else if (brandValue - consumerValue > 0.1) {
      brandTextPart = `To improve Product Benefit Match, brand persona should place more value on ${leftText}.`;
    } else {
      brandTextPart = `This brand persona values ${leftText} similarly to consumers.`;
    }
  } else if (consumerValue > 0.1) {
    consumerTextPart = `Consumers value ${rightText} over ${leftText}. `;
    if (brandValue - consumerValue < -0.1) {
      brandTextPart = `To improve Product Benefit Match, brand persona should place more value on ${rightText}.`;
    } else if (brandValue - consumerValue > 0.1) {
      brandTextPart = `To improve Product Benefit Match, brand persona should place less value on ${rightText}.`;
    } else {
      brandTextPart = `This brand persona values ${rightText} similarly to consumers.`;
    }
  } else {
    consumerTextPart = `Consumers value ${rightText} and ${leftText} equally. `;
    if (brandValue - consumerValue < -0.1) {
      brandTextPart = `To improve Product Benefit Match, brand persona should balance between ${leftText} and ${rightText}.`;
    } else if (brandValue - consumerValue > 0.1) {
      brandTextPart = `To improve Product Benefit Match, brand persona should balance between ${leftText} and ${rightText}.`;
    } else {
      brandTextPart = `This brand persona values ${leftText} and ${rightText} similarly to consumers.`;
    }
  }

  return consumerTextPart + brandTextPart;
};

const socialRangeToDisplayData = (index) => {
  const configObject = [
    {
      leftText: 'Local',
      rightText: 'Global',
      heading: 'Perspective',
      body:
        'Comparisons of perspectives consumers and brands take. Local perspective is more about local and national approach, while global is more international and cosmopolitan.',
    },
    {
      leftText: 'Internal',
      rightText: 'External',
      heading: 'Focus',
      body:
        'Comparison of consumers’ and brand’s focus  on personal benefits (internal) or society around them (external).',
    },
    {
      leftText: 'Liberal',
      rightText: 'Conservative',
      heading: 'Ideology',
      body:
        'Comparison of liberal vs conservative ideological split  between consumers and brands.',
    },
    {
      leftText: 'High',
      rightText: 'Low',
      heading: 'Social Innovativeness',
      body:
        'Comparison of social innovativeness between consumers and brands. ',
    },
  ];
  return configObject[index];
};

export const cleanPersonaAndBatchData = (persona, batch) => {
  let batchFields = [];
  let batchKeys = Object.keys(batch);

  let personaFields = [];
  let personaKeys = Object.keys(persona);
  Object.keys(dataCodeFields).forEach((dataCodeKey) => {
    let foundPersonaKey = personaKeys.find(
      (personaKey) => dataCodeKey === personaKey
    );
    let foundBatchKey = batchKeys.find((batchKey) => dataCodeKey === batchKey);
    if (dataCodeFields[foundPersonaKey]) {
      let foundElement = dataCodeFields[foundPersonaKey].find(
        (dataCodeElement) => dataCodeElement.value === persona[foundPersonaKey]
      );
      personaFields.push(foundElement.text);
    } else {
      if (dataCodeFields[foundPersonaKey] === null) {
        personaFields.push(persona[foundPersonaKey]);
      } else {
        personaFields.push('-');
      }
    }

    if (dataCodeFields[foundBatchKey]) {
      let foundElement = dataCodeFields[foundBatchKey].find(
        (dataCodeElement) => dataCodeElement.value === batch[foundBatchKey]
      );
      batchFields.push(foundElement.text);
    } else {
      if (dataCodeFields[foundBatchKey] === null) {
        batchFields.push(batch[foundBatchKey] || "-");
      } else {
        batchFields.push('-');
      }
    }
  });

  return [personaFields, batchFields];
};

export const makeDataStructureForDataTabel = (criteria, persona, batch) => {
  const cleanData = [];
  for (let index = 0; index < criteria.length; index++) {
    cleanData.push({
      criteria: criteria[index],
      personaInfo: persona[index],
      batchInfo: batch[index],
    });
  }
  return cleanData;
};

export const generateRadarChartData = (
  persona,
  type,
  data,
  subjects,
  labels
) => {
  switch (persona) {
    case 'brand':
      if (type === 'single') {
        return subjects.map((subject, index) => ({
          subject: subject,
          A: Number((data[index] * 100).toFixed(0)),
          fullMark: 100,
        }));
      } else {
        return subjects.map((subject, index) => {
          const count = index + 1;
          const AValue = Number(
            (data[1]['Brand_Issues_' + count] * 100).toFixed(0)
          );
          const BValue = Number(
            (data[0]['PersonalIssues_' + count] * 100).toFixed(0)
          );
          return {
            subject,
            [labels[0]]: AValue ? AValue : 1,
            [labels[1]]: BValue ? BValue : 1,
            fullMark: 100,
          };
        });
      }
    default:
      return null;
  }
};

export const brandDashboardRadarChartSubjects = {
  brandMatch: [
    'Internal vs External Focus Match',
    'Social Causes,Match',
    'Ideology Match',
    'Social Innovation Match',
    'Product,Benefits Match',
  ],
  socialIssues: [
    'State of the Economy',
    'Free Healthcare',
    'Violent Crime',
    'World Peace',
    'Gun Laws',
    'Race and Ethnic Inequality',
    'Gender Inequality',
    'Immigration',
    'Economic Inequality',
    'Climate Change',
    'Corporate Corruption',
  ],
};

export const socialValueDimensionsExplanations = [
  {
    heading: 'Internal vs External Focus Match',
    body:
      'Measures if consumers and brands focus more on personal benefits or society around them.',
  },
  {
    heading: 'Social Causes Match',
    body:
      "Measures consumers' and brands' positions regarding the most important 11 social causes that are commonly used in political research., including gender inequality, state of the economy, climate change, etc",
  },
  {
    heading: 'Ideology Match',
    body:
      'Positions consumers and brands take on a traditional liberal/conservative scale, considering the economic, social, and safety dimensions of ideology.',
  },
  {
    heading: 'Social Innovation Match',
    body:
      'Measures how much match exists between the level of social innovativeness of consumers and brands. ',
  },
];

export const getProductBenefitsSubHeading = () => {
  return `Positions consumers and brands take on a traditional liberal/conservative scale, considering the economic, social, and safety dimensions of ideology.`;
};

export const getCompetitiveMapText = () => {
  return {
    chartLabels: [
      'Business Associate',
      'Reliable Partner',
      'True Friend',
      'Distant Cousin',
      'Love Affair',
    ],
    XYAxisLabels: {
      y: 'Product Benefits Match',
      x: 'Social Values Match',
    },
    explanations: [
      {
        heading: 'Reliable Partner',
        body:
          'Reliable Partners together with True Friends are the best performing brands. These brands provide high product value and benefits that meet consumer expectations while maintaining high similarity in social values with US consumers.',
      },
      {
        heading: 'True Friend',
        body:
          'True Friends together with Reliable Partners are the best performing brands with the strongest relationships with their consumers. These brands establish a strong emotional connection with consumers through matching social values while having acceptably high product benefits.',
      },
      {
        heading: 'Business Associate',
        body:
          'Business Associates are reliable brands that provide strong product value and meet the product and service expectations of consumers without establishing an emotional connection. These brands are often disliked by consumers but still purchase their products since they fulfill their needs.',
      },
      {
        heading: 'Love Affair',
        body:
          'Love Affair brands establish a strong relationship with their customers based primarily on matching social values while the value of the product benefits is more limited. Often consumers express their love and affection for these brands without even purchasing their products.',
      },
      {
        heading: 'Distant Cousin',
        body:
          'Distant Cousins are underperforming brands that do not provide sufficient product benefits and have social values that do not resonate well with their consumers. Consumers purchase or use their products out of necessity or in situations where no other option is available. Consumers are more likely to have negative feelings toward these brands and do not believe they provide sufficient value.',
      },
    ],
  };
};

export const getRealAndFakedCompetitiveValue = ({ x, y }) => {
  const scale = {
    x: [50, 80],
    y: [50, 70],
  };

  const convertedValues = {
    x,
    y,
    realX: x,
    realY: y,
    z: 400,
  };

  if (x < scale.x[0]) {
    convertedValues.x = scale.x[0];
    convertedValues.realX = x;
  }
  if (x > scale.x[1]) {
    convertedValues.x = scale.x[1];
    convertedValues.realX = x;
  }

  if (y < scale.y[0]) {
    convertedValues.y = scale.y[0];
    convertedValues.realY = y;
  }
  if (y > scale.y[1]) {
    convertedValues.y = scale.y[1];
    convertedValues.realY = y;
  }

  return convertedValues;
};

export const getTop3SocialCauses = (causes) => {
  // Sort the list based on "2022 Consumers" value in descending order
  const sortedList = causes.sort(
    (a, b) => b['SELECTED SAMPLE'] - a['SELECTED SAMPLE']
  );

  // Return the top 3 objects
  return sortedList.slice(0, 3);
};

export const getTop3SocialCauses_ConsumerDashboard = (causes) => {
  // Sort the list based on "2022 Consumers" value in descending order
  const sortedList = causes.sort((a, b) => b['A'] - a['A']);

  // Return the top 3 objects
  return sortedList.slice(0, 3);
};

export const getIndustryAverage = (field, data) => {
  let values = data.map((industry) => industry.match[field]);
  let sum = values.reduce((a, b) => a + b, 0);
  return sum / values.length;
};

export const getValuesMatchText = (personaValue, industryValue) => {
  const calculatedValue = personaValue - industryValue;
  if (calculatedValue > 0.02) {
    return 'This brand persona has social values match above the industry average.';
  } else if (0.02 >= calculatedValue && calculatedValue >= -0.02) {
    return 'This brand persona has social values match close to the industry average.';
  } else {
    return 'This brand persona has social values match below the industry average.';
  }
};

export const getTotalMatchText = (personaValue, industryValue) => {
  const calculatedValue = personaValue - industryValue;
  if (calculatedValue > 0.02) {
    return 'This brand persona has total match match above the industry average.';
  } else if (0.02 >= calculatedValue && calculatedValue >= -0.02) {
    return 'This brand persona has total match match close to the industry average.';
  } else {
    return 'This brand persona has total match match below the industry average.';
  }
};

export const getProductBenefitsMatchText = (personaValue, industryValue) => {
  const calculatedValue = personaValue - industryValue;
  if (calculatedValue > 0.02) {
    return 'This brand persona has product benefits match above the industry average.';
  } else if (0.02 >= calculatedValue && calculatedValue >= -0.02) {
    return 'This brand persona has product benefits match close to the industry average.';
  } else {
    return 'This brand persona has product benefits match below the industry average.';
  }
};
