import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardHeader, CardContent, Grid, Button, Typography, Box, CircularProgress } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { Edit, Visibility, DeleteOutline } from '@material-ui/icons';
import DeletionDialog from '../../../myComponents/deletionDialog/DeletionDialog';

import { timeSince } from '../../../helperFunctions';
import useViewportWidth from '../../../myHooks/useViewportWidth';
import { getSurveysWithExtractedFields } from 'helperFunctions/getSurveysWithExtractedFields';
import usePagination from 'myHooks/usePagination';
import { Pagination } from '@material-ui/lab';

const useStyles = makeStyles(theme => ({
  cardWrapper: {
    flex: '1',
    width: '100%',
    '@media (max-width: 1000px)': {
      width: '100%',
      margin: '0 auto',
    },
    '@media (max-width: 700px)': {
      width: '100%',
      '& .MuiGrid-root.MuiGrid-container': {
        flexWrap: 'nowrap !important',
      },
      '& .MuiTypography-root.MuiTypography-body2.MuiTypography-colorTextSecondary': {
        textAlign: 'left !important',
      },

      h6: {
        textAlign: 'left !important',
      },
    },
  },
  card: {
    backgroundColor: 'white',
    boxShadow: '0px 2px 2px 1px #B4B4B4',
    borderRadius: '5px',
    height: '100%',
  },
  cardHeader: {
    backgroundColor: '#E6F3F3',
    paddingBottom: 5,
  },
  cardHeaderInner: {
    alignItems: 'center',
  },
  heading: {
    fontWeight: 'bold',
    margin: 0,
    padding: '5px 0',
    marginLeft: '-6px',
    '@media (max-width: 600px)': {
      fontSize: '13px',
    },
  },
  cardContent: {
    padding: 0,
  },
  gridContainer: {
    padding: '10px',
    borderBottom: '1px solid lightgray',
    flexWrap: 'nowrap !important',
  },
  gridItem: {
    textAlign: 'left',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },

    '& h6': {
      textAlign: 'left',
    },

    '& button': {
      minWidth: '32px',
    },
  },
  headingGridButtonStyle: {
    padding: '5px 10px',
    margin: 0,
    '@media (max-width: 600px)': {
      fontSize: '11px',
      whiteSpace: 'nowrap',
      margin: 0,
      padding: '5px 0px 5px 8px',
    },
  },
  viewAll: {
    padding: '5px 10px !important',
    margin: 0,
    '@media (max-width: 600px)': {
      fontSize: '11px',
      whiteSpace: 'nowrap',
      margin: 0,
      padding: '5px 0px 5px 8px !important',
      flexGrow: '1 !important',
      textAlign: 'right',
    },
  },
  plusIcon: {
    '@media (max-width: 600px)': {
      fontSize: '14px',
    },
  },
  hover: {
    '&:hover': {
      backgroundColor: '#E6F3F3',
      cursor: 'pointer',
    },
  },
  noDataText: {
    fontSize: '20px',
    margin: '0',
    padding: '0',
    textAlign: 'center',
    fontWeight: 'bold',
  },
  noDataContainer: {
    height: '200px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  gridItemButtons: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tableHeaderText: {
    '@media (max-width: 600px)': {
      fontSize: '11px',
      whiteSpace: 'nowrap',
    },
  },
  tableNameColumn: {
    '@media (max-width: 600px)': {
      fontSize: '12px',
    },
  },
  lastEdited: {
    '@media (max-width: 600px)': {
      fontSize: '11px',
      whiteSpace: 'nowrap',
    },
  },
  impactText: {
    '@media (max-width: 600px)': {
      fontSize: '11px',
      whiteSpace: 'nowrap',
    },
  },
  pagnationUlStyle: {
    '& .MuiPaginationItem-root': {
      '&.Mui-selected': {
        background: '#19989B',
        color: '#fff',
      },
    },
  },
}));

const RecentCardSmall = ({
  heading,
  type,
  listSections,
  surveyData,
  onCreateNew,
  onViewAll,
  onEdit,
  viewSpecific,
  isLoading,
  showImpact = true,
  isHomePage = false,
  brandPersonas,
}) => {
  const classes = useStyles();

  const width = useViewportWidth();

  const [dialogOpen, setDialogOpen] = useState(false);
  const [page, setPage] = useState(1);

  const [deletionSurvey, setDeletionSurvey] = useState('');
  const [surveyDataState, setSurveyDataState] = useState(surveyData || []);

  useEffect(() => {
    if (isLoading === false) {
      setSurveyDataState(surveyData);
    }
  }, [surveyData, isLoading]);

  const truncateText = text => {
    if (width < 450) {
      return text.length > 11 ? text.substring(0, 11).concat('...') : text;
    }

    if (width > 768) {
      return text.length > 30 ? text.substring(0, 30).concat('...') : text;
    }

    if (text.length > 14) {
      return text.substring(0, 14).concat('...');
    }
    return text;
  };

  const handlePaginationChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  const isSocial = type == 'social';

  const PER_PAGE = 10;
  const count = Math.ceil(surveyDataState.length / PER_PAGE);
  const _DATA = usePagination(surveyDataState, PER_PAGE);

  const dataExists = _DATA.currentData().length && !isLoading ? true : false;
  const surveysWithExtractedFields = getSurveysWithExtractedFields(_DATA, dataExists, type, brandPersonas);

  return (
    <Box className={classes.cardWrapper}>
      <Card className={classes.card}>
        <CardHeader
          className={classes.cardHeader}
          title={
            <Grid container justifyContent="space-between" className={classes.cardHeaderInner} spacing={3}>
              <Grid item xs="auto">
                <Typography variant="h6" className={classes.heading}>
                  {heading}
                </Typography>
              </Grid>
              <Grid item xs="auto" className={classes.viewAll}>
                <Button variant="text" onClick={onViewAll}>
                  <Typography
                    variant="body2"
                    style={{
                      color: '#2EA0A3',
                      margin: 0,
                      textTransform: 'none',
                    }}>
                    View All
                  </Typography>
                </Button>
              </Grid>
              <Grid item xs container justifyContent="flex-end">
                <Button
                  variant="text"
                  className={classes.headingGridButtonStyle}
                  style={{ color: '#2EA0A3' }}
                  onClick={onCreateNew}>
                  <AddIcon className={classes.plusIcon} style={{ marginRight: '5px' }} />
                  Create new
                </Button>
              </Grid>
            </Grid>
          }
        />
        <CardContent className={classes.cardContent}>
          <Grid
            container
            style={{
              padding: '10px',
              borderBottom: '1px solid lightgray',
              flexWrap: 'nowrap',
            }}>
            {listSections.map((section, index) => {
              return (
                <Grid
                  container
                  key={index}
                  item
                  xs={12}
                  sm={showImpact ? 3 : 4}
                  className={classes.gridItem}
                  justifyContent={index === 0 ? 'flex-start' : 'center'}>
                  <Typography variant="body2" color="textSecondary" className={classes.tableHeaderText}>
                    {section}
                  </Typography>
                </Grid>
              );
            })}
          </Grid>
          {dataExists && surveysWithExtractedFields ? (
            <>
              {surveysWithExtractedFields.map((survey, index) => {
                return (
                  <Grid container key={index} className={`${classes.gridContainer} ${classes.hover}`}>
                    <Grid item xs={12} sm={showImpact ? 3 : 4} className={classes.gridItem} container alignItems="center">
                      <Typography variant="subtitle1" className={classes.tableNameColumn}>
                        {isSocial ? truncateText(survey.postTitle) : truncateText(survey.Name)}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={showImpact ? 3 : 4}
                      className={classes.gridItem}
                      container
                      alignItems="center"
                      justifyContent="center">
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        className={classes.lastEdited}
                        style={{ fontSize: '12px' }}>
                        {timeSince(survey.lastEdited._seconds)}
                      </Typography>
                    </Grid>
                    {showImpact && (
                      <Grid
                        item
                        xs={12}
                        sm={3}
                        className={classes.gridItem}
                        container
                        alignItems="center"
                        justifyContent="center">
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          className={classes.impactText}
                          style={{ fontSize: '12px', paddingLeft: isSocial ? 0 : '20px' }}>
                          {isSocial ? survey.impact : survey.totalMatch}%
                        </Typography>
                      </Grid>
                    )}
                    <Grid
                      container
                      item
                      xs={12}
                      sm={showImpact ? 3 : 4}
                      className={`${classes.gridItemButtons} ${classes.gridItem}`}
                      wrap="nowrap"
                      direction={width > 500 ? 'row' : 'column'}>
                      <Button variant="text" onClick={() => viewSpecific(survey.id)}>
                        <Visibility style={{ color: '#7B7B7B', fontSize: '20px' }} />
                      </Button>
                      <Button variant="text" onClick={() => onEdit(survey.id)}>
                        <Edit style={{ color: '#7B7B7B', fontSize: '20px' }} />
                      </Button>
                      <Button
                        variant="text"
                        onClick={() => {
                          setDialogOpen(true);
                          setDeletionSurvey(survey.id);
                        }}>
                        <DeleteOutline style={{ color: '#7B7B7B', fontSize: '20px' }} />
                      </Button>
                    </Grid>
                  </Grid>
                );
              })}
              <DeletionDialog open={dialogOpen} setDialogOpen={setDialogOpen} type={type} deletionID={deletionSurvey} />
            </>
          ) : (
            <Box className={classes.noDataContainer}>
              {isLoading ? (
                <CircularProgress size={30} style={{ color: '#19989B' }} />
              ) : (
                <Typography variant="h6" color="textSecondary" className={classes.noDataText}>
                  Nothing was created yet.
                </Typography>
              )}
            </Box>
          )}
        </CardContent>
      </Card>
      {_DATA.currentData().length && isHomePage === false ? (
        <Box display="flex" justifyContent="flex-end" style={{ marginTop: '24px' }}>
          <Pagination
            classes={{ ul: classes.pagnationUlStyle }}
            siblingCount={1}
            count={count}
            size="medium"
            page={page}
            variant="outlined"
            shape="rounded"
            onChange={handlePaginationChange}
          />
        </Box>
      ) : null}
    </Box>
  );
};

export default RecentCardSmall;
