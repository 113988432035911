import React from 'react';
import clsx from 'clsx';

import { Box, Slide, Typography, makeStyles } from '@material-ui/core';
import UserDropdownHeader from '../userDropdownHeader';

const useStyles = makeStyles(theme => ({
  pageFull: {
    width: '100%',
    overflowX: 'hidden',
  },
  pageHeaderRoot: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      alignItems: 'center',
      flexDirection: 'row',
    },
  },
  titleRoot: {
    fontSize: '35px',
    fontWeight: 200,
    color: theme.palette.text.secondary,
  },
}));

const PageContainer = ({ heading, headingStyle, children, className, restProps }) => {
  const classes = useStyles();

  return (
    <Slide in={true} direction="up" mountOnEnter unmountOnExit>
      <Box className={clsx(classes.pageFull, className)} {...restProps}>
        {heading && (
          <>
            <UserDropdownHeader bgColor={'#f4f4f7'} />
            <Box className={clsx(classes.pageHeaderRoot, 'page-header')} mb={{ xs: 5, md: 6, lg: 8 }}>
              <Typography component="div" variant="h1" className={clsx(classes.titleRoot, 'title')} style={headingStyle}>
                {heading}
              </Typography>
            </Box>
          </>
        )}
        {children}
      </Box>
    </Slide>
  );
};

export default PageContainer;
