import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Box, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    width: 'fit-content',
    margin: '0 auto',
  },
  text: ({ questionIsEnabled }) => ({
    color: !questionIsEnabled
      ? theme.palette.text.disabled
      : theme.palette.text.primary,
    fontWeight: 300,
    fontSize: '18px',
    marginBottom: '15px',
    maxWidth: '705px',
    margin: '0 auto',
    textAlign: 'center',
    '@media (max-width: 600px)': {
      fontSize: '16px',
      marginInline: 0,
    },
  }),
  buttons: {
    padding: '10px 20px',
    marginBottom: '20px',
    border: '2px solid #A6A6A6',
    borderRadius: '5px',
    width: '100%',
    maxWidth: '380px',
  },
  maxHeight: {
    '@media (max-width: 1380px)': {
      maxHeight: '420px',
      width: "fit-content"
    },
    '@media (max-width: 1010px)': {
      maxHeight: 'unset',
      width: '100%',
    },
  },
}));

const ButtonMultiple = (props) => {
  const questionIsEnabled = !props.dependancySelection
    ? true
    : props.dependancySelection.value.find(
        (value) =>
          Number(value) ===
          Number(props.value[props.dependancySelection.dependancy_question_id])
      );

  const classes = useStyles({ questionIsEnabled });

  const onClickHandler = (id) => {
    if (props.value[id]) {
      props.handleSurveyAnswers(null, id);
    } else {
      props.handleSurveyAnswers(1, id);
    }
  };

  return (
    <Box className={classes.mainContainer}>
      <Typography component='div' variant='h2' className={classes.text}>
        {props.text}
      </Typography>
      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        margin='40px auto 0 auto'
        width='fit-content'
        maxHeight='320px'
        flexWrap='wrap'
        alignContent='center'
        style={{ columnGap: '25px' }}
        className={classes.maxHeight}
      >
        {props.options.map((option, index) => {
          return (
            <Button
              disabled={!questionIsEnabled}
              key={index}
              onClick={() => onClickHandler(props.id[index])}
              className={classes.buttons}
              style={{
                backgroundColor:
                  !questionIsEnabled && props.value[props.id[index]]
                    ? '#C0C0C0'
                    : questionIsEnabled && props.value[props.id[index]]
                    ? '#19989B'
                    : 'white',
                border: `2px solid ${
                  !questionIsEnabled
                    ? '#C0C0C0'
                    : props.value[props.id[index]]
                    ? '#19989B'
                    : '#A6A6A6'
                }`,
              }}
            >
              <p
                style={{
                  margin: 0,
                  color: props.value[props.id[index]]
                    ? 'white'
                    : questionIsEnabled
                    ? 'black'
                    : '#C0C0C0',
                  textTransform: 'none',
                  fontWeight: 300,
                  fontSize: '14px',
                  letterSpacing: '1px',
                }}
              >
                {option}
              </p>
            </Button>
          );
        })}
      </Box>
    </Box>
  );
};

export default ButtonMultiple;
