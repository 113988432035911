import { FIREBASE_INIT_APP } from '../actions/actionTypes';

const INIT_STATE = {
  loading: true,
  firebaseAuth: null,
  firebaseFirestore: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FIREBASE_INIT_APP: {
      return {
        loading: false,
        firebaseAuth: action.auth,
        firebaseFirestore: action.firestore,
      };
    }
    default:
      return state;
  }
};
