import React, { useContext } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import SidebarLogo from './SidebarLogo';
import SidebarThemeContext from '../../../contexts/SidebarThemeContext/SidebarThemeContext';
import LayoutContext from '../../../contexts/LayoutContext/LayoutContext';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '30px 16px 12px 16px',
    borderBottom: props => `solid 1px ${props.sidebarTheme.borderColor}`,
  },
  userInfo: {
    paddingTop: 24,
    transition: 'all 0.1s ease',
    height: 75,
    opacity: 1,
    '.Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &': {
      height: 0,
      paddingTop: 0,
      opacity: 0,
      transition: 'all 0.3s ease',
    },
  },
  userTitle: {
    color: props => props.sidebarTheme.textDarkColor,
    marginBottom: 8,
  },
  userSubTitle: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: 0.25,
  },
}));

const SidebarHeader = () => {
  const { sidebarTheme } = useContext(SidebarThemeContext);
  const { setSidebarOpen } = useContext(LayoutContext);
  const classes = useStyles({ sidebarTheme });

  return (
    <div className={classes.root} onClick={() => setSidebarOpen(false)}>
      <SidebarLogo mr={{ xs: 2, sm: 4, lg: 6, xl: 8 }} color="white" />
    </div>
  );
};

export default SidebarHeader;
