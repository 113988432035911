import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { Box } from '@material-ui/core';
import {
  generateSalesGaugeData,
  generateAverageSalesGaugeData,
  generateRangeChartData,
  generateRadarChartData,
  brandDashboardRadarChartSubjects,
  socialValueDimensionsExplanations,
  getProductBenefitsSubHeading,
  getCompetitiveMapText,
  getTop3SocialCauses,
  getIndustryAverage,
  getValuesMatchText,
  getTotalMatchText,
  getProductBenefitsMatchText,
} from './helpers';
import { getIndustryName } from '../../../../helperFunctions';
import useViewportWidth from '../../../../myHooks/useViewportWidth';

import DashDetailsWrapper from '../../../../myComponents/dashDetailsWrapper';
import DashSectionExplanation from '../../../../myComponents/dashSectionExplanation';
import ContentSwitcher from '../../../../myComponents/contentSwitcher';
import SalesGauge from '../../../../myComponents/chart/SalesGaugeChart';
import RangeChart from '../../../../myComponents/chart/RangeChart';
import RadarChart from '../../../../myComponents/chart/RadarChart';
import ScatterChart from '../../../../myComponents/chart/ScatterChart';
import Watermark from '../../../../myComponents/watermark';
import ConsumerDataInfoTable from '../../../../myComponents/chart/dataInfoTable/consumerDataInfoTable';

const getChartSize = width => {
  return width > 1025 ? 200 : width > 768 ? 150 : width > 520 ? 120 : 100;
};

const useStyles = makeStyles(theme => ({
  radarChartContainerWidth: {
    '@media (max-width: 768px)': {
      maxWidth: '100%',
      width: '100%',
      flex: 'unset',
    },
  },
  brandPerformanceResponsive: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: '30px',
    flexWrap: 'wrap',
    width: '100%',

    '@media (max-width: 1265px)': {
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  productBenefitsWrapper: {
    '@media (max-width: 768px)': {
      width: '100%',
    },
  },
  rangeChartContainer: {
    marginTop: '50px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    width: '100%',

    '@media (max-width: 1260px)': {
      justifyContent: 'center',
    },
  },
  rangeChartWrapper: {
    maxWidth: '500px',
    margin: '0px 60px 100px 0px',
    width: '100%',
    '@media (max-width: 1240px)': {
      maxWidth: '600px',
    },
    '@media (max-width: 768px)': {
      maxWidth: '100%',
      width: '100%',
      margin: '0px 0px 60px 0px',
      padding: 0,
    },
  },
  competitiveMapExplanationContainer: {
    marginTop: '30px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    padding: '0 5% 0 5%',
    width: '100%',

    '@media (max-width: 1244px)': {
      justifyContent: 'center',
    },
  },
  brandMatchMap: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '30px',
    flexWrap: 'wrap',
    width: '100%',
    '@media (max-width: 1090px)': {
      justifyContent: 'center',
    },
  },
  brandStrengthContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: '30px',
    flexWrap: 'wrap',
    width: '100%',

    '@media (max-width: 1500px)': {
      justifyContent: 'center',
    },
  },
  socialCausesRadarChartContent: {
    flex: 1,
    margin: '30px auto 0 auto',
    maxWidth: '650px',
    '@media (max-width: 620px)': {
      width: '100%',
    },
  },
  socialCausesRadarChartText: {
    marginLeft: '40px',
    marginBottom: '65px',
    color: 'lightgray',
    fontWeight: 300,
    fontSize: '16px',
    textAlign: 'left',
    '@media (max-width: 768px)': {
      maxWidth: '420px',
      margin: '0 auto',
    },
  },
  socialCausesRadarChartDashExp: {
    marginTop: '90px',
    marginLeft: '70px',

    '@media (max-width: 768px)': {
      maxWidth: '420px',
      margin: '40px auto 0px auto',
    },
  },
  scatterChartContainer: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#254157',
    padding: '40px 40px 100px 40px',

    '@media (max-width: 1000px)': {
      padding: '40px 0px 100px 0px',
    },
    '@media (max-width: 870px)': {
      transform: 'scale(0.8)',
    },
    '@media (max-width: 700px)': {
      padding: '0',
    },
    '@media (max-width: 650px)': {
      transform: 'scale(0.7)',
    },
    '@media (max-width: 550px)': {
      transform: 'scale(0.6)',
      marginTop: '-30px',
    },
    '@media (max-width: 470px)': {
      transform: 'scale(0.5)',
      marginTop: '-80px',
      marginBottom: '-50px',
    },
    '@media (max-width: 400px)': {
      transform: 'scale(0.47)',
    },
  },
}));

const ChartCalculations = ({ data, name }) => {
  const classes = useStyles();

  const viewportWidth = useViewportWidth();
  const scatterChartWidth = viewportWidth > 1100 ? 900 : 750;

  const generatedSalesGaugeDataText = [];
  let generatedSalesGaugeData = generateSalesGaugeData(data.match);
  let generatedRangeChartSocial = generateRangeChartData(data.average.CSocialValues, data.average.SocialValues, 'social');

  let generatedRangeChartProduct = generateRangeChartData(data.average.CProduct, data.average.PersonalProduct, 'product');

  const generatedAverageSalesGaugeData = generateAverageSalesGaugeData(data.average);

  const brandMatchMapValues = [
    data.match.Comp_IE_Match,
    data.match.Issues_Match,
    data.match.Comp_Ideology_Match,
    data.match.Inovation_Match,
    data.match.Product_Match,
  ];
  const socialValueDimensionsData = generateRadarChartData(
    'single',
    brandMatchMapValues,
    brandDashboardRadarChartSubjects.brandMatch,
  );

  const multipleRadarChartDataLabels = [name, 'SELECTED SAMPLE'];
  const multipleRadarChartData = generateRadarChartData(
    'multiple',
    [data.average.PersonalIssues, data.average.CIssues],
    brandDashboardRadarChartSubjects.socialIssues,
    multipleRadarChartDataLabels,
  );

  let multipleRadarChartDataOrdered = [];
  const radarChartTemplate = [
    'State of the Economy',
    'Free Healthcare',
    'Violent Crime',
    'World Peace',
    'Gun Laws',
    'Race and Ethnic Inequality',
    'Gender Inequality',
    'Immigration',
    'Economic Inequality',
    'Climate Change',
    'Corporate Corruption',
  ];

  radarChartTemplate.forEach(subject => {
    multipleRadarChartData.forEach(el => {
      if (el.subject == subject) {
        multipleRadarChartDataOrdered = [...multipleRadarChartData, { ...el }];
        return;
      }
    });
  });

  multipleRadarChartDataOrdered.pop();

  const top3SocialCauses = getTop3SocialCauses(multipleRadarChartData);

  const productBeneitsSubHeading = getProductBenefitsSubHeading();
  const competitiveMapText = getCompetitiveMapText();
  const valuesIndustryAverage = getIndustryAverage('values', data.companies_match);
  const valuesMatchText = {
    value: valuesIndustryAverage.toFixed(2) * 100,
    text: getValuesMatchText(data.match.Values_Match, valuesIndustryAverage),
  };
  generatedSalesGaugeDataText.push(valuesMatchText);

  const totalIndustryAverage = 0.7;
  const totalMatchText = {
    value: totalIndustryAverage.toFixed(2) * 100,
    text: getTotalMatchText(data.match.Total_Match, totalIndustryAverage),
  };
  generatedSalesGaugeDataText.push(totalMatchText);

  const productIndustryAverage = getIndustryAverage('product', data.companies_match);
  const productBenefitsMatchText = {
    value: productIndustryAverage.toFixed(2) * 100,
    text: getProductBenefitsMatchText(data.match.Product_Match, productIndustryAverage),
  };
  generatedSalesGaugeDataText.push(productBenefitsMatchText);

  const industryName = getIndustryName(data?.companies_match[0]?.industryId);

  const salesGaugeUniqueIds = ['brand-performance', 'social-value-dimensions', 'brand-strength'];

  return (
    <Box>
      {/* Brand Performance */}
      <DashDetailsWrapper
        backgroundColor="white"
        expandColor="black"
        heading1={{
          text: 'Brand Performance',
          color: '#19989B',
        }}>
        <Box className={classes.brandPerformanceResponsive}>
          {viewportWidth <= 768 && (
            <Box key={1}>
              <SalesGauge
                salesGaugeData={generatedSalesGaugeData.brandPerformance[1].data}
                chartId={salesGaugeUniqueIds[0]}
                size="medium"
                color="#03DAC5"
                chartTypeColor="#6C757D"
                chartValueColor="black"
                body1={{
                  headingColor: 'black',
                  headingText: generatedSalesGaugeData.brandPerformance[1].body1.headingText,
                  bodyColor: 'gray',
                  bodyText: generatedSalesGaugeData.brandPerformance[1].body1.bodyText,
                }}
                body2={{
                  headingColor: 'black',
                  headingText: `Industry Average - ${generatedSalesGaugeDataText[1].value}%`,
                  bodyColor: 'black',
                  bodyText: generatedSalesGaugeDataText[1].text,
                }}
              />
            </Box>
          )}
          {generatedSalesGaugeData.brandPerformance.map((salesGaugeData, index) => {
            return index !== 1 || viewportWidth > 768 ? (
              <Box key={index}>
                <SalesGauge
                  salesGaugeData={salesGaugeData.data}
                  chartId={salesGaugeUniqueIds[0] + index}
                  size={index === 1 && viewportWidth > 768 ? 'large' : 'medium'}
                  color="#03DAC5"
                  chartTypeColor="#6C757D"
                  chartValueColor="black"
                  body1={{
                    headingColor: 'black',
                    headingText: salesGaugeData.body1.headingText,
                    bodyColor: 'gray',
                    bodyText: salesGaugeData.body1.bodyText,
                  }}
                  body2={{
                    headingColor: 'black',
                    headingText: `Industry Average - ${generatedSalesGaugeDataText[index].value}%`,
                    bodyColor: 'black',
                    bodyText: generatedSalesGaugeDataText[index].text,
                  }}
                />
              </Box>
            ) : null;
          })}
        </Box>
      </DashDetailsWrapper>

      {/* Social Value Dimensions */}
      <DashDetailsWrapper
        backgroundColor="#254157"
        expandColor="white"
        heading1={{
          text: 'Social Value Dimensions',
          color: 'white',
        }}>
        <ContentSwitcher
          headingText="BRAND MATCH MAP"
          headingColor="white"
          type="brand"
          content1={
            <Box className={classes.brandPerformanceResponsive}>
              {generatedSalesGaugeData.socialValueDimensions.map((salesGaugeData, index) => {
                return (
                  <Box key={index}>
                    <SalesGauge
                      salesGaugeData={salesGaugeData.data}
                      chartId={salesGaugeUniqueIds[1] + index}
                      size={index === 1 && viewportWidth > 768 ? 'large' : 'medium'}
                      color="#03DAC5"
                      chartTypeColor="white"
                      chartValueColor="white"
                      body1={{
                        headingColor: 'white',
                        headingText: salesGaugeData.body1.headingText,
                        bodyColor: 'lightgray',
                        bodyText: salesGaugeData.body1.bodyText,
                      }}
                    />
                  </Box>
                );
              })}
            </Box>
          }
          content2={
            <Box className={classes.brandMatchMap}>
              <Box display="flex" flexDirection="column" justifyContent="space-between" alignItems="flex-start">
                {socialValueDimensionsExplanations.map((explanation, index) => (
                  <Box marginBottom="20px" maxWidth="350px" key={index}>
                    <DashSectionExplanation
                      heading={explanation.heading}
                      headingColor="white"
                      body={explanation.body}
                      bodyColor="lightgray"
                    />
                  </Box>
                ))}
              </Box>
              <Box className={classes.radarChartContainerWidth} flex={1} margin="auto" height="480px" maxWidth="600px">
                <RadarChart
                  personaType="brand"
                  type="single"
                  data={socialValueDimensionsData}
                  size={getChartSize(viewportWidth)}
                  tickTextColor="#90A0B0"
                />
              </Box>
            </Box>
          }
        />
      </DashDetailsWrapper>

      {/* Consumer Demographics */}
      <DashDetailsWrapper
        backgroundColor="white"
        expandColor="black"
        heading1={{
          text: 'Brand Strength',
          color: '#19989B',
        }}>
        <ContentSwitcher
          headingText="CONSUMER DEMOGRAPHIC"
          headingColor="black"
          type="brand"
          content1={
            <Box className={classes.brandStrengthContainer}>
              {generatedAverageSalesGaugeData.map((averageSalesGaugeData, index) => {
                return (
                  <Box key={index}>
                    <SalesGauge
                      salesGaugeData={averageSalesGaugeData.data}
                      chartId={salesGaugeUniqueIds[2] + index}
                      size="medium"
                      color="#03DAC5"
                      chartTypeColor="#6C757D"
                      chartValueColor="black"
                      body1={{
                        headingColor: 'black',
                        headingText: averageSalesGaugeData.headingText,
                        bodyColor: 'gray',
                        bodyText: averageSalesGaugeData.bodyText,
                      }}
                    />
                  </Box>
                );
              })}
            </Box>
          }
          content2={
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="flex-start"
              marginBottom="30px"
              flexWrap="wrap"
              width="100%">
              <ConsumerDataInfoTable data={data} />
            </Box>
          }
        />
      </DashDetailsWrapper>

      {/* Social Values & Social Causes */}
      <DashDetailsWrapper
        backgroundColor="#254157"
        expandColor="white"
        heading1={{
          text: 'Social Values & Social Causes',
          color: 'white',
        }}>
        <Box className={classes.brandPerformanceResponsive}>
          <Box display="flex" flexDirection="column" justifyContent="space-between" alignItems="flex-start">
            {generatedRangeChartSocial.map((socialRangeChartData, index) => (
              <Box marginBottom="20px" maxWidth="420px" key={index}>
                <RangeChart
                  mainLineColor="#667A89"
                  middleLineColor="#EFEFEF"
                  leftText={{
                    text: socialRangeChartData.leftText,
                    color: 'white',
                  }}
                  rightText={{
                    text: socialRangeChartData.rightText,
                    color: 'white',
                  }}
                  valueColor="lightgray"
                  heading={socialRangeChartData.heading}
                  headingColor="white"
                  body={{
                    text: socialRangeChartData.body,
                    color: 'lightgray',
                  }}
                  value1={socialRangeChartData.brandValue}
                  value2={socialRangeChartData.batchValue}
                  type="brand"
                />
              </Box>
            ))}
          </Box>
          <Box className={classes.socialCausesRadarChartContent}>
            <p className={classes.socialCausesRadarChartText}>
              This chart compares consumers' and brands' positions regarding the most important 11 social causes that are
              commonly used in political research and social sciences.
            </p>

            <Box className={classes.radarChartContainerWidth} height="500px" width="700px">
              <RadarChart
                type="multiple"
                data={multipleRadarChartDataOrdered}
                size={getChartSize(viewportWidth)}
                AConfig={{
                  name: multipleRadarChartDataLabels[0],
                  color: '#19989B',
                }}
                BConfig={{
                  name: multipleRadarChartDataLabels[1],
                  color: '#CE5378',
                }}
                tickTextColor="#90A0B0"
              />
            </Box>
            <Box className={classes.socialCausesRadarChartDashExp}>
              <DashSectionExplanation
                heading={'Causes that consumers most care about:'}
                headingColor="white"
                body={
                  <span>
                    {top3SocialCauses.map((cause, index) => (
                      <p key={index} style={{ marginBottom: '1px', fontSize: '15px' }}>
                        - {cause.subject} -<strong> {cause['SELECTED SAMPLE']}%</strong>
                      </p>
                    ))}
                  </span>
                }
                bodyColor="lightgray"
              />
            </Box>
          </Box>
        </Box>
      </DashDetailsWrapper>

      {/* Product Benefits */}
      <DashDetailsWrapper
        backgroundColor="white"
        expandColor="black"
        heading1={{
          text: 'Product Benefits',
          color: '#19989B',
        }}>
        <Box className={classes.productBenefitsWrapper}>
          <Box display="flex" alignItems="center" marginBottom="20px">
            <Box display="flex" alignItems="center" marginRight="20px">
              <Box
                style={{
                  backgroundColor: '#CE5378',
                  width: '15px',
                  height: '15px',
                  borderRadius: '50%',
                  boxShadow: '0px 4px 3px lightgray',
                  marginRight: '10px',
                }}></Box>
              <p
                style={{
                  color: 'black',
                  fontWeight: 300,
                  fontSize: '16px',
                  textAlign: 'left',
                  margin: '0',
                }}>
                SELECTED SAMPLE
              </p>
            </Box>
            <Box display="flex" alignItems="center">
              <Box
                style={{
                  backgroundColor: '#19989B',
                  width: '15px',
                  height: '15px',
                  borderRadius: '50%',
                  boxShadow: '0px 4px 3px lightgray',
                  marginRight: '10px',
                }}></Box>
              <p
                style={{
                  color: 'black',
                  fontWeight: 300,
                  fontSize: '16px',
                  textAlign: 'left',
                  margin: '0',
                }}>
                {name}
              </p>
            </Box>
          </Box>
          <p
            style={{
              color: '#A5A5A5',
              fontWeight: 300,
              fontSize: '16px',
              textAlign: 'left',
              margin: '0',
              maxWidth: '700px',
            }}>
            {productBeneitsSubHeading}
          </p>
          <Box className={classes.rangeChartContainer}>
            {generatedRangeChartProduct.map((productRangeChartData, index) => (
              <Box className={classes.rangeChartWrapper} key={index}>
                <RangeChart
                  mainLineColor="#E5E6E6"
                  middleLineColor="#D9D9D9"
                  leftText={{
                    text: productRangeChartData.leftText,
                    color: '#6C757D',
                  }}
                  rightText={{
                    text: productRangeChartData.rightText,
                    color: '#6C757D',
                  }}
                  valueColor="gray"
                  body={{
                    text: productRangeChartData.body,
                    color: '#6C757D',
                  }}
                  value1={productRangeChartData.brandValue}
                  value2={productRangeChartData.batchValue}
                  type="brand"
                />
              </Box>
            ))}
          </Box>
        </Box>
      </DashDetailsWrapper>

      {/* Competitive Map */}
      <DashDetailsWrapper
        backgroundColor="#254157"
        expandColor="white"
        heading1={{
          text: `Competitive Map - ${industryName?.name}`,
          color: 'white',
        }}>
        <Box width="100%">
          <Box className={classes.scatterChartContainer}>
            <ScatterChart
              matches={{
                targetMatch: {
                  product: data.match.Product_Match,
                  values: data.match.Values_Match,
                  name: name,
                },
                otherCompaniesMatch: data.companies_match,
              }}
              insideLabels={competitiveMapText.chartLabels}
              XYAxisLabels={competitiveMapText.XYAxisLabels}
              chartHeightWidth={{
                width: scatterChartWidth,
                height: 550,
              }}
            />
          </Box>
          <Box className={classes.competitiveMapExplanationContainer}>
            {competitiveMapText.explanations.map((explanation, index) => {
              return (
                <Box marginBottom="20px" maxWidth="500px" key={index}>
                  <DashSectionExplanation
                    heading={explanation.heading}
                    headingColor="white"
                    body={explanation.body}
                    bodyColor="lightgray"
                  />
                </Box>
              );
            })}
          </Box>
          <Watermark lightText={true} />
        </Box>
      </DashDetailsWrapper>
    </Box>
  );
};

export default ChartCalculations;
