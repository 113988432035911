import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { makeURL } from '../../../helperFunctions';
import { Card, CardHeader, CardContent, Grid, Button, Typography, Box, CircularProgress } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { Edit, Visibility, ArrowDropDown, ArrowDropUp, DeleteOutline } from '@material-ui/icons';

import { Pagination } from '@material-ui/lab';
import usePagination from '../../../myHooks/usePagination';
import DeletionDialog from '../../../myComponents/deletionDialog/DeletionDialog';

import { rearrangeSurveyData, timeSince, formatTimestampToDate } from '../../../helperFunctions';
import {
  getCompetitiveSetBrandsSurveyWithExtractedFields,
  getSurveysWithExtractedFields,
} from '../../../helperFunctions/getSurveysWithExtractedFields';
import useViewportWidth from '../../../myHooks/useViewportWidth';
import CSVExportButton from '../../../myComponents/CSVExportButton';

const useStyles = makeStyles(theme => ({
  card: {
    backgroundColor: 'white',
    boxShadow: '0px 2px 2px 1px #B4B4B4',
    borderRadius: '5px',
    flex: '1',
    marginBottom: '20px',

    '@media (max-width: 768px)': {
      '& .MuiGrid-root.MuiGrid-container': {
        flexWrap: 'nowrap !important',
      },
    },
  },
  cardHeader: {
    backgroundColor: '#E6F3F3',
    paddingBottom: 5,
  },
  heading: {
    fontWeight: 'bold',
    margin: 0,
    padding: '5px 0',
  },
  cardContent: {
    padding: 0,
  },
  gridContainer: {
    padding: '10px',
    borderBottom: '1px solid lightgray',
  },
  headingGridButtonStyle: {
    padding: '5px 10px',
    margin: 0,
  },
  select: {
    fontSize: '10px',
  },
  filtersButtonImage: {
    width: '20px',
  },
  pagnationUlStyle: {
    '& .MuiPaginationItem-root': {
      '&.Mui-selected': {
        background: '#19989B',
        color: '#fff',
      },
    },
  },
  hover: {
    transition: '0.2s ease-in-out',
    '&:hover': {
      backgroundColor: '#E6F3F3',
      cursor: 'pointer',
    },
  },
  noDataContainer: {
    height: '350px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  noDataText: {
    fontSize: '20px',
    margin: '0',
    padding: '0',
    textAlign: 'center',
    fontWeight: 'bold',
  },
  gridItemButtons: {
    '& button': {
      minWidth: '32px',
    },
  },
}));

const RecentCardExtended = ({
  heading,
  type,
  listSections,
  surveyData,
  onCreateNew,
  onEdit,
  viewSpecific,
  isLoading,
  brandPersonas,
  csvExport,
}) => {
  const classes = useStyles();
  const [filterActive, setFilterActive] = useState({});
  const [page, setPage] = useState(1);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [deletionSurvey, setDeletionSurvey] = useState('');
  const viewportWidth = useViewportWidth();
  const [surveyDataState, setSurveyDataState] = useState(surveyData || []);

  useEffect(() => {
    setSurveyDataState(surveyData);
    setFilterActive({});
  }, [surveyData]);

  const handlePaginationChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  const truncateText = text => {
    if (viewportWidth > 768) {
      return text.length > 30 ? text.substring(0, 30).concat('...') : text;
    }

    if (text.length > 14) {
      return text.substring(0, 14).concat('...');
    }
    return text;
  };

  const handleFilterSelectChange = section => {
    const filterToUp = filterActive.section === section ? !filterActive.filterToUp : false;

    const filteredSurveyData = rearrangeSurveyData(surveyDataState, section, filterToUp, type, brandPersonas);
    setSurveyDataState(filteredSurveyData);

    setFilterActive({
      section,
      filterToUp,
    });
  };

  const setCompBrandsType = type == 'competitive-brand';

  const PER_PAGE = 10;
  const count = Math.ceil(surveyDataState.length / PER_PAGE);
  const _DATA = usePagination(surveyDataState, PER_PAGE);

  const dataExists = _DATA.currentData().length && !isLoading ? true : false;
  const surveysWithExtractedFields = setCompBrandsType
    ? getCompetitiveSetBrandsSurveyWithExtractedFields(_DATA, dataExists, type, brandPersonas, viewportWidth)
    : getSurveysWithExtractedFields(_DATA, dataExists, type, brandPersonas);

  return (
    <Box>
      <Card className={classes.card}>
        {!setCompBrandsType ? (
          <CardHeader
            className={classes.cardHeader}
            title={
              <Grid container justifyContent="space-between" spacing={3}>
                <Grid item xs="auto">
                  <Typography variant="h6" className={classes.heading}>
                    {heading}
                  </Typography>
                </Grid>
                <Grid item xs container justifyContent="flex-end">
                  <Button
                    variant="text"
                    className={classes.headingGridButtonStyle}
                    style={{ color: '#2EA0A3' }}
                    onClick={onCreateNew}>
                    <AddIcon style={{ marginRight: '5px' }} />
                    Create new
                  </Button>
                  {csvExport && <CSVExportButton csvData={surveyDataState} containerStyle={{ margin: '0 0 0 30px' }} />}
                </Grid>
              </Grid>
            }
          />
        ) : null}
        <CardContent className={classes.cardContent}>
          <Grid
            container
            style={{
              padding: '10px',
              borderBottom: '1px solid lightgray',
            }}
            spacing={3}>
            {listSections.map((section, index) => {
              const isFirstElement = index === 0;
              //Put center alignemnt for "Total Match" and "Actions"
              const isActionsSection = section === 'Actions';
              const isFilterSelected = filterActive.section === section;
              return (
                <Grid
                  key={index}
                  item
                  sm={!setCompBrandsType && isFirstElement ? 2 : true}
                  style={{
                    paddingBottom: '10px',
                  }}
                  container
                  alignItems="center"
                  justifyContent={isFirstElement ? 'flex-start' : 'center'}>
                  {isActionsSection ? (
                    <Typography variant="body2" style={{ fontSize: '13px', textTransform: 'none' }}>
                      {section}
                    </Typography>
                  ) : (
                    <Button
                      className={isFilterSelected ? 'recent-card-filter-active' : ''}
                      onClick={() => handleFilterSelectChange(section)}
                      disabled={!dataExists}>
                      <Typography variant="body2" style={{ fontSize: '13px', textTransform: 'none' }}>
                        {section}
                      </Typography>
                      {isFilterSelected && !filterActive.filterToUp ? <ArrowDropUp /> : <ArrowDropDown />}
                    </Button>
                  )}
                </Grid>
              );
            })}
          </Grid>
          {dataExists ? (
            <>
              {surveysWithExtractedFields.map((survey, index) => {
                return (
                  <Grid
                    container
                    key={index}
                    className={`${classes.gridContainer} ${classes.hover}`}
                    justifyContent="space-between"
                    spacing={5}>
                    {Object.keys(survey).map((surveyField, secondIndex) => {
                      if (surveyField === 'id') return;
                      const isFirstElement = secondIndex === 0;
                      const isName = surveyField === 'Name';
                      const isPostTitle = surveyField === 'postTitle';
                      const isIndustry = surveyField === 'industry';
                      const isFilter = surveyField === 'filter';
                      const isEditedOrCreatedTime =
                        surveyField === 'lastEdited' ? 'edited' : surveyField === 'dateCreated' ? 'created' : false;
                      const isScheduledDate = surveyField === 'scheduledDate' ? 'scheduledDate' : false;
                      const isNumber =
                        !isNaN(survey[surveyField]) && !isFilter && !isEditedOrCreatedTime && !isScheduledDate;

                      return (
                        <Grid
                          item
                          key={secondIndex}
                          sm={isName || isPostTitle ? 2 : true}
                          container
                          alignItems="center"
                          justifyContent={isFirstElement ? 'flex-start' : 'center'}
                          style={{
                            paddingBottom: 0,
                            paddingLeft: isFirstElement ? '20px' : 0,
                          }}>
                          {isName ? (
                            <Typography variant="subtitle1">{truncateText(survey[surveyField])}</Typography>
                          ) : isFilter ? (
                            survey[surveyField] ? (
                              <Button variant="text">
                                <img
                                  alt="filters-button"
                                  src={makeURL('/images/parameter-settings-icon-green.png')}
                                  className={classes.filtersButtonImage}
                                />
                              </Button>
                            ) : (
                              ''
                            )
                          ) : isEditedOrCreatedTime ? (
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              style={{ fontSize: '12px', textAlign: 'center' }}>
                              {isEditedOrCreatedTime === 'edited'
                                ? timeSince(survey[surveyField]._seconds)
                                : formatTimestampToDate(survey[surveyField]._seconds)}
                            </Typography>
                          ) : isScheduledDate ? (
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              style={{ fontSize: '12px', textAlign: 'center' }}>
                              {formatTimestampToDate(survey[surveyField]._seconds)}
                            </Typography>
                          ) : (
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              style={{ fontSize: '12px', textAlign: isIndustry ? 'center' : 'left' }}>
                              {isNumber
                                ? (!setCompBrandsType
                                    ? survey[surveyField]
                                    : (Number(survey[surveyField]) * 100).toFixed(0)) + '%'
                                : truncateText(survey[surveyField])}
                            </Typography>
                          )}
                        </Grid>
                      );
                    })}
                    <Grid
                      item
                      container
                      sm
                      style={{ paddingBottom: '0', justifyContent: 'center' }}
                      wrap="nowrap"
                      className={classes.gridItemButtons}
                      direction={viewportWidth > 900 ? 'row' : 'column'}>
                      <Button
                        variant="text"
                        onClick={() => viewSpecific(survey.id)}
                        style={{ justifyContent: 'flex-start' }}>
                        <Visibility style={{ color: '#7B7B7B', fontSize: '20px' }} />
                      </Button>
                      {!setCompBrandsType ? (
                        <>
                          <Button variant="text" onClick={() => onEdit(survey.id)}>
                            <Edit style={{ color: '#7B7B7B', fontSize: '20px' }} />
                          </Button>
                          <Button
                            variant="text"
                            onClick={() => {
                              setDialogOpen(true);
                              setDeletionSurvey(survey.id);
                            }}>
                            <DeleteOutline style={{ color: '#7B7B7B', fontSize: '20px' }} />
                          </Button>
                        </>
                      ) : null}
                    </Grid>
                  </Grid>
                );
              })}
              {!setCompBrandsType ? (
                <DeletionDialog open={dialogOpen} setDialogOpen={setDialogOpen} type={type} deletionID={deletionSurvey} />
              ) : null}
            </>
          ) : (
            <Box className={classes.noDataContainer}>
              {isLoading ? (
                <CircularProgress size={40} style={{ color: '#19989B' }} />
              ) : (
                <Typography variant="h6" color="textSecondary" className={classes.noDataText}>
                  Nothing was created yet.
                </Typography>
              )}
            </Box>
          )}
        </CardContent>
      </Card>
      {_DATA.currentData().length ? (
        <Box display="flex" justifyContent="flex-end">
          <Pagination
            classes={{ ul: classes.pagnationUlStyle }}
            siblingCount={1}
            count={count}
            size="medium"
            page={page}
            variant="outlined"
            shape="rounded"
            onChange={handlePaginationChange}
          />
        </Box>
      ) : null}
    </Box>
  );
};

export default RecentCardExtended;
