import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { Box } from '@material-ui/core';
import { ToastContainer, toast } from 'react-toastify';

import { algorithmCalculation } from '../../../redux/actions/Firebase';
import { makeURL } from '../../../helperFunctions';

import PageContainer from '../../../myComponents/pageContainer';
import PageLoader from '../../../myComponents/loaders/pageLoader';
import ChartCalculations from './chartCalculations';

import HeaderSelector from '../../../myComponents/headerSelector';
import Filters from '../../../myComponents/filters/Filters';
import PDFExportButton from '../../../myComponents/PDFExportButton';
import UserDropdownHeader from '../../../myComponents/userDropdownHeader';
import ChatBot from '../../../myComponents/chatBot';

const BrandDashboard = () => {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const appData = useSelector(({ data }) => data);
  const [brandPersonaPickedId, setBrandPersonaPickedId] = useState(null);
  const PDFContentRef = useRef(null);

  const [pageIsLoading, setPageIsLoading] = useState(true);
  const [useEffectShouldTrigger, setUseEffectShouldTrigger] = useState(true);

  useEffect(() => {
    if (!appData.loading && useEffectShouldTrigger) {
      setUseEffectShouldTrigger(false);
      if (appData.error) {
        showToastWarn();
      } else {
        if (!id) {
          history.push(makeURL('/brand-personas/all'));
        } else {
          const pickedBrandPersona = !appData.brandPersonas ? false : appData.brandPersonas.find(e => e.id === id);
          if (pickedBrandPersona) {
            personaChangeHandler(pickedBrandPersona);
            setPageIsLoading(false);
          } else {
            history.push(makeURL('/brand-personas/all'));
          }
        }
      }
    }
  }, [appData.loading, id]);

  useEffect(() => {
    if (brandPersonaPickedId) {
      history.push(makeURL(`/brand-personas/${brandPersonaPickedId}`));
      setUseEffectShouldTrigger(true);
    }
  }, [brandPersonaPickedId]);

  //Calculation state
  const [calculations, setCalculations] = useState({
    loading: false,
    results: null,
  });

  const [pickedBrandPersona, setPickedBrandPersona] = useState(null);

  const calculateHandler = (pickedPersona, filters) => {
    setCalculations(prevState => ({ ...prevState, loading: true }));
    algorithmCalculation(
      'Brand',
      {
        persona: pickedPersona,
        filters: filters,
      },
      dispatch,
    )
      .then(res => {
        setCalculations({ results: res, loading: false });
      })
      .catch(err => {
        setCalculations({ results: null, loading: false });
        showToastWarn();
      });
  };

  const showToastWarn = () => {
    toast.warn('Something went wrong, please try again later.', {
      position: 'bottom-right',
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const personaChangeHandler = pickedPersona => {
    setPickedBrandPersona(pickedPersona);
    calculateHandler(pickedPersona, null);
  };

  return (
    <PageContainer>
      {pageIsLoading || calculations.loading ? <PageLoader dashboardCalculation /> : null}
      {calculations.results ? (
        <div ref={PDFContentRef}>
          <UserDropdownHeader />
          {pickedBrandPersona && <ChatBot pickedBrandPersona={pickedBrandPersona} />}

          <Box width="100%" padding="20px 20px 0px 20px" style={{ background: '#fff' }}>
            <HeaderSelector
              selectedId={brandPersonaPickedId ? brandPersonaPickedId : id}
              setPickedId={setBrandPersonaPickedId}
              data={appData}
              type="brand"
              redirect={true}
            />
          </Box>
          <Filters
            calculateHandler={preparedFilterData => calculateHandler(pickedBrandPersona, preparedFilterData)}
            themeColor="green"
          />
          <PDFExportButton contentRef={PDFContentRef} type="green" />
          <ChartCalculations type="brand" data={calculations.results} persona={pickedBrandPersona} />
        </div>
      ) : null}
      <ToastContainer
        theme="colored"
        position="bottom-right"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </PageContainer>
  );
};

export default BrandDashboard;
