import { AUTH_SUCCESS, AUTH_FAIL, AUTH_SIGN_OUT, AUTH_INITIAL_APP_LOAD, AUTH_SIGN_OUT_FAIL } from './actionTypes.js';
import { getAuth, signInWithCustomToken } from 'firebase/auth';
import { store } from '../../App';
import { checkAuthState_API } from '../../API/agent';

const unauthorizedRedirectURL = 'https://www.brandsocialvalue.com/my-account/';
let shouldAuthValidationTrigger = true;

const authenticate = async serverToken => {
  const auth = getAuth();
  const signInResponse = await signInWithCustomToken(auth, serverToken);
  return signInResponse;
};

let sessionTimeout;
let clearSessionTimeout = () => {
  sessionTimeout && clearTimeout(sessionTimeout);
  sessionTimeout = null;
};
export const createFirebaseAuthStateListener = Firebase => {
  return dispatch => {
    Firebase.onAuthStateChanged(async user => {
      if (user) {
        try {
          // Fetch the decoded ID token
          const { claims } = await user.getIdTokenResult();

          // Make sure all the times are in milliseconds!
          const authTime = claims.auth_time * 1000;
          // Intentionally made 1 day and 3 minutes, i want the WP FB token to expire first
          const sessionDuration = 1000 * 60 * 60 * 24 * 1 + 1000 * 30;
          const millisecondsUntilExpiration = sessionDuration - (Date.now() - authTime);
          // Create a session timeout
          clearSessionTimeout();
          sessionTimeout = setTimeout(async () => {
            await store.getState().firebase.firebaseAuth.signOut();
            return dispatch({ type: AUTH_SIGN_OUT });
          }, millisecondsUntilExpiration);

          // Get subscription details
          let userWithClaims = { ...user, customClaims: claims };

          //Update store
          if (shouldAuthValidationTrigger) {
            validateAuthenticationState(dispatch, {
              fromStateListener: true,
              user: userWithClaims,
            });
          }
        } catch (err) {
          console.log('Something went wrong. User can not be decoded.');
        }
      } else {
        //Update store
        if (shouldAuthValidationTrigger) {
          validateAuthenticationState(dispatch, {
            fromStateListener: true,
            user,
          });
        }

        // Clear the session timeout.
        clearSessionTimeout();
      }
    });
  };
};

/*
  Codes:
  0: Everyhting good
  1: Redirect to WP my account
  2: Logout user, show re-login screen
  3: Show re-login screen
  4. Login to WP cookie account
*/
export const validateAuthenticationState = async (dispatch, { fromStateListener, user }) => {
  try {
    if (fromStateListener) dispatch({ type: AUTH_INITIAL_APP_LOAD });

    const { data: checkAuthResponse } = await checkAuthState_API();

    switch (checkAuthResponse.result) {
      case 0:
        //Everyhting good, just continue using the app (if initial load, save user to store)
        if (fromStateListener) dispatch({ type: AUTH_SUCCESS, user });
        return;
      case 1:
        //Redirect to the WP app
        return (window.location.href = unauthorizedRedirectURL);
      case 2:
        //Signout user
        try {
          shouldAuthValidationTrigger = false;
          await store.getState().firebase.firebaseAuth.signOut();
          setTimeout(() => (shouldAuthValidationTrigger = true), 3000);
          return dispatch({ type: AUTH_SIGN_OUT });
        } catch (err) {
          return dispatch({ type: AUTH_SIGN_OUT_FAIL });
        }
      case 3:
        //There is no user, just show re-login page
        return dispatch({ type: AUTH_SIGN_OUT });
      case 4:
        //Login
        try {
          shouldAuthValidationTrigger = false;
          const { user: newelyAuthenticatedUser } = await authenticate(checkAuthResponse.token);
          setTimeout(() => (shouldAuthValidationTrigger = true), 3000);
          return dispatch({
            type: AUTH_SUCCESS,
            user: newelyAuthenticatedUser,
          });
        } catch (err) {
          return dispatch({ type: AUTH_FAIL });
        }
      default:
        return (window.location.href = unauthorizedRedirectURL);
    }
  } catch (err) {
    if (err?.response?.status !== 401) {
      window.location.href = unauthorizedRedirectURL;
    }
  }
};
