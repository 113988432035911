import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import SurveyInputs from '../index';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    width: '100%',
    margin: '0 auto',
    padding: '0 50px',
    '@media (max-width: 768px)': {
      padding: '0px',
    },
  },
  groupContainer: {
    width: '100%',
    margin: '0 auto',
    paddingBottom: '35px',
    borderBottom: '1px solid lightgray',
    marginBottom: '50px',
  },
  groupHeading: {
    color: theme.palette.text.primary,
    fontWeight: 600,
    fontSize: '20px',
    marginBottom: '30px',
    textAlign: 'left',
  },
}));

const SurveySummary = (props) => {
  const classes = useStyles();

  const groupContainerDemographicsStyle = {
    maxWidth: '600px',
    margin: '0 auto',
  };

  return (
    <Box className={classes.mainContainer}>
      {props.questions.groups.map((group, groupIndex) => {
        return (
          <Box className={classes.groupContainer} key={groupIndex}>
            <Typography
              component='div'
              variant='h2'
              className={classes.groupHeading}
            >
              {group.name}
            </Typography>
            <Box
              style={
                group.name === 'Demographics'
                  ? groupContainerDemographicsStyle
                  : {}
              }
            >
              {group.fields.map((field, fieldIndex) => {
                return (
                  <SurveyInputs
                    key={fieldIndex}
                    questions={null}
                    activeQuestion={null}
                    question={field}
                    surveyAnswersState={props.surveyAnswersState}
                    isInGroup={false}
                    handleSurveyAnswers={props.handleSurveyAnswers}
                    name={props.name}
                    isSummary
                  />
                );
              })}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default SurveySummary;
