import { makeStyles } from '@material-ui/core';
import UserDropdown from '../../myComponents/userDropdown';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '100%',
    padding: '12px 0px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',

    '@media (max-width: 768px)': {
      display: 'none',
    },
  },
}));

const UserDropdownHeader = ({ bgColor = '#F9F9F9' }) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper} style={{ background: bgColor }}>
      <UserDropdown />
    </div>
  );
};

export default UserDropdownHeader;
