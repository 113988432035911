import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { consumerAlgorithmCalculation } from 'redux/actions/Firebase';
import ChartCalculations from './chartCalculations';
import PageContainer from '../../../myComponents/pageContainer';
import PageLoader from '../../../myComponents/loaders/pageLoader';

import Filters from '../../../myComponents/filters/Filters';
import UserDropdownHeader from '../../../myComponents/userDropdownHeader';
import PDFExportButton from '../../../myComponents/PDFExportButton';

const ConsumerSample = () => {
  const dispatch = useDispatch();
  const appData = useSelector(({ data }) => data);
  const PDFContentRef = useRef(null);

  const [pageIsLoading, setPageIsLoading] = useState(true);
  const [useEffectShouldTrigger, setUseEffectShouldTrigger] = useState(true);

  //Calculation state
  const [calculations, setCalculations] = useState({
    loading: false,
    results: null,
  });

  useEffect(() => {
    if (!appData.loading && useEffectShouldTrigger) {
      setUseEffectShouldTrigger(false);
      if (appData.error) {
        showToastWarn();
      } else {
        calculateHandler(null);
        setPageIsLoading(false);
      }
    }
  }, [appData.loading]);

  const calculateHandler = filters => {
    setCalculations(prevState => ({ ...prevState, loading: true }));
    consumerAlgorithmCalculation({ filters: filters }, dispatch)
      .then(res => {
        setCalculations({ results: res, loading: false });
      })
      .catch(err => {
        setCalculations({ results: null, loading: false });
        showToastWarn();
      });
  };

  const showToastWarn = () => {
    toast.warn('Something went wrong, please try again later.', {
      position: 'bottom-right',
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  return (
    <PageContainer>
      {pageIsLoading || calculations.loading ? <PageLoader dashboardCalculation /> : null}
      {calculations.results ? (
        <div ref={PDFContentRef}>
          <UserDropdownHeader />
          <Filters calculateHandler={calculateHandler} themeColor="red" />
          <PDFExportButton contentRef={PDFContentRef} type="red" />
          <ChartCalculations data={calculations.results} />
        </div>
      ) : null}
    </PageContainer>
  );
};

export default ConsumerSample;
