import React from 'react';

import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    width: '100%',
    marginTop: '15px',
  },
  text: {
    fontSize: '12px',
    textAlign: 'center',
    fontWeight: 300,
    color: theme.palette.text.secondary,
  },
}));

const Watermark = ({ lightText }) => {
  const classes = useStyles();

  return (
    <Box className={classes.mainContainer}>
      <Typography
        component='div'
        variant='body2'
        className={classes.text}
        style={{ color: lightText ? '#BDC6CC' : '#515151' }}
      >
        Copyright Brand Social Value © 2023 by MADintel
      </Typography>
    </Box>
  );
};

export default Watermark;
