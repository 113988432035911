import React, { useState } from 'react';
import { Switch, Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  switchRoot: {
    '& .MuiSwitch-track': {
      backgroundColor: 'lightgray',
    },
  },
  buyerColor: {
    '& .MuiSwitch-track': {
      backgroundColor: '#AB8628',
    },
  },
}));

const ContentSwitcher = ({
  content1,
  content2,
  headingText,
  headingColor,
  type,
}) => {
  const classes = useStyles();
  const [isChecked, setIsChecked] = useState(false);

  const handleChange = () => {
    setIsChecked(!isChecked);
  };

  return (
    <Box width='100%'>
      <Box
        display='flex'
        alignItems='center'
        justifyContent='flex-start'
        marginBottom='30px'
      >
        <Typography
          component='div'
          variant='h5'
          style={{
            margin: '0 15px 0 0',
            fontSize: '15px',
            fontWeight: 'bold',
            color: headingColor,
            textAlign: 'center',
          }}
        >
          {headingText}
        </Typography>
        <Switch
          checked={isChecked}
          onChange={handleChange}
          color='default'
          classes={{
            root: type == 'brand' ? classes.switchRoot : classes.buyerColor,
          }}
          style={{
            color: isChecked
              ? type == 'brand'
                ? '#19989B'
                : '#AB8628'
              : '#FFFFFF',
          }}
        />
      </Box>

      {!isChecked ? (
        <Box
          width='100%'
          style={{
            transition: 'opacity 0.2s ease-in-out',
            opacity: 1,
          }}
        >
          {content1}
        </Box>
      ) : (
        <Box
          width='100%'
          style={{
            transition: 'opacity 0.2s ease-in-out',
            opacity: 1,
          }}
        >
          {content2}
        </Box>
      )}
    </Box>
  );
};

export default ContentSwitcher;
