import React from 'react';

import globalStyles from '../../theme/GlobalCss';
import VerticalLayout from './verticalLayout';
import MinimalLayout from './minimalLayout';
import useViewportWidth from '../../../myHooks/useViewportWidth';

const AppLayout = ({ children }) => {
  globalStyles();
  let width = useViewportWidth();
  const widthBreakpoint = 768;
  const isScreenWide = width > widthBreakpoint;

  if (isScreenWide) return <VerticalLayout children={children} />;
  else return <MinimalLayout children={children} />;
};

export default AppLayout;
