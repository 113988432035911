import React, { useState } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography, Button } from "@material-ui/core";
import { makeURL } from "../../helperFunctions";
import CircularProgress from "@material-ui/core/CircularProgress";

const themeColorConfig = {
  green: {
    backgroundColor: '#19989B',
    hoverBackgroundColor: '#19878a',
  },
  yellow: {
    backgroundColor: '#AB8628',
    hoverBackgroundColor: '#967217',
  },
  red: {
    backgroundColor: '#E45589',
    hoverBackgroundColor: '#a3375f',
  },
};

const useStyles = makeStyles((theme) => ({
  exportButtonWrapper: {
    display: "flex",
    flexDirection: "row-reverse",
    backgroundColor: "#FFF",
    paddingTop: "20px",
  },
  exportButton: props => ({
    display: "flex",
    padding: "7px 15px",
    borderRadius: "5px",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: themeColorConfig[props.type].backgroundColor,
    "&:hover": {
      backgroundColor: themeColorConfig[props.type].hoverBackgroundColor,
    },

    "@media (max-width: 768px)": {
      justifyContent: "center",
      minWidth: "unset",
      marginRight: "6px",
    },
  }),
  exportButtonImage: {
    width: "25px",
    marginRight: "15px",

    "@media (max-width: 768px)": {
      marginRight: "0",
    },
  },
  exportButtonText: {
    color: "white",
    fontSize: "12px",
    margin: 0,

    "@media (max-width: 768px)": {
      display: "none",
    },
  },
}));

const PDFExportButton = ({ contentRef, type }) => {
  const classes = useStyles({ type });
  const [isLoading, setIsLoading] = useState(false);

  const handleExport = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
    // Get the content to be exported
    const content = contentRef.current;
    // Use html2canvas to capture the content as an image
    html2canvas(content, {scale:1.5}).then((canvas) => {
      // Convert the canvas to a data URL
      const imgData = canvas.toDataURL("image/png");
      // Create a PDF instance
      const pdf = new jsPDF("p", "px", [canvas.width, canvas.height]);
      // Add the captured image to the PDF
      
      pdf.addImage(
        imgData,
        "PNG",
        0,
        0,
        pdf.internal.pageSize.width,
        pdf.internal.pageSize.height
      );

      // Save the PDF
      pdf.save("exported-file.pdf");
    });
  };

  return (
    <Box className={classes.exportButtonWrapper}>
      <Button
        onClick={handleExport}
        className={classes.exportButton}
        disabled={isLoading}
      >
        <img
          alt="export-as-pdf"
          src={makeURL("/images/export-pdf-icon.png")}
          className={classes.exportButtonImage}
        />
        {isLoading ? (
          <div
            style={{
              width: "117px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress size={25} style={{ color: "white" }} />
          </div>
        ) : (
          <Typography
            component="div"
            variant="button"
            className={classes.exportButtonText}
          >
            EXPORT AS PDF
          </Typography>
        )}
      </Button>
    </Box>
  );
};

export default PDFExportButton;
