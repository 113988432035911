import { getSocialMediaPlatform } from './getSurveysWithExtractedFields';
import { industryDataCodes, dataCodeFields } from '../config/dataCodes';
import { USA_States } from '../config/questions';

export const normalize = value => {
  value = Number(value);
  return (value - 1) / 4;
};

export const convert_normalize = value => {
  value = Number(value);
  return value * 4 + 1;
};

export const reverse_coded_normalize = value => {
  value = Number(value);
  return (Math.abs(value - 6) - 1) / 4;
};

export const convert_reverse_coded_normalize = value => {
  value = Number(value);
  return Math.abs(value * 4 + 1 - 6);
};

export const getAverage = arr => {
  let sum = arr.reduce((a, b) => a + b, 0);
  return sum / arr.length;
};

export const addFieldsAndValues = (reference, obj) => {
  let updatedObj = { ...obj };
  Object.keys(reference).forEach(dataKey => {
    updatedObj[dataKey] = Number(getAverage(reference[dataKey].map(e => updatedObj[e])));
  });

  return updatedObj;
};

export const pushRangeOfNumbers = (arr, start, end) => {
  let updatedArr = [...arr];
  for (let i = start; i <= end; i++) {
    updatedArr.push(i);
  }
  return updatedArr;
};

export const convertRange = (value, r1, r2) => {
  return ((value - r1[0]) * (r2[1] - r2[0])) / (r1[1] - r1[0]) + r2[0];
};

export const makeURL = route => {
  const baseUrl = '';
  return `${baseUrl}${route}`;
};

export const rearrangeSurveyData = (arr, key, up, type, brandPersonas) => {
  const reMappedKey = getReMappedKey(type, key);

  let arrayToSort = type === 'social' ? mapSocialMediaDataSource(arr, brandPersonas) : arr;

  arrayToSort.sort(function(a, b) {
    if (getShouldLocalCompare(reMappedKey)) {
      if (!up) {
        return a[reMappedKey].localeCompare(b[reMappedKey]);
      } else {
        return b[reMappedKey].localeCompare(a[reMappedKey]);
      }
    } else if (getShouldTimestampCompare(reMappedKey)) {
      if (!up) {
        return b[reMappedKey]._seconds - a[reMappedKey]._seconds;
      } else {
        return a[reMappedKey]._seconds - b[reMappedKey]._seconds;
      }
    } else {
      if (!up) {
        return b[reMappedKey] - a[reMappedKey];
      } else {
        return a[reMappedKey] - b[reMappedKey];
      }
    }
  });

  return arrayToSort;
};

const mapSocialMediaDataSource = (socialMediaData, brandPersonas) => {
  return socialMediaData.map(item => {
    const { Name: personaName } = brandPersonas.find(({ id }) => id === item.Post_Brand_Persona);

    return {
      ...item,
      Post_Brand_Persona_Text: personaName ? personaName : '',
      Post_Social_Media_Text: getSocialMediaPlatform(item),
    };
  });
};

const getShouldLocalCompare = reMappedKey => {
  let shouldLocalCompare = false;

  if (
    reMappedKey === 'Name' ||
    reMappedKey === 'name' ||
    reMappedKey === 'industry' ||
    reMappedKey === 'Post_Title' ||
    reMappedKey === 'Post_Brand_Persona_Text' ||
    reMappedKey === 'Post_Social_Media_Text' ||
    reMappedKey === 'suggestion'
  ) {
    shouldLocalCompare = true;
  }

  return shouldLocalCompare;
};

const getShouldTimestampCompare = reMappedKey => {
  return reMappedKey === 'lastEdited' || reMappedKey === 'dateCreated' || reMappedKey === 'Post_Date';
};

const getReMappedKey = (type, key) => {
  let reMappedKey = '';

  switch (type) {
    case 'competitive-brand':
      reMappedKey = recentCardFilterKeysReMapped_CompetitiveSetBrands(key);
      break;
    case 'social':
      reMappedKey = recentCardFilterKeysReMapped_SocialMedia(key);
      break;
    default:
      reMappedKey = recentCardFilterKeysReMapped(key);
  }

  return reMappedKey;
};

const recentCardFilterKeysReMapped = key => {
  switch (key) {
    case 'Personas Name':
      return 'Name';
    case 'Last Edited':
      return 'lastEdited';
    case 'Date Created':
      return 'dateCreated';
    case 'Social V. Match':
      return 'socialValueMatch';
    case 'Product B. Match':
      return 'productBenefitsMatch';
    case 'Total Match':
      return 'totalMatch';
    case 'Filter':
      return 'filter';
    default:
      return 'undefined';
  }
};

export const timeSince = timestamp => {
  const seconds = Math.floor((new Date() - timestamp * 1000) / 1000);

  let interval = Math.floor(seconds / 31536000);
  if (interval >= 1) {
    return interval + ' year' + (interval === 1 ? '' : 's') + ' ago';
  }

  interval = Math.floor(seconds / 2592000);
  if (interval >= 1) {
    return interval + ' month' + (interval === 1 ? '' : 's') + ' ago';
  }

  interval = Math.floor(seconds / 86400);
  if (interval >= 1) {
    return interval + ' day' + (interval === 1 ? '' : 's') + ' ago';
  }

  interval = Math.floor(seconds / 3600);
  if (interval >= 1) {
    return interval + ' hour' + (interval === 1 ? '' : 's') + ' ago';
  }

  interval = Math.floor(seconds / 60);
  if (interval >= 1) {
    return interval + ' minute' + (interval === 1 ? '' : 's') + ' ago';
  }

  return Math.floor(seconds) + ' second' + (seconds === 1 ? '' : 's') + ' ago';
};

export const formatTimestampToDate = timestamp => {
  const date = new Date(timestamp * 1000);

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const monthIndex = date.getMonth();
  const monthName = months[monthIndex];
  const day = date.getDate();
  const year = date.getFullYear();

  return `${monthName} ${day}, ${year}`;
};

export const getSocialPostIdsForConversion = () => {
  return [
    'Post_Ideology_1',
    'Post_InterExter_1',
    'Post_LocalGlobal_1',
    'Post_Product_1',
    'Post_Product_2',
    'Post_Product_3',
    'Post_Product_4',
    'Post_Product_5',
    'Post_Product_6',
    'Post_Product_7',
    'Post_Product_8',
    'Post_Product_9',
    'Post_Product_10',
  ];
};

export const formatResponse = response => {
  if (!response || typeof response !== 'string') {
    return null; // Return early if the response is not valid
  }

  const lines = response.split('\n');
  let paragraphs = [];
  let currentParagraph = [];
  let isList = false;

  lines.forEach((line, idx) => {
    if (line.startsWith('- ')) {
      if (!isList) {
        isList = true;
        if (currentParagraph.length > 0) {
          paragraphs.push(
            <p
              key={paragraphs.length}
              style={{
                marginTop: '24px',
              }}>
              {currentParagraph}
            </p>,
          );
          currentParagraph = [];
        }
      }
      currentParagraph.push(<li key={currentParagraph.length}>{line.substring(2)}</li>);
    } else {
      if (isList) {
        isList = false;
        paragraphs.push(
          <ul key={paragraphs.length} style={{ paddingLeft: '32px' }}>
            {currentParagraph}
          </ul>,
        );
        currentParagraph = [];
      }
      currentParagraph.push(<p key={currentParagraph.length}>{line}</p>);
    }
  });

  if (currentParagraph.length > 0) {
    paragraphs.push(
      <p key={paragraphs.length} style={{ paddingLeft: '32px' }}>
        {currentParagraph}
      </p>,
    );
  }

  return paragraphs;
};

export const socialDeleteFieldsWithFalseDependancy = surveyData => {
  let surveyDataCopy = { ...surveyData };
  const { Post_Category } = surveyDataCopy;

  if (Post_Category === '1') {
    // Delete properties for Post_Category "1"
    for (const key in surveyDataCopy) {
      if (
        key.startsWith('Post_Issues_') ||
        key === 'Post_LocalGlobal_1' ||
        key === 'Post_InterExter_1' ||
        key === 'Post_Ideology_1'
      ) {
        delete surveyDataCopy[key];
      }
    }
  } else {
    // Delete properties for other Post_Category values
    for (const key in surveyDataCopy) {
      if (key.startsWith('Post_Product_')) {
        delete surveyDataCopy[key];
      }
    }
  }

  return surveyDataCopy;
};

const recentCardFilterKeysReMapped_CompetitiveSetBrands = key => {
  switch (key) {
    case 'Brand':
      return 'name';
    case 'Industry':
      return 'industry';
    case 'Reputation':
      return 'CReputation';
    case 'Relationship':
      return 'CRelationship';
    case 'Brand Choice':
      return 'CPurchaseChoice';
    case 'Loyalty':
      return 'CLoyalty';
    case 'IE Focus':
      return 'IE_Focus_Match';
    case 'Ideology':
      return 'Ideology_Match';
    case 'Causes':
      return 'Issues_Match';
    case 'SV Match':
      return 'Values_Match';
    case 'PB Match':
      return 'Product_Match';
    case 'Total Match':
      return 'Total_Match';
    default:
      return 'undefined';
  }
};

const recentCardFilterKeysReMapped_SocialMedia = key => {
  switch (key) {
    case 'Post Title':
      return 'Post_Title';
    case 'Last Edited':
      return 'lastEdited';
    case 'Scheduled Date':
      return 'Post_Date';
    case 'Persona':
      return 'Post_Brand_Persona_Text';
    case 'Platform':
      return 'Post_Social_Media_Text';
    case 'Impact':
      return 'impact';
    case 'Suggestion':
      return 'suggestion';
    case 'Filter':
      return 'filter';
    default:
      return 'undefined';
  }
};

export const getTop5RecentlyEdited = items => {
  //If items do not exist, return
  if (!items) return items;

  // Sort the items based on the 'lastEdited' property in descending order
  const sortedItems = items.sort((a, b) => {
    return b.lastEdited._seconds - a.lastEdited._seconds;
  });

  // Return the top 5 items or all items if there are fewer than 5
  return sortedItems.slice(0, Math.min(sortedItems.length, 5));
};

export const uncheckGenGroupFilters = () => {
  const genZSwitch = document.getElementById('GenZ(10-25Years)');
  const genYSwitch = document.getElementById('GenY(26-41Years)');
  const genXSwitch = document.getElementById('GenX(42-57Years)');
  const boomersSwitch = document.getElementById('Boomers(58-76Years)');
  const silentSwitch = document.getElementById('Silent(77+Years)');

  genZSwitch.checked = false;
  genYSwitch.checked = false;
  genXSwitch.checked = false;
  boomersSwitch.checked = false;
  silentSwitch.checked = false;
};

export const uncheckAgeGroupFilters = () => {
  const youngSwitch = document.getElementById('Young18-34');
  const middleSwitch = document.getElementById('Middle35-49');
  const olderSwitch = document.getElementById('Older50+');

  youngSwitch.checked = false;
  middleSwitch.checked = false;
  olderSwitch.checked = false;
};

export const uncheckIncomeGroupFilters = () => {
  const lowSwitch = document.getElementById('Low(under$49,999)');
  const middleSwitch = document.getElementById('Middle($50,000-$99,999)');
  const highSwitch = document.getElementById('High($100,000+)');

  lowSwitch.checked = false;
  middleSwitch.checked = false;
  highSwitch.checked = false;
};

export const uncheckIncomeBracketFilters = () => {
  const firstSwitch = document.getElementById('LESSTHAN$15,000');
  const secondSwitch = document.getElementById('$10,000-$24,999');
  const thirdSwitch = document.getElementById('$25,000-$34,999');
  const fourthSwitch = document.getElementById('$35,000–$49,999');
  const fifthSwitch = document.getElementById('$50,000-$74,999');
  const sixthSwitch = document.getElementById('$75,000-$99,999');
  const seventhSwitch = document.getElementById('$100,000-$149,999');
  const eightSwitch = document.getElementById('$150,000-$199,999');
  const ninthSwitch = document.getElementById('$200,000+');

  firstSwitch.checked = false;
  secondSwitch.checked = false;
  thirdSwitch.checked = false;
  fourthSwitch.checked = false;
  fifthSwitch.checked = false;
  sixthSwitch.checked = false;
  seventhSwitch.checked = false;
  eightSwitch.checked = false;
  ninthSwitch.checked = false;
};

export const getShouldCalculateAgeRange = () => {
  const ageSlider = document.querySelector('#ageRange input');

  if (ageSlider.value !== '0,0') {
    return true;
  }

  return false;
};

export const getUserInitials = username => {
  if (!username) return '';
  const isEmail = username.indexOf('@');
  let displayName = username;

  if (isEmail !== -1) {
    displayName = username.substring(0, username.indexOf('@'));
  }
  let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');

  let initials = [...displayName.matchAll(rgx)] || [];

  initials = ((initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')).toUpperCase();

  return initials;
};

export const getIndustryName = industryCode => {
  return industryDataCodes.find(industry => industry.id === industryCode);
};

export const getIssuesFromBrandPersona = persona => {
  const questionIDs = [
    'Brand_Issues_1',
    'Brand_Issues_2',
    'Brand_Issues_3',
    'Brand_Issues_4',
    'Brand_Issues_5',
    'Brand_Issues_6',
    'Brand_Issues_7',
    'Brand_Issues_8',
    'Brand_Issues_9',
    'Brand_Issues_10',
    'Brand_Issues_11',
  ];
  const questionText = [
    'State of the economy',
    'Free Healthcare',
    'Violent crime',
    'World peace',
    'Gun laws',
    'Race and ethnic inequality',
    'Gender inequality',
    'Immigration',
    'Economic inequality',
    'Climate change',
    'Corporate corruption',
  ];

  const extractedValues = {};
  questionIDs.map(id => ({ ...extractedValues, [questionText[id]]: convert_normalize(persona[id]) }));

  return extractedValues;
};

export const getStateByCode = code => {
  let value = USA_States.find(state => state.value == code);
  return value?.text;
};

export const getCountryByCode = code => {
  let value = dataCodeFields.Country.find(country => country.value == code);
  return value?.text;
};

export const getEthnicityByCode = code => {
  let value = dataCodeFields.Ethnicity.find(ethnicity => ethnicity.value == code);
  return value?.text;
};

export const getGenderByCode = code => {
  let value = dataCodeFields.Gender.find(gender => gender.value == code);
  return value?.text;
};

export const getEducationByCode = code => {
  let value = dataCodeFields.Education.find(education => education.value == code);
  return value?.text;
};

export const getMaritalStatusByCode = code => {
  let value = dataCodeFields.Marital_status.find(status => status.value == code);
  return value?.text;
};
