import { filterSectionFields } from '../../config/filterFields';
import { getStateByCode, getCountryByCode } from 'helperFunctions';

export const defaultFilterState = () => ({
  Batch: ['batch_3'],
  Age: [],
  Gender: [],
  Homeownership: [],
  Ethnicity: [],
  Income: [],
  State: [],
  Location: [],
  PersonalIdeology_1: [],
  Education: [],
  Marital_status: [],
  Country: [187],
});

export const formatLabelValue = key => {
  if (key === 'PersonalIdeology_1') {
    return 'Ideology';
  }

  return key;
};

export const formatFilterValue = (key, valueToMap) => {
  switch (key) {
    case 'Age':
      return mapAgeFilterValue(key, valueToMap);
    case 'Country':
      return getCountryByCode(valueToMap);
    case 'State':
      return getStateByCode(valueToMap);
    case 'Ethnicity':
      return mapEthnicityFilterValue(valueToMap);
    case 'Education':
      return mapEducationFilterValue(valueToMap);
    case 'Gender':
      return mapGenderFilterValue(valueToMap);
    case 'Homeownership':
      return mapHomeownershipFilterValue(valueToMap);
    case 'Income':
      return mapIncomeFilterValue(valueToMap);
    case 'Location':
      return mapLocationFilterValue(valueToMap);
    case 'PersonalIdeology_1':
      return mapPersonalIdeologyFilterValue(valueToMap);
    case 'Marital_status':
      return mapMaritalStatusFilterValue(valueToMap);
    default:
      break;
  }
};

const mapAgeFilterValue = (key, valueToMap) => {
  let value = '';
  const valueToMapFormated = `[${valueToMap.toString()}]`;

  filterSectionFields.forEach(section => {
    if (section.id == key) {
      section.sections[0].fields.forEach(field => {
        if (field.value == valueToMapFormated) {
          value = field.value;
        }
      });
    }
  });

  if (value) {
    return value;
  } else {
    filterSectionFields.forEach(section => {
      if (section.id == key) {
        section.sections[2].fields.forEach(field => {
          if (field.value == valueToMapFormated) {
            value = field.value;
          }
        });
      }
    });

    return value ? value : valueToMapFormated;
  }
};

const mapEthnicityFilterValue = valueToMap => {
  let value = '';

  filterSectionFields.forEach(section => {
    if (section.id == 'Ethnicity') {
      section.fields.forEach(field => {
        if (field.value == valueToMap) {
          value = field.label;
        }
      });
    }
  });

  return value;
};

const mapEducationFilterValue = valueToMap => {
  let value = '';

  filterSectionFields.forEach(section => {
    if (section.id == 'Education') {
      section.fields.forEach(field => {
        if (field.value == valueToMap) {
          value = field.label;
        }
      });
    }
  });

  return value;
};

const mapGenderFilterValue = valueToMap => {
  let value = '';

  filterSectionFields.forEach(section => {
    if (section.id == 'Gender') {
      section.fields.forEach(field => {
        if (field.value == valueToMap) {
          value = field.label;
        }
      });
    }
  });

  return value;
};

const mapHomeownershipFilterValue = valueToMap => {
  let value = '';

  filterSectionFields.forEach(section => {
    if (section.id == 'Homeownership') {
      section.fields.forEach(field => {
        if (field.value == valueToMap) {
          value = field.label;
        }
      });
    }
  });

  return value;
};

const mapIncomeFilterValue = valueToMap => {
  let value = '';

  if (valueToMap[0] === '[') {
    filterSectionFields.forEach(section => {
      if (section.id == 'Income') {
        section.sections[0].fields.forEach(field => {
          if (field.value == valueToMap) {
            value = field.label;
          }
        });
      }
    });
  } else {
    filterSectionFields.forEach(section => {
      if (section.id == 'Income') {
        section.sections[1].fields.forEach(field => {
          if (field.value == valueToMap) {
            value = field.label;
          }
        });
      }
    });
  }

  return value;
};

const mapLocationFilterValue = valueToMap => {
  let value = '';

  filterSectionFields.forEach(section => {
    if (section.id == 'Location') {
      section.sections[3].fields.forEach(field => {
        if (field.value == valueToMap) {
          value = field.label;
        }
      });
    }
  });

  return value;
};

const mapMaritalStatusFilterValue = valueToMap => {
  let value = '';

  filterSectionFields.forEach(section => {
    if (section.id == 'Marital_status') {
      section.fields.forEach(field => {
        if (field.value == valueToMap) {
          value = field.label;
        }
      });
    }
  });

  return value;
};

const mapPersonalIdeologyFilterValue = valueToMap => {
  let value = '';

  filterSectionFields.forEach(section => {
    if (section.id == 'PersonalIdeology_1') {
      section.fields.forEach(field => {
        if (field.value == valueToMap) {
          value = field.label;
        }
      });
    }
  });

  return value;
};
