import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import { Box, Typography, TextField, Button, CircularProgress } from '@material-ui/core';

import { toast } from 'react-toastify';

import { AITextGenerator } from '../../../API/agent';
import { socialPostSurveyQuestions } from '../../../config/questions';

const useStyles = makeStyles(theme => ({
  mainContainer: {
    width: '100%',
    maxWidth: '650px',
    margin: '0 auto 0 auto',
  },
  text: {
    fontWeight: 800,
    fontSize: '17px',
    margin: '0 0 15px 0',
    textAlign: 'left',
    '@media (max-width: 600px)': {
      fontSize: '16px',
      marginInline: 0,
    },
  },
  textInput: {
    padding: '5px 10px',
  },
  textAreaContainer: {
    position: 'relative',
    width: '100%',
    paddingBottom: '15px',
    marginBottom: '22px',
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: '20px',
  },
  selectButtonSelected: {
    padding: '5px 10px',
    fontWeight: 400,
    border: '2px solid #19989B',
    borderRadius: '4px',
  },
  aiLoader: {
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
    zIndex: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(255,255,255,0.7)',
  },
}));

const MultiAiTextArea = props => {
  const appData = useSelector(({ data }) => data);
  const classes = useStyles();

  const initialTextAreaValuesHandler = () => {
    const { id: selectedFieldId } = props.textAreas.find(textAreaField => !textAreaField.ai);
    const initialValue = props.textAreas.map(({ id, ai, text }) => ({
      id,
      ai,
      text,
      value: '',
      isSelected: selectedFieldId === id ? true : false,
    }));
    return initialValue;
  };

  const [textAreaValues, setTextAreaValues] = useState(props.value ? props.value : initialTextAreaValuesHandler());
  const textAreaValuesStateRef = useRef();
  textAreaValuesStateRef.current = textAreaValues;

  const [aiTextLoading, setAiTextLoading] = useState(false);

  // [
  //   {
  //     id: "field_1",
  //     ai: true,
  //     value: "something",
  //     isSelected: false
  //   },
  //   {
  //     id: "field_2",
  //     ai: false,
  //     value: "nothing really",
  //     isSelected: true
  //   }
  // ]

  useEffect(() => {
    const aiGeneratedAnswer = textAreaValues.find(answer => answer.ai);
    if (!aiGeneratedAnswer.value) {
      setAiTextLoading(true);

      //Find brand name
      const { Name: personaName } = appData.brandPersonas.find(
        persona => persona.id === props.surveyAnswersState.Post_Brand_Persona,
      );

      //Find social media chosen, number got from props and i need name in text
      let socialMediaFields;
      for (let socialQuestions of socialPostSurveyQuestions) {
        socialMediaFields = socialQuestions.fields.find(field => field.id === 'Post_Social_Media');
        if (socialMediaFields) break;
      }
      const { text: socialMediaName } = socialMediaFields.options.find(
        option => option.value === props.surveyAnswersState.Post_Social_Media,
      );

      AITextGenerator(
        personaName,
        socialMediaName,
        props.surveyAnswersState.Post_Title,
        props.surveyAnswersState.Post_Keyword_1,
        props.surveyAnswersState.Post_Keyword_2,
        props.surveyAnswersState.Post_Keyword_3,
      )
        .then(updateTextValuesAfterAICall)
        .catch(err => {
          toast.warn('AI could not generate the text', {
            position: 'bottom-right',
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setAiTextLoading(false);
        });
    }
  }, []);

  const updateTextValuesAfterAICall = aiGeneratedText => {
    const updatedTextAreaValues = textAreaValuesStateRef.current.map(teaxtAreaValue => ({
      ...teaxtAreaValue,
      value: teaxtAreaValue.ai ? aiGeneratedText : teaxtAreaValue.value,
    }));
    setTextAreaValues(updatedTextAreaValues);
    props.handleSurveyAnswers(updatedTextAreaValues, props.id);
    setAiTextLoading(false);
  };

  const handleChange = (event, fieldId) => {
    const updatedTextAreaValues = textAreaValues.map(teaxtAreaValue => ({
      ...teaxtAreaValue,
      value: fieldId === teaxtAreaValue.id ? event.target.value : teaxtAreaValue.value,
    }));
    setTextAreaValues(updatedTextAreaValues);
    props.handleSurveyAnswers(updatedTextAreaValues, props.id);
  };

  const selectHandler = fieldId => {
    const updatedTextAreaValues = textAreaValues.map(teaxtAreaValue => ({
      ...teaxtAreaValue,
      isSelected: fieldId === teaxtAreaValue.id,
    }));
    setTextAreaValues(updatedTextAreaValues);
    props.handleSurveyAnswers(updatedTextAreaValues, props.id);
  };

  return (
    <Box className={classes.mainContainer}>
      {textAreaValues.map((textArea, index) => (
        <Box
          key={index}
          className={classes.textAreaContainer}
          style={{
            borderBottom: index === textAreaValues.length - 1 ? 'none' : '1px solid lightgray',
          }}>
          {textArea.ai && aiTextLoading ? (
            <Box className={classes.aiLoader}>
              <CircularProgress size={40} style={{ color: '#19989B', marginTop: '-35px' }} />
            </Box>
          ) : null}
          <Typography
            component="div"
            variant="h3"
            className={classes.text}
            style={{
              color: textArea.isSelected ? 'black' : '#C0C0C0',
            }}>
            {textArea.text}
          </Typography>
          <TextField
            fullWidth
            onChange={event => handleChange(event, textArea.id)}
            variant="outlined"
            multiline
            minRows={4}
            disabled={!textArea.isSelected}
            value={textArea.value}
            inputProps={{
              className: classes.textInput,
            }}
          />
          <Box className={classes.buttonContainer}>
            <Button
              onClick={() => selectHandler(textArea.id)}
              className={classes.selectButtonSelected}
              style={{
                backgroundColor: textArea.isSelected ? '#19989B' : 'white',
                color: textArea.isSelected ? 'white' : '#19989B',
              }}>
              Select
            </Button>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default MultiAiTextArea;
