import React from 'react';
import { useSelector } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import makeStyles from '@material-ui/core/styles/makeStyles';

import CmtVertical from '../../../../navigation';
import { sidebarItems } from './SidebarItems';

const useStyles = makeStyles(() => ({
  perfectScrollbarSidebar: {
    height: '100%',
    transition: 'all 0.3s ease',
    '.Cmt-sidebar-fixed &, .Cmt-Drawer-container &': {
      height: 'calc(100% - 167px)',
    },
    '.Cmt-modernLayout &': {
      height: 'calc(100% - 72px)',
    },
    '.Cmt-miniLayout &': {
      height: 'calc(100% - 91px)',
    },
    '.Cmt-miniLayout .Cmt-sidebar-content:hover &': {
      height: 'calc(100% - 167px)',
    },
  },
}));

const SidebarContent = () => {
  const classes = useStyles();
  const authState = useSelector(({ auth }) => auth);

  const subscriptionFilteredSidebarItems = sidebarItems.filter(
    item => !item.restrictedSubscriptions.includes(authState?.user?.customClaims?.subscriptionType),
  );

  return (
    <PerfectScrollbar className={classes.perfectScrollbarSidebar}>
      <CmtVertical menuItems={subscriptionFilteredSidebarItems} />
    </PerfectScrollbar>
  );
};

export default SidebarContent;
