import { useEffect } from "react";
import { validateAuthenticationState } from "redux/actions/Auth";

const useCheckAuthStateInterval = (dispatch, interval) => {
  useEffect(() => {
    const intervalId = setInterval(() => {
      validateAuthenticationState(dispatch, {
        fromStateListener: false,
        user: null,
      });
    }, interval);

    return () => clearInterval(intervalId); // Clean up on unmount
  }, [interval]);
};

export default useCheckAuthStateInterval;
