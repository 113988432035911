export const recentCardSmallText = {
  brand: {
    listSections: ['Persona Name', 'Last Edited', 'Total Match', 'Actions'],
  },
  buyer: {
    listSections: ['Persona Name', 'Last Edited', 'Total Match', 'Actions'],
  },
  social: {
    listSections: ['Title', 'Last Edited', 'Impact', 'Actions'],
  },
};

export const recentCardExtendedText = {
  brand: {
    listSections: [
      'Personas Name',
      'Last Edited',
      'Date Created',
      'Social V. Match',
      'Product B. Match',
      'Total Match',
      'Filter',
      'Actions',
    ],
  },
  social: {
    listSections: [
      'Post Title',
      'Last Edited',
      'Scheduled Date',
      'Persona',
      'Platform',
      'Impact',
      'Suggestion',
      'Filter',
      'Actions',
    ],
  },
  competitiveBrands: {
    listSections: [
      'Brand',
      'Industry',
      'Reputation',
      'Relationship',
      'Brand Choice',
      'Loyalty',
      'IE Focus',
      'Ideology',
      'Causes',
      'SV Match',
      'PB Match',
      'Total Match',
      'Actions',
    ],
  },
};

export const getRecentCardSmallText = (showImpact) => {
  if (showImpact) {
    return {
      brand: {
        listSections: ['Persona Name', 'Last Edited', 'Total Match', 'Actions'],
      },
      buyer: {
        listSections: ['Persona Name', 'Last Edited', 'Total Match', 'Actions'],
      },
      social: {
        listSections: ['Title', 'Last Edited', 'Impact', 'Actions'],
      },
    };
  }

  return {
    brand: {
      listSections: ['Persona Name', 'Last Edited', 'Actions'],
    },
    buyer: {
      listSections: ['Persona Name', 'Last Edited', 'Actions'],
    },
    social: {
      listSections: ['Title', 'Last Edited', 'Actions'],
    },
  };
};
