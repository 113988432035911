import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';

import StyledDatePicker from './styledDatePicker';

const useStyles = makeStyles(theme => ({
  mainContainer: ({ isInGroup, isSummary }) => ({
    width: '100%',
    maxWidth: '650px',
    margin: isInGroup ? '50px auto 50px auto' : isSummary ? '50px auto 15px auto' : '0 auto 30px auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: isInGroup ? '1px solid lightgray' : 'none',
    paddingBottom: isInGroup ? '40px' : 'none',
  }),
  text: ({ isSummary }) => ({
    color: theme.palette.text.primary,
    fontWeight: 800,
    fontSize: '17px',
    margin: '0',
    textAlign: 'left',
    '@media (max-width: 600px)': {
      fontSize: '16px',
      marginInline: 0,
    },
  }),
  dropdown: {
    padding: '10px 13px',
  },
  datePickerContainer: {
    minWidth: '300px',
    '@media (max-width: 600px)': {
      minWidth: '250px',
    },
  },
}));

const DatePicker = props => {
  const classes = useStyles({
    isSummary: props.isSummary,
    isInGroup: props.isInGroup,
  });

  const [selectedValue, setSelectedValue] = useState(props.value ? props.value : new Date());

  const handleChange = event => {
    const value = event ? event.toDate() : new Date();
    setSelectedValue(value);
    props.handleSurveyAnswers(value, props.id);
  };

  return (
    <Box className={classes.mainContainer}>
      <Typography component="div" variant="h3" className={classes.text}>
        {props.text}
      </Typography>
      <Box className={classes.datePickerContainer}>
        <StyledDatePicker value={selectedValue} onChange={handleChange} />
      </Box>
    </Box>
  );
};

export default DatePicker;
