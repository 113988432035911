export const additionalBuyerFieldsReference = {
  Buyer_LocalGlobal: [
    "Buyer_LocalGlobal_1",
    "Buyer_LocalGlobal_2",
    "Buyer_LocalGlobal_3",
  ],
  Buyer_InterExter: [
    "Buyer_InterExter_1",
    "Buyer_InterExter_2",
    "Buyer_InterExter_3",
    "Buyer_InterExter_4",
    "Buyer_InterExter_5",
    "Buyer_InterExter_6",
  ],
  Buyer_Ideology: [
    "Buyer_Ideology_1",
    "Buyer_Ideology_2",
    "Buyer_Ideology_3",
    "Buyer_Ideology_4",
  ],
  Buyer_SocialInovat: [
    "Buyer_SocialInovat_1",
    "Buyer_SocialInovat_2",
    "Buyer_SocialInovat_3",
  ],
};

export const additionalBrandFieldsReference = {
  Brand_LocalGlobal: [
    "Brand_LocalGlobal_1",
    "Brand_LocalGlobal_2",
    "Brand_LocalGlobal_3",
  ],

  Brand_InterExter: [
    "Brand_InterExter_1",
    "Brand_InterExter_2",
    "Brand_InterExter_3",
    "Brand_InterExter_4",
    "Brand_InterExter_5",
    "Brand_InterExter_6",
  ],

  Brand_Ideology: [
    "Brand_Ideology_1",
    "Brand_Ideology_2",
    "Brand_Ideology_3",
    "Brand_Ideology_4",
  ],

  Brand_SocialInovation: [
    "Brand_SocialInovation_1",
    "Brand_SocialInovation_2",
    "Brand_SocialInovation_3",
  ],
};
