import React from 'react';
import { makeStyles } from '@material-ui/core';
import { makeURL } from '../../../helperFunctions';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '29%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 0,
    padding: '10px 0px',

    '@media (max-width: 600px)': {
      width: '100%',
    },
  },
  content: {
    flex: 1,

    '& h3': {
      color: '#fff',
      fontSize: '20px',
      fontWeight: 600,
      marginBottom: '12px',
    },
    '& h5': {
      fontWeight: 300,
      fontSize: '16px',
      margin: 0,
      padding: 0,
      width: '80%',
    },
  },
}));

const ConsumerConsistencyCard = ({ id, subject, text, value, textColor }) => {
  const classes = useStyles();

  let imgSrc = makeURL('/images/icons/include-icon.svg');

  if (value < 0) {
    imgSrc = makeURL('/images/icons/x-icon.svg');
  } else if (value == null) {
    imgSrc = makeURL('/images/icons/exclude-icon.svg');
  }

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <h3>{subject}</h3>
        <h5 style={{ color: textColor }}>{text}</h5>
      </div>
      <img className={classes.icon} src={imgSrc} />
    </div>
  );
};

export default ConsumerConsistencyCard;
