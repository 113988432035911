/**
 * @key error --> If the cloud functions fails for some reason
 * @description --> No access
 *
 * @key deleted --> Somehow the subscription got deleted from Firestore (not possible)
 * @description --> No access
 *
 * @key admin --> Admin accounts (We set it manually)
 * @description Unlimited access
 *
 * @key academic --> Woocomerce "Academic Plan"
 * @description 2 new brand personas, 2 new buyer personas, 20 new social media posts, competitive set of 10 brands
 *
 * @key demo --> Woocomerce "BSV Subscription - Demo"
 * @description 1 new brand persona, 1 new buyer persona, 5 new social media posts, no competitive set
 *
 * @key standard --> Woocomerce "BSV Subscription - Standard"
 * @description 5 new brand personas, 5 new buyer personas, 50 new social media posts, competitive set of 30 brands
 *
 * @key advanced --> Woocomerce "BSV Subscription - Advanced"
 * @description Unlimited new brand personas, unlimited new buyer personas, 150 new social media posts, all competitive set
 *
 * @key enterprise --> Woocomerce "BSV Subscription - Enterprise"
 * @description Unlimited access
 */
export const subscriptionTypeNames = {
  error: 'function_error', //If the cloud functions fails for some reason
  deleted: 'deleted', //Somehow the subscription got deleted from Firestore (not possible)
  admin: 'unlimited', //Admin accounts
  academic: 'Academic Plan',
  demo: 'BSV Subscription - Demo',
  standard: 'BSV Subscription - Standard',
  advanced: 'BSV Subscription - Advanced',
  enterprise: 'BSV Subscription - Enterprise',
};
