import { timeSince } from "../../helperFunctions";
import { getSocialMediaPlatform } from "../../helperFunctions/getSurveysWithExtractedFields";

const sortBrandPersonaExcelData = (data) => {
  return data.map((item) => ({
    "Personas Name": item.Name,
    "Last Edited": timeSince(item.lastEdited._seconds),
    "Date Created": timeSince(item.dateCreated._seconds),
    "Social V. Match": item.socialValueMatch,
    "Product B. Match": item.productBenefitsMatch,
    "Total Match": item.totalMatch,
    Filter: item.filter,
  }));
};

const sortBuyerPersonaExcelData = (data) => {
  return data.map((item) => ({
    "Personas Name": item.Name,
    "Last Edited": timeSince(item.lastEdited._seconds),
    "Date Created": timeSince(item.dateCreated._seconds),
    "Social V. Match": item.socialValueMatch,
    "Product B. Match": item.productBenefitsMatch,
    "Total Match": item.totalMatch,
    Filter: item.filter,
  }));
};

const sortSocialMediaPostExcelData = (brandPersonas, data) => {
  return data.map((item) => {
    const { Name: personaName } = brandPersonas.find(
      ({ id }) => id === item.Post_Brand_Persona
    );
    return {
      "Post title": item.Post_Title,
      "Last Edited": timeSince(item.lastEdited._seconds),
      "Scheduled Date": timeSince(item.Post_Date._seconds),
      Persona: personaName,
      Platform: getSocialMediaPlatform({
        Post_Social_Media: item.Post_Social_Media,
      }),
      Impact: item.impact,
      Suggestion: item.suggestion,
      Filter: item.filter,
    };
  });
};

const sortCompetitiveSetBrandsExcelData = (data) => {
  return data.map((item) => ({
    Brand: item.name,
    Industry: item.industry,
    Reputation: Number((item.CReputation * 100).toFixed(0)),
    Relationship: Number((item.CRelationship * 100).toFixed(0)),
    "Brand Choice": Number((item.CPurchaseChoice * 100).toFixed(0)),
    Loyalty: Number((item.CLoyalty * 100).toFixed(0)),
    "IE Focus": Number((item.IE_Focus_Match * 100).toFixed(0)),
    Ideology: Number((item.Ideology_Match * 100).toFixed(0)),
    Causes: Number((item.Issues_Match * 100).toFixed(0)),
    "SV Match": Number((item.Values_Match * 100).toFixed(0)),
    "PB Match": Number((item.Product_Match * 100).toFixed(0)),
    "Total Match": Number((item.Total_Match * 100).toFixed(0)),
  }));
};

export const sortExcelData = (type, data, brandPersonas) => {
  switch (type) {
    case "/social-media/all":
      return sortSocialMediaPostExcelData(brandPersonas, data);
    case "/competitive-set-brands/all":
      return sortCompetitiveSetBrandsExcelData(data);
    case "/buyer-personas/all":
      return sortBuyerPersonaExcelData(data);
    case "/brand-personas/all":
      return sortBrandPersonaExcelData(data);
    default:
      return data;
  }
};
