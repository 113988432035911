import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import {
  Box,
  Typography,
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
} from '@material-ui/core';
import { Create } from '@material-ui/icons';
import { addNameToText } from '../helperFunctions';

const useStyles = makeStyles((theme) => ({
  mainContainer: ({ isSummary, isDependancy }) => ({
    display: isDependancy ? 'flex': null,
    width: (isSummary || isDependancy) ? '100%' : 'fit-content',
    margin: isSummary ? '0 auto 50px auto' : '0 auto',
    justifyContent: isDependancy ? 'center': null,
  }),
  contentContainer: ({ isSummary , isDependancy }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: isDependancy ? 'column' : 'row',
    width: isSummary ? '100%' : 'fit-content',
    '@media (max-width:870px)': {
      justifyContent: 'start',
      gap: isDependancy ? '0px' : '165px',
    },
    '@media (max-width:768px)': {
      justifyContent: 'flex-start',
      flexDirection: 'column',
      gap: '6px',
      alignItems: 'flex-start',
      width: '100%',
    },
  }),
  text: ({isDependancy}) => ({
    color: theme.palette.text.primary,
    fontWeight: 300,
    fontSize: '18px',
    maxWidth: '800px',
        '@media (max-width:1025px)': {
      fontSize: isDependancy ? '16px' : '18px',
    },
        '@media (max-width: 600px)': {
      fontSize: isDependancy ? '14px' : '16px',
    },
  }),
  
  textContainer: {
    marginRight: '50px',
    marginTop: 'auto',
  },
  formControl: {
    width: '215px',
    '@media (max-width:1025px)': {
      width: '150px',
    },
    '@media (max-width: 768px)': {
      width: '100%',
    },
  },
  input: {
    '@media (max-width: 768px)': {
      width: '100%',
    },
  },
  inputWrapper: {
    '@media (max-width: 768px)': {
      width: '100%',
    },
  },
  icon: {
    color: theme.palette.text.secondary,
  },
}));

const NumberFormElement = (props) => {
  const [numberValue, setNumberValue] = useState('');
  const isDependancy = props?.isDependent;
  const classes = useStyles({ isSummary: props.isSummary, isDependancy });

  useEffect(() => {
    setNumberValue('');
  }, [props.id]);

  const handleChange = (event) => {
    setNumberValue(event.target.value);
    props.handleSurveyAnswers(event.target.value, props.id);
  };

  return (
    <Box className={classes.mainContainer}>
      <Box className={classes.contentContainer}>
        <Box className={classes.textContainer}>
          <Typography component='div' variant='h3' className={classes.text}>
            {addNameToText(props.text, props.name)}
          </Typography>
        </Box>
        <Box className={classes.inputWrapper}>
          <FormControl variant='filled' className={classes.formControl}>
            <InputLabel htmlFor='filled-adornment-password'></InputLabel>
            <Input
              id='filled-adornment-password'
              type='number'
              value={props.value ? props.value : numberValue}
              onChange={handleChange}
              className={classes.input}
              endAdornment={
                <InputAdornment position='end'>
                  <Create className={classes.icon} fontSize='small' />
                </InputAdornment>
              }
            />
          </FormControl>
        </Box>
      </Box>
    </Box>
  );
};

export default NumberFormElement;
