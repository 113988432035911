import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Box, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    width: '100%',
    maxWidth: '650px',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    color: '#19989B',
    fontWeight: 400,
    fontSize: '25px',
    marginBottom: '20px',
    textAlign: 'center',
    '@media (max-width: 600px)': {
      fontSize: '20px',
      marginBottom: '15px',
    },
  },
  buttons: {
    padding: '8px 0',
    marginBottom: '20px',
    border: '2px solid #A6A6A6',
    borderRadius: '4px',
    width: '100%',
  },
  description: {
    width: '100%',
    color: theme.palette.text.primary,
    fontWeight: 300,
    fontSize: '13px',
    margin: '0',
  },
}));

const ShouldAiGenerate = (props) => {
  const [selectedButton, setSelectedButton] = useState(props.value || false);
  const classes = useStyles();

  const onClickHandler = (value) => {
    setSelectedButton(value);
    props.handleSurveyAnswers(value, props.id);
  };

  return (
    <Box className={classes.mainContainer}>
      <Typography component='div' variant='h2' className={classes.text}>
        {props.text}
      </Typography>
      <Box
        display='flex'
        alignItems='flex-start'
        justifyContent='space-between'
        width='100%'
      >
        {props.options.map((option, index) => {
          return (
            <Box key={index} width='47%'>
              <Button
                onClick={() => onClickHandler(option.ai)}
                className={classes.buttons}
                style={{
                  marginBottom: '20px',
                  backgroundColor:
                    option.ai === selectedButton ? '#19989B' : 'white',
                  border: `2px solid ${
                    option.ai === selectedButton ? '#19989B' : '#A6A6A6'
                  }`,
                }}
              >
                <p
                  style={{
                    margin: 0,
                    color: option.ai === selectedButton ? 'white' : 'black',
                    textTransform: 'none',
                    fontWeight: 300,
                    fontSize: '20px',
                  }}
                >
                  {option.text}
                </p>
              </Button>
              <Typography
                component='div'
                variant='body2'
                className={classes.description}
              >
                {option.description}
              </Typography>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default ShouldAiGenerate;
