import { convertRange } from "../../../../helperFunctions";
import { productBenefitsRangeToDisplayData } from "../../../../routes/Pages/BuyerPersonas/chartCalculations/helpers";

export const generateConsumerConsistencyData = (subjects, data) => {
  return subjects.map((subject, index) => {
    const count = index + 1;
    let value =
      data["IssuesImpact_" + count].value == null
        ? null
        : data["IssuesImpact_" + count].value;

    const text = data["IssuesImpact_" + count].ConsumerConsistencyText;

    const textToShow = value == null ? "Not applicable" : text;

    return {
      id: count,
      subject,
      value: value,
      text: textToShow,
      textColor: getTextColor(textToShow),
    };
  });
};

const getTextColor = (text) => {
  let color = "#87EDEF";

  if (text.toLowerCase() == "not applicable") {
    color = "#979797";
  } else if (text.toLowerCase().startsWith("explore")) {
    color = "#F891B4";
  }

  return color;
};

export const getPostCategory = (id) => {
  const options = [
    {
      value: 1,
      text: "Content directly related to your products and services",
    },
    {
      text: "General announcement about your brand",
      value: 2,
    },
    {
      text: "Fun or useful content",
      value: 3,
    },
    {
      text: "Comment about things not directly related to your brand",
      value: 4,
    },
  ];

  let postCategory = "";

  options.forEach((option) => {
    if (option.value == id) {
      postCategory = option.text;
    }
  });

  return postCategory;
};

export const SocialIssuesSubjects = [
  "State of the Economy",
  "Free Healthcare",
  "Violent Crime",
  "World Peace",
  "Gun Laws",
  "Race and Ethnic Inequality",
  "Gender Inequality",
  "Immigration",
  "Economic Inequality",
  "Climate Change",
  "Corporate Corruption",
];

const positioningValueLabel = [
  ["Post_LocalGlobal_1", "PersonalLocalGlobal", "LocalGlobalImpact"],
  ["Post_InterExter_1", "PersonalInterExter", "InternalExternalImpact"],
  ["Post_Ideology_1", "PersonalIdeology", "IdeologyImpact"],
];

const positioningImpactTextField = [
  "LoclaGlobalImpact",
  "InternalExternalImpact",
  "IdeologyImpact",
];
export const generateSocialPositioningData = (
  consumerPositioningImpactData,
  socialPositioningImpact,
  personaPositioningImpactData,
  socialPost
) => {
  let positioningData = [];

  for (let i = 0; i < 3; i++) {
    let positioningDataItem = {};

    positioningDataItem.heading = positionDataText[i].heading;
    positioningDataItem.leftText = positionDataText[i].leftText;
    positioningDataItem.rightText = positionDataText[i].rightText;

    //potrebna provera -> ako je null vrati 0

    let socialPositioningValue = Number(
      (socialPost[positioningValueLabel[i][0]] * 100).toFixed(0)
    );

    let consumerPositioningValue = Number(
      (
        consumerPositioningImpactData[positioningValueLabel[i][1]] * 100
      ).toFixed(0)
    );

    let personaPositioningValue = Number(
      (personaPositioningImpactData[positioningValueLabel[i][2]] * 100).toFixed(
        0
      )
    );

    positioningDataItem.socialValue =
      socialPost[positioningValueLabel[i][0]] !== null &&
      typeof socialPost[positioningValueLabel[i][0]] !== "undefined"
        ? { value: convertRange(socialPositioningValue, [0, 100], [-100, 100]), isNull: false }
        : { value: 0, isNull: true };

    positioningDataItem.consumerValue =
      consumerPositioningImpactData[positioningValueLabel[i][1]] !== null
        ? convertRange(consumerPositioningValue, [0, 100], [-100, 100])
        : 0;

    positioningDataItem.personaValue =
      personaPositioningImpactData[positioningValueLabel[i][2]] !== null
        ? convertRange(personaPositioningValue, [0, 100], [-100, 100])
        : 0;

    positioningDataItem.BrandImageConsistencyText = socialPost[
      positioningValueLabel[i][0]
    ]
      ? socialPositioningImpact[positioningImpactTextField[i]]
          .BrandImageConsistencyText
      : "Not Applicable";

    positioningDataItem.ConsumerConsistencyText = socialPost[
      positioningValueLabel[i][0]
    ]
      ? socialPositioningImpact[positioningImpactTextField[i]]
          .ConsumerConsistencyText
      : "Not Applicable";

    positioningDataItem.bodyText = positionDataText[i].body1;

    positioningData = [...positioningData, positioningDataItem];
  }

  return positioningData;
};

const positionDataText = [
  {
    heading: "Perspective",
    leftText: "Local",
    rightText: "Global",
    body1:
      "Comparisons of perspectives consumers and brands take. Local perspective is more about local and national approach, while global is more international and cosmopolitan. ",
    body2:
      "Comparisons of perspectives consumers and brands take. Local perspective is more about local and national approach, while global is more international and cosmopolitan. ",
  },
  {
    heading: "Focus",
    leftText: "Internal",
    rightText: "External",
    body1:
      "Comparison of consumers’ and brand’s focus  on personal benefits (internal) or society around them (external).",
    body2:
      "Comparison of consumers’ and brand’s focus  on personal benefits (internal) or society around them (external).",
  },
  {
    heading: "Ideology",
    leftText: "Liberal",
    rightText: "Conservative",
    body1:
      "Comparison of liberal vs conservative ideological split  between consumers and brands.",
    body2:
      "Comparison of liberal vs conservative ideological split  between consumers and brands.",
  },
];

export const generateProductBenefitsData = (
  consumerBenefits,
  socialBenefits,
  personaBenefits,
  socialPost
) => {
  let productBenefitsData = [];

  for (let i = 0; i < 10; i++) {
    let count = i + 1;

    let productBenefitsDataItem = {};

    productBenefitsDataItem.leftText = productBenefitsRangeToDisplayData(
      i
    ).leftText;
    productBenefitsDataItem.rightText = productBenefitsRangeToDisplayData(
      i
    ).rightText;

    let socialBenefitsValue = Number(
      (socialPost["Post_Product_" + count] * 100).toFixed(0)
    );

    productBenefitsDataItem.socialValue =
      socialPost["Post_Product_" + count] !== null &&
      typeof socialPost["Post_Product_" + count] !== "undefined"
        ? { value: Number(convertRange(socialBenefitsValue, [0, 100], [-100, 100])), isNull: false }
        : { value: 0, isNull: true };

    let consumerBenefitsValue = Number(
      (consumerBenefits["PersonalProduct_" + count] * 100).toFixed(0)
    );

    let personaBenefitsValue = Number(
      (personaBenefits["Brand_Product_" + count] * 100).toFixed(0)
    );

    productBenefitsDataItem.consumerValue =
      consumerBenefits["PersonalProduct_" + count] !== null
        ? convertRange(consumerBenefitsValue, [0, 100], [-100, 100])
        : 0;

    productBenefitsDataItem.personaValue =
      personaBenefits["Brand_Product_" + count] !== null
        ? convertRange(personaBenefitsValue, [0, 100], [-100, 100])
        : 0;

    productBenefitsDataItem.BrandImageConsistencyText =
      socialBenefits["ProductImpact_" + count].BrandImageConsistencyText;
    productBenefitsDataItem.ConsumerConsistencyText =
      socialBenefits["ProductImpact_" + count].ConsumerConsistencyText;

    productBenefitsData = [...productBenefitsData, productBenefitsDataItem];
  }

  return productBenefitsData;
};

export const isValidUrl = (url) => {
  const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
  return urlRegex.test(url);
};
