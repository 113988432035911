import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import { makeURL } from "../../../helperFunctions";

import { Box } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  filterBadgeContainer: {
    position: "relative",
    padding: "6px 12px",
    borderRadius: "5px",
    color: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  filterBadgeContent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  badgeLabel: {
    whiteSpace: "no-wrap",
  },
  badgeValue: {
    whiteSpace: "no-wrap",
    textWrap: "nowrap",
  },
  removeFilterBtn: {
    position: "absolute",
    cursor: "pointer",
    top: -4,
    right: -4,
    padding: "4px",
    borderRadius: "10px",
    background: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "2px 4px 10px rgba(0,0,0,0.1)",
    zIndex: 11,

    "& img": {
      width: "8px",
      height: "8px",
    },
  },
}));

const FilterBadge = ({ label, valueToShow, value, onRemove, bgColor }) => {
  const classes = useStyles();

  return (
    <Box
      className={classes.filterBadgeContainer}
      style={{ background: bgColor }}
    >
      <Box className={classes.filterBadgeContent}>
        <Box className={classes.badgeLabel}>{label}:&nbsp;</Box>
        <Box className={classes.badgeValue}>{valueToShow}</Box>
      </Box>
      <Box
        className={classes.removeFilterBtn}
        onClick={() => onRemove(label, value)}
      >
        <img src={makeURL("/images/removeFilterIcon.svg")} />
      </Box>
    </Box>
  );
};

export default FilterBadge;
