export const USA_States = [
  {
    text: 'Alabama',
    value: 1,
  },
  {
    text: 'Alaska',
    value: 2,
  },
  {
    text: 'Arizona',
    value: 3,
  },
  {
    text: 'Arkansas',
    value: 4,
  },
  {
    text: 'California',
    value: 5,
  },
  {
    text: 'Colorado',
    value: 6,
  },
  {
    text: 'Connecticut',
    value: 7,
  },
  {
    text: 'Delaware',
    value: 8,
  },
  {
    text: 'District of Columbia',
    value: 9,
  },
  {
    text: 'Florida',
    value: 10,
  },
  {
    text: 'Georgia',
    value: 11,
  },
  {
    text: 'Hawaii',
    value: 12,
  },
  {
    text: 'Idaho',
    value: 13,
  },
  {
    text: 'Illinois',
    value: 14,
  },
  {
    text: 'Indiana',
    value: 15,
  },
  {
    text: 'Iowa',
    value: 16,
  },
  {
    text: 'Kansas',
    value: 17,
  },
  {
    text: 'Kentucky',
    value: 18,
  },
  {
    text: 'Louisiana',
    value: 19,
  },
  {
    text: 'Maine',
    value: 20,
  },
  {
    text: 'Maryland',
    value: 21,
  },
  {
    text: 'Massachusetts',
    value: 22,
  },
  {
    text: 'Michigan',
    value: 23,
  },
  {
    text: 'Minnesota',
    value: 24,
  },
  {
    text: 'Mississippi',
    value: 25,
  },
  {
    text: 'Missouri',
    value: 26,
  },
  {
    text: 'Montana',
    value: 27,
  },
  {
    text: 'Nebraska',
    value: 28,
  },
  {
    text: 'Nevada',
    value: 29,
  },
  {
    text: 'New Hampshire',
    value: 30,
  },
  {
    text: 'New Jersey',
    value: 31,
  },
  {
    text: 'New Mexico',
    value: 32,
  },
  {
    text: 'New York',
    value: 33,
  },
  {
    text: 'North Carolina',
    value: 34,
  },
  {
    text: 'North Dakota',
    value: 35,
  },
  {
    text: 'Ohio',
    value: 36,
  },
  {
    text: 'Oklahoma',
    value: 37,
  },
  {
    text: 'Oregon',
    value: 38,
  },
  {
    text: 'Pennsylvania',
    value: 39,
  },
  {
    text: 'Puerto Rico',
    value: 40,
  },
  {
    text: 'Rhode Island',
    value: 41,
  },
  {
    text: 'South Carolina',
    value: 42,
  },
  {
    text: 'South Dakota',
    value: 43,
  },
  {
    text: 'Tennessee',
    value: 44,
  },
  {
    text: 'Texas',
    value: 45,
  },
  {
    text: 'Utah',
    value: 46,
  },
  {
    text: 'Vermont',
    value: 47,
  },
  {
    text: 'Virginia',
    value: 48,
  },
  {
    text: 'Washington',
    value: 49,
  },
  {
    text: 'West Virginia',
    value: 50,
  },
  {
    text: 'Wisconsin',
    value: 51,
  },
  {
    text: 'Wyoming',
    value: 52,
  },
  {
    text: 'I do not reside in the United States',
    value: 53,
  },
];

export const socialPostSurveyQuestions = [
  {
    type: 'group',
    fields: [
      {
        id: 'Post_Brand_Persona',
        text: 'Choose Brand Persona',
        type: 'persona-dropdown',
      },
      {
        id: 'Post_Social_Media',
        text: 'Choose Platform:',
        type: 'button',
        options: [
          {
            value: 1,
            text: 'Facebook',
          },
          {
            value: 2,
            text: 'Instagram',
          },
          {
            text: 'Linkedin',
            value: 3,
          },
          {
            value: 4,
            text: 'Youtube',
          },
          {
            text: 'Twitter',
            value: 5,
          },
          {
            text: 'TikTok',
            value: 6,
          },
          {
            value: 7,
            text: 'Snapchat',
          },
          {
            value: 8,
            text: 'Other',
          },
        ],
      },
    ],
  },
  {
    type: 'group',
    fields: [
      {
        heading: 'Please enter the title of your content',
        subHeading: '(this will be later used to identify different content)',
        type: 'text-heading-subHeading',
        id: 'Post_Title',
      },
      {
        text: 'Post Date',
        type: 'date-picker',
        id: 'Post_Date',
      },
      {
        type: 'wrapper',
        heading: 'Keywords',
        subHeading: 'Please enter up to three keywords related to your content',
        fields: [
          {
            text: 'Keyword 1',
            type: 'text',
            id: 'Post_Keyword_1',
          },
          {
            text: 'Keyword 2',
            id: 'Post_Keyword_2',
            type: 'text',
          },
          {
            text: 'Keyword 3',
            type: 'text',
            id: 'Post_Keyword_3',
          },
        ],
      },
    ],
  },
  {
    type: 'group',
    fields: [
      {
        type: 'multi-ai-text-area',
        id: 'Post_Text',
        textAreas: [
          {
            ai: true,
            text: 'Suggested social media content:',
            id: 'field_1',
          },
          {
            ai: false,
            text: 'Or enter the text of your social media content (if any).',
            id: 'field_2',
          },
        ],
      },
      {
        type: 'text-heading-subHeading',
        id: 'Post_Link',
        heading: 'Paste a link to your social media content if you have it.',
        subHeading: '',
      },
    ],
  },
  {
    type: 'radio',
    id: 'Post_Category',
    text: 'Please select one of the categories best describes your content:',
    options: [
      {
        value: 1,
        text:
          'Content directly related to your products and services (e.g. quality, price, new products)',
      },
      {
        text:
          'General announcement about your brand (e.g. new policy, giveaway, new team members)',
        value: 2,
      },
      {
        text:
          'Fun or useful content (e.g. tutorial, challenge, meme, inspirational quote)',
        value: 3,
      },
      {
        text:
          'All content or comments about things not directly related to your brand (e.g. commenting on other brands, cultures, society)',
        value: 4,
      },
    ],
  },
  {
    type: 'should-ai-generate',
    id: 'Post_should_ai_generate',
    text: 'Choose Analysis',
    options: [
      {
        ai: false,
        text: 'Manual',
        description:
          'In the next pages we will ask you to fill out a short survey and identify attributes of your Social Media Post. The scores from the survey are used for post evaluation.',
      },
      {
        ai: true,
        text: 'AI',
        description:
          'Advanced large language model will analyze the content of your post and generate responses to the same survey. You will have an opportunity to edit survey responses.',
      },
    ],
  },
  {
    text:
      'Select product/service benefits and attributes that are the main focus of your post. Skip all of those that are not relevant or mentioned in your post.',
    dependancySelection: {
      dependancy_question_id: 'Post_Category',
      value: [1],
    },
    id: [
      'Post_Product_1',
      'Post_Product_2',
      'Post_Product_3',
      'Post_Product_4',
      'Post_Product_5',
      'Post_Product_6',
      'Post_Product_7',
      'Post_Product_8',
      'Post_Product_9',
      'Post_Product_10',
    ],
    radiosLength: 5,
    left: [
      'Price',
      'Design',
      'Polite Service',
      'Professionalism',
      'Durability',
      'Uniqueness',
      'Privacy',
      'Convenience',
      'Core Product',
      'Experience',
    ],
    type: 'radio-group-multiple',
    right: [
      'Quality',
      'Functionality',
      'Fast Service',
      'Easy Going Attitude',
      'Affordability',
      'Compatibility',
      'Personalization',
      'Exclusivity',
      'Customer Support',
      'Ownership',
    ],
  },
  {
    type: 'group',
    fields: [
      {
        type: 'radio-group-multiple',
        dependancySelection: {
          dependancy_question_id: 'Post_Category',
          value: [2, 3, 4],
        },
        id: ['Post_LocalGlobal_1'],
        left: ['Local Issues'],
        right: ['Global Issues'],
        radiosLength: 5,
        text:
          'Is your social media content focused more on local or global issues?',
      },
      {
        type: 'radio-group-multiple',
        dependancySelection: {
          dependancy_question_id: 'Post_Category',
          value: [2, 3, 4],
        },
        id: ['Post_InterExter_1'],
        left: ['Internal Company Focus'],
        right: ['External Social/ Environmental Focus'],
        radiosLength: 5,
        text:
          'Is your social media content about benefits for your company and owners or focuses externally on society and environment?',
      },
      {
        type: 'radio-group-multiple',
        dependancySelection: {
          dependancy_question_id: 'Post_Category',
          value: [2, 3, 4],
        },
        id: ['Post_Ideology_1'],
        left: ['Liberal Values'],
        right: ['Conservative Values'],
        radiosLength: 5,
        text:
          'Does your social media content promote more liberal or conservative values?',
      },
    ],
  },
  {
    text:
      'Which one of the following social issues are addressed in your social media content? (select all that apply)',
    dependancySelection: {
      dependancy_question_id: 'Post_Category',
      value: [2, 3, 4],
    },
    id: [
      'Post_Issues_1',
      'Post_Issues_2',
      'Post_Issues_3',
      'Post_Issues_4',
      'Post_Issues_5',
      'Post_Issues_6',
      'Post_Issues_7',
      'Post_Issues_8',
      'Post_Issues_9',
      'Post_Issues_10',
      'Post_Issues_11',
    ],
    type: 'button-multiple',
    options: [
      'State of the economy',
      'Free healthcare',
      'Violent crime',
      'World peace',
      'Gunlaws',
      'Race and ethnic inequality',
      'Gender inequality',
      'Immigration',
      'Economic inequality',
      'Climate change ',
      'Corporate Corruption',
    ],
  },
  {
    type: 'survey-summary',
    groups: [
      {
        name: '',
        fields: [
          {
            id: 'Post_Brand_Persona',
            text: 'Brand Persona',
            type: 'persona-dropdown',
          },
          {
            id: 'Post_Social_Media',
            text: 'Platform',
            type: 'dropdown',
            options: [
              {
                value: 1,
                text: 'Facebook',
              },
              {
                value: 2,
                text: 'Instagram',
              },
              {
                text: 'Linkedin',
                value: 3,
              },
              {
                value: 4,
                text: 'Youtube',
              },
              {
                text: 'Twitter',
                value: 5,
              },
              {
                text: 'TikTok',
                value: 6,
              },
              {
                value: 7,
                text: 'Snapchat',
              },
              {
                value: 8,
                text: 'Other',
              },
            ],
          },
        ],
      },
      {
        name: '',
        fields: [
          {
            heading: 'Title',
            subHeading: '',
            type: 'text-heading-subHeading',
            id: 'Post_Title',
          },
          {
            text: 'Post Date',
            type: 'date-picker',
            id: 'Post_Date',
          },
        ],
      },
      {
        name: '',
        fields: [
          {
            type: 'text',
            id: 'Post_Keyword_1',
            text: 'Keyword 1',
          },
          {
            type: 'text',
            id: 'Post_Keyword_2',
            text: 'Keyword 2',
          },
          {
            type: 'text',
            id: 'Post_Keyword_3',
            text: 'Keyword 3',
          },
        ],
      },
      {
        name: '',
        fields: [
          {
            type: 'multi-ai-text-area',
            id: 'Post_Text',
            textAreas: [
              {
                ai: true,
                text: 'Suggested social media content:',
                id: 'field_1',
              },
              {
                ai: false,
                text:
                  'Or enter the text of your social media content (if any).',
                id: 'field_2',
              },
            ],
          },
          {
            type: 'text-heading-subHeading',
            id: 'Post_Link',
            heading:
              'Paste a link to your social media content if you have it.',
            subHeading: '',
          },
        ],
      },
      {
        name: '',
        fields: [
          {
            type: 'dropdown',
            id: 'Post_Category',
            text: 'Content Category',
            options: [
              {
                value: '1',
                text:
                  'Content directly related to your products and services (e.g. quality, price, new products)',
              },
              {
                text:
                  'General announcement about your brand (e.g. new policy, giveaway, new team members)',
                value: '2',
              },
              {
                text:
                  'Fun or useful content (e.g. tutorial, challenge, meme, inspirational quote)',
                value: '3',
              },
              {
                text:
                  'All content or comments about things not directly related to your brand (e.g. commenting on other brands, cultures, society)',
                value: '4',
              },
            ],
          },
        ],
      },
      {
        name: 'Product Benefits/Attributes',
        fields: [
          {
            text: '',
            dependancySelection: {
              dependancy_question_id: 'Post_Category',
              value: [1],
            },
            id: [
              'Post_Product_1',
              'Post_Product_2',
              'Post_Product_3',
              'Post_Product_4',
              'Post_Product_5',
              'Post_Product_6',
              'Post_Product_7',
              'Post_Product_8',
              'Post_Product_9',
              'Post_Product_10',
            ],
            radiosLength: 5,
            left: [
              'Price',
              'Design',
              'Polite Service',
              'Professionalism',
              'Durability',
              'Uniqueness',
              'Privacy',
              'Convenience',
              'Core Product',
              'Experience',
            ],
            type: 'radio-group-multiple',
            right: [
              'Quality',
              'Functionality',
              'Fast Service',
              'Easy Going Attitude',
              'Affordability',
              'Compatibility',
              'Personalization',
              'Exclusivity',
              'Customer Support',
              'Ownership',
            ],
          },
        ],
      },
      {
        name: 'Local and Global Perspective',
        fields: [
          {
            dependancySelection: {
              dependancy_question_id: 'Post_Category',
              value: [2, 3, 4],
            },
            type: 'radio-group-multiple',
            id: ['Post_LocalGlobal_1'],
            left: ['Local Issues'],
            right: ['Global Issues'],
            radiosLength: 5,
            text: '',
          },
        ],
      },
      {
        name: 'Internal and External Focus',
        fields: [
          {
            dependancySelection: {
              dependancy_question_id: 'Post_Category',
              value: [2, 3, 4],
            },
            type: 'radio-group-multiple',
            id: ['Post_InterExter_1'],
            left: ['Internal Company Focus'],
            right: ['External Social/ Environmental Focus'],
            radiosLength: 5,
            text: '',
          },
        ],
      },
      {
        name: 'Ideology',
        fields: [
          {
            dependancySelection: {
              dependancy_question_id: 'Post_Category',
              value: [2, 3, 4],
            },
            type: 'radio-group-multiple',
            id: ['Post_Ideology_1'],
            left: ['Liberal Values'],
            right: ['Conservative Values'],
            radiosLength: 5,
            text: '',
          },
        ],
      },
      {
        name: 'Social Causes',
        fields: [
          {
            text: '',
            dependancySelection: {
              dependancy_question_id: 'Post_Category',
              value: [2, 3, 4],
            },
            id: [
              'Post_Issues_1',
              'Post_Issues_2',
              'Post_Issues_3',
              'Post_Issues_4',
              'Post_Issues_5',
              'Post_Issues_6',
              'Post_Issues_7',
              'Post_Issues_8',
              'Post_Issues_9',
              'Post_Issues_10',
              'Post_Issues_11',
            ],
            type: 'button-multiple',
            options: [
              'State of the economy',
              'Free healthcare',
              'Violent crime',
              'World peace',
              'Gunlaws',
              'Race and ethnic inequality',
              'Gender inequality',
              'Immigration',
              'Economic inequality',
              'Climate change ',
              'Corporate Corruption',
            ],
          },
        ],
      },
    ],
  },
];

export const brandPersonaSurveyQuestions = [
  {
    fields: [
      {
        id: 'Name',
        text: 'Brand Persona Name',
        type: 'text',
      },
      {
        options: [
          {
            value: 1,
            text: 'CONSUMER SERVICES',
          },
          {
            value: 2,
            text: 'RETAIL',
          },
          {
            value: 3,
            text: 'FINANCIAL SERVICES',
          },
          {
            value: 4,
            text: 'TECHNOLOGY',
          },
          {
            value: 5,
            text: 'TELECOM ',
          },
          {
            text: 'CONSUMER PRODUCTS',
            value: 6,
          },
          {
            value: 7,
            text: 'MEDIA AND ENTERTAINMENT',
          },
          {
            value: 8,
            text: 'HOSPITALITY',
          },
          {
            text: 'APPAREL',
            value: 9,
          },
          {
            text: 'FOODSERVICE',
            value: 10,
          },
          {
            value: 11,
            text: 'AUTOMOTIVE',
          },
          {
            value: 12,
            text: 'LUXURY',
          },
          {
            text: 'OTHER',
            value: 20,
          },
        ],
        type: 'dropdown',
        text: 'Please select your industry.',
        id: 'Industry',
      },
    ],
    type: 'group',
  },
  {
    type: 'group',
    fields: [
      {
        type: 'text',
        id: 'Value',
        text:
          "What is ${Name}'s main value? (e.g. loyalty, success, achievement)",
      },
      {
        id: 'Interest',
        type: 'text',
        text:
          "What is ${Name}'s main general interest? (e.g. football, movies, family)",
      },
      {
        text:
          "What is ${Name}'s main goal in life? (e.g. wealth, social status)",
        id: 'Goal',
        type: 'text',
      },
      {
        id: 'Problem',
        type: 'text',
        text: "What is ${Name}'s main life problem?  (e.g. health, poverty)",
      },
      {
        id: 'Brand',
        text: "What is ${Name}'s favorite brand?",
        type: 'text',
      },
      {
        text: 'Which influencer or celebrity does ${Name} like the most?',
        type: 'text',
        id: 'Influencers',
      },
    ],
  },
  {
    right: [
      'Global Brand',
      'Focuses on international customers',
      'Sells mostly to international customers',
    ],
    text: 'Select attributes that best describe the brand ${Name} represents.',
    type: 'radio-group-multiple',
    radiosLength: 5,
    left: [
      'Local Brand',
      'Focuses on local customers',
      'Sells mostly to local customers',
    ],
    id: ['Brand_LocalGlobal_1', 'Brand_LocalGlobal_2', 'Brand_LocalGlobal_3'],
    mandatory: true,
  },
  {
    type: 'radio-group',
    scale: [
      'None at all',
      'A little',
      'A moderate amount',
      'A lot',
      'A great deal',
    ],
    radiosLength: 6,
    options: [
      'Profits',
      'Shareholders/Owners',
      'Community',
      'Social good',
      'Employees',
      'Environment',
    ],
    text: 'How much does ${Name} care about?',
    id: [
      'Brand_InterExter_1',
      'Brand_InterExter_2',
      'Brand_InterExter_3',
      'Brand_InterExter_4',
      'Brand_InterExter_5',
      'Brand_InterExter_6',
    ],
    mandatory: true,
  },
  {
    type: 'group',
    fields: [
      {
        type: 'radio-group-multiple',
        right: ['Extremely Conservative'],
        id: ['Brand_Ideology_1'],
        text:
          'On the political continuum from progressive (in the sense of propagating social change) to conservative (in the sense of propagating tradition), how is ${Name} positioned?',
        radiosLength: 5,
        left: ['Extremely Liberal'],
      },
      {
        type: 'radio-group',
        text:
          'On the following issues/dimensions, how left-or right ${Name} is?',
        radiosLength: 3,
        scale: ['Left', '', '', '', 'Right'],
        id: ['Brand_Ideology_2', 'Brand_Ideology_3', 'Brand_Ideology_4'],
        options: ['Economic', 'Social', 'Safety'],
      },
    ],
    mandatory: true,
  },
  {
    right: [
      'Quality',
      'Functionality',
      'Fast Service',
      'Easy Going Attitude',
      'Affordability',
      'Compatibility',
      'Personalization',
      'Exclusivity',
      'Customer Support',
      'Ownership',
    ],
    id: [
      'Brand_Product_1',
      'Brand_Product_2',
      'Brand_Product_3',
      'Brand_Product_4',
      'Brand_Product_5',
      'Brand_Product_6',
      'Brand_Product_7',
      'Brand_Product_8',
      'Brand_Product_9',
      'Brand_Product_10',
    ],
    radiosLength: 5,
    type: 'radio-group-multiple',
    left: [
      'Price',
      'Design',
      'Polite Service',
      'Professionalism',
      'Durability',
      'Uniqueness',
      'Privacy',
      'Convenience',
      'Core Product',
      'Experience',
    ],
    text: 'Please think about your brand. What is more important for ${Name}?',
    mandatory: true,
  },
  {
    options: [
      'State of the economy',
      'Free Healthcare',
      'Violent crime',
      'World peace',
      'Gun laws',
      'Race and ethnic inequality',
      'Gender inequality',
      'Immigration',
      'Economic inequality',
      'Climate change',
      'Corporate corruption',
    ],
    type: 'radio-group',
    scale: [
      'None at all',
      'A little',
      'A moderate amount',
      'A lot',
      'A great deal',
    ],
    radiosLength: 11,
    id: [
      'Brand_Issues_1',
      'Brand_Issues_2',
      'Brand_Issues_3',
      'Brand_Issues_4',
      'Brand_Issues_5',
      'Brand_Issues_6',
      'Brand_Issues_7',
      'Brand_Issues_8',
      'Brand_Issues_9',
      'Brand_Issues_10',
      'Brand_Issues_11',
    ],
    text: 'How much does ${Name} care about the following social issues?',
    mandatory: true,
  },
  {
    id: [
      'Brand_SocialInovation_1',
      'Brand_SocialInovation_2',
      'Brand_SocialInovation_3',
    ],
    options: [
      '${Name} is among the first brands to adapt to new social norms.',
      'Compared to other brands, ${Name} is quick to react to social movements (e.g. BLM, Me Too).',
      '${Name} is socially responsible.',
    ],
    text: 'How much do you agree with the following statements?',
    scale: [
      'Strongly disagree',
      'Disagree',
      'Neutral',
      'Agree',
      'Strongly agree',
    ],
    type: 'radio-group',
    radiosLength: 3,
    mandatory: true,
  },
  {
    id: 'country',
    dependency: [
      {
        id: 'State',
        options: [
          {
            text: 'Alabama',
            value: 1,
          },
          {
            text: 'Alaska',
            value: 2,
          },
          {
            text: 'Arizona',
            value: 3,
          },
          {
            value: 4,
            text: 'Arkansas',
          },
          {
            text: 'California',
            value: 5,
          },
          {
            text: 'Colorado',
            value: 6,
          },
          {
            value: 7,
            text: 'Connecticut',
          },
          {
            text: 'Delaware',
            value: 8,
          },
          {
            value: 9,
            text: 'District of Columbia',
          },
          {
            text: 'Florida',
            value: 10,
          },
          {
            text: 'Georgia',
            value: 11,
          },
          {
            value: 12,
            text: 'Hawaii',
          },
          {
            value: 13,
            text: 'Idaho',
          },
          {
            value: 14,
            text: 'Illinois',
          },
          {
            value: 15,
            text: 'Indiana',
          },
          {
            text: 'Iowa',
            value: 16,
          },
          {
            value: 17,
            text: 'Kansas',
          },
          {
            value: 18,
            text: 'Kentucky',
          },
          {
            value: 19,
            text: 'Louisiana',
          },
          {
            text: 'Maine',
            value: 20,
          },
          {
            text: 'Maryland',
            value: 21,
          },
          {
            text: 'Massachusetts',
            value: 22,
          },
          {
            text: 'Michigan',
            value: 23,
          },
          {
            text: 'Minnesota',
            value: 24,
          },
          {
            value: 25,
            text: 'Mississippi',
          },
          {
            text: 'Missouri',
            value: 26,
          },
          {
            value: 27,
            text: 'Montana',
          },
          {
            text: 'Nebraska',
            value: 28,
          },
          {
            text: 'Nevada',
            value: 29,
          },
          {
            value: 30,
            text: 'New Hampshire',
          },
          {
            value: 31,
            text: 'New Jersey',
          },
          {
            value: 32,
            text: 'New Mexico',
          },
          {
            text: 'New York',
            value: 33,
          },
          {
            value: 34,
            text: 'North Carolina',
          },
          {
            value: 35,
            text: 'North Dakota',
          },
          {
            value: 36,
            text: 'Ohio',
          },
          {
            text: 'Oklahoma',
            value: 37,
          },
          {
            text: 'Oregon',
            value: 38,
          },
          {
            value: 39,
            text: 'Pennsylvania',
          },
          {
            value: 40,
            text: 'Puerto Rico',
          },
          {
            text: 'Rhode Island',
            value: 41,
          },
          {
            text: 'South Carolina',
            value: 42,
          },
          {
            value: 43,
            text: 'South Dakota',
          },
          {
            text: 'Tennessee',
            value: 44,
          },
          {
            text: 'Texas',
            value: 45,
          },
          {
            value: 46,
            text: 'Utah',
          },
          {
            text: 'Vermont',
            value: 47,
          },
          {
            value: 48,
            text: 'Virginia',
          },
          {
            value: 49,
            text: 'Washington',
          },
          {
            text: 'West Virginia',
            value: 50,
          },
          {
            text: 'Wisconsin',
            value: 51,
          },
          {
            text: 'Wyoming',
            value: 52,
          },
          {
            text: 'I do not reside in the United States',
            value: 53,
          },
        ],
        type: 'dropdown',
        dependencyValue: 187,
        text: 'Which state does ${Name} come from?',
      },
    ],
    text: 'Which country does ${Name} come from?',
    options: [
      {
        value: 1,
        text: 'Afghanistan',
      },
      {
        value: 2,
        text: 'Albania',
      },
      {
        text: 'Algeria',
        value: 3,
      },
      {
        text: 'Andorra',
        value: 4,
      },
      {
        text: 'Angola',
        value: 5,
      },
      {
        value: 6,
        text: 'Antigua and Barbuda',
      },
      {
        value: 7,
        text: 'Argentina',
      },
      {
        value: 8,
        text: 'Armenia',
      },
      {
        value: 9,
        text: 'Australia',
      },
      {
        text: 'Austria',
        value: 10,
      },
      {
        text: 'Azerbaijan',
        value: 11,
      },
      {
        text: 'Bahamas',
        value: 12,
      },
      {
        value: 13,
        text: 'Bahrain',
      },
      {
        value: 14,
        text: 'Bangladesh',
      },
      {
        value: 15,
        text: 'Barbados',
      },
      {
        text: 'Belarus',
        value: 16,
      },
      {
        text: 'Belgium',
        value: 17,
      },
      {
        value: 18,
        text: 'Belize',
      },
      {
        text: 'Benin',
        value: 19,
      },
      {
        text: 'Bhutan',
        value: 20,
      },
      {
        text: 'Bolivia',
        value: 21,
      },
      {
        text: 'Bosnia and Herzegovina',
        value: 22,
      },
      {
        value: 23,
        text: 'Botswana',
      },
      {
        text: 'Brazil',
        value: 24,
      },
      {
        text: 'Brunei Darussalam',
        value: 25,
      },
      {
        text: 'Bulgaria',
        value: 26,
      },
      {
        value: 27,
        text: 'Burkina Faso',
      },
      {
        text: 'Burundi',
        value: 28,
      },
      {
        value: 29,
        text: 'Cambodia',
      },
      {
        text: 'Cameroon',
        value: 30,
      },
      {
        value: 31,
        text: 'Canada',
      },
      {
        text: 'Cape Verde',
        value: 32,
      },
      {
        text: 'Central African Republic',
        value: 33,
      },
      {
        text: 'Chad',
        value: 34,
      },
      {
        text: 'Chile',
        value: 35,
      },
      {
        value: 36,
        text: 'China',
      },
      {
        text: 'Colombia',
        value: 37,
      },
      {
        value: 38,
        text: 'Comoros',
      },
      {
        text: 'Congo, Republic of the...',
        value: 39,
      },
      {
        value: 40,
        text: 'Costa Rica',
      },
      {
        text: "Côte d'Ivoire",
        value: 41,
      },
      {
        text: 'Croatia',
        value: 42,
      },
      {
        text: 'Cuba',
        value: 43,
      },
      {
        text: 'Cyprus',
        value: 44,
      },
      {
        value: 45,
        text: 'Czech Republic',
      },
      {
        value: 47,
        text: 'Democratic Republic of the Congo',
      },
      {
        value: 48,
        text: 'Denmark',
      },
      {
        text: 'Djibouti',
        value: 49,
      },
      {
        value: 50,
        text: 'Dominica',
      },
      {
        value: 51,
        text: 'Dominican Republic',
      },
      {
        text: 'Ecuador',
        value: 52,
      },
      {
        value: 53,
        text: 'Egypt',
      },
      {
        text: 'El Salvador',
        value: 54,
      },
      {
        text: 'Equatorial Guinea',
        value: 55,
      },
      {
        text: 'Eritrea',
        value: 56,
      },
      {
        value: 57,
        text: 'Estonia',
      },
      {
        text: 'Ethiopia',
        value: 58,
      },
      {
        text: 'Fiji',
        value: 59,
      },
      {
        value: 60,
        text: 'Finland',
      },
      {
        value: 61,
        text: 'France',
      },
      {
        value: 62,
        text: 'Gabon',
      },
      {
        value: 63,
        text: 'Gambia',
      },
      {
        text: 'Georgia',
        value: 64,
      },
      {
        value: 65,
        text: 'Germany',
      },
      {
        value: 66,
        text: 'Ghana',
      },
      {
        text: 'Greece',
        value: 67,
      },
      {
        value: 68,
        text: 'Grenada',
      },
      {
        text: 'Guatemala',
        value: 69,
      },
      {
        value: 70,
        text: 'Guinea',
      },
      {
        text: 'Guinea-Bissau',
        value: 71,
      },
      {
        value: 72,
        text: 'Guyana',
      },
      {
        value: 73,
        text: 'Haiti',
      },
      {
        value: 74,
        text: 'Honduras',
      },
      {
        text: 'Hong Kong (S.A.R.)',
        value: 75,
      },
      {
        text: 'Hungary',
        value: 76,
      },
      {
        value: 77,
        text: 'Iceland',
      },
      {
        text: 'India',
        value: 78,
      },
      {
        value: 79,
        text: 'Indonesia',
      },
      {
        value: 80,
        text: 'Iran',
      },
      {
        value: 81,
        text: 'Iraq',
      },
      {
        value: 82,
        text: 'Ireland',
      },
      {
        text: 'Israel',
        value: 83,
      },
      {
        text: 'Italy',
        value: 84,
      },
      {
        text: 'Jamaica',
        value: 85,
      },
      {
        text: 'Japan',
        value: 86,
      },
      {
        text: 'Jordan',
        value: 87,
      },
      {
        text: 'Kazakhstan',
        value: 88,
      },
      {
        value: 89,
        text: 'Kenya',
      },
      {
        value: 90,
        text: 'Kiribati',
      },
      {
        value: 91,
        text: 'Kuwait',
      },
      {
        value: 92,
        text: 'Kyrgyzstan',
      },
      {
        value: 93,
        text: "Lao People's Democratic Republic",
      },
      {
        text: 'Latvia',
        value: 94,
      },
      {
        value: 95,
        text: 'Lebanon',
      },
      {
        text: 'Lesotho',
        value: 96,
      },
      {
        value: 97,
        text: 'Liberia',
      },
      {
        text: 'Libyan Arab Jamahiriya',
        value: 98,
      },
      {
        text: 'Liechtenstein',
        value: 99,
      },
      {
        text: 'Lithuania',
        value: 100,
      },
      {
        text: 'Luxembourg',
        value: 101,
      },
      {
        text: 'Madagascar',
        value: 102,
      },
      {
        text: 'Malawi',
        value: 103,
      },
      {
        text: 'Malaysia',
        value: 104,
      },
      {
        text: 'Maldives',
        value: 105,
      },
      {
        text: 'Mali',
        value: 106,
      },
      {
        text: 'Malta',
        value: 107,
      },
      {
        value: 108,
        text: 'Marshall Islands',
      },
      {
        value: 109,
        text: 'Mauritania',
      },
      {
        text: 'Mauritius',
        value: 110,
      },
      {
        value: 111,
        text: 'Mexico',
      },
      {
        value: 112,
        text: 'Micronesia, Federated States of...',
      },
      {
        text: 'Monaco',
        value: 113,
      },
      {
        text: 'Mongolia',
        value: 114,
      },
      {
        value: 115,
        text: 'Montenegro',
      },
      {
        value: 116,
        text: 'Morocco',
      },
      {
        text: 'Mozambique',
        value: 117,
      },
      {
        text: 'Myanmar',
        value: 118,
      },
      {
        text: 'Namibia',
        value: 119,
      },
      {
        text: 'Nauru',
        value: 120,
      },
      {
        value: 121,
        text: 'Nepal',
      },
      {
        text: 'Netherlands',
        value: 122,
      },
      {
        value: 123,
        text: 'New Zealand',
      },
      {
        value: 124,
        text: 'Nicaragua',
      },
      {
        value: 125,
        text: 'Niger',
      },
      {
        text: 'Nigeria',
        value: 126,
      },
      {
        text: 'North Korea',
        value: 127,
      },
      {
        text: 'Norway',
        value: 128,
      },
      {
        value: 129,
        text: 'Oman',
      },
      {
        text: 'Pakistan',
        value: 130,
      },
      {
        value: 131,
        text: 'Palau',
      },
      {
        text: 'Panama',
        value: 132,
      },
      {
        text: 'Papua New Guinea',
        value: 133,
      },
      {
        value: 134,
        text: 'Paraguay',
      },
      {
        value: 135,
        text: 'Peru',
      },
      {
        value: 136,
        text: 'Philippines',
      },
      {
        text: 'Poland',
        value: 137,
      },
      {
        value: 138,
        text: 'Portugal',
      },
      {
        text: 'Qatar',
        value: 139,
      },
      {
        text: 'Republic of Moldova',
        value: 141,
      },
      {
        text: 'Romania',
        value: 142,
      },
      {
        text: 'Russian Federation',
        value: 143,
      },
      {
        text: 'Rwanda',
        value: 144,
      },
      {
        text: 'Saint Kitts and Nevis',
        value: 145,
      },
      {
        value: 146,
        text: 'Saint Lucia',
      },
      {
        value: 147,
        text: 'Saint Vincent and the Grenadines',
      },
      {
        text: 'Samoa',
        value: 148,
      },
      {
        value: 149,
        text: 'San Marino',
      },
      {
        value: 150,
        text: 'Sao Tome and Principe',
      },
      {
        text: 'Saudi Arabia',
        value: 151,
      },
      {
        text: 'Senegal',
        value: 152,
      },
      {
        text: 'Serbia',
        value: 153,
      },
      {
        value: 154,
        text: 'Seychelles',
      },
      {
        value: 155,
        text: 'Sierra Leone',
      },
      {
        value: 156,
        text: 'Singapore',
      },
      {
        value: 157,
        text: 'Slovakia',
      },
      {
        value: 158,
        text: 'Slovenia',
      },
      {
        text: 'Solomon Islands',
        value: 159,
      },
      {
        text: 'Somalia',
        value: 160,
      },
      {
        text: 'South Africa',
        value: 161,
      },
      {
        value: 162,
        text: 'South Korea',
      },
      {
        value: 163,
        text: 'Spain',
      },
      {
        value: 164,
        text: 'Sri Lanka',
      },
      {
        value: 165,
        text: 'Sudan',
      },
      {
        value: 166,
        text: 'Suriname',
      },
      {
        text: 'Swaziland',
        value: 167,
      },
      {
        text: 'Sweden',
        value: 168,
      },
      {
        value: 169,
        text: 'Switzerland',
      },
      {
        text: 'Syrian Arab Republic',
        value: 170,
      },
      {
        text: 'Tajikistan',
        value: 171,
      },
      {
        value: 172,
        text: 'Thailand',
      },
      {
        text: 'The former Yugoslav Republic of Macedonia',
        value: 173,
      },
      {
        text: 'Timor-Leste',
        value: 174,
      },
      {
        text: 'Togo',
        value: 175,
      },
      {
        value: 176,
        text: 'Tonga',
      },
      {
        value: 177,
        text: 'Trinidad and Tobago',
      },
      {
        text: 'Tunisia',
        value: 178,
      },
      {
        text: 'Turkey',
        value: 179,
      },
      {
        value: 180,
        text: 'Turkmenistan',
      },
      {
        text: 'Tuvalu',
        value: 181,
      },
      {
        text: 'Uganda',
        value: 182,
      },
      {
        value: 183,
        text: 'Ukraine',
      },
      {
        value: 184,
        text: 'United Arab Emirates',
      },
      {
        text: 'United Kingdom of Great Britain and Northern Ireland',
        value: 185,
      },
      {
        text: 'United Republic of Tanzania',
        value: 186,
      },
      {
        text: 'United States of America',
        value: 187,
      },
      {
        text: 'Uruguay',
        value: 188,
      },
      {
        text: 'Uzbekistan',
        value: 189,
      },
      {
        text: 'Vanuatu',
        value: 190,
      },
      {
        value: 191,
        text: 'Venezuela, Bolivarian Republic of...',
      },
      {
        text: 'Viet Nam',
        value: 192,
      },
      {
        text: 'Yemen',
        value: 193,
      },
      {
        text: 'Zambia',
        value: 580,
      },
      {
        value: 1357,
        text: 'Zimbabwe',
      },
    ],
    type: 'dropdown',
  },
  {
    type: 'group',
    fields: [
      {
        text:
          'Which of the following categories best describes the location where ${Name} lives?',
        options: [
          {
            text: 'Urban',
            value: 1,
          },
          {
            text: 'Suburban',
            value: 2,
          },
          {
            text: 'Rural',
            value: 3,
          },
        ],
        type: 'button',
        id: 'Location',
      },
      {
        type: 'number',
        id: 'Age',
        text: "What is ${Name}'s age?",
      },
    ],
  },
  {
    text: "What is ${Name}'s gender?",
    id: 'Gender',
    options: [
      {
        value: 1,
        text: 'Male',
      },
      {
        text: 'Female',
        value: 2,
      },
      {
        value: 3,
        text: 'Trans male',
      },
      {
        text: 'Trans female',
        value: 4,
      },
      {
        value: 5,
        text: 'Preferred not to state',
      },
      {
        value: 6,
        text: 'Other - Preferred identity',
      },
    ],
    type: 'button',
  },
  {
    options: [
      {
        text: 'High School or less',
        value: 1,
      },
      {
        text: 'Some college/Associate Degree',
        value: 2,
      },
      {
        value: 3,
        text: "Bachelor's Degree",
      },
      {
        text: "Master's Degree/ Some graduate school",
        value: 4,
      },
      {
        value: 5,
        text: 'Doctoral and/or Professional Degree (e.g. Ph.D., JD, MD)',
      },
    ],
    type: 'button',
    text: "What is ${Name}'s educational level?",
    id: 'Education',
  },
  {
    id: 'Ethnicity',
    text: "What is ${Name}'s ethnicity?",
    type: 'button',
    options: [
      {
        text: 'Caucasian',
        value: 1,
      },
      {
        value: 2,
        text: 'Native American',
      },
      {
        text: 'Hispanic',
        value: 3,
      },
      {
        text: 'African American',
        value: 4,
      },
      {
        value: 5,
        text: 'Asian',
      },
      {
        value: 6,
        text: 'Pacific Islander',
      },
      {
        value: 7,
        text: 'Other',
      },
    ],
  },
  {
    id: 'Marital_status',
    text: "What is ${Name}'s marital status?",
    options: [
      {
        value: 1,
        text: 'Married or living with a partner without children',
      },
      {
        value: 2,
        text: 'Married or living with a partner with children',
      },
      {
        text: 'Widowed',
        value: 3,
      },
      {
        value: 4,
        text: 'Divorced',
      },
      {
        text: 'Separated',
        value: 5,
      },
      {
        value: 6,
        text: 'Never married',
      },
    ],
    type: 'button',
  },
  {
    type: 'survey-summary',
    groups: [
      {
        name: '',
        fields: [
          {
            id: 'Name',
            text: 'Brand Persona Name',
            type: 'text',
          },
          {
            options: [
              {
                value: 1,
                text: 'CONSUMER SERVICES',
              },
              {
                value: 2,
                text: 'RETAIL',
              },
              {
                value: 3,
                text: 'FINANCIAL SERVICES',
              },
              {
                value: 4,
                text: 'TECHNOLOGY',
              },
              {
                value: 5,
                text: 'TELECOM ',
              },
              {
                text: 'CONSUMER PRODUCTS',
                value: 6,
              },
              {
                value: 7,
                text: 'MEDIA AND ENTERTAINMENT',
              },
              {
                value: 8,
                text: 'HOSPITALITY',
              },
              {
                text: 'APPAREL',
                value: 9,
              },
              {
                text: 'FOODSERVICE',
                value: 10,
              },
              {
                value: 11,
                text: 'AUTOMOTIVE',
              },
              {
                value: 12,
                text: 'LUXURY',
              },
              {
                text: 'OTHER',
                value: 20,
              },
            ],
            type: 'dropdown',
            text: 'Industry',
            id: 'Industry',
          },
        ],
      },
      {
        name: '',
        fields: [
          {
            type: 'text',
            id: 'Value',
            text: 'Main value',
          },
          {
            id: 'Interest',
            type: 'text',
            text: 'Main general interest',
          },
          {
            text: 'Main goal in life',
            id: 'Goal',
            type: 'text',
          },
          {
            id: 'Problem',
            type: 'text',
            text: 'Main life problem',
          },
          {
            id: 'Brand',
            text: 'Favorite brand',
            type: 'text',
          },
          {
            text: 'Favorite influencer/celebrity',
            type: 'text',
            id: 'Influencers',
          },
        ],
      },
      {
        name: 'Local and Global Perspective',
        fields: [
          {
            left: [
              'Local Brand',
              'Focuses on local customers',
              'Sells mostly to local customers',
            ],
            type: 'radio-group-multiple',
            radiosLength: 5,
            right: [
              'Global Brand',
              'Focuses on international customers',
              'Sells mostly to international customers',
            ],
            id: [
              'Brand_LocalGlobal_1',
              'Brand_LocalGlobal_2',
              'Brand_LocalGlobal_3',
            ],
            text: '',
          },
        ],
      },
      {
        name: 'Internal and External Focus Importance',
        fields: [
          {
            text: '',
            options: [
              'Profits',
              'Shareholders/Owners',
              'Community',
              'Social good',
              'Employees',
              'Environment',
            ],
            id: [
              'Brand_InterExter_1',
              'Brand_InterExter_2',
              'Brand_InterExter_3',
              'Brand_InterExter_4',
              'Brand_InterExter_5',
              'Brand_InterExter_6',
            ],
            scale: [
              'None at all',
              'A little',
              'A moderate amount',
              'A lot',
              'A great deal',
            ],
            radiosLength: 6,
            type: 'radio-group',
          },
        ],
      },
      {
        name: 'Overall Ideology',
        fields: [
          {
            text: '',
            right: ['Extremely Conservative'],
            id: ['Brand_Ideology_1'],
            left: ['Extremely Liberal'],
            radiosLength: 5,
            type: 'radio-group-multiple',
          },
        ],
      },
      {
        name: 'Ideology Dimensions',
        fields: [
          {
            text: '',
            options: ['Economic', 'Social', 'Safety'],
            type: 'radio-group',
            radiosLength: 3,
            id: ['Brand_Ideology_2', 'Brand_Ideology_3', 'Brand_Ideology_4'],
            scale: ['Left', '', '', '', 'Right'],
          },
        ],
      },
      {
        name: 'Product Benefits',
        fields: [
          {
            text: '',
            left: [
              'Price',
              'Design',
              'Polite Service',
              'Professionalism',
              'Durability',
              'Uniqueness',
              'Privacy',
              'Convenience',
              'Core Product',
              'Experience',
            ],
            id: [
              'Brand_Product_1',
              'Brand_Product_2',
              'Brand_Product_3',
              'Brand_Product_4',
              'Brand_Product_5',
              'Brand_Product_6',
              'Brand_Product_7',
              'Brand_Product_8',
              'Brand_Product_9',
              'Brand_Product_10',
            ],
            type: 'radio-group-multiple',
            right: [
              'Quality',
              'Functionality',
              'Fast Service',
              'Easy Going Attitude',
              'Affordability',
              'Compatibility',
              'Personalization',
              'Exclusivity',
              'Customer Support',
              'Ownership',
            ],
            radiosLength: 5,
          },
        ],
      },
      {
        name: 'Social Causes Importance',
        fields: [
          {
            radiosLength: 11,
            id: [
              'Brand_Issues_1',
              'Brand_Issues_2',
              'Brand_Issues_3',
              'Brand_Issues_4',
              'Brand_Issues_5',
              'Brand_Issues_6',
              'Brand_Issues_7',
              'Brand_Issues_8',
              'Brand_Issues_9',
              'Brand_Issues_10',
              'Brand_Issues_11',
            ],
            type: 'radio-group',
            options: [
              'State of the economy',
              'Free Healthcare',
              'Violent crime',
              'World peace',
              'Gun laws',
              'Race and ethnic inequality',
              'Gender inequality',
              'Immigration',
              'Economic inequality',
              'Climate change',
              'Corporate corruption',
            ],
            scale: [
              'None at all',
              'A little',
              'A moderate amount',
              'A lot',
              'A great deal',
            ],
            text: '',
          },
        ],
      },
      {
        name: 'Social Innovativeness',
        fields: [
          {
            scale: [
              'None at all',
              'A little',
              'A moderate amount',
              'A lot',
              'A great deal',
            ],
            type: 'radio-group',
            options: [
              'Adapt to new social norms',
              'Quick to react to social movements',
              'Socially responsible',
            ],
            radiosLength: 3,
            text: '',
            id: [
              'Brand_SocialInovation_1',
              'Brand_SocialInovation_2',
              'Brand_SocialInovation_3',
            ],
          },
        ],
      },
      {
        name: 'Demographics',
        fields: [
          {
            text: 'Country',
            id: 'country',
            type: 'dropdown',
            dependency: [
              {
                id: 'State',
                dependencyValue: 187,
                type: 'dropdown',
                text: 'State/Region',
                options: [
                  {
                    text: 'Alabama',
                    value: 1,
                  },
                  {
                    text: 'Alaska',
                    value: 2,
                  },
                  {
                    value: 3,
                    text: 'Arizona',
                  },
                  {
                    value: 4,
                    text: 'Arkansas',
                  },
                  {
                    text: 'California',
                    value: 5,
                  },
                  {
                    value: 6,
                    text: 'Colorado',
                  },
                  {
                    value: 7,
                    text: 'Connecticut',
                  },
                  {
                    text: 'Delaware',
                    value: 8,
                  },
                  {
                    value: 9,
                    text: 'District of Columbia',
                  },
                  {
                    value: 10,
                    text: 'Florida',
                  },
                  {
                    value: 11,
                    text: 'Georgia',
                  },
                  {
                    text: 'Hawaii',
                    value: 12,
                  },
                  {
                    value: 13,
                    text: 'Idaho',
                  },
                  {
                    text: 'Illinois',
                    value: 14,
                  },
                  {
                    text: 'Indiana',
                    value: 15,
                  },
                  {
                    value: 16,
                    text: 'Iowa',
                  },
                  {
                    value: 17,
                    text: 'Kansas',
                  },
                  {
                    text: 'Kentucky',
                    value: 18,
                  },
                  {
                    text: 'Louisiana',
                    value: 19,
                  },
                  {
                    text: 'Maine',
                    value: 20,
                  },
                  {
                    text: 'Maryland',
                    value: 21,
                  },
                  {
                    value: 22,
                    text: 'Massachusetts',
                  },
                  {
                    value: 23,
                    text: 'Michigan',
                  },
                  {
                    text: 'Minnesota',
                    value: 24,
                  },
                  {
                    value: 25,
                    text: 'Mississippi',
                  },
                  {
                    value: 26,
                    text: 'Missouri',
                  },
                  {
                    text: 'Montana',
                    value: 27,
                  },
                  {
                    value: 28,
                    text: 'Nebraska',
                  },
                  {
                    text: 'Nevada',
                    value: 29,
                  },
                  {
                    text: 'New Hampshire',
                    value: 30,
                  },
                  {
                    text: 'New Jersey',
                    value: 31,
                  },
                  {
                    value: 32,
                    text: 'New Mexico',
                  },
                  {
                    value: 33,
                    text: 'New York',
                  },
                  {
                    value: 34,
                    text: 'North Carolina',
                  },
                  {
                    value: 35,
                    text: 'North Dakota',
                  },
                  {
                    text: 'Ohio',
                    value: 36,
                  },
                  {
                    value: 37,
                    text: 'Oklahoma',
                  },
                  {
                    value: 38,
                    text: 'Oregon',
                  },
                  {
                    text: 'Pennsylvania',
                    value: 39,
                  },
                  {
                    value: 40,
                    text: 'Puerto Rico',
                  },
                  {
                    text: 'Rhode Island',
                    value: 41,
                  },
                  {
                    value: 42,
                    text: 'South Carolina',
                  },
                  {
                    value: 43,
                    text: 'South Dakota',
                  },
                  {
                    text: 'Tennessee',
                    value: 44,
                  },
                  {
                    value: 45,
                    text: 'Texas',
                  },
                  {
                    value: 46,
                    text: 'Utah',
                  },
                  {
                    value: 47,
                    text: 'Vermont',
                  },
                  {
                    value: 48,
                    text: 'Virginia',
                  },
                  {
                    value: 49,
                    text: 'Washington',
                  },
                  {
                    value: 50,
                    text: 'West Virginia',
                  },
                  {
                    text: 'Wisconsin',
                    value: 51,
                  },
                  {
                    value: 52,
                    text: 'Wyoming',
                  },
                  {
                    text: 'I do not reside in the United States',
                    value: 53,
                  },
                ],
              },
            ],
            options: [
              {
                text: 'Afghanistan',
                value: 1,
              },
              {
                text: 'Albania',
                value: 2,
              },
              {
                value: 3,
                text: 'Algeria',
              },
              {
                value: 4,
                text: 'Andorra',
              },
              {
                value: 5,
                text: 'Angola',
              },
              {
                text: 'Antigua and Barbuda',
                value: 6,
              },
              {
                value: 7,
                text: 'Argentina',
              },
              {
                value: 8,
                text: 'Armenia',
              },
              {
                value: 9,
                text: 'Australia',
              },
              {
                value: 10,
                text: 'Austria',
              },
              {
                text: 'Azerbaijan',
                value: 11,
              },
              {
                value: 12,
                text: 'Bahamas',
              },
              {
                value: 13,
                text: 'Bahrain',
              },
              {
                value: 14,
                text: 'Bangladesh',
              },
              {
                value: 15,
                text: 'Barbados',
              },
              {
                text: 'Belarus',
                value: 16,
              },
              {
                value: 17,
                text: 'Belgium',
              },
              {
                value: 18,
                text: 'Belize',
              },
              {
                value: 19,
                text: 'Benin',
              },
              {
                text: 'Bhutan',
                value: 20,
              },
              {
                text: 'Bolivia',
                value: 21,
              },
              {
                text: 'Bosnia and Herzegovina',
                value: 22,
              },
              {
                value: 23,
                text: 'Botswana',
              },
              {
                value: 24,
                text: 'Brazil',
              },
              {
                text: 'Brunei Darussalam',
                value: 25,
              },
              {
                text: 'Bulgaria',
                value: 26,
              },
              {
                value: 27,
                text: 'Burkina Faso',
              },
              {
                value: 28,
                text: 'Burundi',
              },
              {
                value: 29,
                text: 'Cambodia',
              },
              {
                text: 'Cameroon',
                value: 30,
              },
              {
                text: 'Canada',
                value: 31,
              },
              {
                text: 'Cape Verde',
                value: 32,
              },
              {
                value: 33,
                text: 'Central African Republic',
              },
              {
                text: 'Chad',
                value: 34,
              },
              {
                value: 35,
                text: 'Chile',
              },
              {
                text: 'China',
                value: 36,
              },
              {
                value: 37,
                text: 'Colombia',
              },
              {
                value: 38,
                text: 'Comoros',
              },
              {
                value: 39,
                text: 'Congo, Republic of the...',
              },
              {
                value: 40,
                text: 'Costa Rica',
              },
              {
                value: 41,
                text: "Côte d'Ivoire",
              },
              {
                value: 42,
                text: 'Croatia',
              },
              {
                value: 43,
                text: 'Cuba',
              },
              {
                text: 'Cyprus',
                value: 44,
              },
              {
                text: 'Czech Republic',
                value: 45,
              },
              {
                value: 47,
                text: 'Democratic Republic of the Congo',
              },
              {
                value: 48,
                text: 'Denmark',
              },
              {
                text: 'Djibouti',
                value: 49,
              },
              {
                value: 50,
                text: 'Dominica',
              },
              {
                value: 51,
                text: 'Dominican Republic',
              },
              {
                text: 'Ecuador',
                value: 52,
              },
              {
                value: 53,
                text: 'Egypt',
              },
              {
                text: 'El Salvador',
                value: 54,
              },
              {
                value: 55,
                text: 'Equatorial Guinea',
              },
              {
                value: 56,
                text: 'Eritrea',
              },
              {
                text: 'Estonia',
                value: 57,
              },
              {
                text: 'Ethiopia',
                value: 58,
              },
              {
                text: 'Fiji',
                value: 59,
              },
              {
                text: 'Finland',
                value: 60,
              },
              {
                text: 'France',
                value: 61,
              },
              {
                value: 62,
                text: 'Gabon',
              },
              {
                text: 'Gambia',
                value: 63,
              },
              {
                value: 64,
                text: 'Georgia',
              },
              {
                value: 65,
                text: 'Germany',
              },
              {
                text: 'Ghana',
                value: 66,
              },
              {
                value: 67,
                text: 'Greece',
              },
              {
                text: 'Grenada',
                value: 68,
              },
              {
                text: 'Guatemala',
                value: 69,
              },
              {
                text: 'Guinea',
                value: 70,
              },
              {
                value: 71,
                text: 'Guinea-Bissau',
              },
              {
                value: 72,
                text: 'Guyana',
              },
              {
                value: 73,
                text: 'Haiti',
              },
              {
                value: 74,
                text: 'Honduras',
              },
              {
                value: 75,
                text: 'Hong Kong (S.A.R.)',
              },
              {
                value: 76,
                text: 'Hungary',
              },
              {
                text: 'Iceland',
                value: 77,
              },
              {
                text: 'India',
                value: 78,
              },
              {
                value: 79,
                text: 'Indonesia',
              },
              {
                text: 'Iran',
                value: 80,
              },
              {
                text: 'Iraq',
                value: 81,
              },
              {
                text: 'Ireland',
                value: 82,
              },
              {
                text: 'Israel',
                value: 83,
              },
              {
                text: 'Italy',
                value: 84,
              },
              {
                text: 'Jamaica',
                value: 85,
              },
              {
                value: 86,
                text: 'Japan',
              },
              {
                text: 'Jordan',
                value: 87,
              },
              {
                value: 88,
                text: 'Kazakhstan',
              },
              {
                value: 89,
                text: 'Kenya',
              },
              {
                value: 90,
                text: 'Kiribati',
              },
              {
                value: 91,
                text: 'Kuwait',
              },
              {
                value: 92,
                text: 'Kyrgyzstan',
              },
              {
                text: "Lao People's Democratic Republic",
                value: 93,
              },
              {
                text: 'Latvia',
                value: 94,
              },
              {
                text: 'Lebanon',
                value: 95,
              },
              {
                text: 'Lesotho',
                value: 96,
              },
              {
                value: 97,
                text: 'Liberia',
              },
              {
                value: 98,
                text: 'Libyan Arab Jamahiriya',
              },
              {
                value: 99,
                text: 'Liechtenstein',
              },
              {
                value: 100,
                text: 'Lithuania',
              },
              {
                value: 101,
                text: 'Luxembourg',
              },
              {
                text: 'Madagascar',
                value: 102,
              },
              {
                text: 'Malawi',
                value: 103,
              },
              {
                value: 104,
                text: 'Malaysia',
              },
              {
                value: 105,
                text: 'Maldives',
              },
              {
                text: 'Mali',
                value: 106,
              },
              {
                value: 107,
                text: 'Malta',
              },
              {
                text: 'Marshall Islands',
                value: 108,
              },
              {
                text: 'Mauritania',
                value: 109,
              },
              {
                value: 110,
                text: 'Mauritius',
              },
              {
                text: 'Mexico',
                value: 111,
              },
              {
                text: 'Micronesia, Federated States of...',
                value: 112,
              },
              {
                value: 113,
                text: 'Monaco',
              },
              {
                value: 114,
                text: 'Mongolia',
              },
              {
                value: 115,
                text: 'Montenegro',
              },
              {
                text: 'Morocco',
                value: 116,
              },
              {
                text: 'Mozambique',
                value: 117,
              },
              {
                text: 'Myanmar',
                value: 118,
              },
              {
                value: 119,
                text: 'Namibia',
              },
              {
                value: 120,
                text: 'Nauru',
              },
              {
                value: 121,
                text: 'Nepal',
              },
              {
                text: 'Netherlands',
                value: 122,
              },
              {
                text: 'New Zealand',
                value: 123,
              },
              {
                value: 124,
                text: 'Nicaragua',
              },
              {
                value: 125,
                text: 'Niger',
              },
              {
                value: 126,
                text: 'Nigeria',
              },
              {
                value: 127,
                text: 'North Korea',
              },
              {
                text: 'Norway',
                value: 128,
              },
              {
                value: 129,
                text: 'Oman',
              },
              {
                text: 'Pakistan',
                value: 130,
              },
              {
                value: 131,
                text: 'Palau',
              },
              {
                text: 'Panama',
                value: 132,
              },
              {
                value: 133,
                text: 'Papua New Guinea',
              },
              {
                text: 'Paraguay',
                value: 134,
              },
              {
                text: 'Peru',
                value: 135,
              },
              {
                text: 'Philippines',
                value: 136,
              },
              {
                value: 137,
                text: 'Poland',
              },
              {
                text: 'Portugal',
                value: 138,
              },
              {
                value: 139,
                text: 'Qatar',
              },
              {
                text: 'Republic of Moldova',
                value: 141,
              },
              {
                text: 'Romania',
                value: 142,
              },
              {
                text: 'Russian Federation',
                value: 143,
              },
              {
                text: 'Rwanda',
                value: 144,
              },
              {
                value: 145,
                text: 'Saint Kitts and Nevis',
              },
              {
                text: 'Saint Lucia',
                value: 146,
              },
              {
                text: 'Saint Vincent and the Grenadines',
                value: 147,
              },
              {
                text: 'Samoa',
                value: 148,
              },
              {
                text: 'San Marino',
                value: 149,
              },
              {
                text: 'Sao Tome and Principe',
                value: 150,
              },
              {
                text: 'Saudi Arabia',
                value: 151,
              },
              {
                value: 152,
                text: 'Senegal',
              },
              {
                value: 153,
                text: 'Serbia',
              },
              {
                text: 'Seychelles',
                value: 154,
              },
              {
                value: 155,
                text: 'Sierra Leone',
              },
              {
                text: 'Singapore',
                value: 156,
              },
              {
                text: 'Slovakia',
                value: 157,
              },
              {
                text: 'Slovenia',
                value: 158,
              },
              {
                value: 159,
                text: 'Solomon Islands',
              },
              {
                text: 'Somalia',
                value: 160,
              },
              {
                value: 161,
                text: 'South Africa',
              },
              {
                text: 'South Korea',
                value: 162,
              },
              {
                text: 'Spain',
                value: 163,
              },
              {
                value: 164,
                text: 'Sri Lanka',
              },
              {
                text: 'Sudan',
                value: 165,
              },
              {
                value: 166,
                text: 'Suriname',
              },
              {
                value: 167,
                text: 'Swaziland',
              },
              {
                value: 168,
                text: 'Sweden',
              },
              {
                value: 169,
                text: 'Switzerland',
              },
              {
                text: 'Syrian Arab Republic',
                value: 170,
              },
              {
                value: 171,
                text: 'Tajikistan',
              },
              {
                value: 172,
                text: 'Thailand',
              },
              {
                text: 'The former Yugoslav Republic of Macedonia',
                value: 173,
              },
              {
                text: 'Timor-Leste',
                value: 174,
              },
              {
                text: 'Togo',
                value: 175,
              },
              {
                value: 176,
                text: 'Tonga',
              },
              {
                text: 'Trinidad and Tobago',
                value: 177,
              },
              {
                text: 'Tunisia',
                value: 178,
              },
              {
                text: 'Turkey',
                value: 179,
              },
              {
                text: 'Turkmenistan',
                value: 180,
              },
              {
                text: 'Tuvalu',
                value: 181,
              },
              {
                text: 'Uganda',
                value: 182,
              },
              {
                text: 'Ukraine',
                value: 183,
              },
              {
                value: 184,
                text: 'United Arab Emirates',
              },
              {
                text: 'United Kingdom of Great Britain and Northern Ireland',
                value: 185,
              },
              {
                text: 'United Republic of Tanzania',
                value: 186,
              },
              {
                value: 187,
                text: 'United States of America',
              },
              {
                text: 'Uruguay',
                value: 188,
              },
              {
                value: 189,
                text: 'Uzbekistan',
              },
              {
                value: 190,
                text: 'Vanuatu',
              },
              {
                text: 'Venezuela, Bolivarian Republic of...',
                value: 191,
              },
              {
                value: 192,
                text: 'Viet Nam',
              },
              {
                value: 193,
                text: 'Yemen',
              },
              {
                text: 'Zambia',
                value: 580,
              },
              {
                text: 'Zimbabwe',
                value: 1357,
              },
            ],
          },
          {
            type: 'dropdown',
            id: 'Location',
            text: 'Location',
            options: [
              {
                value: 1,
                text: 'Urban',
              },
              {
                value: 2,
                text: 'Suburban',
              },
              {
                value: 3,
                text: 'Rural',
              },
            ],
          },
          {
            type: 'number',
            id: 'Age',
            text: 'Age',
          },
          {
            id: 'Gender',
            type: 'dropdown',
            options: [
              {
                value: 1,
                text: 'Male',
              },
              {
                text: 'Female',
                value: 2,
              },
              {
                text: 'Trans male',
                value: 3,
              },
              {
                text: 'Trans female',
                value: 4,
              },
              {
                value: 5,
                text: 'Preferred not to state',
              },
              {
                value: 6,
                text: 'Other - Preferred identity',
              },
            ],
            text: 'Gender',
          },
          {
            type: 'dropdown',
            id: 'Education',
            options: [
              {
                text: 'High School or less',
                value: 1,
              },
              {
                text: 'Some college/Associate Degree',
                value: 2,
              },
              {
                text: "Bachelor's Degree",
                value: 3,
              },
              {
                text: "Master's Degree/ Some graduate school",
                value: 4,
              },
              {
                text: 'Doctoral and/or Professional Degree',
                value: 5,
              },
            ],
            text: 'Education',
          },
          {
            id: 'Ethnicity',
            text: 'Ethnicity',
            options: [
              {
                value: 1,
                text: 'Caucasian',
              },
              {
                value: 2,
                text: 'Native American',
              },
              {
                text: 'Hispanic',
                value: 3,
              },
              {
                value: 4,
                text: 'African American',
              },
              {
                value: 5,
                text: 'Asian',
              },
              {
                text: 'Pacific Islander',
                value: 6,
              },
              {
                value: 7,
                text: 'Other',
              },
            ],
            type: 'dropdown',
          },
          {
            id: 'Marital_status',
            type: 'dropdown',
            text: 'Marital Status',
            options: [
              {
                text: 'Married/live with partner no children',
                value: 1,
              },
              {
                value: 2,
                text: 'Married/live with partner and children',
              },
              {
                value: 3,
                text: 'Widowed',
              },
              {
                value: 4,
                text: 'Divorced',
              },
              {
                text: 'Separated',
                value: 5,
              },
              {
                text: 'Never married',
                value: 6,
              },
            ],
          },
        ],
      },
    ],
  },
];

export const buyerPersonaSurveyQuestions = [
  {
    text: 'Buyer Persona Name',
    type: 'text',
    id: 'Name',
  },
  {
    type: 'group',
    fields: [
      {
        id: 'Value',
        type: 'text',
        text:
          "What is ${Name}'s main value? (e.g. loyalty, success, achievement)",
      },
      {
        id: 'Interest',
        text:
          "What is ${Name}'s main general interest? (e.g. football, movies, family)",
        type: 'text',
      },
      {
        text:
          "What is ${Name}'s main goal in life? (e.g. wealth, social status)",
        type: 'text',
        id: 'Goal',
      },
      {
        text: "What is ${Name}'s main life problem?  (e.g. health, poverty)",
        type: 'text',
        id: 'Problem',
      },
      {
        text: "What is ${Name}'s favorite brand?",
        id: 'Brand',
        type: 'text',
      },
      {
        type: 'text',
        id: 'Influencers',
        text: 'Which influencer or celebrity does ${Name} like the most?',
      },
    ],
  },
  {
    text: 'Please answer a few questions about ${Name}.',
    type: 'radio-group-multiple',
    left: [
      '${Name} would see themselves as a citizen of their own country',
      '${Name} mostly has friends that are of the same nationality',
      '${Name} usually buys products made in their own country',
    ],
    right: [
      '${Name} would see themselves as a citizen of the World.',
      '${Name} mostly has international friends.',
      '${Name} usually buys imported products.',
    ],
    radiosLength: 5,
    id: ['Buyer_LocalGlobal_1', 'Buyer_LocalGlobal_2', 'Buyer_LocalGlobal_3'],
    mandatory: true,
  },
  {
    scale: [
      'None at all',
      'A little',
      'A moderate amount',
      'A lot',
      'A great deal',
    ],
    type: 'radio-group',
    options: [
      'Personal Income',
      'Wealth',
      'Community',
      'Social good',
      'Neighbors',
      'Environment',
    ],
    radiosLength: 6,
    text: 'How much does ${Name} care about?',
    id: [
      'Buyer_InterExter_1',
      'Buyer_InterExter_2',
      'Buyer_InterExter_3',
      'Buyer_InterExter_4',
      'Buyer_InterExter_5',
      'Buyer_InterExter_6',
    ],
    mandatory: true,
  },
  {
    type: 'group',
    fields: [
      {
        text:
          'On the political continuum from progressive (in the sense of propagating social change) to conservative (in the sense of propagating tradition), how is ${Name} positioned?',
        type: 'radio-group-multiple',
        right: ['Extremely Conservative'],
        radiosLength: 5,
        left: ['Extremely Liberal'],
        id: ['Buyer_Ideology_1'],
      },
      {
        scale: ['Left', '', '', '', 'Right'],
        type: 'radio-group',
        id: ['Buyer_Ideology_2', 'Buyer_Ideology_3', 'Buyer_Ideology_4'],
        radiosLength: 3,
        options: ['Economic', 'Social', 'Safety'],
        text:
          'On the following issues/dimensions, how left-or right ${Name} is?',
      },
    ],
    mandatory: true,
  },
  {
    type: 'radio-group-multiple',
    radiosLength: 5,
    left: [
      'Price',
      'Design',
      'Polite Service',
      'Professionalism',
      'Durability',
      'Uniqueness',
      'Privacy',
      'Convenience',
      'Core Product',
      'Experience',
    ],
    text:
      'Which of the following are more important to ${Name} when they make decision to buy products and services?',
    right: [
      'Quality',
      'Functionality',
      'Fast Service',
      'Easy Going Attitude',
      'Affordability',
      'Compatibility',
      'Personalization',
      'Exclusivity',
      'Customer Support',
      'Ownership',
    ],
    id: [
      'Buyer_Product_1',
      'Buyer_Product_2',
      'Buyer_Product_3',
      'Buyer_Product_4',
      'Buyer_Product_5',
      'Buyer_Product_6',
      'Buyer_Product_7',
      'Buyer_Product_8',
      'Buyer_Product_9',
      'Buyer_Product_10',
    ],
    mandatory: true,
  },
  {
    text: 'How much does ${Name} care about the following social issues?',
    type: 'radio-group',
    id: [
      'Buyer_Issues_1',
      'Buyer_Issues_2',
      'Buyer_Issues_3',
      'Buyer_Issues_4',
      'Buyer_Issues_5',
      'Buyer_Issues_6',
      'Buyer_Issues_7',
      'Buyer_Issues_8',
      'Buyer_Issues_9',
      'Buyer_Issues_10',
      'Buyer_Issues_11',
    ],
    scale: [
      'None at all',
      'A little',
      'A moderate amount',
      'A lot',
      'A great deal',
    ],
    options: [
      'State of the economy',
      'Free Healthcare',
      'Violent crime',
      'World peace',
      'Gun laws',
      'Race and ethnic inequality',
      'Gender inequality',
      'Immigration',
      'Economic inequality',
      'Climate change',
      'Corporate corruption',
    ],
    radiosLength: 11,
    mandatory: true,
  },
  {
    text: 'How much do ${Name} agree with the following statements?',
    id: [
      'Buyer_SocialInovat_1',
      'Buyer_SocialInovat_2',
      'Buyer_SocialInovat_3',
    ],
    options: [
      '${Name} is among the first people to adapt to new social norms.',
      'Compared to other people, ${Name} is quick to react to social movements (e.g. BLM, Me Too).',
      '${Name} is socially responsible.',
    ],
    type: 'radio-group',
    radiosLength: 3,
    scale: [
      'None at all',
      'A little',
      'A moderate amount',
      'A lot',
      'A great deal',
    ],
    mandatory: true,
  },
  {
    type: 'dropdown',
    dependency: [
      {
        id: 'City',
        type: 'text',
        text: 'In which city does ${Name} currently reside?',
        dependencyValue: 187,
      },
      {
        text: 'Which state does ${Name} come from?',
        id: 'State',
        options: [
          {
            text: 'Alabama',
            value: 1,
          },
          {
            value: 2,
            text: 'Alaska',
          },
          {
            value: 3,
            text: 'Arizona',
          },
          {
            value: 4,
            text: 'Arkansas',
          },
          {
            value: 5,
            text: 'California',
          },
          {
            text: 'Colorado',
            value: 6,
          },
          {
            value: 7,
            text: 'Connecticut',
          },
          {
            value: 8,
            text: 'Delaware',
          },
          {
            text: 'District of Columbia',
            value: 9,
          },
          {
            value: 10,
            text: 'Florida',
          },
          {
            value: 11,
            text: 'Georgia',
          },
          {
            text: 'Hawaii',
            value: 12,
          },
          {
            text: 'Idaho',
            value: 13,
          },
          {
            text: 'Illinois',
            value: 14,
          },
          {
            value: 15,
            text: 'Indiana',
          },
          {
            value: 16,
            text: 'Iowa',
          },
          {
            text: 'Kansas',
            value: 17,
          },
          {
            text: 'Kentucky',
            value: 18,
          },
          {
            value: 19,
            text: 'Louisiana',
          },
          {
            value: 20,
            text: 'Maine',
          },
          {
            value: 21,
            text: 'Maryland',
          },
          {
            value: 22,
            text: 'Massachusetts',
          },
          {
            text: 'Michigan',
            value: 23,
          },
          {
            value: 24,
            text: 'Minnesota',
          },
          {
            text: 'Mississippi',
            value: 25,
          },
          {
            text: 'Missouri',
            value: 26,
          },
          {
            value: 27,
            text: 'Montana',
          },
          {
            text: 'Nebraska',
            value: 28,
          },
          {
            text: 'Nevada',
            value: 29,
          },
          {
            text: 'New Hampshire',
            value: 30,
          },
          {
            text: 'New Jersey',
            value: 31,
          },
          {
            value: 32,
            text: 'New Mexico',
          },
          {
            value: 33,
            text: 'New York',
          },
          {
            text: 'North Carolina',
            value: 34,
          },
          {
            value: 35,
            text: 'North Dakota',
          },
          {
            value: 36,
            text: 'Ohio',
          },
          {
            value: 37,
            text: 'Oklahoma',
          },
          {
            text: 'Oregon',
            value: 38,
          },
          {
            value: 39,
            text: 'Pennsylvania',
          },
          {
            value: 40,
            text: 'Puerto Rico',
          },
          {
            text: 'Rhode Island',
            value: 41,
          },
          {
            value: 42,
            text: 'South Carolina',
          },
          {
            value: 43,
            text: 'South Dakota',
          },
          {
            text: 'Tennessee',
            value: 44,
          },
          {
            text: 'Texas',
            value: 45,
          },
          {
            text: 'Utah',
            value: 46,
          },
          {
            text: 'Vermont',
            value: 47,
          },
          {
            text: 'Virginia',
            value: 48,
          },
          {
            text: 'Washington',
            value: 49,
          },
          {
            value: 50,
            text: 'West Virginia',
          },
          {
            value: 51,
            text: 'Wisconsin',
          },
          {
            value: 52,
            text: 'Wyoming',
          },
          {
            text: 'I do not reside in the United States',
            value: 53,
          },
        ],
        type: 'dropdown',
        dependencyValue: 187,
      },
      {
        type: 'number',
        dependencyValue: 187,
        text: "What is ${Name}'s ZIP code?",
        id: 'Zip',
      },
    ],
    text: 'In which country does ${Name} currently reside?',
    options: [
      {
        text: 'Afghanistan',
        value: 1,
      },
      {
        text: 'Albania',
        value: 2,
      },
      {
        value: 3,
        text: 'Algeria',
      },
      {
        value: 4,
        text: 'Andorra',
      },
      {
        value: 5,
        text: 'Angola',
      },
      {
        text: 'Antigua and Barbuda',
        value: 6,
      },
      {
        value: 7,
        text: 'Argentina',
      },
      {
        text: 'Armenia',
        value: 8,
      },
      {
        value: 9,
        text: 'Australia',
      },
      {
        value: 10,
        text: 'Austria',
      },
      {
        text: 'Azerbaijan',
        value: 11,
      },
      {
        text: 'Bahamas',
        value: 12,
      },
      {
        text: 'Bahrain',
        value: 13,
      },
      {
        value: 14,
        text: 'Bangladesh',
      },
      {
        value: 15,
        text: 'Barbados',
      },
      {
        text: 'Belarus',
        value: 16,
      },
      {
        value: 17,
        text: 'Belgium',
      },
      {
        value: 18,
        text: 'Belize',
      },
      {
        text: 'Benin',
        value: 19,
      },
      {
        text: 'Bhutan',
        value: 20,
      },
      {
        text: 'Bolivia',
        value: 21,
      },
      {
        text: 'Bosnia and Herzegovina',
        value: 22,
      },
      {
        text: 'Botswana',
        value: 23,
      },
      {
        text: 'Brazil',
        value: 24,
      },
      {
        value: 25,
        text: 'Brunei Darussalam',
      },
      {
        value: 26,
        text: 'Bulgaria',
      },
      {
        value: 27,
        text: 'Burkina Faso',
      },
      {
        text: 'Burundi',
        value: 28,
      },
      {
        value: 29,
        text: 'Cambodia',
      },
      {
        value: 30,
        text: 'Cameroon',
      },
      {
        text: 'Canada',
        value: 31,
      },
      {
        text: 'Cape Verde',
        value: 32,
      },
      {
        value: 33,
        text: 'Central African Republic',
      },
      {
        text: 'Chad',
        value: 34,
      },
      {
        text: 'Chile',
        value: 35,
      },
      {
        text: 'China',
        value: 36,
      },
      {
        text: 'Colombia',
        value: 37,
      },
      {
        value: 38,
        text: 'Comoros',
      },
      {
        value: 39,
        text: 'Congo, Republic of the...',
      },
      {
        text: 'Costa Rica',
        value: 40,
      },
      {
        value: 41,
        text: "Côte d'Ivoire",
      },
      {
        text: 'Croatia',
        value: 42,
      },
      {
        text: 'Cuba',
        value: 43,
      },
      {
        value: 44,
        text: 'Cyprus',
      },
      {
        text: 'Czech Republic',
        value: 45,
      },
      {
        text: 'Democratic Republic of the Congo',
        value: 47,
      },
      {
        text: 'Denmark',
        value: 48,
      },
      {
        value: 49,
        text: 'Djibouti',
      },
      {
        text: 'Dominica',
        value: 50,
      },
      {
        value: 51,
        text: 'Dominican Republic',
      },
      {
        text: 'Ecuador',
        value: 52,
      },
      {
        text: 'Egypt',
        value: 53,
      },
      {
        text: 'El Salvador',
        value: 54,
      },
      {
        value: 55,
        text: 'Equatorial Guinea',
      },
      {
        value: 56,
        text: 'Eritrea',
      },
      {
        text: 'Estonia',
        value: 57,
      },
      {
        text: 'Ethiopia',
        value: 58,
      },
      {
        text: 'Fiji',
        value: 59,
      },
      {
        value: 60,
        text: 'Finland',
      },
      {
        text: 'France',
        value: 61,
      },
      {
        value: 62,
        text: 'Gabon',
      },
      {
        value: 63,
        text: 'Gambia',
      },
      {
        text: 'Georgia',
        value: 64,
      },
      {
        value: 65,
        text: 'Germany',
      },
      {
        text: 'Ghana',
        value: 66,
      },
      {
        text: 'Greece',
        value: 67,
      },
      {
        value: 68,
        text: 'Grenada',
      },
      {
        value: 69,
        text: 'Guatemala',
      },
      {
        text: 'Guinea',
        value: 70,
      },
      {
        value: 71,
        text: 'Guinea-Bissau',
      },
      {
        value: 72,
        text: 'Guyana',
      },
      {
        text: 'Haiti',
        value: 73,
      },
      {
        text: 'Honduras',
        value: 74,
      },
      {
        text: 'Hong Kong (S.A.R.)',
        value: 75,
      },
      {
        value: 76,
        text: 'Hungary',
      },
      {
        value: 77,
        text: 'Iceland',
      },
      {
        text: 'India',
        value: 78,
      },
      {
        value: 79,
        text: 'Indonesia',
      },
      {
        text: 'Iran',
        value: 80,
      },
      {
        value: 81,
        text: 'Iraq',
      },
      {
        value: 82,
        text: 'Ireland',
      },
      {
        text: 'Israel',
        value: 83,
      },
      {
        value: 84,
        text: 'Italy',
      },
      {
        value: 85,
        text: 'Jamaica',
      },
      {
        text: 'Japan',
        value: 86,
      },
      {
        text: 'Jordan',
        value: 87,
      },
      {
        value: 88,
        text: 'Kazakhstan',
      },
      {
        text: 'Kenya',
        value: 89,
      },
      {
        value: 90,
        text: 'Kiribati',
      },
      {
        text: 'Kuwait',
        value: 91,
      },
      {
        value: 92,
        text: 'Kyrgyzstan',
      },
      {
        value: 93,
        text: "Lao People's Democratic Republic",
      },
      {
        text: 'Latvia',
        value: 94,
      },
      {
        value: 95,
        text: 'Lebanon',
      },
      {
        text: 'Lesotho',
        value: 96,
      },
      {
        text: 'Liberia',
        value: 97,
      },
      {
        value: 98,
        text: 'Libyan Arab Jamahiriya',
      },
      {
        value: 99,
        text: 'Liechtenstein',
      },
      {
        value: 100,
        text: 'Lithuania',
      },
      {
        value: 101,
        text: 'Luxembourg',
      },
      {
        text: 'Madagascar',
        value: 102,
      },
      {
        value: 103,
        text: 'Malawi',
      },
      {
        text: 'Malaysia',
        value: 104,
      },
      {
        text: 'Maldives',
        value: 105,
      },
      {
        text: 'Mali',
        value: 106,
      },
      {
        text: 'Malta',
        value: 107,
      },
      {
        text: 'Marshall Islands',
        value: 108,
      },
      {
        text: 'Mauritania',
        value: 109,
      },
      {
        text: 'Mauritius',
        value: 110,
      },
      {
        text: 'Mexico',
        value: 111,
      },
      {
        text: 'Micronesia, Federated States of...',
        value: 112,
      },
      {
        text: 'Monaco',
        value: 113,
      },
      {
        value: 114,
        text: 'Mongolia',
      },
      {
        value: 115,
        text: 'Montenegro',
      },
      {
        text: 'Morocco',
        value: 116,
      },
      {
        text: 'Mozambique',
        value: 117,
      },
      {
        text: 'Myanmar',
        value: 118,
      },
      {
        value: 119,
        text: 'Namibia',
      },
      {
        text: 'Nauru',
        value: 120,
      },
      {
        text: 'Nepal',
        value: 121,
      },
      {
        value: 122,
        text: 'Netherlands',
      },
      {
        text: 'New Zealand',
        value: 123,
      },
      {
        text: 'Nicaragua',
        value: 124,
      },
      {
        value: 125,
        text: 'Niger',
      },
      {
        value: 126,
        text: 'Nigeria',
      },
      {
        value: 127,
        text: 'North Korea',
      },
      {
        text: 'Norway',
        value: 128,
      },
      {
        value: 129,
        text: 'Oman',
      },
      {
        text: 'Pakistan',
        value: 130,
      },
      {
        value: 131,
        text: 'Palau',
      },
      {
        text: 'Panama',
        value: 132,
      },
      {
        text: 'Papua New Guinea',
        value: 133,
      },
      {
        value: 134,
        text: 'Paraguay',
      },
      {
        value: 135,
        text: 'Peru',
      },
      {
        value: 136,
        text: 'Philippines',
      },
      {
        value: 137,
        text: 'Poland',
      },
      {
        text: 'Portugal',
        value: 138,
      },
      {
        text: 'Qatar',
        value: 139,
      },
      {
        text: 'Republic of Moldova',
        value: 141,
      },
      {
        text: 'Romania',
        value: 142,
      },
      {
        text: 'Russian Federation',
        value: 143,
      },
      {
        text: 'Rwanda',
        value: 144,
      },
      {
        value: 145,
        text: 'Saint Kitts and Nevis',
      },
      {
        text: 'Saint Lucia',
        value: 146,
      },
      {
        text: 'Saint Vincent and the Grenadines',
        value: 147,
      },
      {
        value: 148,
        text: 'Samoa',
      },
      {
        text: 'San Marino',
        value: 149,
      },
      {
        text: 'Sao Tome and Principe',
        value: 150,
      },
      {
        value: 151,
        text: 'Saudi Arabia',
      },
      {
        text: 'Senegal',
        value: 152,
      },
      {
        text: 'Serbia',
        value: 153,
      },
      {
        text: 'Seychelles',
        value: 154,
      },
      {
        text: 'Sierra Leone',
        value: 155,
      },
      {
        text: 'Singapore',
        value: 156,
      },
      {
        value: 157,
        text: 'Slovakia',
      },
      {
        value: 158,
        text: 'Slovenia',
      },
      {
        text: 'Solomon Islands',
        value: 159,
      },
      {
        text: 'Somalia',
        value: 160,
      },
      {
        value: 161,
        text: 'South Africa',
      },
      {
        text: 'South Korea',
        value: 162,
      },
      {
        value: 163,
        text: 'Spain',
      },
      {
        text: 'Sri Lanka',
        value: 164,
      },
      {
        text: 'Sudan',
        value: 165,
      },
      {
        text: 'Suriname',
        value: 166,
      },
      {
        text: 'Swaziland',
        value: 167,
      },
      {
        value: 168,
        text: 'Sweden',
      },
      {
        value: 169,
        text: 'Switzerland',
      },
      {
        text: 'Syrian Arab Republic',
        value: 170,
      },
      {
        text: 'Tajikistan',
        value: 171,
      },
      {
        value: 172,
        text: 'Thailand',
      },
      {
        value: 173,
        text: 'The former Yugoslav Republic of Macedonia',
      },
      {
        value: 174,
        text: 'Timor-Leste',
      },
      {
        text: 'Togo',
        value: 175,
      },
      {
        text: 'Tonga',
        value: 176,
      },
      {
        value: 177,
        text: 'Trinidad and Tobago',
      },
      {
        value: 178,
        text: 'Tunisia',
      },
      {
        text: 'Turkey',
        value: 179,
      },
      {
        value: 180,
        text: 'Turkmenistan',
      },
      {
        text: 'Tuvalu',
        value: 181,
      },
      {
        value: 182,
        text: 'Uganda',
      },
      {
        text: 'Ukraine',
        value: 183,
      },
      {
        value: 184,
        text: 'United Arab Emirates',
      },
      {
        text: 'United Kingdom of Great Britain and Northern Ireland',
        value: 185,
      },
      {
        text: 'United Republic of Tanzania',
        value: 186,
      },
      {
        value: 187,
        text: 'United States of America',
      },
      {
        value: 188,
        text: 'Uruguay',
      },
      {
        text: 'Uzbekistan',
        value: 189,
      },
      {
        value: 190,
        text: 'Vanuatu',
      },
      {
        value: 191,
        text: 'Venezuela, Bolivarian Republic of...',
      },
      {
        value: 192,
        text: 'Viet Nam',
      },
      {
        text: 'Yemen',
        value: 193,
      },
      {
        text: 'Zambia',
        value: 580,
      },
      {
        text: 'Zimbabwe',
        value: 1357,
      },
    ],
    id: 'country',
  },
  {
    type: 'group',
    fields: [
      {
        type: 'button',
        id: 'Location',
        text:
          'Which of the following categories best describe the location where ${Name} lives?',
        options: [
          {
            text: 'Urban',
            value: 1,
          },
          {
            value: 2,
            text: 'Suburban',
          },
          {
            value: 3,
            text: 'Rural',
          },
        ],
      },
      {
        type: 'number',
        text: "What is ${Name}'s age?",
        id: 'Age',
      },
    ],
  },
  {
    options: [
      {
        value: 1,
        text: 'Male',
      },
      {
        text: 'Female',
        value: 2,
      },
      {
        value: 3,
        text: 'Trans male',
      },
      {
        text: 'Trans female',
        value: 4,
      },
      {
        value: 5,
        text: 'Preferred not to state',
      },
      {
        value: 6,
        text: 'Other - Preferred identity',
      },
    ],
    id: 'Gender',
    text: "What is ${Name}'s gender?",
    type: 'button',
  },
  {
    options: [
      {
        text: 'High School or less',
        value: 1,
      },
      {
        text: 'Some college/Associate Degree',
        value: 2,
      },
      {
        value: 3,
        text: "Bachelor's Degree",
      },
      {
        value: 4,
        text: "Master's Degree/ Some graduate school",
      },
      {
        text: 'Doctoral and/or Professional Degree (e.g. Ph.D., JD, MD)',
        value: 5,
      },
    ],
    type: 'button',
    text: "What is ${Name}'s educational level?",
    id: 'Education',
  },
  {
    text: "What is ${Name}'s ethnicity?",
    options: [
      {
        text: 'Caucasian/White',
        value: 1,
      },
      {
        value: 2,
        text: 'Native American',
      },
      {
        text: 'Hispanic',
        value: 3,
      },
      {
        text: 'African American',
        value: 4,
      },
      {
        text: 'Asian',
        value: 5,
      },
      {
        text: 'Pacific Islander',
        value: 6,
      },
      {
        value: 7,
        text: 'Other',
      },
    ],
    id: 'Ethnicity',
    type: 'button',
  },
  {
    options: [
      {
        text: 'Married or living with a partner without children',
        value: 1,
      },
      {
        value: 2,
        text: 'Married or living with a partner with children',
      },
      {
        value: 3,
        text: 'Widowed',
      },
      {
        text: 'Divorced',
        value: 4,
      },
      {
        value: 5,
        text: 'Separated',
      },
      {
        value: 6,
        text: 'Never married',
      },
    ],
    type: 'button',
    id: 'Marital_status',
    text: "What is ${Name}'s marital status?",
  },
  {
    type: 'button',
    text:
      'Which of the following categories best describes ${Name} household annual income?',
    id: 'Income',
    options: [
      {
        value: 1,
        text: 'Less than $10,000',
      },
      {
        text: '$10,000 - $24,999',
        value: 2,
      },
      {
        text: '$25,000 - $34,999',
        value: 3,
      },
      {
        value: 4,
        text: '$35,000 - $49,999',
      },
      {
        text: '$50,000 - $74,999',
        value: 5,
      },
      {
        value: 6,
        text: '$75,000 - $99,999',
      },
      {
        text: '$100,000 - $149,999',
        value: 7,
      },
      {
        text: '$150,000 - $199,999',
        value: 8,
      },
      {
        value: 9,
        text: '$200,000 +',
      },
    ],
  },
  {
    type: 'button',
    id: 'Homeownership',
    options: [
      {
        text: 'Rent on my own/with related individuals',
        value: 1,
      },
      {
        text: 'Rent with unrelated individuals',
        value: 2,
      },
      {
        text: "Live with someone else and don't pay for housing",
        value: 3,
      },
      {
        text: "Own a home and don't have any debt on it",
        value: 4,
      },
      {
        text: 'Have a mortgage on my home',
        value: 5,
      },
      {
        text: 'Other',
        value: 6,
      },
    ],
    text: "What is ${Name}'s homeownership status?",
  },

  {
    type: 'survey-summary',
    groups: [
      {
        name: '',
        fields: [
          {
            text: 'Buyer Persona Name',
            type: 'text',
            id: 'Name',
          },
        ],
      },
      {
        name: '',
        fields: [
          {
            id: 'Value',
            type: 'text',
            text: 'Main value',
          },
          {
            id: 'Interest',
            text: 'Main general interest',
            type: 'text',
          },
          {
            text: 'Main goal in life',
            type: 'text',
            id: 'Goal',
          },
          {
            text: 'Main life problem',
            type: 'text',
            id: 'Problem',
          },
          {
            text: 'Favorite brand',
            id: 'Brand',
            type: 'text',
          },
          {
            type: 'text',
            id: 'Influencers',
            text: 'Favorite influencer/celebrity',
          },
        ],
      },
      {
        name: 'Local and Global Perspective',
        fields: [
          {
            text: '',
            type: 'radio-group-multiple',
            left: [
              'Citizen of their own country',
              'Friends that are of the same nationality',
              'Buys products made in their own country',
            ],
            right: [
              'Citizen of the World',
              'International friends',
              'Buys imported products',
            ],
            radiosLength: 5,
            id: [
              'Buyer_LocalGlobal_1',
              'Buyer_LocalGlobal_2',
              'Buyer_LocalGlobal_3',
            ],
          },
        ],
      },
      {
        name: 'Internal and External Focus Importance',
        fields: [
          {
            scale: [
              'None at all',
              'A little',
              'A moderate amount',
              'A lot',
              'A great deal',
            ],
            type: 'radio-group',
            options: [
              'Personal Income',
              'Wealth',
              'Community',
              'Social good',
              'Neighbors',
              'Environment',
            ],
            radiosLength: 6,
            text: '',
            id: [
              'Buyer_InterExter_1',
              'Buyer_InterExter_2',
              'Buyer_InterExter_3',
              'Buyer_InterExter_4',
              'Buyer_InterExter_5',
              'Buyer_InterExter_6',
            ],
          },
        ],
      },
      {
        name: 'Overall Ideology',
        fields: [
          {
            text: '',
            type: 'radio-group-multiple',
            right: ['Extremely Conservative'],
            radiosLength: 5,
            left: ['Extremely Liberal'],
            id: ['Buyer_Ideology_1'],
          },
        ],
      },
      {
        name: 'Ideology Dimensions',
        fields: [
          {
            scale: ['Left', '', '', '', 'Right'],
            type: 'radio-group',
            id: ['Buyer_Ideology_2', 'Buyer_Ideology_3', 'Buyer_Ideology_4'],
            radiosLength: 3,
            options: ['Economic', 'Social', 'Safety'],
            text: '',
          },
        ],
      },
      {
        name: 'Product Benefits',
        fields: [
          {
            type: 'radio-group-multiple',
            radiosLength: 5,
            left: [
              'Price',
              'Design',
              'Polite Service',
              'Professionalism',
              'Durability',
              'Uniqueness',
              'Privacy',
              'Convenience',
              'Core Product',
              'Experience',
            ],
            text: '',
            right: [
              'Quality',
              'Functionality',
              'Fast Service',
              'Easy Going Attitude',
              'Affordability',
              'Compatibility',
              'Personalization',
              'Exclusivity',
              'Customer Support',
              'Ownership',
            ],
            id: [
              'Buyer_Product_1',
              'Buyer_Product_2',
              'Buyer_Product_3',
              'Buyer_Product_4',
              'Buyer_Product_5',
              'Buyer_Product_6',
              'Buyer_Product_7',
              'Buyer_Product_8',
              'Buyer_Product_9',
              'Buyer_Product_10',
            ],
          },
        ],
      },
      {
        name: 'Social Causes Importance',
        fields: [
          {
            text: '',
            type: 'radio-group',
            id: [
              'Buyer_Issues_1',
              'Buyer_Issues_2',
              'Buyer_Issues_3',
              'Buyer_Issues_4',
              'Buyer_Issues_5',
              'Buyer_Issues_6',
              'Buyer_Issues_7',
              'Buyer_Issues_8',
              'Buyer_Issues_9',
              'Buyer_Issues_10',
              'Buyer_Issues_11',
            ],
            scale: [
              'None at all',
              'A little',
              'A moderate amount',
              'A lot',
              'A great deal',
            ],
            options: [
              'State of the economy',
              'Free Healthcare',
              'Violent crime',
              'World peace',
              'Gun laws',
              'Race and ethnic inequality',
              'Gender inequality',
              'Immigration',
              'Economic inequality',
              'Climate change',
              'Corporate corruption',
            ],
            radiosLength: 11,
          },
        ],
      },
      {
        name: 'Social Innovativeness',
        fields: [
          {
            text: '',
            id: [
              'Buyer_SocialInovat_1',
              'Buyer_SocialInovat_2',
              'Buyer_SocialInovat_3',
            ],
            options: [
              'Adapt to new social norms',
              'Quick to react to social movements',
              'Socially responsible',
            ],
            type: 'radio-group',
            radiosLength: 3,
            scale: [
              'None at all',
              'A little',
              'A moderate amount',
              'A lot',
              'A great deal',
            ],
          },
        ],
      },
      {
        name: 'Demographics',
        fields: [
          {
            type: 'dropdown',
            dependency: [
              {
                id: 'City',
                type: 'text',
                text: 'City',
                dependencyValue: 187,
              },
              {
                text: 'State/Region',
                id: 'State',
                options: [
                  {
                    text: 'Alabama',
                    value: 1,
                  },
                  {
                    value: 2,
                    text: 'Alaska',
                  },
                  {
                    value: 3,
                    text: 'Arizona',
                  },
                  {
                    value: 4,
                    text: 'Arkansas',
                  },
                  {
                    value: 5,
                    text: 'California',
                  },
                  {
                    text: 'Colorado',
                    value: 6,
                  },
                  {
                    value: 7,
                    text: 'Connecticut',
                  },
                  {
                    value: 8,
                    text: 'Delaware',
                  },
                  {
                    text: 'District of Columbia',
                    value: 9,
                  },
                  {
                    value: 10,
                    text: 'Florida',
                  },
                  {
                    value: 11,
                    text: 'Georgia',
                  },
                  {
                    text: 'Hawaii',
                    value: 12,
                  },
                  {
                    text: 'Idaho',
                    value: 13,
                  },
                  {
                    text: 'Illinois',
                    value: 14,
                  },
                  {
                    value: 15,
                    text: 'Indiana',
                  },
                  {
                    value: 16,
                    text: 'Iowa',
                  },
                  {
                    text: 'Kansas',
                    value: 17,
                  },
                  {
                    text: 'Kentucky',
                    value: 18,
                  },
                  {
                    value: 19,
                    text: 'Louisiana',
                  },
                  {
                    value: 20,
                    text: 'Maine',
                  },
                  {
                    value: 21,
                    text: 'Maryland',
                  },
                  {
                    value: 22,
                    text: 'Massachusetts',
                  },
                  {
                    text: 'Michigan',
                    value: 23,
                  },
                  {
                    value: 24,
                    text: 'Minnesota',
                  },
                  {
                    text: 'Mississippi',
                    value: 25,
                  },
                  {
                    text: 'Missouri',
                    value: 26,
                  },
                  {
                    value: 27,
                    text: 'Montana',
                  },
                  {
                    text: 'Nebraska',
                    value: 28,
                  },
                  {
                    text: 'Nevada',
                    value: 29,
                  },
                  {
                    text: 'New Hampshire',
                    value: 30,
                  },
                  {
                    text: 'New Jersey',
                    value: 31,
                  },
                  {
                    value: 32,
                    text: 'New Mexico',
                  },
                  {
                    value: 33,
                    text: 'New York',
                  },
                  {
                    text: 'North Carolina',
                    value: 34,
                  },
                  {
                    value: 35,
                    text: 'North Dakota',
                  },
                  {
                    value: 36,
                    text: 'Ohio',
                  },
                  {
                    value: 37,
                    text: 'Oklahoma',
                  },
                  {
                    text: 'Oregon',
                    value: 38,
                  },
                  {
                    value: 39,
                    text: 'Pennsylvania',
                  },
                  {
                    value: 40,
                    text: 'Puerto Rico',
                  },
                  {
                    text: 'Rhode Island',
                    value: 41,
                  },
                  {
                    value: 42,
                    text: 'South Carolina',
                  },
                  {
                    value: 43,
                    text: 'South Dakota',
                  },
                  {
                    text: 'Tennessee',
                    value: 44,
                  },
                  {
                    text: 'Texas',
                    value: 45,
                  },
                  {
                    text: 'Utah',
                    value: 46,
                  },
                  {
                    text: 'Vermont',
                    value: 47,
                  },
                  {
                    text: 'Virginia',
                    value: 48,
                  },
                  {
                    text: 'Washington',
                    value: 49,
                  },
                  {
                    value: 50,
                    text: 'West Virginia',
                  },
                  {
                    value: 51,
                    text: 'Wisconsin',
                  },
                  {
                    value: 52,
                    text: 'Wyoming',
                  },
                  {
                    text: 'I do not reside in the United States',
                    value: 53,
                  },
                ],
                type: 'dropdown',
                dependencyValue: 187,
              },
              {
                type: 'number',
                dependencyValue: 187,
                text: 'ZIP Code',
                id: 'Zip',
              },
            ],
            text: 'Country',
            options: [
              {
                text: 'Afghanistan',
                value: 1,
              },
              {
                text: 'Albania',
                value: 2,
              },
              {
                value: 3,
                text: 'Algeria',
              },
              {
                value: 4,
                text: 'Andorra',
              },
              {
                value: 5,
                text: 'Angola',
              },
              {
                text: 'Antigua and Barbuda',
                value: 6,
              },
              {
                value: 7,
                text: 'Argentina',
              },
              {
                text: 'Armenia',
                value: 8,
              },
              {
                value: 9,
                text: 'Australia',
              },
              {
                value: 10,
                text: 'Austria',
              },
              {
                text: 'Azerbaijan',
                value: 11,
              },
              {
                text: 'Bahamas',
                value: 12,
              },
              {
                text: 'Bahrain',
                value: 13,
              },
              {
                value: 14,
                text: 'Bangladesh',
              },
              {
                value: 15,
                text: 'Barbados',
              },
              {
                text: 'Belarus',
                value: 16,
              },
              {
                value: 17,
                text: 'Belgium',
              },
              {
                value: 18,
                text: 'Belize',
              },
              {
                text: 'Benin',
                value: 19,
              },
              {
                text: 'Bhutan',
                value: 20,
              },
              {
                text: 'Bolivia',
                value: 21,
              },
              {
                text: 'Bosnia and Herzegovina',
                value: 22,
              },
              {
                text: 'Botswana',
                value: 23,
              },
              {
                text: 'Brazil',
                value: 24,
              },
              {
                value: 25,
                text: 'Brunei Darussalam',
              },
              {
                value: 26,
                text: 'Bulgaria',
              },
              {
                value: 27,
                text: 'Burkina Faso',
              },
              {
                text: 'Burundi',
                value: 28,
              },
              {
                value: 29,
                text: 'Cambodia',
              },
              {
                value: 30,
                text: 'Cameroon',
              },
              {
                text: 'Canada',
                value: 31,
              },
              {
                text: 'Cape Verde',
                value: 32,
              },
              {
                value: 33,
                text: 'Central African Republic',
              },
              {
                text: 'Chad',
                value: 34,
              },
              {
                text: 'Chile',
                value: 35,
              },
              {
                text: 'China',
                value: 36,
              },
              {
                text: 'Colombia',
                value: 37,
              },
              {
                value: 38,
                text: 'Comoros',
              },
              {
                value: 39,
                text: 'Congo, Republic of the...',
              },
              {
                text: 'Costa Rica',
                value: 40,
              },
              {
                value: 41,
                text: "Côte d'Ivoire",
              },
              {
                text: 'Croatia',
                value: 42,
              },
              {
                text: 'Cuba',
                value: 43,
              },
              {
                value: 44,
                text: 'Cyprus',
              },
              {
                text: 'Czech Republic',
                value: 45,
              },
              {
                text: 'Democratic Republic of the Congo',
                value: 47,
              },
              {
                text: 'Denmark',
                value: 48,
              },
              {
                value: 49,
                text: 'Djibouti',
              },
              {
                text: 'Dominica',
                value: 50,
              },
              {
                value: 51,
                text: 'Dominican Republic',
              },
              {
                text: 'Ecuador',
                value: 52,
              },
              {
                text: 'Egypt',
                value: 53,
              },
              {
                text: 'El Salvador',
                value: 54,
              },
              {
                value: 55,
                text: 'Equatorial Guinea',
              },
              {
                value: 56,
                text: 'Eritrea',
              },
              {
                text: 'Estonia',
                value: 57,
              },
              {
                text: 'Ethiopia',
                value: 58,
              },
              {
                text: 'Fiji',
                value: 59,
              },
              {
                value: 60,
                text: 'Finland',
              },
              {
                text: 'France',
                value: 61,
              },
              {
                value: 62,
                text: 'Gabon',
              },
              {
                value: 63,
                text: 'Gambia',
              },
              {
                text: 'Georgia',
                value: 64,
              },
              {
                value: 65,
                text: 'Germany',
              },
              {
                text: 'Ghana',
                value: 66,
              },
              {
                text: 'Greece',
                value: 67,
              },
              {
                value: 68,
                text: 'Grenada',
              },
              {
                value: 69,
                text: 'Guatemala',
              },
              {
                text: 'Guinea',
                value: 70,
              },
              {
                value: 71,
                text: 'Guinea-Bissau',
              },
              {
                value: 72,
                text: 'Guyana',
              },
              {
                text: 'Haiti',
                value: 73,
              },
              {
                text: 'Honduras',
                value: 74,
              },
              {
                text: 'Hong Kong (S.A.R.)',
                value: 75,
              },
              {
                value: 76,
                text: 'Hungary',
              },
              {
                value: 77,
                text: 'Iceland',
              },
              {
                text: 'India',
                value: 78,
              },
              {
                value: 79,
                text: 'Indonesia',
              },
              {
                text: 'Iran',
                value: 80,
              },
              {
                value: 81,
                text: 'Iraq',
              },
              {
                value: 82,
                text: 'Ireland',
              },
              {
                text: 'Israel',
                value: 83,
              },
              {
                value: 84,
                text: 'Italy',
              },
              {
                value: 85,
                text: 'Jamaica',
              },
              {
                text: 'Japan',
                value: 86,
              },
              {
                text: 'Jordan',
                value: 87,
              },
              {
                value: 88,
                text: 'Kazakhstan',
              },
              {
                text: 'Kenya',
                value: 89,
              },
              {
                value: 90,
                text: 'Kiribati',
              },
              {
                text: 'Kuwait',
                value: 91,
              },
              {
                value: 92,
                text: 'Kyrgyzstan',
              },
              {
                value: 93,
                text: "Lao People's Democratic Republic",
              },
              {
                text: 'Latvia',
                value: 94,
              },
              {
                value: 95,
                text: 'Lebanon',
              },
              {
                text: 'Lesotho',
                value: 96,
              },
              {
                text: 'Liberia',
                value: 97,
              },
              {
                value: 98,
                text: 'Libyan Arab Jamahiriya',
              },
              {
                value: 99,
                text: 'Liechtenstein',
              },
              {
                value: 100,
                text: 'Lithuania',
              },
              {
                value: 101,
                text: 'Luxembourg',
              },
              {
                text: 'Madagascar',
                value: 102,
              },
              {
                value: 103,
                text: 'Malawi',
              },
              {
                text: 'Malaysia',
                value: 104,
              },
              {
                text: 'Maldives',
                value: 105,
              },
              {
                text: 'Mali',
                value: 106,
              },
              {
                text: 'Malta',
                value: 107,
              },
              {
                text: 'Marshall Islands',
                value: 108,
              },
              {
                text: 'Mauritania',
                value: 109,
              },
              {
                text: 'Mauritius',
                value: 110,
              },
              {
                text: 'Mexico',
                value: 111,
              },
              {
                text: 'Micronesia, Federated States of...',
                value: 112,
              },
              {
                text: 'Monaco',
                value: 113,
              },
              {
                value: 114,
                text: 'Mongolia',
              },
              {
                value: 115,
                text: 'Montenegro',
              },
              {
                text: 'Morocco',
                value: 116,
              },
              {
                text: 'Mozambique',
                value: 117,
              },
              {
                text: 'Myanmar',
                value: 118,
              },
              {
                value: 119,
                text: 'Namibia',
              },
              {
                text: 'Nauru',
                value: 120,
              },
              {
                text: 'Nepal',
                value: 121,
              },
              {
                value: 122,
                text: 'Netherlands',
              },
              {
                text: 'New Zealand',
                value: 123,
              },
              {
                text: 'Nicaragua',
                value: 124,
              },
              {
                value: 125,
                text: 'Niger',
              },
              {
                value: 126,
                text: 'Nigeria',
              },
              {
                value: 127,
                text: 'North Korea',
              },
              {
                text: 'Norway',
                value: 128,
              },
              {
                value: 129,
                text: 'Oman',
              },
              {
                text: 'Pakistan',
                value: 130,
              },
              {
                value: 131,
                text: 'Palau',
              },
              {
                text: 'Panama',
                value: 132,
              },
              {
                text: 'Papua New Guinea',
                value: 133,
              },
              {
                value: 134,
                text: 'Paraguay',
              },
              {
                value: 135,
                text: 'Peru',
              },
              {
                value: 136,
                text: 'Philippines',
              },
              {
                value: 137,
                text: 'Poland',
              },
              {
                text: 'Portugal',
                value: 138,
              },
              {
                text: 'Qatar',
                value: 139,
              },
              {
                text: 'Republic of Moldova',
                value: 141,
              },
              {
                text: 'Romania',
                value: 142,
              },
              {
                text: 'Russian Federation',
                value: 143,
              },
              {
                text: 'Rwanda',
                value: 144,
              },
              {
                value: 145,
                text: 'Saint Kitts and Nevis',
              },
              {
                text: 'Saint Lucia',
                value: 146,
              },
              {
                text: 'Saint Vincent and the Grenadines',
                value: 147,
              },
              {
                value: 148,
                text: 'Samoa',
              },
              {
                text: 'San Marino',
                value: 149,
              },
              {
                text: 'Sao Tome and Principe',
                value: 150,
              },
              {
                value: 151,
                text: 'Saudi Arabia',
              },
              {
                text: 'Senegal',
                value: 152,
              },
              {
                text: 'Serbia',
                value: 153,
              },
              {
                text: 'Seychelles',
                value: 154,
              },
              {
                text: 'Sierra Leone',
                value: 155,
              },
              {
                text: 'Singapore',
                value: 156,
              },
              {
                value: 157,
                text: 'Slovakia',
              },
              {
                value: 158,
                text: 'Slovenia',
              },
              {
                text: 'Solomon Islands',
                value: 159,
              },
              {
                text: 'Somalia',
                value: 160,
              },
              {
                value: 161,
                text: 'South Africa',
              },
              {
                text: 'South Korea',
                value: 162,
              },
              {
                value: 163,
                text: 'Spain',
              },
              {
                text: 'Sri Lanka',
                value: 164,
              },
              {
                text: 'Sudan',
                value: 165,
              },
              {
                text: 'Suriname',
                value: 166,
              },
              {
                text: 'Swaziland',
                value: 167,
              },
              {
                value: 168,
                text: 'Sweden',
              },
              {
                value: 169,
                text: 'Switzerland',
              },
              {
                text: 'Syrian Arab Republic',
                value: 170,
              },
              {
                text: 'Tajikistan',
                value: 171,
              },
              {
                value: 172,
                text: 'Thailand',
              },
              {
                value: 173,
                text: 'The former Yugoslav Republic of Macedonia',
              },
              {
                value: 174,
                text: 'Timor-Leste',
              },
              {
                text: 'Togo',
                value: 175,
              },
              {
                text: 'Tonga',
                value: 176,
              },
              {
                value: 177,
                text: 'Trinidad and Tobago',
              },
              {
                value: 178,
                text: 'Tunisia',
              },
              {
                text: 'Turkey',
                value: 179,
              },
              {
                value: 180,
                text: 'Turkmenistan',
              },
              {
                text: 'Tuvalu',
                value: 181,
              },
              {
                value: 182,
                text: 'Uganda',
              },
              {
                text: 'Ukraine',
                value: 183,
              },
              {
                value: 184,
                text: 'United Arab Emirates',
              },
              {
                text: 'United Kingdom of Great Britain and Northern Ireland',
                value: 185,
              },
              {
                text: 'United Republic of Tanzania',
                value: 186,
              },
              {
                value: 187,
                text: 'United States of America',
              },
              {
                value: 188,
                text: 'Uruguay',
              },
              {
                text: 'Uzbekistan',
                value: 189,
              },
              {
                value: 190,
                text: 'Vanuatu',
              },
              {
                value: 191,
                text: 'Venezuela, Bolivarian Republic of...',
              },
              {
                value: 192,
                text: 'Viet Nam',
              },
              {
                text: 'Yemen',
                value: 193,
              },
              {
                text: 'Zambia',
                value: 580,
              },
              {
                text: 'Zimbabwe',
                value: 1357,
              },
            ],
            id: 'country',
          },
          {
            type: 'dropdown',
            id: 'Location',
            text: 'Location',
            options: [
              {
                text: 'Urban',
                value: 1,
              },
              {
                value: 2,
                text: 'Suburban',
              },
              {
                value: 3,
                text: 'Rural',
              },
            ],
          },
          {
            type: 'number',
            text: 'Age',
            id: 'Age',
          },
          {
            options: [
              {
                value: 1,
                text: 'Male',
              },
              {
                text: 'Female',
                value: 2,
              },
              {
                value: 3,
                text: 'Trans male',
              },
              {
                text: 'Trans female',
                value: 4,
              },
              {
                value: 5,
                text: 'Preferred not to state',
              },
              {
                value: 6,
                text: 'Other - Preferred identity',
              },
            ],
            id: 'Gender',
            text: 'Gender',
            type: 'dropdown',
          },
          {
            options: [
              {
                text: 'High School or less',
                value: 1,
              },
              {
                text: 'Some college/Associate Degree',
                value: 2,
              },
              {
                value: 3,
                text: "Bachelor's Degree",
              },
              {
                value: 4,
                text: "Master's Degree/ Some graduate school",
              },
              {
                text:
                  'Doctoral and/or Professional Degree (e.g. Ph.D., JD, MD)',
                value: 5,
              },
            ],
            type: 'dropdown',
            text: 'Education',
            id: 'Education',
          },
          {
            text: 'Ethnicity?',
            options: [
              {
                text: 'Caucasian/White',
                value: 1,
              },
              {
                value: 2,
                text: 'Native American',
              },
              {
                text: 'Hispanic',
                value: 3,
              },
              {
                text: 'African American',
                value: 4,
              },
              {
                text: 'Asian',
                value: 5,
              },
              {
                text: 'Pacific Islander',
                value: 6,
              },
              {
                value: 7,
                text: 'Other',
              },
            ],
            id: 'Ethnicity',
            type: 'dropdown',
          },
          {
            options: [
              {
                text: 'Married or living with a partner without children',
                value: 1,
              },
              {
                value: 2,
                text: 'Married or living with a partner with children',
              },
              {
                value: 3,
                text: 'Widowed',
              },
              {
                text: 'Divorced',
                value: 4,
              },
              {
                value: 5,
                text: 'Separated',
              },
              {
                value: 6,
                text: 'Never married',
              },
            ],
            type: 'dropdown',
            id: 'Marital_status',
            text: 'Marital Status',
          },
          {
            type: 'dropdown',
            text: 'Income',
            id: 'Income',
            options: [
              {
                value: 1,
                text: 'Less than $10,000',
              },
              {
                text: '$10,000 - $24,999',
                value: 2,
              },
              {
                text: '$25,000 - $34,999',
                value: 3,
              },
              {
                value: 4,
                text: '$35,000 - $49,999',
              },
              {
                text: '$50,000 - $74,999',
                value: 5,
              },
              {
                value: 6,
                text: '$75,000 - $99,999',
              },
              {
                text: '$100,000 - $149,999',
                value: 7,
              },
              {
                text: '$150,000 - $199,999',
                value: 8,
              },
              {
                value: 9,
                text: '$200,000 +',
              },
            ],
          },
          {
            type: 'dropdown',
            id: 'Homeownership',
            options: [
              {
                text: 'Rent on my own/with related individuals',
                value: 1,
              },
              {
                text: 'Rent with unrelated individuals',
                value: 2,
              },
              {
                text: "Live with someone else and don't pay for housing",
                value: 3,
              },
              {
                text: "Own a home and don't have any debt on it",
                value: 4,
              },
              {
                text: 'Have a mortgage on my home',
                value: 5,
              },
              {
                text: 'Other',
                value: 6,
              },
            ],
            text: 'Home ownership',
          },
        ],
      },
    ],
  },
];
