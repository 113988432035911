import React from 'react';
import { Box, Typography } from '@material-ui/core';

const GenerateHeading = ({ isPersona, personaName, type }) => {
  const color = isPersona
    ? type == 'brand'
      ? '#19989B'
      : '#AB8628'
    : '#CE5378';
  const text = isPersona ? personaName : 'SELECTED SAMPLE';

  return (
    <Box display='flex' alignItems='flex-end' justifyContent='flex-start'>
      <Box display='flex' flexDirection='column' marginRight='20px'>
        <Typography
          component='div'
          variant='caption'
          style={{
            margin: '0',
            fontSize: '10px',
            color: '#B3B3B3',
          }}
        >
          selected sample dataset
        </Typography>
        <Typography
          component='div'
          variant='h2'
          style={{
            margin: '0',
            fontSize: '20px',
            fontWeight: '800',
          }}
        >
          {text}
        </Typography>
      </Box>
      <Box
        style={{
          backgroundColor: color,
          width: '15px',
          height: '15px',
          borderRadius: '50%',
          boxShadow: '0px 4px 3px lightgray',
          marginBottom: '5px',
        }}
      ></Box>
    </Box>
  );
};

export default GenerateHeading;
