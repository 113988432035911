import {
  FETCH_DATA_START,
  FETCH_DATA_SUCCESS,
  FETCH_DATA_FAIL,
} from "../actions/actionTypes.js";

const INIT_STATE = {
  brandPersonaSurveyQuestions: null,
  buyerPersonaSurveyQuestions: null,
  socialPostSurveyQuestions: null,
  brandPersonas: null,
  buyerPersonas: null,
  socialPosts: null,
  userData: null,
  batchBrands: null,
  loading: true,
  error: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_DATA_START:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case FETCH_DATA_SUCCESS:
      return {
        loading: false,
        error: false,
        brandPersonaSurveyQuestions: action.brandPersonaSurveyQuestions,
        buyerPersonaSurveyQuestions: action.buyerPersonaSurveyQuestions,
        socialPostSurveyQuestions: action.socialPostSurveyQuestions,
        brandPersonas: action.brandPersonas,
        buyerPersonas: action.buyerPersonas,
        socialPosts: action.socialPosts,
        userData: action.userData,
        batchBrands: action.batchBrands,
      };
    case FETCH_DATA_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
      };
    default:
      return state;
  }
};
