//Firebase
export const FIREBASE_INIT_APP = 'FIREBASE_INIT_APP';

//Auth
export const AUTH_INITIAL_APP_LOAD = 'AUTH_INITIAL_APP_LOAD';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_SIGN_OUT = 'AUTH_SIGN_OUT';
export const AUTH_SIGN_OUT_FAIL = 'AUTH_SIGN_OUT_FAIL';

//Data
export const FETCH_DATA_START = 'FETCH_DATA_START';
export const FETCH_DATA_SUCCESS = 'FETCH_DATA_SUCCESS';
export const FETCH_DATA_FAIL = 'FETCH_DATA_FAIL';
