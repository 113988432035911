import React from 'react';
import PageContainer from '../../../myComponents/pageContainer';

const Reports = () => {
  return (
    <PageContainer heading="Reports page">
      <h1>See your Reports!</h1>
    </PageContainer>
  );
};

export default Reports;
