import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { ToastContainer, toast } from 'react-toastify';

import { recentCardExtendedText, recentCardSmallText } from '../../../config/recentCards';
import { makeURL } from '../../../helperFunctions';

import PageLoader from '../../../myComponents/loaders/pageLoader';
import PageContainer from '../../../myComponents/pageContainer';
import useViewportWidth from '../../../myHooks/useViewportWidth';
import RecentCardExtended from '../../../myComponents/recentView/recentCardExpanded';
import RecentCardSmall from '../../../myComponents/recentView/recentCardSmall';
import UserDropdownHeader from '../../../myComponents/userDropdownHeader';

const useStyles = makeStyles(theme => ({
  mainContainer: {
    width: '95%',
    margin: '30px auto',
  },
}));

const BuyerViewAll = () => {
  const classes = useStyles();
  const history = useHistory();
  const appData = useSelector(({ data }) => data);

  const [pageIsLoading, setPageIsLoading] = useState(true);

  const width = useViewportWidth();

  useEffect(() => {
    if (!appData.loading) {
      if (appData.error) {
        showToastWarn();
      } else {
        setPageIsLoading(false);
      }
    }
  }, [appData]);

  const createNewHandler = () => {
    if (appData.buyerPersonaSurveyQuestions) {
      history.push(makeURL('/create-new/Buyer/new'));
    } else {
      showToastWarn();
    }
  };

  const showToastWarn = () => {
    toast.warn('Something went wrong, please try again later.', {
      position: 'bottom-right',
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const editSurveyHandler = surveyId => {
    history.push(makeURL(`/create-new/Buyer/${surveyId}`));
  };

  const viewSpecificSurveyHandler = surveyId => {
    history.push(makeURL(`/buyer-personas/${surveyId}`));
  };

  return (
    <>
      <UserDropdownHeader bgColor="#f4f4f7" />
      <PageContainer
        headingStyle={{
          fontWeight: 'bold',
          margin: '25px 0 0 25px',
        }}
        style={{
          position: 'relative',
        }}>
        {pageIsLoading ? (
          <PageLoader />
        ) : (
          <Box className={classes.mainContainer}>
            {width > 1200 ? (
              <RecentCardExtended
                heading="Buyer Personas"
                type="buyer"
                listSections={recentCardExtendedText.brand.listSections}
                surveyData={appData.buyerPersonas}
                onCreateNew={createNewHandler}
                onEdit={editSurveyHandler}
                viewSpecific={viewSpecificSurveyHandler}
                isLoading={appData.loading}
                csvExport
              />
            ) : (
              <RecentCardSmall
                heading="Buyer Personas"
                type="buyer"
                listSections={recentCardSmallText.brand.listSections}
                surveyData={appData.buyerPersonas}
                onCreateNew={createNewHandler}
                onEdit={editSurveyHandler}
                viewSpecific={viewSpecificSurveyHandler}
                isLoading={appData.loading}
              />
            )}
            <ToastContainer
              theme="colored"
              position="bottom-right"
              autoClose={4000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </Box>
        )}
      </PageContainer>
    </>
  );
};

export default BuyerViewAll;
