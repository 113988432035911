import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GenerateHeading from '../dataInfoTable/generateHeading';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core';

const DataInfoTableSm = ({ data, personaName, type }) => {
  return (
    <Table sx={{ width: '100%' }} aria-label='simple table'>
      <TableHead>
        <TableRow>
          <TableCell>
            <div></div>
          </TableCell>
          <TableCell>
            <GenerateHeading
              isPersona={true}
              personaName={personaName}
              type={type}
            />
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((row, index) => {
          return (
            <TableRow
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component='th' scope='row' style={{ padding: '9px' }}>
                {row.criteria}
              </TableCell>
              <TableCell align='left' style={{ padding: '9px' }}>
                {row.personaInfo}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
      <div style={{ paddingBlock: '20px' }}></div>
      <TableHead>
        <TableRow>
          <TableCell>
            <div></div>
          </TableCell>
          <TableCell>
            <GenerateHeading isPersona={false} />
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((row, index) => {
          return (
            <TableRow
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component='th' scope='row' style={{ padding: '9px' }}>
                {row.criteria}
              </TableCell>
              <TableCell align='left' style={{ padding: '9px' }}>
                {row.batchInfo}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default DataInfoTableSm;
