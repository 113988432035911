import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import { addNameToText } from "../helperFunctions";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    width: "fit-content",
    margin: "0 auto",
    textAlign: "center"
  },
  text: {
    color: theme.palette.text.primary,
    fontWeight: 300,
    fontSize: "18px",
    maxWidth: "800px",
    margin: "0 auto 15px auto",
    '@media (max-width: 600px)': {
      fontSize: '16px',
    },
  },
}));

const InfoFormElement = (props) => {
  const classes = useStyles();
  const mainContainerMargin = props.titleOnly ? "50px auto 50px auto" : "50px auto 100px auto";
  return (
    <Box className={classes.mainContainer} style={{ margin: mainContainerMargin }}>
      <Typography component="div" variant="h1" className={classes.text}>
        {addNameToText(props.text, props.name)}
      </Typography>
    </Box>
  );
};

export default InfoFormElement;
