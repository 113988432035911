import React from 'react';
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

import { useSelector } from 'react-redux';

import { getRecentCardSmallText } from '../../../config/recentCards';
import { makeURL, getTop5RecentlyEdited } from '../../../helperFunctions';
import PageContainer from '../../../myComponents/pageContainer';
import CreateSurveyCard from '../../../myComponents/createSurveyCard';
import Watermark from '../../../myComponents/watermark';
import RecentCardSmall from '../../../myComponents/recentView/recentCardSmall';
import UserDropdownHeader from '../../../myComponents/userDropdownHeader';
import useViewportWidth from '../../../myHooks/useViewportWidth';

const useStyles = makeStyles(theme => ({
  surveyTypeSection: {
    display: 'flex',
    marginBottom: '20px',
    width: '100%',
    '@media (max-width: 1000px)': {
      justifyContent: 'center',
      flexDirection: 'column',
      gap: '12px',
      marginBottom: '32px',
    },
  },
  pageWrapper: {
    padding: '15px 50px',

    '@media (max-width: 1000px)': {
      padding: '15px 30px',
    },
    '@media (max-width: 1000px)': {
      padding: '15px 10px',
    },
  },
}));

const Home = () => {
  const classes = useStyles();
  const history = useHistory();
  const appData = useSelector(({ data }) => data);
  const viewportWidth = useViewportWidth();

  const recentCardSmallText = getRecentCardSmallText(false);

  const viewAllHandler = route => {
    history.push(makeURL(`${route}/all`));
  };

  const editSurveyHandler = (route, surveyId) => {
    history.push(makeURL(`/create-new${route}/${surveyId}`));
  };

  const viewSpecificSurveyHandler = (route, surveyId) => {
    history.push(makeURL(`${route}/${surveyId}`));
  };

  const createNewHandler = (appDataField, type) => {
    if (!appData[appDataField]) {
      toast.warn('Something went wrong, please try again later.', {
        position: 'bottom-right',
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    history.push(makeURL(`/create-new/${type}/new`));
  };

  return (
    <>
      <UserDropdownHeader bgColor="#f4f4f7" />
      <div className={classes.pageWrapper}>
        <PageContainer>
          <Box display="flex" flexDirection="column" justifyContent="space-between" alignItems="flex-start">
            <Box className={classes.surveyTypeSection}>
              <CreateSurveyCard
                type="brandPersona"
                createNewHandler={() => createNewHandler('brandPersonaSurveyQuestions', 'Brand')}
              />
              <Box style={{ height: '100%', width: '20px' }}></Box>
              <RecentCardSmall
                heading="Recent Brand Personas"
                surveyData={getTop5RecentlyEdited(appData.brandPersonas)}
                listSections={recentCardSmallText.brand.listSections}
                onCreateNew={() => createNewHandler('brandPersonaSurveyQuestions', 'Brand')}
                onViewAll={() => viewAllHandler('/brand-personas')}
                onEdit={surveyId => editSurveyHandler('/Brand', surveyId)}
                viewSpecific={surveyId => viewSpecificSurveyHandler('/brand-personas', surveyId)}
                isLoading={appData.loading}
                showImpact={false}
                type="brand"
                isHomePage={true}
              />
            </Box>

            <Box className={classes.surveyTypeSection}>
              <CreateSurveyCard
                type={'buyerPersona'}
                createNewHandler={() => createNewHandler('buyerPersonaSurveyQuestions', 'Buyer')}
              />
              <Box style={{ height: '100%', width: '20px' }}></Box>
              <RecentCardSmall
                heading="Recent Buyer Personas"
                surveyData={getTop5RecentlyEdited(appData.buyerPersonas)}
                listSections={recentCardSmallText.buyer.listSections}
                onCreateNew={() => createNewHandler('buyerPersonaSurveyQuestions', 'Buyer')}
                onViewAll={() => viewAllHandler('/buyer-personas')}
                onEdit={surveyId => editSurveyHandler('/Buyer', surveyId)}
                viewSpecific={surveyId => viewSpecificSurveyHandler('/buyer-personas', surveyId)}
                isLoading={appData.loading}
                showImpact={false}
                type="buyer"
                isHomePage={true}
              />
            </Box>

            <Box className={classes.surveyTypeSection}>
              <CreateSurveyCard
                type={'surveyPost'}
                createNewHandler={() => createNewHandler('socialPostSurveyQuestions', 'Social')}
              />
              <Box style={{ height: '100%', width: '20px' }}></Box>
              <RecentCardSmall
                heading="Recent Social Media Posts"
                type="social"
                surveyData={getTop5RecentlyEdited(appData.socialPosts)}
                listSections={recentCardSmallText.social.listSections}
                onCreateNew={() => createNewHandler('socialPostSurveyQuestions', 'Social')}
                onViewAll={() => viewAllHandler('/social-media')}
                onEdit={surveyId => editSurveyHandler('/Social', surveyId)}
                viewSpecific={surveyId => viewSpecificSurveyHandler('/social-media', surveyId)}
                isLoading={appData.loading}
                showImpact={false}
                brandPersonas={appData?.brandPersonas ? appData?.brandPersonas : []}
                isHomePage={true}
              />
            </Box>
          </Box>
          {/* <button onClick={signOut}>Sign out</button> */}
          <ToastContainer
            theme="colored"
            position="bottom-right"
            autoClose={4000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <Watermark />
        </PageContainer>
      </div>
    </>
  );
};

export default Home;
