import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { makeStyles, createTheme, ThemeProvider } from '@material-ui/core/styles';
import { Box, Typography, MobileStepper, Button, CircularProgress } from '@material-ui/core';
import CubeLoader from '../../../myComponents/loaders/cubeLoader';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
// import { ThemeProvider } from "@material-ui/styles";
import { useSelector, useDispatch } from 'react-redux';
import { fetchData } from '../../../redux/actions/Data';
import { ToastContainer, toast } from 'react-toastify';

import Watermark from '../../../myComponents/watermark';
import { submitSurvey, updateSurvey, AIProductSocialCausesAnswerGenerator } from '../../../API/agent';
import SurveyInputs from '../../../myComponents/surveyInputs';
import PageContainer from '../../../myComponents/pageContainer';
import AlertDialog from '../../../myComponents/alertDialog';
import SurveyContent from '../../../myComponents/surveyContent';
import {
  normalize,
  reverse_coded_normalize,
  addFieldsAndValues,
  makeURL,
  getSocialPostIdsForConversion,
  socialDeleteFieldsWithFalseDependancy,
} from '../../../helperFunctions';
import { additionalBrandFieldsReference, additionalBuyerFieldsReference } from '../../../config/additionalSurveyFields';
import {
  socialPostSurveyQuestions,
  brandPersonaSurveyQuestions,
  buyerPersonaSurveyQuestions,
} from '../../../config/questions';
import PageLoader from '../../../myComponents/loaders/pageLoader';
import UserDropdownHeader from '../../../myComponents/userDropdownHeader';

const useStyles = makeStyles(theme => ({
  pageWrapper: {
    maxWidth: '100%',
    overflowX: 'hidden',
    padding: '15px 65px',

    '@media (max-width: 600px)': {
      padding: '15px 10px',
    },
  },
  surveyWrapper: {
    maxWidth: '100%',
  },
  stepperRoot: {
    width: '100%',
    backgroundColor: 'transparent',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  stepperProgress: {
    background: '#C0C0C0',
    backgroundColor: '#C0C0C0',
    height: '8px',
    borderRadius: '5px',
    width: '100%',
  },
  stepperText: {
    color: theme.palette.text.secondary,
    fontWeight: 300,
    '@media (max-width: 600px)': {
      fontSize: '14px !important',
    },
  },
  nextBackButtonsContainer: {
    width: '100%',
    marginTop: 'auto',
    flexWrap: 'wrap',
    '@media (max-width: 768px)': {
      gap: '20px',
    },
  },
  nextBackButtons: {
    padding: '8px 30px',
    color: 'white',
    fontWeight: 300,
    order: 1,
    backgroundColor: '#19989B',
    '&:hover': {
      backgroundColor: '#19878a',
    },
    '&:focus': {
      backgroundColor: '#19878a !important',
      color: 'white',
    },
    '&.Mui-disabled': {
      background: '#82C2C3',
      color: 'white',
    },
    '&.MuiButton-root:hover.Mui-disabled': {
      background: '#82C2C3 !important',
    },
    '-webkit-appearance': 'button !important',
    '@media (max-width: 870px)': {
      order: 2,
    },
    '@media (max-width: 600px)': {
      padding: '6px 16px',
      fontSize: '13px !important',
      lineHeight: '13px !important',
    },
  },
  aiLoader: {
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
    zIndex: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(255,255,255,0.7)',
  },
  progressBarWrapper: {
    minWidth: '350px',
    order: 2,
    '@media (max-width: 870px)': {
      width: '100%',
      order: 1,
    },
    '@media (max-width: 500px)': {
      minWidth: '100%',
    },
  },
}));

const muiTheme = createTheme({
  palette: {
    primary: {
      main: '#19989B',
    },
  },
});

const CreateNew = () => {
  const { type, id } = useParams();
  const classes = useStyles();
  const appData = useSelector(({ data }) => data);
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const dispatch = useDispatch();
  const surveyQuestionsKey =
    type === 'Brand'
      ? 'brandPersonaSurveyQuestions'
      : type === 'Buyer'
      ? 'buyerPersonaSurveyQuestions'
      : 'socialPostSurveyQuestions';

  useEffect(() => {
    if (!appData.loading) {
      createSurveyHandler(type);
      if (id !== 'new') {
        //Editing existing survey
        const whatSurvey = type === 'Brand' ? 'brandPersonas' : type === 'Buyer' ? 'buyerPersonas' : 'socialPosts';

        const surveyForEditing = appData[whatSurvey].find(survey => survey.id === id);
        if (!surveyForEditing || !appData[surveyQuestionsKey]) {
          toast.warn('Something went wrong, selected survey does not exist.', {
            position: 'bottom-right',
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          setSurveyInfo({ ...surveyForEditing });

          let length = 0;
          if (type === 'Brand') {
            length = brandPersonaSurveyQuestions.length;
          } else if (type === 'Buyer') {
            length = buyerPersonaSurveyQuestions.length;
          } else {
            length = socialPostSurveyQuestions.length;
          }

          // setActiveStep(appData[surveyQuestionsKey].length);
          setActiveStep(length - 1);
        }
        setIsLoading(false);
      } else {
        //New survey
        setIsLoading(false);
      }
    }
  }, [appData]);

  //Survey info
  const [surveyInfo, setSurveyInfo] = useState({});
  const [surveyQuestions, setSurveyQuestions] = useState([]);
  const [surveyType, setSurveyType] = useState('');
  const [surveySubmiting, setSurveySubmiting] = useState(false);
  const [exitAlertShown, setExitAlertShown] = useState(false);
  const [aiAnswersLoading, setAiAnswersLoading] = useState(false);
  const aiGenerateAnswersStep = 4;

  //Prevent progression
  const [allowProgression, setAllowProgression] = useState(true);

  // Type radio is currently only in use on social media survey creation,
  // so we aren't handling it here.
  const QuestionType = {
    GROUP: 'group',
    RADIO: 'radio',
    RADIO_GROUP: 'radio-group',
    RADIO_GROUP_MULTIPLE: 'radio-group-multiple',
    DROPDOWN: 'dropdown',
    BUTTON: 'button',
    TEXT: 'text',
  };

  //Stepper
  const [activeStep, setActiveStep] = useState(0);
  const maxStep = surveyQuestions.length;
  const lastStepReached = activeStep === surveyQuestions.length - 1;

  const getActiveStep = (forward, prevActiveStep) => {
    const nextStep = forward ? prevActiveStep + 1 : prevActiveStep - 1;
    const result = {
      questionEnabled: true,
      activeStepResult: nextStep,
    };

    //Check if dependancySelecton exists
    const dependancySelection =
      surveyQuestions[nextStep].type !== 'group'
        ? surveyQuestions[nextStep].dependancySelection
        : surveyQuestions[nextStep].fields[0].dependancySelection;
    if (dependancySelection) {
      //Figure out next step
      const questionIsEnabled = dependancySelection.value.find(
        value => Number(value) === Number(surveyInfo[dependancySelection.dependancy_question_id]),
      );
      if (!questionIsEnabled) {
        result.questionEnabled = false;
      }
    }

    return result;
  };

  useEffect(() => {
    if (surveyQuestions[activeStep]?.mandatory) {
      if (surveyType === 'Brand' || surveyType === 'Buyer') {
        let allowProgression = true;

        switch (surveyQuestions[activeStep].type) {
          case QuestionType.GROUP:
            surveyQuestions[activeStep].fields.forEach(field => {
              if (Array.isArray(field.id)) {
                field.id.forEach(question => {
                  if (typeof surveyInfo[question] === 'undefined') {
                    allowProgression = false;
                  }
                });
              } else {
                if (typeof surveyInfo[field.id] === 'undefined') {
                  allowProgression = false;
                }
              }
            });
            break;
          case QuestionType.RADIO_GROUP:
          case QuestionType.RADIO_GROUP_MULTIPLE:
            surveyQuestions[activeStep].id.forEach(question => {
              if (typeof surveyInfo[question] === 'undefined') {
                allowProgression = false;
              }
            });
            break;
          case QuestionType.DROPDOWN:
          case QuestionType.BUTTON:
          case QuestionType.TEXT:
            if (typeof surveyInfo[surveyQuestions[activeStep].id] === 'undefined') {
              allowProgression = false;
            }
            break;
          default:
            return null;
        }

        setAllowProgression(allowProgression);
      }
    } else {
      setAllowProgression(true);
    }
  }, [surveyInfo, activeStep]);

  const handleNext = prevActiveStep => {
    const { questionEnabled, activeStepResult } = getActiveStep(true, prevActiveStep);
    if (questionEnabled) {
      if (surveyType === 'Social' && activeStep === aiGenerateAnswersStep && surveyInfo['Post_should_ai_generate']) {
        //AI should generate answers
        setAiAnswersLoading(true);

        const onlyProductQuestions = surveyInfo.Post_Category == 1;
        AIProductSocialCausesAnswerGenerator(onlyProductQuestions, surveyInfo.Post_Text)
          .then(res => {
            setSurveyInfo(prevState => ({
              ...prevState,
              ...res,
            }));

            //Go to survey summary
            setActiveStep(socialPostSurveyQuestions.length - 1);

            setAiAnswersLoading(false);
          })
          .catch(err => {
            toast.warn('AI could not generate the answers', {
              position: 'bottom-right',
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setAiAnswersLoading(false);
          });
      } else {
        setActiveStep(activeStepResult);
      }
    } else {
      handleNext(activeStepResult);
    }
  };

  const handleBack = prevActiveStep => {
    const { questionEnabled, activeStepResult } = getActiveStep(false, prevActiveStep);
    if (questionEnabled) {
      setActiveStep(activeStepResult);
    } else {
      handleBack(activeStepResult);
    }
  };

  const resetSurveyState = () => {
    setSurveyInfo({});
    setActiveStep(0);
  };

  const createSurveyHandler = type => {
    if (!appData[surveyQuestionsKey]) {
      toast.warn('Something went wrong, please try again later.', {
        position: 'bottom-right',
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    resetSurveyState();

    //setSurveyQuestions(appData[surveyQuestionsKey]);
    if (type === 'Brand') {
      setSurveyQuestions(brandPersonaSurveyQuestions);
    } else if (type === 'Buyer') {
      setSurveyQuestions(buyerPersonaSurveyQuestions);
    } else {
      setSurveyQuestions(socialPostSurveyQuestions);
    }

    setSurveyType(type);
  };

  const handleSurveyAnswers = (answer, id) => {
    //Normalize/Reverse_Normalize survey fields
    let normalizedAnswer = answer;
    if (surveyType === 'Brand' || surveyType === 'Buyer') {
      const answerFieldType = `${surveyType}_`;
      if (id.indexOf(answerFieldType) !== -1) {
        if (id === `${answerFieldType}InterExter_1` || id === `${answerFieldType}InterExter_2`) {
          normalizedAnswer = reverse_coded_normalize(answer);
        } else {
          normalizedAnswer = normalize(answer);
        }
      }
    } else if (surveyType === 'Social') {
      const idsForCovnersion = getSocialPostIdsForConversion();
      if (idsForCovnersion.indexOf(id) !== -1) {
        normalizedAnswer = normalize(answer);
      }
    }

    setSurveyInfo(prevState => ({
      ...prevState,
      [id]: normalizedAnswer,
    }));
  };

  const handleSurveySubmit = () => {
    if (id !== 'new') {
      submitEditSurveyAnswers();
    } else {
      submitSurveyAnswers();
    }
  };

  const submitSurveyAnswers = () => {
    setSurveySubmiting(true);

    //add additional fields
    let surveyInfoWithNewFields =
      surveyType === 'Social'
        ? { ...surveyInfo }
        : addFieldsAndValues(
            surveyType === 'Brand' ? additionalBrandFieldsReference : additionalBuyerFieldsReference,
            surveyInfo,
          );

    if (surveyType === 'Social') {
      if (typeof surveyInfoWithNewFields.Post_Date === 'undefined') surveyInfoWithNewFields.Post_Date = new Date();
      surveyInfoWithNewFields = socialDeleteFieldsWithFalseDependancy(surveyInfoWithNewFields);
    }

    submitSurvey(surveyType, surveyInfoWithNewFields)
      .then(res => {
        if (res.err) {
          showToast('warn', 'Something went wrong, please try again later.');
        } else {
          showToast('success', 'You have successfully submitted the survey.');
          const { refId } = res;

          dispatch(fetchData()).then(res => {
            setSurveySubmiting(false);
            if (!res.err) {
              history.push(makeURL(`${exitRoute}/${refId}`));
            } else {
              history.push(makeURL(`${exitRoute}/all`));
            }
          });
        }
      })
      .catch(err => {
        const errorMessage = err?.response?.data?.errMessage;
        showToast('warn', errorMessage);
      });
  };

  const submitEditSurveyAnswers = () => {
    setSurveySubmiting(true);

    //add additional fields
    let surveyInfoWithNewFields =
      surveyType === 'Social'
        ? { ...surveyInfo }
        : addFieldsAndValues(
            surveyType === 'Brand' ? additionalBrandFieldsReference : additionalBuyerFieldsReference,
            surveyInfo,
          );

    if (surveyType === 'Social') {
      if (typeof surveyInfoWithNewFields.Post_Date === 'undefined') surveyInfoWithNewFields.Post_Date = new Date();
      surveyInfoWithNewFields = socialDeleteFieldsWithFalseDependancy(surveyInfoWithNewFields);
    }

    updateSurvey(surveyType, surveyInfoWithNewFields.id, surveyInfoWithNewFields).then(res => {
      if (res.err) {
        showToast('warn', 'Something went wrong, please try again later.');
      } else {
        showToast('success', 'You have successfully edited the survey.');
        dispatch(fetchData()).then(res => {
          setSurveySubmiting(false);
          if (!res.err) {
            history.push(makeURL(`${exitRoute}/${surveyInfoWithNewFields.id}`));
          } else {
            history.push(makeURL(`${exitRoute}/all`));
          }
        });
      }
    });
  };

  const showExitAlertHandler = () => {
    const somethingWasFilledIn = Object.keys(surveyInfo).length > 0;
    if (somethingWasFilledIn) {
      setExitAlertShown(true);
    } else {
      history.push(makeURL(`${exitRoute}/all`));
    }
  };

  const exitAlertActionButton = confirmed => {
    if (!confirmed) {
      setExitAlertShown(false);
    } else {
      history.push(makeURL(`${exitRoute}/all`));
    }
  };

  const showToast = (type, message) => {
    toast[type](message, {
      position: 'bottom-right',
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const exitRoute = type === 'Brand' ? '/brand-personas' : type === 'Buyer' ? '/buyer-personas' : '/social-media';

  if (isLoading)
    return (
      <>
        <PageLoader />
        <ToastContainer
          theme="colored"
          position="bottom-right"
          autoClose={4000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </>
    );

  const surveyContentText = {
    Brand: {
      heading: 'Brand Persona',
      subHeading: 'Survey',
    },
    Buyer: {
      heading: 'Buyer Persona',
      subHeading: 'Survey',
    },
    Social: {
      heading: 'Social Media',
      subHeading: 'Content Analysis',
    },
  };

  //This is used to prevent the user from comming to the AI prompt step without the needed fields
  const socialSurveyPreventNext =
    surveyType === 'Social' &&
    ((activeStep === 0 && (!surveyInfo.Post_Brand_Persona || !surveyInfo.Post_Social_Media)) ||
      (activeStep === 1 &&
        (!surveyInfo.Post_Title || !surveyInfo.Post_Keyword_1 || !surveyInfo.Post_Keyword_2 || !surveyInfo.Post_Keyword_3)));

  return (
    <>
      <UserDropdownHeader bgColor={'#f4f4f7'} />
      <PageContainer className={classes.pageWrapper}>
        <SurveyContent
          heading={surveyContentText[surveyType].heading}
          subHeading={surveyContentText[surveyType].subHeading}
          onClose={showExitAlertHandler}
          className={classes.surveyWrapper}>
          {aiAnswersLoading ? (
            <Box className={classes.aiLoader}>
              <CubeLoader />
            </Box>
          ) : null}
          <Box mb={15}>
            <SurveyInputs
              questions={surveyQuestions}
              activeQuestion={activeStep}
              surveyAnswersState={surveyInfo}
              name={surveyType === 'Social' ? surveyInfo['Post_Title'] : surveyInfo['Name']}
              handleSurveyAnswers={handleSurveyAnswers}
              isInGroup={false}
              isDependent={false}
            />
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            className={classes.nextBackButtonsContainer}>
            <Button
              size="large"
              onClick={() => handleBack(activeStep)}
              disabled={activeStep === 0 || surveySubmiting}
              className={classes.nextBackButtons}>
              <KeyboardArrowLeft />
              Back
            </Button>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              // minWidth='350px'
              className={classes.progressBarWrapper}>
              <ThemeProvider theme={muiTheme}>
                <MobileStepper
                  variant="progress"
                  steps={maxStep}
                  position="static"
                  activeStep={activeStep}
                  LinearProgressProps={{ color: 'primary' }}
                  classes={{
                    root: classes.stepperRoot,
                    progress: classes.stepperProgress,
                  }}
                />
              </ThemeProvider>
              <Typography component="div" variant="h4" className={classes.stepperText}>
                Survey {lastStepReached ? 100 : Math.round(((activeStep - 0) * 100) / (maxStep - 0))}% completed
              </Typography>
            </Box>
            <Button
              size="large"
              disabled={surveySubmiting || socialSurveyPreventNext || !allowProgression}
              onClick={lastStepReached ? handleSurveySubmit : () => handleNext(activeStep)}
              className={classes.nextBackButtons}
              style={{ order: 3 }}>
              {surveySubmiting ? (
                <Box display="flex" justifyContent="center" alignItems="center" width="65.78px" height="26.5px">
                  <CircularProgress size={23} style={{ color: 'white' }} />
                </Box>
              ) : lastStepReached ? (
                <Box>
                  <Typography style={{ margin: '0', padding: '0' }} component="div" variant="body1">
                    Submit
                  </Typography>
                </Box>
              ) : (
                'Next'
              )}
              {!surveySubmiting && !lastStepReached ? <KeyboardArrowRight /> : null}
            </Button>
          </Box>
        </SurveyContent>
        <AlertDialog
          heading="Are you sure you want to exit the survey?"
          body="The data that you filled in will not be saved."
          isShown={exitAlertShown}
          buttonAction={exitAlertActionButton}
          noButtonText="No"
          yesButtonText="Yes"
        />
        <ToastContainer
          theme="colored"
          position="bottom-right"
          autoClose={4000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Watermark />
      </PageContainer>
    </>
  );
};

export default CreateNew;
