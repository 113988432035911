import { makeURL } from '../helperFunctions';

export const surveyCardText = {
    brandPersona: {
        icon: makeURL("/images/brand-persona-research-image.png"),
        titlePrimary: "Brand Persona",
        bodyPrimary: "Develop your own unique brand personality by giving a set of human characteristics to your brand. Brand persona is humanized representation of your brand’s values, benefits, and image.",
        bodySecondary: "Why just make one, develop a custom brand persona for each market segment you target."
    },
    buyerPersona: {
        icon: makeURL("/images/buyer-persona-research-image.png"),
        titlePrimary: "Buyer Persona",
        bodyPrimary: "Develop your own unique brand personality by giving a set of human characteristics to your brand. Brand persona is humanized representation of your brand’s values, benefits, and image.",
        bodySecondary: "Why just make one, develop a custom brand persona for each market segment you target."
    },
    surveyPost: {
        icon: makeURL("/images/survey-post-research-image.png"),
        titlePrimary: "Social Media",
        titleSecondary: "Sentiment Analysis",
        bodyPrimary: "Social Media Sentiment Analysis allows you to test your social media content before it is posted.",
        bodySecondary: "Evaluate how different content would be perceived by different market segments and get recommendations if you should proceed with the post."
    }
};