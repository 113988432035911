import React from 'react';

import { makeStyles } from '@material-ui/styles';
import { Typography, Box, Button } from '@material-ui/core';
import { Close } from '@material-ui/icons';

import { makeURL } from '../../helperFunctions';
import Image from '../image';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    width: '100%',
    minWidth: 'fit-content',
    // overflowX: 'show',
    padding: '20px',
    borderRadius: '5px',
    boxShadow: '0px 2px 4px 1px #B4B4B4',
    // overflowY: 'auto',
    minHeight: '79vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    backgroundColor: 'white',

    '@media (max-width: 7680px)': {
      minWidth: 'unset',
      width: '100%',
    },
  },
  closeIconButton: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    cursor: 'pointer',
  },
  img: {
    width: '60px',
    height: '70px',
    marginRight: '15px',
  },
  headingContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    margin: '30px 0 40px 0',
  },
  headingText: {
    fontSize: '25px',
    fontWeight: 300,
    color: theme.palette.text.secondary,
    margin: 0,
  },
  subHeadingText: {
    fontSize: '20px',
    fontWeight: 300,
    color: '#19989B',
    margin: 0,
  },
}));

export default ({ heading, subHeading, onClose, children }) => {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <Button
        variant='text'
        className={classes.closeIconButton}
        onClick={onClose}
      >
        <Close style={{ color: '#545454', fontSize: '30px' }} />
      </Button>
      <Box className={classes.headingContainer}>
        <Box className={classes.img}>
          <Image src={makeURL('/images/survey-content-image.png')} />
        </Box>
        <Box flex={1}>
          <Typography
            component='div'
            variant='h2'
            className={classes.headingText}
          >
            {heading}
          </Typography>
          <Typography
            component='div'
            variant='body1'
            className={classes.subHeadingText}
          >
            {subHeading}
          </Typography>
        </Box>
      </Box>
      {children}
    </Box>
  );
};
