import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { ToastContainer, toast } from 'react-toastify';

import { socialMediaAlgorithmCalculation } from '../../../redux/actions/Firebase';
import { AISocialMediaStrategyGenerator } from '../../../API/agent';
import { getSocialMediaPlatform } from '../../../helperFunctions/getSurveysWithExtractedFields';
import { makeURL } from '../../../helperFunctions';

import PageContainer from '../../../myComponents/pageContainer';
import PageLoader from '../../../myComponents/loaders/pageLoader';
import ChartCalculations from './chartCalculations';
import HeaderSelector from '../../../myComponents/headerSelector';
import Filters from '../../../myComponents/filters/Filters';
import PDFExportButton from '../../../myComponents/PDFExportButton';
import UserDropdownHeader from '../../../myComponents/userDropdownHeader';

const useStyles = makeStyles((theme) => ({
  selectorSection: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    '@media (max-width: 768px)': {
      flexWrap: 'wrap',
    },
  },
  socialPostSelector: {
    flex: 1,
  },
}));

const SocialMediaDashboard = () => {
  const { id } = useParams();
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const appData = useSelector(({ data }) => data);
  const PDFContentRef = useRef(null);

  const [pageIsLoading, setPageIsLoading] = useState(true);
  const [useEffectShouldTrigger, setUseEffectShouldTrigger] = useState(true);

  //Ai strategy state
  const [AIStrategyGenerator, setAIStrategyGenerator] = useState({
    text: '',
    loading: true,
  });

  //Input States
  const [pickedBrandPersona, setPickedBrandPersona] = useState(null);
  const [brandPersonaPickedId, setBrandPersonaPickedId] = useState(null);

  const [pickedSocialPost, setPickedSocialPost] = useState(null);
  const [socialPostPickedId, setSocialPostPickedId] = useState(null);

  //Calculation state
  const [calculations, setCalculations] = useState({
    loading: false,
    results: null,
  });

  useEffect(() => {
    if (socialPostPickedId) {
      history.push(makeURL(`/social-media/${socialPostPickedId}`));
      setUseEffectShouldTrigger(true);
    }
  }, [socialPostPickedId]);

  useEffect(() => {
    if (brandPersonaPickedId) {
      setUseEffectShouldTrigger(true);
    }
  }, [brandPersonaPickedId]);

  useEffect(() => {
    if (!appData.loading && useEffectShouldTrigger) {
      setUseEffectShouldTrigger(false);
      if (appData.error) {
        showToastWarn();
      } else {
        if (!id) {
          history.push(makeURL('/social-media/all'));
        } else {
          const pickedSocialMediaPost = !appData.socialPosts
            ? false
            : appData.socialPosts.find((e) => e.id === id);
          if (pickedSocialMediaPost) {
            socialPostChangeHandler(pickedSocialMediaPost);
            setPageIsLoading(false);
          } else {
            history.push(makeURL('/social-media/all'));
          }
        }
      }
    }
  }, [appData.loading, id, useEffectShouldTrigger]);

  const socialPostChangeHandler = (pickedSocialPost) => {
    if (calculations.loading) return;
    let pickedBrandPersona = null;

    if (!brandPersonaPickedId) {
      pickedBrandPersona = appData.brandPersonas.find(
        (persona) => persona.id == pickedSocialPost.Post_Brand_Persona
      );
    } else {
      pickedBrandPersona = appData.brandPersonas.find(
        (persona) => persona.id == brandPersonaPickedId
      );
    }

    setPickedSocialPost(pickedSocialPost);
    setPickedBrandPersona(pickedBrandPersona);
    setBrandPersonaPickedId(pickedBrandPersona.id);
    calculateHandler(pickedSocialPost, pickedBrandPersona, null);
  };

  const calculateHandler = (socialMediaPost, pickedPersona, filters) => {
    setCalculations((prevState) => ({ ...prevState, loading: true }));
    socialMediaAlgorithmCalculation(
      {
        filters: filters,
        socialPost: socialMediaPost,
        persona: pickedPersona,
      },
      dispatch
    )
      .then((res) => {
        setCalculations({ results: res, loading: false });

        //Check if marketing strategy should be generated
        const { marketingStrategies, Post_Title, id } = socialMediaPost;
        if (
          typeof marketingStrategies !== 'undefined' &&
          typeof marketingStrategies[pickedPersona.id] !== 'undefined'
        ) {
          setAIStrategyGenerator({
            loading: false,
            text: marketingStrategies[pickedPersona.id],
          });
        } else {
          //Generate new marketing strategy
          setAIStrategyGenerator({ ...AIStrategyGenerator, loading: true });
          const { Name } = pickedPersona;
          const platform = getSocialMediaPlatform(socialMediaPost);
          const { value: postText } = socialMediaPost.Post_Text.find(
            ({ isSelected }) => isSelected
          );
          AISocialMediaStrategyGenerator(
            Post_Title,
            Name,
            platform,
            postText,
            id,
            pickedPersona.id,
            marketingStrategies
          )
            .then((res) => {
              setAIStrategyGenerator({ loading: false, text: res });
            })
            .catch((err) => {
              setAIStrategyGenerator({ loading: false, text: '' });
              showToastWarn();
            });
        }
      })
      .catch((err) => {
        setCalculations({ results: null, loading: false });
        showToastWarn();
      });
  };

  const showToastWarn = () => {
    toast.warn('Something went wrong, please try again later.', {
      position: 'bottom-right',
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  return (
    <PageContainer>
      {pageIsLoading || calculations.loading ? <PageLoader /> : null}
      {calculations.results ? (
        <div ref={PDFContentRef}>
          <UserDropdownHeader />
          <Box
            className={classes.selectorSection}
            padding='20px 20px 0px 20px'
            style={{ background: '#fff' }}
          >
            <HeaderSelector
              className={classes.socialPostSelector}
              selectedId={id}
              data={appData}
              type='social'
              setPickedId={setSocialPostPickedId}
              redirect={true}
            />
            <HeaderSelector
              selectedId={brandPersonaPickedId}
              data={appData}
              type='brand'
              setPickedId={setBrandPersonaPickedId}
            />
          </Box>
          <Filters
            calculateHandler={(preparedFilterData) =>
              calculateHandler(
                pickedSocialPost,
                pickedBrandPersona,
                preparedFilterData
              )
            }
            themeColor='green'
          />
          <PDFExportButton contentRef={PDFContentRef} type="green"/>
          <ChartCalculations
            type='social'
            data={calculations.results}
            persona={pickedBrandPersona}
            socialPost={pickedSocialPost}
            AIStrategyGenerator={AIStrategyGenerator}
            setAIStrategyGenerator={setAIStrategyGenerator}
          />
        </div>
      ) : null}

      <ToastContainer
        theme='colored'
        position='bottom-right'
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </PageContainer>
  );
};

export default SocialMediaDashboard;
