import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { uniqueId } from 'lodash';
import {
  generateSalesGaugeData,
  generateRangeChartData,
  cleanPersonaAndBatchData,
  generateRadarChartData,
  buyerDashboardRadarChartSubjects,
  makeDataStructureForDataTabel,
  socialValueDimensionsExplanations,
  getProductBenefitsSubHeading,
  getTop3SocialCauses,
} from './helpers';

import { getTableDataCriteria } from './exampleData';
import useViewportWidth from '../../../../myHooks/useViewportWidth';

import DashDetailsWrapper from '../../../../myComponents/dashDetailsWrapper';
import DashSectionExplanation from '../../../../myComponents/dashSectionExplanation';
import ContentSwitcher from '../../../../myComponents/contentSwitcher';
import SalesGauge from '../../../../myComponents/chart/SalesGaugeChart';
import RangeChart from '../../../../myComponents/chart/RangeChart';
import DataInfoTable from '../../../../myComponents/chart/dataInfoTable';
import RadarChart from '../../../../myComponents/chart/RadarChart';
import Watermark from '../../../../myComponents/watermark';
import DataInfoTableSm from '../../../../myComponents/chart/dataInfoTableSm';

const useStyles = makeStyles(theme => ({
  textStyle: {
    fontSize: '18px',
    fontWeight: 'bold',
    color: theme.palette.text.primary,
    textAlign: 'center',
    marginTop: '10px',
  },
  productBenefitsWrapper: {
    '@media (max-width: 768px)': {
      width: '100%',
    },
  },
  rangeChartContainer: {
    marginTop: '50px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    width: '100%',

    '@media (max-width: 1260px)': {
      justifyContent: 'center',
    },
  },
  rangeChartWrapper: {
    maxWidth: '500px',
    margin: '0px 60px 100px 0px',
    width: '100%',
    '@media (max-width: 1240px)': {
      maxWidth: '600px',
    },
    '@media (max-width: 768px)': {
      maxWidth: '100%',
      width: '100%',
      margin: '0px 0px 60px 0px',
      padding: 0,
    },
  },
  socialValueDimensionFlexContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '30px',
    flexWrap: 'wrap',
    width: '100%',
    '@media (max-width: 1090px)': {
      justifyContent: 'center',
    },
    '@media (max-width: 768px)': {
      flexDirection: 'column !important',
      flexWrap: 'wrap',
    },
  },
  radarChartContainerWidth: {
    '@media (max-width: 768px)': {
      maxWidth: '100%',
      width: '100%',
      flex: 'unset',
    },
  },
  radarChartWrapper: {
    '@media (max-width: 768px)': {
      width: '100%',
    },
  },
  brandPerformanceResponsive: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: '30px',
    flexWrap: 'wrap',
    width: '100%',

    '@media (max-width: 1265px)': {
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  socialCausesRadarChartContent: {
    flex: 1,
    margin: '30px auto 0 auto',
    maxWidth: '650px',
    '@media (max-width: 620px)': {
      width: '100%',
    },
  },
  socialCausesRadarChartText: {
    marginLeft: '40px',
    marginBottom: '65px',
    color: 'lightgray',
    fontWeight: 300,
    fontSize: '16px',
    textAlign: 'left',
    '@media (max-width: 768px)': {
      maxWidth: '420px',
      margin: '0 auto',
    },
  },
  socialCausesRadarChartDashExp: {
    marginTop: '90px',
    marginLeft: '70px',

    '@media (max-width: 768px)': {
      maxWidth: '420px',
      margin: '40px auto 0px auto',
    },
  },
}));

const ChartCalculations = ({ type, data, persona }) => {
  const classes = useStyles();
  const viewportWidth = useViewportWidth();

  const tableDataCriteria = getTableDataCriteria();
  const cleanBatchDataInfo = {
    ...data.most_occurring,
    Age: Math.round(data.average.Age),
  };
  const cleanPersonaInfo = {
    ...persona,
    Country: persona.country,
  };
  const [personaTableData, batchTableData] = cleanPersonaAndBatchData(cleanPersonaInfo, cleanBatchDataInfo);
  const tableDataInfo = makeDataStructureForDataTabel(tableDataCriteria, personaTableData, batchTableData);

  let generatedSalesGaugeData = generateSalesGaugeData(type, data.match);
  let generatedRangeChartSocial = generateRangeChartData(persona, data.average.SocialValues, 'social', 'buyer');

  let generatedRangeChartProduct = generateRangeChartData(persona, data.average.PersonalProduct, 'product', 'buyer');

  const buyerMatchMapValues = [
    data.match.Comp_IE_Match,
    data.match.Issues_Match,
    data.match.Comp_Ideology_Match,
    data.match.Inovation_Match,
    data.match.Product_Match,
  ];
  const socialValueDimensionsData = generateRadarChartData(
    'buyer',
    'single',
    buyerMatchMapValues,
    buyerDashboardRadarChartSubjects.brandMatch,
  );

  const multipleRadarChartDataLabels = [persona.Name, 'SELECTED SAMPLE'];
  const multipleRadarChartData = generateRadarChartData(
    'buyer',
    'multiple',
    [data.average.PersonalIssues, persona],
    buyerDashboardRadarChartSubjects.socialIssues,
    multipleRadarChartDataLabels,
  );

  let multipleRadarChartDataOrdered = [];
  const radarChartTemplate = [
    'State of the Economy',
    'Free Healthcare',
    'Violent Crime',
    'World Peace',
    'Gun Laws',
    'Race and Ethnic Inequality',
    'Gender Inequality',
    'Immigration',
    'Economic Inequality',
    'Climate Change',
    'Corporate Corruption',
  ];

  radarChartTemplate.forEach(subject => {
    multipleRadarChartData.forEach(el => {
      if (el.subject == subject) {
        multipleRadarChartDataOrdered = [...multipleRadarChartData, { ...el }];
        return;
      }
    });
  });

  multipleRadarChartDataOrdered.pop();

  const top3SocialCauses = getTop3SocialCauses(multipleRadarChartData);

  const productBeneitsSubHeading = getProductBenefitsSubHeading();

  const getChartSize = width => {
    return width > 1025 ? 200 : width > 768 ? 150 : width > 520 ? 120 : 100;
  };

  const salesGaugeUniqueIds = ['brand-erformance', 'social-value-dimensions'];

  return (
    <Box>
      {/* Brand Performance */}
      <DashDetailsWrapper
        backgroundColor="white"
        expandColor="black"
        heading1={{
          text: 'Brand Performance',
          color: '#AB8628',
        }}>
        <Box className={classes.brandPerformanceResponsive}>
          {viewportWidth <= 768 && (
            <Box key={uniqueId('Box')}>
              <SalesGauge
                salesGaugeData={salesGaugeUniqueIds[0]}
                chartId={uniqueId('Gauge')}
                size={'medium'}
                color="#D9B14A"
                chartTypeColor="#6C757D"
                chartValueColor="black"
                body1={{
                  headingColor: 'black',
                  headingText: generatedSalesGaugeData.brandPerformance[1].body1.headingText,
                  bodyColor: 'gray',
                  bodyText: generatedSalesGaugeData.brandPerformance[1].body1.bodyText,
                }}
              />
            </Box>
          )}
          {generatedSalesGaugeData.brandPerformance.map((salesGaugeData, index) => {
            return (
              (index !== 1 || viewportWidth > 768) && (
                <Box key={index}>
                  <SalesGauge
                    salesGaugeData={salesGaugeData.data}
                    chartId={salesGaugeUniqueIds[0] + index}
                    size={index === 1 && viewportWidth > 768 ? 'large' : 'medium'}
                    color="#D9B14A"
                    chartTypeColor="#6C757D"
                    chartValueColor="black"
                    body1={{
                      headingColor: 'black',
                      headingText: salesGaugeData.body1.headingText,
                      bodyColor: 'gray',
                      bodyText: salesGaugeData.body1.bodyText,
                    }}
                  />
                </Box>
              )
            );
          })}
        </Box>
      </DashDetailsWrapper>

      {/* Social Value Dimensions */}
      <DashDetailsWrapper
        backgroundColor="#254157"
        expandColor="white"
        heading1={{
          text: 'Social Value Dimensions',
          color: 'white',
        }}>
        <ContentSwitcher
          headingText="BRAND MATCH MAP"
          headingColor="white"
          type="buyer"
          content1={
            <Box className={classes.brandPerformanceResponsive}>
              {generatedSalesGaugeData.socialValueDimensions.map((salesGaugeData, index) => {
                return (
                  <Box key={index}>
                    <SalesGauge
                      salesGaugeData={salesGaugeData.data}
                      chartId={salesGaugeUniqueIds[1] + index}
                      size={index === 1 && viewportWidth > 768 ? 'large' : 'medium'}
                      color="#D9B14A"
                      chartTypeColor="white"
                      chartValueColor="white"
                      body1={{
                        headingColor: 'white',
                        headingText: salesGaugeData.body1.headingText,
                        bodyColor: 'lightgray',
                        bodyText: salesGaugeData.body1.bodyText,
                      }}
                    />
                  </Box>
                );
              })}
            </Box>
          }
          content2={
            <Box className={classes.socialValueDimensionFlexContainer}>
              <Box display="flex" flexDirection="column" justifyContent="space-between" alignItems="flex-start">
                {socialValueDimensionsExplanations.map((explanation, index) => (
                  <Box marginBottom="20px" maxWidth="350px" key={index}>
                    <DashSectionExplanation
                      heading={explanation.heading}
                      headingColor="white"
                      body={explanation.body}
                      bodyColor="lightgray"
                    />
                  </Box>
                ))}
              </Box>
              <Box className={classes.radarChartContainerWidth} flex={1} margin="auto" height="480px" maxWidth="600px">
                <RadarChart
                  personaType="buyer"
                  type="single"
                  data={socialValueDimensionsData}
                  size={getChartSize(viewportWidth)}
                  tickTextColor="#90A0B0"
                />
              </Box>
            </Box>
          }
        />
      </DashDetailsWrapper>

      {/* Brand Persona & Consumer Demographics */}
      <DashDetailsWrapper
        backgroundColor="white"
        expandColor="black"
        heading1={{
          text: 'Buyer Persona & Consumer Demographics',
          color: '#AB8628',
        }}>
        {viewportWidth > 1024 ? (
          <DataInfoTable data={tableDataInfo} personaName={persona.Name} type="buyer" />
        ) : (
          <DataInfoTableSm data={tableDataInfo} personaName={persona.Name} type="buyer" />
        )}
      </DashDetailsWrapper>

      {/* Social Values & Social Causes */}
      <DashDetailsWrapper
        backgroundColor="#254157"
        expandColor="white"
        heading1={{
          text: 'Social Values & Social Causes',
          color: 'white',
        }}>
        <Box className={classes.brandPerformanceResponsive}>
          <Box display="flex" flexDirection="column" justifyContent="space-between" alignItems="flex-start">
            {generatedRangeChartSocial.map((socialRangeChartData, index) => (
              <Box marginBottom="20px" maxWidth="420px" key={index}>
                <RangeChart
                  mainLineColor="#667A89"
                  middleLineColor="#EFEFEF"
                  leftText={{
                    text: socialRangeChartData.leftText,
                    color: 'white',
                  }}
                  rightText={{
                    text: socialRangeChartData.rightText,
                    color: 'white',
                  }}
                  valueColor="lightgray"
                  heading={socialRangeChartData.heading}
                  headingColor="white"
                  body={{
                    text: socialRangeChartData.body,
                    color: 'lightgray',
                  }}
                  value1={socialRangeChartData.personaValue}
                  value2={socialRangeChartData.batchValue}
                  type="buyer"
                />
              </Box>
            ))}
          </Box>
          <Box className={classes.socialCausesRadarChartContent}>
            <p className={classes.socialCausesRadarChartText}>
              This chart compares consumers' and brands' positions regarding the most important 11 social causes that are
              commonly used in political research and social sciences.
            </p>
            <Box className={classes.radarChartWrapper} height="500px" width="700px">
              <RadarChart
                type="multiple"
                data={multipleRadarChartDataOrdered}
                size={getChartSize(viewportWidth)}
                AConfig={{
                  name: multipleRadarChartDataLabels[0],
                  color: '#AB8628',
                }}
                BConfig={{
                  name: multipleRadarChartDataLabels[1],
                  color: '#CE5378',
                }}
                tickTextColor="#90A0B0"
              />
            </Box>
            <Box className={classes.socialCausesRadarChartDashExp}>
              <DashSectionExplanation
                heading={'Causes that consumers most care about:'}
                headingColor="white"
                body={
                  <span>
                    {top3SocialCauses.map((cause, index) => (
                      <p key={index} style={{ marginBottom: '1px', fontSize: '15px' }}>
                        - {cause.subject} -<strong> {cause['SELECTED SAMPLE']}%</strong>
                      </p>
                    ))}
                  </span>
                }
                bodyColor="lightgray"
              />
            </Box>
          </Box>
        </Box>
      </DashDetailsWrapper>

      {/* Product Benefits */}
      <DashDetailsWrapper
        backgroundColor="white"
        expandColor="black"
        heading1={{
          text: 'Product Benefits',
          color: '#AB8628',
        }}>
        <Box className={classes.productBenefitsWrapper}>
          <Box display="flex" alignItems="center" marginBottom="20px">
            <Box display="flex" alignItems="center" marginRight="20px">
              <Box
                style={{
                  backgroundColor: '#CE5378',
                  width: '15px',
                  height: '15px',
                  borderRadius: '50%',
                  boxShadow: '0px 4px 3px lightgray',
                  marginRight: '10px',
                }}></Box>
              <p
                style={{
                  color: 'black',
                  fontWeight: 300,
                  fontSize: '16px',
                  textAlign: 'left',
                  margin: '0',
                }}>
                SELECTED SAMPLE
              </p>
            </Box>
            <Box display="flex" alignItems="center">
              <Box
                style={{
                  backgroundColor: '#AB8628',
                  width: '15px',
                  height: '15px',
                  borderRadius: '50%',
                  boxShadow: '0px 4px 3px lightgray',
                  marginRight: '10px',
                }}></Box>
              <p
                style={{
                  color: 'black',
                  fontWeight: 300,
                  fontSize: '16px',
                  textAlign: 'left',
                  margin: '0',
                }}>
                {persona.Name}
              </p>
            </Box>
          </Box>
          <p
            style={{
              color: '#A5A5A5',
              fontWeight: 300,
              fontSize: '16px',
              textAlign: 'left',
              margin: '0',
              maxWidth: '700px',
              width: '100%',
            }}>
            {productBeneitsSubHeading}
          </p>
          <Box className={classes.rangeChartContainer}>
            {generatedRangeChartProduct.map((productRangeChartData, index) => (
              <Box className={classes.rangeChartWrapper} key={index}>
                <RangeChart
                  mainLineColor="#E5E6E6"
                  middleLineColor="#D9D9D9"
                  leftText={{
                    text: productRangeChartData.leftText,
                    color: '#6C757D',
                  }}
                  rightText={{
                    text: productRangeChartData.rightText,
                    color: '#6C757D',
                  }}
                  valueColor="gray"
                  body={{
                    text: productRangeChartData.body,
                    color: '#6C757D',
                  }}
                  value1={productRangeChartData.personaValue}
                  value2={productRangeChartData.batchValue}
                />
              </Box>
            ))}
          </Box>
          <Watermark />
        </Box>
      </DashDetailsWrapper>
    </Box>
  );
};

export default ChartCalculations;
