import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import { Box, Typography, TextField } from "@material-ui/core";
import { addNameToText } from "../helperFunctions";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    width: "fit-content",
    margin: "0 auto 0 auto",
  },
  text: {
    color: theme.palette.text.primary,
    fontWeight: 300,
    fontSize: "18px",
    maxWidth: "800px",
    margin: "0 auto 25px auto",
    '@media (max-width: 600px)': {
      fontSize: '16px',
      marginInline: 0,
    },
  },
  textInput: {
    padding: "12px 18px",
  },
  textField: {
    width: "100%",
  },
}));

const TextAreaFormElement = (props) => {
  const [textValue, setTextValue] = useState("");
  const classes = useStyles();

  useEffect(() => {
    setTextValue("");
  }, [props.id]);

  const handleChange = (event) => {
    setTextValue(event.target.value);
    props.handleSurveyAnswers(event.target.value, props.id);
  };

  return (
    <Box className={classes.mainContainer}>
      <Typography component="div" variant="h2" className={classes.text}>
        {addNameToText(props.text, props.name)}
      </Typography>
      <Box
        display="flex"
        justifyContent="center"
        maxWidth="400px"
        margin="0 auto"
      >
        <TextField
          onChange={handleChange}
          variant="outlined"
          multiline
          minRows={4}
          placeholder="Enter Here"
          value={props.value ? props.value : textValue}
          className={classes.textField}
          inputProps={{
            className: classes.textInput,
          }}
        />
      </Box>
    </Box>
  );
};

export default TextAreaFormElement;
