import React from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import configureStore, { history } from './redux/store';
import AppWrapper from './@jumbo/components/appWrapper';
import AppContextProvider from "./@jumbo/contexts/AppContext";
import Routes from './routes';
import './index.css';

export const store = configureStore();

const App = () => {
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
      <AppContextProvider>
        <AppWrapper>
          <Routes />
        </AppWrapper>
        </AppContextProvider>
      </ConnectedRouter>
    </Provider>
  );
};

export default App;
