import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import Auth from './Auth';
import Firebase from './Firebase';
import Data from './Data';

export default history =>
  combineReducers({
    router: connectRouter(history),
    auth: Auth,
    firebase: Firebase,
    data: Data
  });
