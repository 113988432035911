import React from 'react';
import PageContainer from '../../../myComponents/pageContainer';

const BrandConsumers = () => {
  return (
    <PageContainer heading="Brand & Consumers page">
      <h1>See your Brand & Consumers!</h1>
    </PageContainer>
  );
};

export default BrandConsumers;
