import { Box, Typography } from '@material-ui/core';
import useViewportWidth from '../../../myHooks/useViewportWidth';
import React from 'react';
import {
  Legend,
  PolarAngleAxis,
  PolarGrid,
  PolarRadiusAxis,
  Radar,
  RadarChart,
  ResponsiveContainer,
} from 'recharts';

const SpecifiedDomainRadarChart = (props) => {
  return props.type === 'single' ? (
    <Box width='100%' height='100%'>
      {props.heading && (
        <Typography
          component='div'
          variant='h2'
          style={{
            margin: '0',
            fontSize: '20px',
            color: '#000000',
            textAlign: 'center',
          }}
        >
          {props.heading}
        </Typography>
      )}
      <ResponsiveContainer width='100%'>
        <RadarChart outerRadius={props.size || 150} data={props.data}>
          <Radar
            dot
            legendType='circle'
            dataKey='A'
            stroke={
              props.personaType == 'brand'
                ? '#19989B'
                : props.personaType == 'consumerDash'
                ? '#CD225C'
                : '#AB8628'
            }
            fill={
              props.personaType == 'brand'
                ? '#19989B'
                : props.personaType == 'consumerDash'
                ? '#CD225C'
                : '#AB8628'
            }
            strokeWidth={3}
            fillOpacity={0.4}
          />
          <PolarGrid />
          <PolarAngleAxis
            dataKey='subject'
            tick={(tickProps) => (
              <CustomTick {...tickProps} tickTextColor={props.tickTextColor} />
            )}
          />
          <PolarRadiusAxis
            angle={90}
            domain={[0, 100]}
            tickCount={6}
            orientation='middle'
            tick={(tickProps) => <CustomAxisTick {...tickProps} />}
          />
        </RadarChart>
      </ResponsiveContainer>
    </Box>
  ) : (
    <Box width='100%' height='100%'>
      <Typography
        component='div'
        variant='h2'
        style={{
          margin: '0',
          fontSize: '20px',
          color: '#000000',
          textAlign: 'center',
        }}
      >
        {props.heading}
      </Typography>
      <ResponsiveContainer width='100%'>
        <RadarChart outerRadius={props.size || 150} data={props.data}>
          <Radar
            dot
            strokeLinecap='butt'
            legendType='circle'
            dataKey={props.AConfig.name}
            stroke={props.AConfig.color}
            fill={props.AConfig.color}
            fillOpacity={0.2}
            strokeWidth={3}
          />
          {props.BConfig && (
            <Radar
              dot
              legendType='circle'
              dataKey={props.BConfig.name}
              stroke={props.BConfig.color}
              fill={props.BConfig.color}
              fillOpacity={0.2}
              strokeWidth={3}
            />
          )}
          <PolarGrid />
          <Legend
            formatter={(value, entry, index) => (
              <span style={{ color: props.tickTextColor }}>{value}</span>
            )}
          />
          <PolarAngleAxis
            dataKey='subject'
            tick={(tickProps) => (
              <CustomTick {...tickProps} tickTextColor={props.tickTextColor} />
            )}
          />
          <PolarRadiusAxis
            angle={90}
            domain={[0, 100]}
            tickCount={6}
            orientation='middle'
            tick={(tickProps) => <CustomAxisTick {...tickProps} />}
          />
        </RadarChart>
      </ResponsiveContainer>
    </Box>
  );
};

const CustomAxisTick = (props) => {
  return (
    <g opacity={0.8}>
      <rect
        x={props.x - 11}
        y={props.y - 9}
        rx='3'
        ry='3'
        width='22'
        height='15'
        fill='white'
      />
      <text
        x={props.x}
        y={props.y}
        fill='black'
        textAnchor='middle'
        alignmentBaseline='middle'
        fontSize={11}
        fontWeight='bold'
      >
        {props.payload.value}
      </text>
    </g>
  );
};

const CustomTick = ({
  index,
  payload,
  x,
  y,
  textAnchor,
  stroke,
  radius,
  tickTextColor,
}) => {
  const width = useViewportWidth();

  // console.log(payload, x, y, textAnchor, stroke, radius, "aha");
  return (
    <g className='recharts-layer recharts-polar-angle-axis-tick'>
      <text
        radius={radius}
        stroke={stroke}
        x={x}
        y={index !== 0 ? y : y - 20}
        style={{
          fill: tickTextColor,
        }}
        className='recharts-text recharts-polar-angle-axis-tick-value'
        textAnchor={textAnchor}
      >
        {payload.value.split(',').map((tickTextPart, tickTextPartIndex) => {
          const yValue = y + tickTextPartIndex * 20;
          return (
            <tspan
              style={{ fontSize: width > 768 ? '14px' : '12px' }}
              x={x}
              dy='0em'
              y={index !== 0 ? yValue : yValue - 10}
              key={tickTextPartIndex}
            >
              {tickTextPart}
            </tspan>
          );
        })}
      </text>
    </g>
  );
};

export default SpecifiedDomainRadarChart;
