import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Typography,
  MenuItem,
  FormControl,
  Select,
} from '@material-ui/core';
import { addNameToText } from '../helperFunctions';

const useStyles = makeStyles((theme) => ({
  mainContainer: ({ isInGroup }) => ({
    width: 'fit-content',
    margin: isInGroup ? '0 auto 50px auto' : '0 auto',
    '@media (max-width: 768px)': {
      width: '100%',
    },
  }),
  mainContainerSummary: ({ isSocial }) => ({
    width: '100%',
    maxWidth: isSocial ? '650px' : '600px',
    margin: '0 auto 30px auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '@media (max-width:768px)': {
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: '8px',
      marginInline: '0px !important',
      maxWidth: isSocial ? '550px' : '500px',
    },
  }),
  text: ({ isSummary, isSocial }) => ({
    color: isSummary && isSocial ? 'black' : theme.palette.text.primary,
    fontWeight: isSummary && isSocial ? 800 : 300,
    fontSize: '18px',
    maxWidth: '800px',
    margin: isSummary ? '0' : '0 auto 15px auto',
    textAlign: isSummary ? 'left' : 'unset',
    '@media (max-width:600px)': {
      fontSize: '16px',
    },
  }),
  dropdown: {
    padding: '10px 13px',
    '@media (max-width:600px)': {
      fontSize: '14px',
    },
  },
  dropdownWidth: ({ isSummary }) => ({
    minWidth: isSummary ? '360px' : 'unset',
    maxWidth: isSummary ? '450px' : 'unset',
    '@media (max-width: 768px)': {
      maxWidth: '100%',
      minWidth: 'unset',
      width: '100%',
    },
  }),
}));

const DropdownFormElement = (props) => {
  const [selectedValue, setSelectedValue] = useState(props.options[0].value);
  const classes = useStyles({
    isSummary: props.isSummary,
    isInGroup: props.isInGroup,
    isSocial: props.id.indexOf('Post_') !== -1,
  });

  useEffect(() => {
    setSelectedValue(props.options[0].value);
  }, [props.id]);

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    props.handleSurveyAnswers(event.target.value, props.id);
  };

  useEffect(() => {
    if (typeof props.value === 'undefined') {
      props.handleSurveyAnswers(props.options[0].value, props.id);
    }
  }, []);

  const mainContainerClass = props.isSummary
    ? classes.mainContainerSummary
    : classes.mainContainer;

  return (
    <Box className={mainContainerClass}>
      <Typography component='div' variant='h3' className={classes.text}>
        {addNameToText(props.text, props.name)}
      </Typography>
      <Box className={classes.dropdownWidth}>
        <FormControl fullWidth>
          <Select
            labelId='demo-simple-select-label'
            id='demo-simple-select'
            variant='outlined'
            value={props.value ? props.value : selectedValue}
            onChange={handleChange}
            classes={{ root: classes.dropdown }}
          >
            {props.options.map((e, index) => {
              return (
                <MenuItem key={index} value={e.value}>
                  {e.text}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
};

export default DropdownFormElement;
