import React, { useEffect } from 'react';

import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { Box } from '@material-ui/core';

am4core.useTheme(am4themes_animated);
am4core.options.autoDispose = true;

const SalesGauge = ({
  salesGaugeData,
  chartId,
  size,
  color,
  body1,
  body2,
  chartTypeColor,
  chartValueColor,
}) => {
  useEffect(() => {
    let chart = am4core.create(`chartdiv-${chartId}`, am4charts.RadarChart);
    chart.paddingRight = 20;
    chart.responsive.enabled = true;

    chart.data = salesGaugeData;

    chart.colors.list = [am4core.color(color)];

    // Make chart not full circle
    chart.startAngle = -90;
    chart.endAngle = 180;
    chart.innerRadius = am4core.percent(85);

    // Set number format
    chart.numberFormatter.numberFormat = "#.#'%'";

    // Create axes
    let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = 'category';
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.grid.template.strokeOpacity = 0;
    categoryAxis.renderer.labels.template.horizontalCenter = 'right';
    categoryAxis.renderer.labels.template.fontWeight = 500;

    categoryAxis.renderer.labels.template.adapter.add('fill', () => {
      return chartTypeColor;
    });
    categoryAxis.renderer.minGridDistance = 20;

    let valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.grid.template.strokeOpacity = 0;
    valueAxis.min = 0;
    valueAxis.max = 100;
    valueAxis.strictMinMax = true;
    valueAxis.fontSize = 10;
    valueAxis.renderer.labels.template.fill = am4core.color(chartTypeColor);

    // Create series
    let series1 = chart.series.push(new am4charts.RadarColumnSeries());
    series1.dataFields.valueX = 'full';
    series1.dataFields.categoryY = 'category';
    series1.clustered = false;
    series1.columns.template.fill = new am4core.InterfaceColorSet().getFor(
      'alternativeBackground'
    );
    series1.columns.template.fillOpacity = 0.08;
    series1.columns.template.cornerRadiusTopLeft = 30;
    series1.columns.template.strokeWidth = 0;
    series1.columns.template.radarColumn.cornerRadius = 30;

    let series2 = chart.series.push(new am4charts.RadarColumnSeries());
    series2.dataFields.valueX = 'value';
    series2.dataFields.categoryY = 'category';
    series2.clustered = false;
    series2.columns.template.strokeWidth = 0;
    //disable tooltip
    // series2.columns.template.tooltipText = "{category}: [bold]{value}[/]";
    series2.columns.template.radarColumn.cornerRadius = 30;
    series2.columns.template.disabled = false;
    series2.columns.template.adapter.add('fill', function(fill, target) {
      return chart.colors.getIndex(target.dataItem.index);
    });

    // Add cursor
    chart.cursor = new am4charts.RadarCursor();

    return () => {
      if (chart) {
        chart.dispose();
      }
    };
  }, [salesGaugeData]);

  const isMediumSize = size === 'medium';

  return (
    <Box padding='10px 20px'>
      <Box position='relative' marginBottom='30px'>
        <div
          id={`chartdiv-${chartId}`}
          style={{
            width: isMediumSize ? '295px' : '390px',
            height: isMediumSize ? '230px' : '270px',
          }}
        />
        <h1
          style={{
            position: 'absolute',
            fontSize: isMediumSize ? '35px' : '44px',
            margin: 0,
            top: isMediumSize ? '89px' : '95px',
            left: isMediumSize ? '106px' : '153px',
            textShadow: '-1px 2px 2px rgba(0,0,0,0.4)',
            color: chartValueColor,
          }}
        >
          {salesGaugeData[0].value}%
        </h1>
      </Box>
      <Box
        width='100%'
        maxWidth={isMediumSize ? '280px' : '390px'}
        textAlign='left'
        marginBottom='20px'
        marginLeft='20px'
      >
        <h5
          style={{
            color: body1.headingColor,
            fontSize: '16px',
          }}
        >
          {body1.headingText}
        </h5>
        <p
          style={{
            color: body1.bodyColor,
            margin: 0,
            fontSize: '12px',
            fontWeight: 300,
          }}
        >
          {body1.bodyText}
        </p>
      </Box>
      {body2 && (
        <Box
          width='100%'
          maxWidth={isMediumSize ? '280px' : '390px'}
          textAlign='left'
          marginLeft='20px'
        >
          <h5 style={{ color: body2.headingColor, fontSize: '15px' }}>
            {body2.headingText}
          </h5>
          <p
            style={{
              color: body2.bodyColor,
              margin: 0,
              fontSize: '12px',
            }}
          >
            {body2.bodyText}
          </p>
        </Box>
      )}
    </Box>
  );
};

export default SalesGauge;
