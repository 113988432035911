import React from 'react';
import { makeStyles } from '@material-ui/core';
import { makeURL } from '../../../helperFunctions';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    gap: '20px',

    '& h5': {
      fontSize: '24px',
      textAlign: "center"
    },
    '& img': {
      alignSelf: 'center',
    },
    '& p': {
      alignSelf: 'center',
      fontSize: '32px',
    },
  },
}));

const PublishContentCard = ({ status }) => {
  const classes = useStyles();

  let topText = 'Publish content';
  let bottomText = 'Pass';
  let imgPath = makeURL('/images/icons/pass-icon.svg');
  let textColor = '#19989B';

  if (status.toLowerCase().indexOf('fail') !== -1) {
    topText = 'Do not publish content';
    bottomText = 'Fail';
    imgPath = makeURL('/images/icons/fail-icon.svg');
    textColor = '#CD225C';
  } else if (status.toLowerCase().indexOf('conditional') !== -1) {
    topText = 'Revise Content Before Publishing ';
    bottomText = 'Conditional Pass';
    imgPath = makeURL('/images/icons/conditional-pass-icon.svg');
    textColor = '#AB8628';
  } else if (status.toLowerCase().indexOf('not applicable') !== -1) {
    topText = 'No Impact';
    bottomText = '';
    imgPath = makeURL('/images/icons/main-status-not-applicable.svg');
    textColor = '#939ea6';
  }

  return (
    <div className={classes.container}>
      <h5 style={{ color: textColor }}>{topText}</h5>
      <img src={imgPath} />
      <p style={{ fontSize: '32px', textAlign: "center", lineHeight: "30px" }}>{bottomText}</p>
    </div>
  );
};

export default PublishContentCard;
