export const salesGaugeData = [
  [
    {
      category: "Total Orders",
      value: 65,
      full: 100,
    },
    {
      category: "Past Orders",
      value: 80,
      full: 100,
    },
    {
      category: "Total Revenue",
      value: 90,
      full: 100,
    },
  ],
  [
    {
      category: "Total Orders",
      value: 65,
      full: 100,
    },
    {
      category: "Past Orders",
      value: 80,
      full: 100,
    },
    {
      category: "Total Revenue",
      value: 90,
      full: 100,
    },
  ],
];

export const getTableDataCriteria = () => {
  return [
    "Country",
    "City",
    "State",
    "Region",
    "ZIP",
    "Location",
    "Age",
    "Gender",
    "Education",
    "Ethnicity",
    "Marital Status",
    "Income",
    "Homeownership",
    "Values",
    "Interests",
    "Goals",
    "Problems",
    "Brand",
    "Influencers",
  ];
};

export const exampleCalculationResponse = {
  match: {
    IE_Focus_Match: 0.6824149328368064,
    Comp_IE_Match: 0.8873885834333722,
    LG_Match: 0,
    Comp_Ideology_Match: 0.7990696278511404,
    Comp_LocalGlobal_Match: 0,
    Product_Match: 0.6497714797053258,
    Issues_Match: 0.6920793902114524,
    Inovation_Match: 0.71830199940423,
    Ideology_Match: 0.745593591111853,
    Values_Match: 0.7856548994408653,
    Total_Match: 0.7046667144870953,
  },
  most_occurring: {
    Country: 187,
    City: "Houston",
    State: 5,
    ZIP: 77449,
    Location: 2,
    Gender: 2,
    Education: 1,
    Ethnicity: 1,
    Marital_status: 1,
    Income: 5,
    Homeownership: 5,
  },
  companies_match: [
    {
      name: "Twitter",
      industryId: 4,
      match: { product: 0.6452283279329569, values: 0.6795871471120858 },
    },
    {
      name: "Nvidia",
      industryId: 4,
      match: { product: 0.6615614218289684, values: 0.6896110552768374 },
    },
    {
      name: "Dell Technologies",
      industryId: 4,
      match: { product: 0.6619222770831743, values: 0.7318786182266062 },
    },
    {
      name: "Alphabet (Google)",
      industryId: 4,
      match: { product: 0.6407259358492201, values: 0.7105817269371656 },
    },
    {
      name: "Meta (Facebook)",
      industryId: 4,
      match: { product: 0.6236959206446665, values: 0.6204634165041832 },
    },
  ],
  average: {
    Age: 48.05962384953982,
    PersonalProduct: {
      PersonalProduct_1: 0.5918367346938775,
      PersonalProduct_2: 0.7037815126050421,
      PersonalProduct_3: 0.4452781112444978,
      PersonalProduct_4: 0.48999599839935976,
      PersonalProduct_5: 0.4233693477390956,
      PersonalProduct_6: 0.5741296518607443,
      PersonalProduct_7: 0.4061624649859944,
      PersonalProduct_8: 0.36504601840736295,
      PersonalProduct_9: 0.5411164465786314,
      PersonalProduct_10: 0.4699879951980792,
    },
    PersonalIssues: {
      PersonalIssues_1: 0.6768707482993197,
      PersonalIssues_2: 0.6004401760704282,
      PersonalIssues_3: 0.7275910364145658,
      PersonalIssues_4: 0.6899759903961584,
      PersonalIssues_5: 0.6502601040416166,
      PersonalIssues_6: 0.6326530612244898,
      PersonalIssues_7: 0.5692276910764306,
      PersonalIssues_8: 0.6318527410964386,
      PersonalIssues_9: 0.6139455782312925,
      PersonalIssues_10: 0.6152460984393757,
      PersonalIssues_11: 0.6438575430172069,
    },
    SocialValues: {
      PersonalLocalGlobal: 0.3818527410964383,
      PersonalInterExter: 0.5423502734427101,
      PersonalIdeology: 0.5380652260904362,
      PersonalSocialInovat: 0.44244364412431586,
    },
  },
};
