import React from 'react';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CubeLoader from '../cubeLoader';

const useStyles = makeStyles(theme => ({
  loaderContainer: {
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 200,
  },
  loader: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: "translateX(-50%) translateY(-50%) !important",
  },
}));

const PageLoader = ({ dashboardCalculation }) => {
  const backgroundStyle = dashboardCalculation
    ? {
        backgroundColor: 'rgb(255 255 255 / 0.3)',
        backdropFilter: 'blur(2px)',
      }
    : {
        background: 'transparent',
      };
  const classes = useStyles();

  return (
    <Box className={classes.loaderContainer} style={backgroundStyle}>
      <Box className={classes.loader}>
        <CubeLoader />
      </Box>
    </Box>
  );
};

export default PageLoader;
