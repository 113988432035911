import React, { useState } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ExpandMore } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  headings: {
    fontSize: '30px',
    fontWeight: 200,
  },
}));

const DashDetailsWrapper = ({
  backgroundColor,
  expandColor,
  heading1,
  heading2,
  children,
  overflowX,
  socialMediaStrategy = false,
}) => {
  const classes = useStyles();

  const [expanded, setExpanded] = useState(true);

  return (
    <Box
      style={{
        backgroundColor,
        overflowX: overflowX ? 'scroll' : 'unset',
      }}
    >
      <Accordion
        style={{ padding: '10px 15px', backgroundColor: 'transparent' }}
        expanded={expanded}
        onChange={() => setExpanded(!expanded)}
      >
        <AccordionSummary
          expandIcon={
            <ExpandMore fontSize='large' style={{ color: expandColor }} />
          }
        >
          <Box display='flex' alignItems='center' width='95%'>
            <Typography
              variant='h4'
              className={classes.headings}
              style={{ color: heading1.color }}
            >
              {heading1.text}
            </Typography>
            {heading2 && (
              <Typography
                variant='h4'
                className={classes.headings}
                style={{ color: heading2.color, marginLeft: '32%' }}
              >
                {heading2.text}
              </Typography>
            )}
          </Box>
        </AccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default DashDetailsWrapper;
