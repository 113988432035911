import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography, MenuItem, FormControl, Select } from '@material-ui/core';
import useViewportWidth from '../../../myHooks/useViewportWidth';

const useStyles = makeStyles(theme => ({
  mainContainer: ({ isInGroup }) => ({
    width: '100%',
    maxWidth: '650px',
    margin: '0 auto 30px auto',
    gap: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: isInGroup ? '1px solid lightgray' : 'none',
    paddingBottom: isInGroup ? '25px' : 'none',
    flexWrap: 'wrap',

    '@media (max-width: 768px)': {
      width: '100% !important',
      maxWidth: '100%',
    },
  }),
  text: ({ isSummary }) => ({
    color: theme.palette.text.primary,
    fontWeight: 800,
    fontSize: '18px',
    margin: '0',
    textAlign: 'left',
    '@media (max-width: 600px)': {
      fontSize: '16px',
    },
  }),
  dropdownWrapper: {
    '@media (max-width: 768px)': {
      width: '100%',
    },
  },
  dropdown: {
    padding: '10px 13px',
    '@media (max-width: 768px)': {
      width: '100%',
    },
  },
}));

const PersonaDropdown = props => {
  const appData = useSelector(({ data }) => data);
  let width = useViewportWidth();

  const dropdownValues =
    appData.brandPersonas && appData.brandPersonas.length
      ? appData.brandPersonas.map(persona => ({
          text: persona.Name,
          value: persona.id,
        }))
      : [{ text: 'None', value: 0 }];

  const [selectedValue, setSelectedValue] = useState(dropdownValues[0].value);
  const classes = useStyles({
    isSummary: props.isSummary,
    isInGroup: props.isInGroup,
  });

  useEffect(() => {
    if (!props.value) {
      props.handleSurveyAnswers(selectedValue, props.id);
    }
  }, []);

  const handleChange = event => {
    setSelectedValue(event.target.value);
    props.handleSurveyAnswers(event.target.value, props.id);
  };

  const isBigScreen = width > 400;

  return (
    <Box className={classes.mainContainer}>
      <Typography component="div" variant="h3" className={classes.text}>
        {props.text}
      </Typography>
      <Box className={classes.dropdownWrapper} style={{ minWidth: props.isSummary && isBigScreen ? '360px' : '300px' }}>
        <FormControl fullWidth>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            variant="outlined"
            value={props.value ? props.value : selectedValue}
            onChange={handleChange}
            classes={{ root: classes.dropdown }}>
            {dropdownValues.map((persona, index) => {
              return (
                <MenuItem key={index} value={persona.value}>
                  {persona.text}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
};

export default PersonaDropdown;
