import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Box, Typography, Radio, RadioGroup, FormControl } from '@material-ui/core';
import { addNameToText } from '../helperFunctions';
import { convert_normalize, convert_reverse_coded_normalize } from '../../../helperFunctions';

const useStyles = makeStyles(theme => ({
  mainContainer: {
    textAlign: 'center',
    width: '100%',
    margin: '0 auto',
    overflowX: 'auto',
    '@media (max-width: 768px)': {
      margin: 0,
      textAlign: 'left',
    },
  },
  text: ({ questionIsEnabled }) => ({
    color: !questionIsEnabled ? theme.palette.text.disabled : theme.palette.text.primary,
    fontWeight: 300,
    fontSize: '18px',
    maxWidth: '800px',
    margin: '0 auto 30px auto',
    margin: '0 auto 30px auto',
    '@media (max-width: 600px)': {
      fontSize: '16px',
      marginInline: 0,
    },
  }),
  radioElementsText: ({ questionIsEnabled }) => ({
    color: !questionIsEnabled ? theme.palette.text.disabled : theme.palette.text.primary,
    width: '27%',
    fontWeight: 300,
    fontSize: '15px',
    textAlign: 'right',
    '@media (max-width: 1025px)': {
      fontSize: '14px !important',
    },
    '@media (max-width: 768px)': {
      textAlign: 'left',
    },
  }),
  radioElementRightText: {
    textAlign: "left !important",
    '@media (max-width: 768px)': {
      paddingLeft: '20px',
    },
  },
  formGroup: {
    width: 'fit-content',
    margin: '0 30px',
    '@media (max-width: 800px)': {
      margin: 0,
    },
  },
  radioGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'nowrap',
    '@media (max-width: 800px)': {
      justifyContent: 'start',
    },
  },
  radioGroupWrapper: {
    justifyContent: 'center',
    '@media (max-width: 800px)': {
      justifyContent: 'space-between !important',
    },
  },
  allRadioWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    '@media (max-width: 550px)': {
      width: '130%',
    },
  },
}));

const RadioGroupMultipleFormElement = props => {
  const initialSateHandle = () => {
    let obj = {};
    for (let key of props.id) {
      const value = typeof props.value[key] !== undefined && props.value[key] !== null ? props.value[key] : '';

      if (value !== '') {
        if (key.indexOf('InterExter_1') !== -1 || key.indexOf('InterExter_2') !== -1) {
          obj[key] = convert_reverse_coded_normalize(value);
        } else {
          obj[key] = convert_normalize(value);
        }
      } else {
        obj[key] = value;
      }
    }
    return obj;
  };
  const [selectedValue, setSelectedValue] = useState(initialSateHandle());

  const questionIsEnabled = !props.dependancySelection
    ? true
    : props.dependancySelection.value.find(
        value => Number(value) === Number(props.value[props.dependancySelection.dependancy_question_id]),
      );

  const classes = useStyles({ questionIsEnabled });

  useEffect(() => {
    setSelectedValue(initialSateHandle());
  }, [props.id]);

  const handleChange = (event, id) => {
    setSelectedValue(prevState => ({
      ...prevState,
      [id]: event.target.value,
    }));
    props.handleSurveyAnswers(event.target.value, id);
  };

  const styleForGroup = props.isInGroup
    ? {
        marginBottom: '50px',
        paddingBottom: '50px',
        borderBottom: '1px solid lightgray',
      }
    : {};

  return (
    <Box className={classes.mainContainer} style={styleForGroup}>
      <Typography component="div" variant="h2" className={classes.text}>
        {addNameToText(props.text, props.name)}
      </Typography>
      <Box className={classes.allRadioWrapper}>
        {props.left.map((option, index1) => {
          return (
            <Box
              key={index1}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              className={classes.radioGroupWrapper}
              margin="8px 0"
              width="100%">
              <Typography
                component="div"
                variant="body2"
                className={classes.radioElementsText}
                // style={{ textAlign: 'right' }}
              >
                {addNameToText(option, props.name)}
              </Typography>
              <FormControl className={classes.formGroup} disabled={!questionIsEnabled}>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  className={classes.radioGroup}
                  value={typeof selectedValue[props.id[index1]] !== 'undefined' ? selectedValue[props.id[index1]] + '' : ''}>
                  {[...new Array(props.radioLength)].map((e, index2) => {
                    return (
                      <Radio
                        value={index2 + 1 + ''}
                        onChange={event => handleChange(event, props.id[index1])}
                        name="radio-button"
                        key={index2}
                        style={{
                          color: !questionIsEnabled ? '#C0C0C0' : '#19989B',
                        }}
                      />
                    );
                  })}
                </RadioGroup>
              </FormControl>
              <Typography
                component="div"
                variant="body2"
                className={clsx(classes.radioElementsText, classes.radioElementRightText)}>
                {addNameToText(props.right[index1], props.name)}
              </Typography>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default RadioGroupMultipleFormElement;
