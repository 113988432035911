import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";

import SurveyInputs from "..";

const useStyles = makeStyles((theme) => ({
  mainContainer: ({ isSummary }) => ({
    margin: "20px auto",
    alignItems: "center",
    width: "100%",
    maxWidth: "650px",
  }),
  heading: {
    color: theme.palette.text.primary,
    fontWeight: 800,
    fontSize: "17px",
  },
  subHeading: {
    color: theme.palette.text.primary,
    fontWeight: 300,
    fontSize: "17px",
  },
}));

const Wrapper = (props) => {
  const classes = useStyles({ isSummary: props.isSummary });

  return (
    <Box className={classes.mainContainer}>
      <Box marginBottom={4}>
        <Typography component="div" variant="h3" className={classes.heading}>
          {props.heading}
        </Typography>
        {props.subHeading ? (
          <Typography
            component="div"
            variant="h4"
            className={classes.subHeading}
          >
            {props.subHeading}
          </Typography>
        ) : null}
      </Box>
      <Box>
        {props.fields.map((field, index) => (
          <SurveyInputs
            key={index}
            question={field}
            surveyAnswersState={props.surveyAnswersState}
            handleSurveyAnswers={props.handleSurveyAnswers}
            isInGroup={true}
            isSummary={false}
          />
        ))}
      </Box>
    </Box>
  );
};

export default Wrapper;
