import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import {
  Box,
  Typography,
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
} from "@material-ui/core";
import { Create } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  mainContainer: ({ isSummary }) => ({
    margin: "20px auto",
    alignItems: "center",
    width: "100%",
    maxWidth: "650px",
  }),
  heading: {
    color: theme.palette.text.primary,
    fontWeight: 800,
    fontSize: "17px",
  },
  subHeading: {
    color: theme.palette.text.primary,
    fontWeight: 300,
    fontSize: "17px",
  },
  icon: {
    color: theme.palette.text.secondary,
  },
}));

const TextHeadingSubheading = (props) => {
  const [textValue, setTextValue] = useState("");

  const classes = useStyles({ isSummary: props.isSummary });

  useEffect(() => {
    setTextValue("");
  }, [props.id]);

  const handleChange = (event) => {
    setTextValue(event.target.value);
    props.handleSurveyAnswers(event.target.value, props.id);
  };

  return (
    <Box className={classes.mainContainer}>
      <Box>
        <Typography component="div" variant="h3" className={classes.heading}>
          {props.heading}
        </Typography>
        {props.subHeading ? (
          <Typography
            component="div"
            variant="h4"
            className={classes.subHeading}
          >
            {props.subHeading}
          </Typography>
        ) : null}
      </Box>
      <Box>
        <FormControl variant="filled" fullWidth>
          <InputLabel htmlFor="filled-adornment-password"></InputLabel>
          <Input
            id="filled-adornment-password"
            type="text"
            value={props.value ? props.value : textValue}
            onChange={handleChange}
            endAdornment={
              <InputAdornment position="end">
                <Create className={classes.icon} fontSize="small" />
              </InputAdornment>
            }
          />
        </FormControl>
      </Box>
    </Box>
  );
};

export default TextHeadingSubheading;
