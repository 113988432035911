export const getTotalImpactData = (arrowValue) => {
  return {
    series: [
      {
        name: 'Speed',
        data: [arrowValue],
      },
    ],
  };
};

export const getTotalImpactOptions = (labelValue, viewportWidth) => {
  const width = viewportWidth >= 600 ? 500 : 400;
  const height = viewportWidth >= 600 ? 450 : 360;

  return {
    chart: { width, height },
    exportMenu: {
      visible: false,
    },
    theme: {
      exportMenu: {
        visible: false,
      },
      circularAxis: {
        title: { fontWeight: 400, fontSize: 22, color: '#000' },
        label: {
          fontSize: 15,
        },
      },
      tooltip: {
        display: 'none',
      },
      series: {
        clockHand: {
          color: '#ccc',
        },
        pin: {
          radius: 16,
          color: '#585858',
          borderWidth: 0,
        },
        hover: {
          pin: {
            radius: 16,
            color: '#585858',
            borderWidth: 0,
          },
        },
      },
      plot: {
        bands: { barWidth: 40 },
      },
    },
    series: {
      angleRange: {
        start: 250,
        end: 110,
      },
    },
    circularAxis: {
      scale: {
        min: -100,
        max: 100,
      },

      title: { text: `${labelValue}%`, offsetY: 80 },
    },
    plot: {
      bands: [
        { range: [0, 30], color: '#DF3371' },
        { range: [30, 70], color: '#979797' },
        { range: [70, 100], color: '#23A9A9' },
      ],
    },
  };
};

export const getProductPositioningImpactData = (arrowValue) => {
  return {
    series: [
      {
        name: 'Speed',
        data: [arrowValue],
      },
    ],
  };
};

export const getProductPositioningImpactOptions = (labelValue) => {
  return {
    chart: { width: 400, height: 400 },
    exportMenu: {
      visible: false,
    },
    theme: {
      exportMenu: {
        visible: false,
      },
      circularAxis: {
        title: { fontWeight: 400, fontSize: 18, color: '#000' },
        label: {
          fontSize: 15,
        },
      },
      tooltip: {
        display: 'none',
      },
      series: {
        clockHand: {
          color: '#ccc',
        },
        pin: {
          radius: 16,
          color: '#585858',
          borderWidth: 0,
        },
        hover: {
          pin: {
            radius: 16,
            color: '#585858',
            borderWidth: 0,
          },
        },
      },
      plot: {
        bands: { barWidth: 40 },
      },
    },
    series: {
      angleRange: {
        start: 250,
        end: 110,
      },
    },
    circularAxis: {
      scale: {
        min: -100,
        max: 100,
      },

      title: { text: `${labelValue}%`, offsetY: 65 },
    },
    plot: {
      bands: [
        { range: [0, 30], color: '#DF3371' },
        { range: [30, 70], color: '#979797' },
        { range: [70, 100], color: '#23A9A9' },
      ],
    },
  };
};

export const getSocialPositioningImpactData = (arrowValue) => {
  return {
    series: [
      {
        name: 'Speed',
        data: [arrowValue],
      },
    ],
  };
};

export const getSocialPositioningImpactOptions = (labelValue) => {
  return {
    chart: { width: 400, height: 400 },
    exportMenu: {
      visible: false,
    },
    theme: {
      exportMenu: {
        visible: false,
      },
      circularAxis: {
        title: { fontWeight: 400, fontSize: 18, color: '#000' },
        label: {
          fontSize: 15,
        },
      },
      tooltip: {
        display: 'none',
      },
      series: {
        clockHand: {
          color: '#ccc',
        },
        pin: {
          radius: 16,
          color: '#585858',
          borderWidth: 0,
        },
        hover: {
          pin: {
            radius: 16,
            color: '#585858',
            borderWidth: 0,
          },
        },
      },
      plot: {
        bands: { barWidth: 40 },
      },
    },
    series: {
      angleRange: {
        start: 250,
        end: 110,
      },
    },
    circularAxis: {
      scale: {
        min: -100,
        max: 100,
      },

      title: { text: `${labelValue}%`, offsetY: 65 },
    },
    plot: {
      bands: [
        { range: [0, 30], color: '#DF3371' },
        { range: [30, 70], color: '#979797' },
        { range: [70, 100], color: '#23A9A9' },
      ],
    },
  };
};

export const getCausesMatchImpactData = (arrowValue) => {
  return {
    series: [
      {
        name: 'Speed',
        data: [arrowValue],
      },
    ],
  };
};

export const getCausesMatchImpactOptions = (labelValue) => {
  return {
    chart: { width: 400, height: 400 },
    exportMenu: {
      visible: false,
    },
    theme: {
      exportMenu: {
        visible: false,
      },
      circularAxis: {
        title: { fontWeight: 400, fontSize: 18, color: '#000' },
        label: {
          fontSize: 15,
        },
      },
      tooltip: {
        display: 'none',
      },
      series: {
        clockHand: {
          color: '#ccc',
        },
        pin: {
          radius: 16,
          color: '#585858',
          borderWidth: 0,
        },
        hover: {
          pin: {
            radius: 16,
            color: '#585858',
            borderWidth: 0,
          },
        },
      },
      plot: {
        bands: { barWidth: 40 },
      },
    },
    series: {
      angleRange: {
        start: 250,
        end: 110,
      },
    },
    circularAxis: {
      scale: {
        min: -100,
        max: 100,
      },

      title: { text: `${labelValue}%`, offsetY: 65 },
    },
    plot: {
      bands: [
        { range: [0, 30], color: '#DF3371' },
        { range: [30, 70], color: '#979797' },
        { range: [70, 100], color: '#23A9A9' },
      ],
    },
  };
};
