import React from 'react';

import { Box, Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  flexWrapper: {
    '@media (max-width: 500px)': {
      flexWrap: 'wrap',
      gap: '48px 0px',
      justifyContent: 'space-between',
    },
  },

  leftText: {
    '@media (max-width: 500px)': {
      order: 1,
      width: '48%',
      fontSize: '12px !important',
      margin: '0px !important',
      maxWidth: 'unset',
    },
  },
  rightText: {
    '@media (max-width: 500px)': {
      width: '48%',
      order: 2,
      fontSize: '12px !important',
      textAlign: 'right',
      margin: '0px !important',
      maxWidth: 'unset',
    },
  },

  chart: {
    '@media (max-width: 500px)': {
      order: 3,
      margin: '0 auto',
      width: '100%',
    },
  },
}));

const RangeChart = ({
  mainLineColor,
  middleLineColor,
  leftText,
  rightText,
  valueColor,
  heading,
  headingColor,
  value1,
  value2,
  value3,
  body,
  type,
}) => {
  const classes = useStyles();

  const lineWidth = 250;
  const lineHeight = 5;
  const arrowSize = 10;

  const calculatePosition = value => {
    return lineWidth / 2 + (value / 100) * (lineWidth / 2) - 8.5;
  };

  const renderArrow = (value, color, direction) => {
    const arrowDirection =
      direction === 'up'
        ? { borderTop: `${arrowSize + 4}px solid ${color}` }
        : { borderBottom: `${arrowSize + 4}px solid ${color}` };
    const arrowStyle = {
      width: 0,
      height: 0,
      borderLeft: `${arrowSize}px solid transparent`,
      borderRight: `${arrowSize}px solid transparent`,
      filter: 'drop-shadow(1px 1px 1.5px rgba(0, 0, 0, 0.45))',
      ...arrowDirection,
    };

    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        position="absolute"
        zIndex={10}
        style={{
          transform: `translateX(${calculatePosition(value)}px)`,
          top: direction === 'up' ? -arrowSize - 7 : lineHeight + 3,
        }}>
        <p
          style={{
            position: 'absolute',
            top: direction === 'up' ? -arrowSize - 12 : lineHeight + 9,
            color: valueColor,
          }}>
          {Math.abs(value)}%
        </p>
        <Box style={arrowStyle} />
      </Box>
    );
  };

  const renderSquare = (value, color) => {
    const squareSize = 15;
    const squareStyle = {
      width: squareSize,
      height: squareSize,
      position: 'absolute',
      top: -(squareSize / 2),
      backgroundColor: color,
      transform: `translateX(${calculatePosition(value)}px) rotate(45deg)`,
      zIndex: 10,
      filter: 'drop-shadow(1px 1px 1.5px rgba(0, 0, 0, 0.45))',
    };

    return <div style={squareStyle} />;
  };

  const mainLineStyle = {
    width: lineWidth,
    minWidth: lineWidth,
    maxWidth: lineWidth,
    height: lineHeight,
    minHeight: lineHeight,
    maxHeight: lineHeight,
    backgroundColor: mainLineColor,
    position: 'relative',
    borderRadius: '5px',
  };

  const middleLineStyle = {
    width: 2,
    height: lineHeight * 4,
    backgroundColor: middleLineColor,
    position: 'absolute',
    left: '50%',
    transform: 'translateY(-38%)',
    borderRadius: '2px',
  };

  return (
    <Box margin="10px 0">
      {heading && (
        <Typography
          component="div"
          variant="h2"
          style={{
            marginBottom: '40px',
            fontSize: '25px',
            color: headingColor,
            textAlign: 'left',
          }}>
          {heading}
        </Typography>
      )}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom="40px"
        className={classes.flexWrapper}>
        <p
          style={{
            fontWeight: 300,
            fontSize: '15px',
            color: leftText.color,
            maxWidth: '120px',
            marginRight: '10px',
            lineBreak: 'auto',
          }}
          className={classes.leftText}>
          {leftText.text}
        </p>
        <Box className={classes.chart} style={mainLineStyle}>
          {typeof value1 !== 'undefined' ? renderArrow(value1, type == 'brand' ? '#19989B' : '#AB8628', 'up') : null}
          {typeof value2 !== 'undefined' ? renderArrow(value2, '#CD235C', 'down') : null}
          {typeof value3 !== 'undefined' && typeof value3.value !== 'undefined'
            ? renderSquare(value3.value, value3.isNull ? 'rgba(140, 140, 140, 0.4)' : '#D9B14A')
            : null}
          <Box style={middleLineStyle} />
        </Box>
        <p
          style={{
            fontWeight: 300,
            fontSize: '15px',
            color: rightText.color,
            maxWidth: '120px',
            marginLeft: '10px',
            lineBreak: 'auto',
          }}
          className={classes.rightText}>
          {rightText.text}
        </p>
      </Box>
      <Box>
        <p
          style={{
            color: body.color,
            fontWeight: 300,
            fontSize: '14px',
            textAlign: 'left',
          }}>
          {body.text}
        </p>
      </Box>
    </Box>
  );
};

export default RangeChart;
