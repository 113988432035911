import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';
import { CSVLink } from "react-csv";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Box } from "@material-ui/core";
import { makeURL } from "../../helperFunctions";
import { sortExcelData } from "./sortExcelExportDataHandler";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    height: "fit-content",
    marginRight: "15px",
  },
  exportContentContainer: {
    display: "flex",
    padding: "7px 15px",
    borderRadius: "5px",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#19989B",
    "&:hover": {
      backgroundColor: "#19878a",
    },

    "@media (max-width: 768px)": {
      justifyContent: "center",
      minWidth: "unset",
      marginRight: "6px",
    },
  },
  exportImage: {
    width: "20px",
    marginRight: "15px",

    "@media (max-width: 768px)": {
      marginRight: "0",
    },
  },
  exportText: {
    color: "white",
    fontSize: "12px",
    margin: 0,
    fontWeight: "bold",
    "@media (max-width: 768px)": {
      display: "none",
    },
  },
}));

const CSVExportButton = ({ csvData, containerStyle }) => {
  const appData = useSelector(({ data }) => data);
  const location = useLocation();
  const classes = useStyles();
  
  const recodedData = sortExcelData(location.pathname, csvData, appData.brandPersonas);

  return (
    <CSVLink
      data={recodedData}
      className={classes.mainContainer}
      style={{ ...containerStyle }}
    >
      <Box className={classes.exportContentContainer}>
        <img
          alt="export-as-csv"
          src={makeURL("/images/csv-export-button.png")}
          className={classes.exportImage}
        />
        <Typography
          component="div"
          variant="button"
          className={classes.exportText}
        >
          EXPORT TO EXCEL
        </Typography>
      </Box>
    </CSVLink>
  );
};

export default CSVExportButton;
