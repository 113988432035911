import React from 'react';

import { Hidden } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';

import CmtVerticalLayout from '../layoutComponents';
import CmtSidebar from '../layoutComponents/Sidebar';
import CmtContent from '../layoutComponents/Content';
import CmtHeader from '../layoutComponents/Header';
import SidebarToggleHandler from '../layoutComponents/SidebarToggleHandler';
import SidebarHeader from '../layoutComponents/SidebarHeader';
import SidebarContent from '../layoutComponents//sidebarContent';

import { HEADER_TYPE, SIDEBAR_TYPE } from '../../../theme/ThemeOptions';
import defaultContext from '../../../contexts/AppContext/defaultContext';
import ContentLoader from '../../../../myComponents/loaders/contentLoader';
import UserDropdown from '../../../../myComponents/userDropdown';

const useStyles = makeStyles(theme => ({
  header: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    '& .Cmt-toggle-menu': {
      color: theme.palette.text.primary,
      marginLeft: 15,
    },
  },
  displayFlex: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

const layoutOptions = {
  headerType: HEADER_TYPE.STATIC,
  sidebarType: SIDEBAR_TYPE.MINI,
  isSidebarFixed: defaultContext.isSidebarFixed,
  isSidebarOpen: false,
  miniSidebarWidth: 80,
  layoutStyle: defaultContext.layoutType,
};
const MinimalLayout = ({ className, children }) => {
  const classes = useStyles();

  return (
    <CmtVerticalLayout
      layoutOptions={layoutOptions}
      className={className}
      header={
        <CmtHeader className={classes.header}>
          <Hidden lgUp>
            <div className={classes.displayFlex}>
              <SidebarToggleHandler edge="start" color="inherit" aria-label="menu" />
              <UserDropdown />
            </div>
          </Hidden>
        </CmtHeader>
      }
      sidebar={
        <CmtSidebar>
          <SidebarHeader />
          <SidebarContent />
        </CmtSidebar>
      }>
      <CmtContent>
        {children}
        <ContentLoader />
      </CmtContent>
    </CmtVerticalLayout>
  );
};

export default MinimalLayout;
