import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import {
  Box,
  Typography,
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
  TextField,
} from '@material-ui/core';
import { Create } from '@material-ui/icons';
import { addNameToText } from '../helperFunctions';

const useStyles = makeStyles((theme) => ({
  mainContainer: ({ inputUnderLabel, isSummaryAndName, isSocial, isDependancy }) => ({
    margin: isSummaryAndName ? '20px auto 30px auto' : '20px auto',
    display: 'flex',
    alignItems: 'center',
    flexDirection: isDependancy ? 'column' : inputUnderLabel ? 'column' : 'row',
    width: inputUnderLabel ? 'unset' : isSocial ? '650px' : '600px',
    justifyContent: inputUnderLabel ? 'center' : 'space-between',
    '@media (max-width: 900px)': {
      width: '100%',
      flexDirection: 'column',
      marginInline: 0,
    },
    '@media (max-width: 768px)': {
      alignItems: 'flex-start',
    },
  }),
  text: {
    color: theme.palette.text.primary,
    fontWeight: 300,
    fontSize: '17px',
    '@media (max-width:1025px)': {
      fontSize: '16px',
    },
        '@media (max-width: 600px)': {
      fontSize: '14px',
    },
  },
  textContainer: ({ inputUnderLabel }) => ({
    margin: inputUnderLabel ? '0 0 12px 0' : 'auto 50px 0 0',
  }),
  textFieldName: {
    width: '300px',
    '@media (max-width:1025px)': {
      width: '150px',
    },
    '@media (max-width: 768px)': {
      width: '100%',
    },
  },
  textFieldNamePlaceholder: {
    textAlign: 'center',
    '&::placeholder': {
      textAlign: 'center',
    },
  },
  formControl: {
    width: '215px',
    '@media (max-width:1025px)': {
      fontSize: '14px',
    },
    '@media (max-width:768px)': {
      width: '100%',
    },
  },
  input: {
    '@media (max-width: 768px)': {
      width: '100%',
    },
  },
  inputWrapper: {
    '@media (max-width: 768px)': {
      width: '100%',
    },
  },
  icon: {
    color: theme.palette.text.secondary,
  },
}));

const TextFormElement = (props) => {
  const [textValue, setTextValue] = useState('');

  const inputUnderLabel = !props.isSummary && props.id === 'Name';
  const isSummaryAndName = props.isSummary && props.id === 'Name';
  const isSocial = props.id.indexOf('Post_') !== -1;
  const isDependancy = props?.isDependent;
  const classes = useStyles({ inputUnderLabel, isSummaryAndName, isSocial, isDependancy });

  useEffect(() => {
    setTextValue('');
  }, [props.id]);

  const handleChange = (event) => {
    setTextValue(event.target.value);
    props.handleSurveyAnswers(event.target.value, props.id);
  };

  return (
    <Box className={classes.mainContainer}>
      <Box className={classes.textContainer}>
        <Typography component='div' variant='h3' className={classes.text}>
          {addNameToText(props.text, props.name)}
        </Typography>
      </Box>
      <Box className={classes.inputWrapper}>
        {inputUnderLabel ? (
          <TextField
            InputProps={{
              classes: { input: classes.textFieldNamePlaceholder },
            }}
            className={classes.textFieldName}
            value={props.value ? props.value : textValue}
            onChange={handleChange}
            placeholder='ENTER NAME'
            variant='standard'
          />
        ) : (
          <FormControl variant='filled' className={classes.formControl}>
            <InputLabel htmlFor='filled-adornment-password'></InputLabel>
            <Input
              id='filled-adornment-password'
              type='text'
              value={props.value ? props.value : textValue}
              onChange={handleChange}
              className={classes.input}
              endAdornment={
                <InputAdornment position='end'>
                  <Create className={classes.icon} fontSize='small' />
                </InputAdornment>
              }
            />
          </FormControl>
        )}
      </Box>
    </Box>
  );
};

export default TextFormElement;
