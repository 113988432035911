import React, { useLayoutEffect, useRef } from 'react';
import Chart from '@toast-ui/chart';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button } from '@material-ui/core';
import { getSocialMediaPlatform } from '../../../../helperFunctions/getSurveysWithExtractedFields';
import { convertRange, formatResponse } from '../../../../helperFunctions';
import {
  SocialIssuesSubjects,
  generateConsumerConsistencyData,
  generateProductBenefitsData,
  generateSocialPositioningData,
  getPostCategory,
  isValidUrl,
} from './helpers';
import DashDetailsWrapper from '../../../../myComponents/dashDetailsWrapper';
import ConsumerConsistencyCard from '../../../../myComponents/chart/ConsumerConsistencyCard/ConsumerConsistencyCard';
import RangeChart from '../../../../myComponents/chart/RangeChart';
import PublishContentCard from '../../../../myComponents/chart/PublishContentCard/PublishContentCard';
import Watermark from '../../../../myComponents/watermark';
import CubeLoader from '../../../../myComponents/loaders/cubeLoader';
import { AISocialMediaStrategyGenerator } from '../../../../API/agent';
import {
  getCausesMatchImpactData,
  getCausesMatchImpactOptions,
  getProductPositioningImpactData,
  getProductPositioningImpactOptions,
  getSocialPositioningImpactData,
  getSocialPositioningImpactOptions,
  getTotalImpactData,
  getTotalImpactOptions,
} from './gaugeCharts';
import useViewportWidth from '../../../../myHooks/useViewportWidth';

const useStyles = makeStyles(theme => ({
  textStyle: {
    fontSize: '18px',
    fontWeight: 'bold',
    color: theme.palette.text.primary,
    textAlign: 'center',
    marginTop: '10px',
  },
  productBenefitsWrapper: {
    '@media (max-width: 768px)': {
      width: '100%',
    },
  },
  rangeChartContainer: {
    marginTop: '50px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    width: '100%',

    '@media (max-width: 1260px)': {
      justifyContent: 'center',
    },
  },
  rangeChartWrapper: {
    maxWidth: '500px',
    margin: '0px 60px 100px 0px',
    width: '100%',
    '@media (max-width: 1240px)': {
      maxWidth: '600px',
    },
    '@media (max-width: 768px)': {
      maxWidth: '100%',
      width: '100%',
      margin: '0px 0px 60px 0px',
      padding: 0,
    },
  },
  consumerConsistencyContent: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: '40px',
    marginBottom: '30px',
    flexWrap: 'wrap',
    width: '100%',
    gap: '48px',

    '@media (max-width: 768px)': {
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
    },
  },
  positioningHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: '#FFF',
    borderBottom: '1px solid rgba(217,217,217, 0.5)',
    width: '100%',
    paddingBottom: '20px',
    fontWeight: 400,
    gap: '0px',
    '@media (max-width: 1366px)': {
      display: 'none',
    },
  },
  positioningHeaderTextElements: {
    width: '25%',
    fontSize: '22px',
    marginLeft: '-10px',
  },
  socialPositioningContentContainer: {
    width: '100%',
    '@media (max-width: 1366px)': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  socialPositioningContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: '#FFF',
    paddingBottom: '20px',
  },
  socialPositioningChart: {
    width: '34%',
    maxWidth: '500px',
    '@media (max-width: 1366px)': {
      width: '100%',
    },
  },

  consistency1Text: {
    width: '25%',
    '@media (max-width: 1366px)': {
      display: 'none',
    },
  },

  consistency2Text: {
    width: '25%',
    '@media (max-width: 1366px)': {
      display: 'none',
    },
  },

  chartBodyText: {
    maxWidth: '500px',
    '@media (max-width: 1366px)': {
      width: '100% !important',
    },
  },

  consistencyTextSm: {
    display: 'none',

    fontSize: '17px',
    marginTop: '32px',

    '@media (max-width: 1366px)': {
      display: 'block',
    },
  },

  consistencyName: {
    color: '#fff',
    display: 'block',
  },

  positioiningLegends: {
    width: '100%',
    display: 'flex',
    color: '#fff',
    padding: '24px 0px',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: '20px',
    marginTop: '-80px',

    '@media (max-width: 1366px)': {
      marginTop: '20px',
      justifyContent: 'center',
    },

    '@media (max-width: 700px)': {
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      marginLeft: '20px',
    },
  },
  positioningLegend: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '8px',

    '& div': {
      width: '16px',
      height: '16px',
      borderRadius: '20px',
      boxShadow: '0px 4px 4px rgba(0,0,0,0.2)',
    },
  },
  productBenefitsLegend: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '8px',
    color: 'black',

    '& div': {
      width: '16px',
      height: '16px',
      borderRadius: '20px',
      boxShadow: '0px 4px 4px rgba(0,0,0,0.2)',
    },
  },
  subtitle: {
    width: '60%',
    color: '#A5A5A5',
    fontWeight: '17px',
    fontWeight: 300,
    lineHeight: '24px',
  },
  productBenefitsContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  consistencyBenefitsText: {
    color: '#858585',
    fontWeight: '300',
    display: 'block',
    marginTop: '8px',
    marginBottom: '8px',
  },
  contentImpact: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    gap: '32px',

    '@media (max-width: 1200px)': {
      flexWrap: 'wrap',
    },

    '@media (max-width: 768px)': {
      gap: '72px',
      justifyContent: 'center',
      flexWrap: 'wrap',
    },
  },
  contentImpactResponsive: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    gap: '32px',

    '@media (max-width: 1375px)': {
      flexWrap: 'wrap',
      justifyContent: 'center',
    },

    '@media (max-width: 768px)': {
      gap: '72px',
      justifyContent: 'center',
      flexWrap: 'wrap',

      '& canvas': {
        marginLeft: '-40px',
      },
    },
  },
  contentImpactParagraph: {
    maxWidth: '430px',
  },
  contentImpactItemResponsive: {
    '& h3': {
      fontSize: '18px',
      fontWeight: 600,
    },
    '& h5': {
      marginTop: '16px',
      fontSize: '20px',
      width: '90%',
      fontWeight: 300,
      marginBottom: '8px',
      color: '#515151',
    },
    '& p': {
      fontSize: '14px',
      color: '#515151',
    },
  },
  contentImpactItem: {
    marginBottom: '30px',
    '& h3': {
      fontSize: '18px',
      fontWeight: 600,
    },
    '& h5': {
      marginTop: '16px',
      fontSize: '20px',
      width: '90%',
      fontWeight: 300,
      marginBottom: '8px',
      color: '#515151',
    },
    '& p': {
      fontSize: '14px',
      color: '#515151',
    },
    '@media (max-width: 600px)': {
      marginLeft: '10px',
    },
  },
  contentImpactItemLast: {
    marginBottom: '30px',
    '& h3': {
      fontSize: '18px',
      fontWeight: 600,
    },
    '& h5': {
      marginTop: '16px',
      fontSize: '20px',
      width: '90%',
      fontWeight: 300,
      marginBottom: '8px',
      color: '#515151',
    },
    '& p': {
      fontSize: '14px',
      color: '#515151',
    },
    '@media (max-width: 991px)': {
      marginLeft: '60px',
    },
    '@media (max-width: 600px)': {
      marginLeft: '10px',
    },
  },
  gaugeGraphs: {},
  heroSection: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    gap: '40px',

    '@media (max-width:1250px)': {
      flexDirection: 'column',
    },
  },
  infoSection: {
    width: 'calc(35% - 20px)',

    '@media (max-width:1250px)': {
      width: '100%',
    },
  },
  infoSectionHeader: {
    marginLeft: '150px',
    marginBottom: '24px',

    '@media (max-width: 1250px)': {
      marginLeft: '0px',
    },

    '& span': {
      fontSize: '13px',
      color: '#B3B3B3',
      display: 'block',
    },
    '& h4': {
      fontWeight: '700',
      fontSize: '18px',
      textTransform: 'uppercase',
    },
  },
  infoSectionTable: {
    width: '100%',
  },
  infoSectionTableItem: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    borderBottom: '1px solid rgba(0,0,0,0.05)',
    padding: '4px 0px',
  },
  infoSectionTableItemPostText: {
    width: '100%',
    borderBottom: '1px solid rgba(0,0,0,0.05)',
    padding: '4px 0px',
  },
  tableKey: {
    minWidth: '150px',
  },
  statSection: {
    width: 'calc(65% - 20px)',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    gap: '48px',

    '@media (max-width:1250px)': {
      width: '100%',
    },

    '@media (max-width: 880px)': {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  totalImpact: {
    maxWidth: 'calc(60% - 24px)',

    '& h4': {
      fontSize: '20px',
      fontWeight: 700,
      marginBottom: '12px',
      marginTop: '16px',
    },
    '& p': {
      fontSize: '15px',
      color: '#515151',
      lineHeight: '24px',
      marginBottom: '16px',
    },

    '@media (max-width: 880px)': {
      alignSelf: 'center',
      width: '100%',
      maxWidth: 'unset',
    },

    '@media (max-width: 400px)': {
      '& canvas': {
        marginLeft: '-20px',
      },
    },
  },
  totalImpactGraphContainer: {
    marginLeft: '-30px',
  },
  postEvaluation: {
    width: 'calc(40% - 24px)',

    '& h4': {
      fontSize: '20px',
      fontWeight: 700,
      marginBottom: '12px',
      marginTop: '16px',
    },
    '& p': {
      fontSize: '15px',
      color: '#515151',
      lineHeight: '24px',
      marginBottom: '16px',
    },

    '@media (max-width: 880px)': {
      width: '100%',
    },
  },
  AiTextWrapper: {
    position: 'relative',
    width: '80%',
    margin: '0 auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '20px 0 60px 0',

    '@media (max-width: 768px)': {
      width: '90%',
    },

    '& p': {
      color: '#E8E8E8',
    },
  },
  regenerateButton: {
    margin: '20px 0',
    display: 'flex',
    justifyContent: 'flex-end',
    '& button': {
      color: 'white',
      backgroundColor: '#19989B',
      padding: '8px 20px',
      '&:hover': {
        backgroundColor: '#19878a',
      },
    },
  },
  AiLoader: {
    margin: '60px 0',
  },
  iFrameWrapper: {
    width: '100%',
    marginTop: '50px',
  },
  productBenefitsResponsive: {
    marginTop: '50px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    width: '100%',

    '@media (max-width: 1260px)': {
      justifyContent: 'center',
    },
  },
}));

const ChartCalculations = ({ type, data, persona, socialPost, AIStrategyGenerator, setAIStrategyGenerator }) => {
  const classes = useStyles();
  const totalMatchChartRef = useRef(null);
  const productPositioningMatchChartRef = useRef(null);
  const socialPositioningMatchChartRef = useRef(null);
  const causesMatchChartRef = useRef(null);
  const issuesImpact = generateConsumerConsistencyData(SocialIssuesSubjects, data.social_post_data.IssuesImpact);

  const viewportWidth = useViewportWidth();

  // Total impact gauge chart
  useLayoutEffect(() => {
    const totalImpactGaugeChartEl = document.querySelector('#totalImpactGaugeChart');

    if (!data) return;

    let totalMatchValueLabel = data?.social_post_data?.TotalImpact?.value;
    totalMatchValueLabel = Math.round(Number(totalMatchValueLabel) * 100);
    let totalMatchRawValue = convertRange(totalMatchValueLabel, [-100, 100], [0, 100]);

    const totalImpactData = getTotalImpactData(totalMatchRawValue);

    const totalImpactOptions = getTotalImpactOptions(totalMatchValueLabel, viewportWidth);

    if (!totalMatchChartRef.current) {
      totalMatchChartRef.current = Chart.gaugeChart({
        el: totalImpactGaugeChartEl,
        data: totalImpactData,
        options: totalImpactOptions,
      });
    } else {
      totalMatchChartRef.current.setData(totalImpactData);
      totalMatchChartRef.current.setOptions(totalImpactOptions);
    }
  }, [data]);

  // productPositioningGaugeChart
  useLayoutEffect(() => {
    const productPositioningGaugeChartEl = document.querySelector('#productPositioningGaugeChart');

    if (!data) return;

    let productMatchValueLabel = data?.social_post_data?.ProductImpact?.TotalProductImpact?.value;
    productMatchValueLabel = Math.round(Number(productMatchValueLabel) * 100);
    let productMatchRawValue = convertRange(productMatchValueLabel, [-100, 100], [0, 100]);

    const productPositioningImpactData = getProductPositioningImpactData(productMatchRawValue);

    const productPositioningImpactOptions = getProductPositioningImpactOptions(productMatchValueLabel);

    if (!productPositioningMatchChartRef.current) {
      productPositioningMatchChartRef.current = Chart.gaugeChart({
        el: productPositioningGaugeChartEl,
        data: productPositioningImpactData,
        options: productPositioningImpactOptions,
      });
    } else {
      productPositioningMatchChartRef.current.setData(productPositioningImpactData);
      productPositioningMatchChartRef.current.setOptions(productPositioningImpactOptions);
    }
  }, [data]);

  // socialPositioningImpactData
  useLayoutEffect(() => {
    const socialPositioningGaugeChartEl = document.querySelector('#socialPositioningGaugeChart');

    if (!data) return;

    let socialPositioningMatchValueLabel =
      data?.social_post_data?.SocialPositioningImpact?.TotalSocialPositioningImpact?.value;
    socialPositioningMatchValueLabel = Math.round(Number(socialPositioningMatchValueLabel) * 100);
    let ocialPositioningMatchRawValue = convertRange(socialPositioningMatchValueLabel, [-100, 100], [0, 100]);

    const socialPositioningImpactData = getSocialPositioningImpactData(ocialPositioningMatchRawValue);

    const socialPositioningImpactOptions = getSocialPositioningImpactOptions(socialPositioningMatchValueLabel);

    if (!socialPositioningMatchChartRef.current) {
      socialPositioningMatchChartRef.current = Chart.gaugeChart({
        el: socialPositioningGaugeChartEl,
        data: socialPositioningImpactData,
        options: socialPositioningImpactOptions,
      });
    } else {
      socialPositioningMatchChartRef.current.setData(socialPositioningImpactData);
      socialPositioningMatchChartRef.current.setOptions(socialPositioningImpactOptions);
    }
  }, [data]);

  ///
  useLayoutEffect(() => {
    const causesMatchImpactGaugeChartEl = document.querySelector('#causesMatchImpactGaugeChart');

    if (!data) return;

    let issuesMatchValueLabel = data?.social_post_data?.IssuesImpact?.TotalIssuesImpact?.value;
    issuesMatchValueLabel = Math.round(Number(issuesMatchValueLabel) * 100);
    let issuesMatchRawValue = convertRange(issuesMatchValueLabel, [-100, 100], [0, 100]);

    const causesMatchImpactData = getCausesMatchImpactData(issuesMatchRawValue);

    const causesMatchImpactOptions = getCausesMatchImpactOptions(issuesMatchValueLabel);

    if (!causesMatchChartRef.current) {
      causesMatchChartRef.current = Chart.gaugeChart({
        el: causesMatchImpactGaugeChartEl,
        data: causesMatchImpactData,
        options: causesMatchImpactOptions,
      });
    } else {
      causesMatchChartRef.current.setData(causesMatchImpactData);
      causesMatchChartRef.current.setOptions(causesMatchImpactOptions);
    }
  }, [data]);

  const consumerPositioningImpactData = data.personal_average.SocialValues;
  const socialPositioningImpact = data.social_post_data.SocialPositioningImpact;
  const personaPositioningImpactData = {
    IdeologyImpact: persona.Brand_Ideology,
    InternalExternalImpact: persona.Brand_InterExter,
    LocalGlobalImpact: persona.Brand_LocalGlobal,
  };

  const socialPositioningData = generateSocialPositioningData(
    consumerPositioningImpactData,
    socialPositioningImpact,
    personaPositioningImpactData,
    socialPost,
  );

  const consumerProductBenefits = data.personal_average.PersonalProduct;
  const socialProductBenefits = data.social_post_data.ProductImpact;
  let personaProductBenefits = {};

  for (let i = 0; i < 10; i++) {
    let count = i + 1;

    let name = 'Brand_Product_' + count;

    personaProductBenefits = {
      ...personaProductBenefits,
      [name]: persona['Brand_Product_' + count],
    };
  }

  const productBenefitsData = generateProductBenefitsData(
    consumerProductBenefits,
    socialProductBenefits,
    personaProductBenefits,
    socialPost,
  );

  const socialPositionImpactData = data.social_post_data.SocialPositioningImpact.TotalSocialPositioningImpact;
  const socialCausesImpactData = data.social_post_data.IssuesImpact.TotalIssuesImpact;
  const productPositionImpactData = data.social_post_data.ProductImpact.TotalProductImpact;

  const getConsistencyTextColor = text => {
    let color = '#87edef';

    if (text.toLowerCase() == 'not applicable') {
      color = '#979797';
    } else if (text.toLowerCase().startsWith('inconsistent')) {
      color = '#ed8fb3';
    }

    return color;
  };

  let postText = '';

  socialPost.Post_Text.forEach(text => {
    if (text.isSelected) {
      postText = text.value;
    }
  });

  const getProductBenefitsSpanColor = text => {
    let color = '#19989B';

    if (text.toLowerCase() == 'not applicable') {
      color = '#979797';
    } else if (text.toLowerCase().startsWith('inconsistent')) {
      color = '#CE5378';
    }

    return color;
  };

  const aiText = formatResponse(AIStrategyGenerator.text);

  const handleRegeneration = () => {
    setAIStrategyGenerator({ loading: true, text: '' });
    AISocialMediaStrategyGenerator(
      socialPost.Post_Title,
      persona.Name,
      getSocialMediaPlatform({ Post_Social_Media: socialPost.Post_Social_Media }),
      socialPost.Post_Text.find(({ isSelected }) => isSelected),
      socialPost.id,
      persona.id,
      socialPost.marketingStrategies,
    )
      .then(res => {
        setAIStrategyGenerator({ loading: false, text: res });
      })
      .catch(err => {
        setAIStrategyGenerator({ loading: false, text: '' });
        showToastWarn();
      });
  };
  return (
    <Box>
      <DashDetailsWrapper
        backgroundColor="white"
        expandColor="black"
        heading1={{
          text: 'Social Media Content Analysis',
          color: '#19989B',
        }}>
        <div className={classes.heroSection} style={{ marginBottom: '80px' }}>
          <div className={classes.infoSection}>
            <div className={classes.infoSectionHeader}>
              <span>current post</span>
              <h4>{socialPost.Post_Title}</h4>
            </div>
            <div className={classes.infoSectionTable}>
              <div className={classes.infoSectionTableItem}>
                <span className={classes.tableKey}>Platform</span>
                <span className={classes.tableValue}>
                  {getSocialMediaPlatform({
                    Post_Social_Media: socialPost.Post_Social_Media,
                  })}
                </span>
              </div>
              <div className={classes.infoSectionTableItem}>
                <span className={classes.tableKey}>Post Title</span>
                <span className={classes.tableValue}>{socialPost.Post_Title}</span>
              </div>
              <div className={classes.infoSectionTableItem}>
                <span className={classes.tableKey}>Post Date</span>
                <span className={classes.tableValue}>{moment(socialPost.Post_Date._seconds * 1000).format('LL')}</span>
              </div>
              <div className={classes.infoSectionTableItem}>
                <span className={classes.tableKey} style={{ marginBottom: '20px' }}>
                  Post Type
                </span>
                <span className={classes.tableValue} style={{ display: 'block', width: '70%' }}>
                  {getPostCategory(socialPost.Post_Category)}
                </span>
              </div>
              <div className={classes.infoSectionTableItem}>
                <span className={classes.tableKey}>Keyword 1</span>
                <span className={classes.tableValue}>{socialPost.Post_Keyword_1}</span>
              </div>

              <div className={classes.infoSectionTableItem}>
                <span className={classes.tableKey}>Keyword 2</span>
                <span className={classes.tableValue}>{socialPost.Post_Keyword_2}</span>
              </div>

              <div className={classes.infoSectionTableItem}>
                <span className={classes.tableKey}>Keyword 3</span>
                <span className={classes.tableValue}>{socialPost.Post_Keyword_3}</span>
              </div>
              <div className={classes.infoSectionTableItemPostText}>
                <p className={classes.tableKey} style={{ marginBottom: '10px' }}>
                  Post Text:
                </p>
                <p style={{ marginBottom: '10px' }} className={classes.tableValue}>
                  <em>{postText}</em>
                </p>
              </div>

              {socialPost.Post_Link && (
                <div className={classes.infoSectionTableItem}>
                  <span className={classes.tableKey}>Post Link</span>
                  <span className={classes.tableValue}>{socialPost.Post_Link}</span>
                </div>
              )}
            </div>
          </div>
          <div className={classes.statSection}>
            <div className={classes.totalImpact}>
              <h4>Total Impact</h4>
              <p>
                Total Impact measures the potential effect a social media post would have on the overall alignment between
                the selected brand persona and the chosen consumer segment.
              </p>
              <Box width="fit-content" margin="0 auto">
                <div id="totalImpactGaugeChart" className={classes.totalImpactGraphContainer}></div>
              </Box>
            </div>
            <div className={classes.postEvaluation}>
              <h4>Overall Post Evaluation</h4>
              <p>
                This evaluation relies on the survey results. Any error in the input may result in a suboptimal
                recommendation.
              </p>
              <PublishContentCard status={data.social_post_data.TotalImpact.mainStatus} />
            </div>
          </div>
        </div>
      </DashDetailsWrapper>
      {/* YT Video */}
      <DashDetailsWrapper
        backgroundColor="#254157"
        expandColor="white"
        heading1={{
          text: 'Social Media Strategy',
          color: '#fff',
        }}>
        <Box display="flex" flexDirection="column" justifyContent="flex-end" width="100%">
          {!AIStrategyGenerator.loading && (
            <div className={classes.regenerateButton}>
              <Button onClick={handleRegeneration}>Regenerate Strategy</Button>
            </div>
          )}
          <div className={classes.AiTextWrapper}>
            {!AIStrategyGenerator.loading && aiText ? (
              <p>{aiText}</p>
            ) : (
              <div className={classes.AiLoader}>
                <CubeLoader />
              </div>
            )}
            {socialPost.Post_Link && isValidUrl(socialPost.Post_Link) ? (
              <div className={classes.iFrameWrapper}>
                <iframe src={socialPost.Post_Link} title="Link to the social post" width="100%" height="400px"></iframe>
              </div>
            ) : null}
          </div>
        </Box>
      </DashDetailsWrapper>
      {/* Total Social Media Impact */}
      <DashDetailsWrapper
        backgroundColor="white"
        expandColor="black"
        heading1={{
          text: 'Social Media Content Impact',
          color: '#19989B',
        }}>
        <div className={classes.contentImpactResponsive}>
          <div className={classes.contentImpactItemResponsive}>
            <h3>Product Benefits</h3>
            <h5 style={{ height: '60px' }}>{productPositionImpactData.text}</h5>
            <Box>
              <div id="productPositioningGaugeChart"></div>
            </Box>
            <Box marginTop="-100px">
              <h5>Product Positioning Impact</h5>
              <p className={classes.contentImpactParagraph}>
                Product Positioning Impact measures the effect social media post would have on the product benefits match
                between selected brand persona and selected consumer segment.
              </p>
            </Box>
          </div>
          <div className={classes.contentImpactItem}>
            <h3>Social Values</h3>
            <h5 style={{ height: '60px' }}>{socialPositionImpactData.text}</h5>
            <Box>
              <div id="socialPositioningGaugeChart"></div>
            </Box>
            <Box marginTop="-100px">
              <h5>Social Positioning Impact</h5>
              <p className={classes.contentImpactParagraph}>
                Social Positioning Impact measures the effect of social media posts on the alignment of social values between
                the selected brand persona and the chosen consumer segment.
              </p>
            </Box>
          </div>
          <div className={classes.contentImpactItemLast}>
            <h3>Social Causes</h3>
            <h5 style={{ height: '60px' }}>{socialCausesImpactData.text}</h5>
            <Box>
              <div id="causesMatchImpactGaugeChart"></div>
            </Box>
            <Box marginTop="-100px">
              <h5>Causes Match Impact</h5>
              <p className={classes.contentImpactParagraph}>
                Causes Match Impact measures the effect of social media posts on the alignment between the social causes
                championed by the brand persona and the social causes that are important to the selected consumer segment.
              </p>
            </Box>
          </div>
        </div>
      </DashDetailsWrapper>
      {/* Social Positioning Analysis */}
      <DashDetailsWrapper
        backgroundColor="#254157"
        expandColor="white"
        heading1={{
          text: 'Social Positioning Analysis',
          color: '#fff',
        }}>
        <div className={classes.socialPositioningContentContainer}>
          <div className={classes.positioningHeader}>
            <div style={{ maxWidth: '500px', width: '34%' }}></div>
            <span className={classes.positioningHeaderTextElements}>Brand Image Consistency</span>
            <span className={classes.positioningHeaderTextElements}>Consumer Consistency</span>
          </div>
          {socialPositioningData.map((socialRangeChartData, index) => (
            <div
              key={index}
              style={{
                borderBottom: '1px solid rgba(217,217,217, 0.5)',
                padding: '20px 0px',
              }}>
              <Box className={classes.socialPositioningContent}>
                <div className={classes.socialPositioningChart}>
                  <RangeChart
                    mainLineColor="#667A89"
                    middleLineColor="#EFEFEF"
                    leftText={{
                      text: socialRangeChartData.leftText,
                      color: 'white',
                    }}
                    rightText={{
                      text: socialRangeChartData.rightText,
                      color: 'white',
                    }}
                    valueColor="lightgray"
                    heading={socialRangeChartData.heading}
                    headingColor="white"
                    body={{
                      text: socialRangeChartData.body,
                      color: 'lightgray',
                    }}
                    value1={socialRangeChartData.personaValue}
                    value2={socialRangeChartData.consumerValue}
                    value3={socialRangeChartData.socialValue}
                    type="brand"
                  />
                </div>
                <span
                  className={classes.consistency1Text}
                  style={{
                    color: getConsistencyTextColor(socialRangeChartData.BrandImageConsistencyText),
                    marginTop: '37px',
                  }}>
                  {socialRangeChartData.BrandImageConsistencyText}
                </span>
                <span
                  className={classes.consistency2Text}
                  style={{
                    color: getConsistencyTextColor(socialRangeChartData.ConsumerConsistencyText),
                    marginTop: '37px',
                  }}>
                  {socialRangeChartData.ConsumerConsistencyText}
                </span>
              </Box>
              <p
                style={{
                  color: '#E8E8E8',
                  fontWeight: 300,
                  width: '35%',
                  fontSize: '15px',
                }}
                className={classes.chartBodyText}>
                {socialRangeChartData.bodyText}
              </p>

              <div
                className={classes.consistencyTextSm}
                style={{
                  color: getConsistencyTextColor(socialRangeChartData.BrandImageConsistencyText),
                }}>
                <span className={classes.consistencyName}>Brand Image Consistency:</span>{' '}
                {socialRangeChartData.BrandImageConsistencyText}
              </div>
              <div
                className={classes.consistencyTextSm}
                style={{
                  color: getConsistencyTextColor(socialRangeChartData.ConsumerConsistencyText),
                }}>
                <span className={classes.consistencyName}>Consumer Consistency:</span>{' '}
                {socialRangeChartData.ConsumerConsistencyText}
              </div>
            </div>
          ))}
          <div className={classes.positioiningLegends}>
            <div className={classes.positioningLegend}>
              <div style={{ background: '#D9B14A' }}></div>
              {socialPost.Post_Title}
            </div>
            <div className={classes.positioningLegend}>
              <div style={{ background: '#CD225C' }}></div>SELECTED SAMPLE
            </div>
            <div className={classes.positioningLegend}>
              <div style={{ background: '#23A9A9' }}></div>
              {persona.Name}
            </div>
          </div>
        </div>
      </DashDetailsWrapper>
      {/* Product Benefits Analysis */}
      <DashDetailsWrapper
        backgroundColor="white"
        expandColor="black"
        heading1={{
          text: 'Product Benefits Analysis',
          color: '#19989B',
        }}>
        <div>
          <p className={classes.subtitle}>
            Positions consumers and brands take on a traditional liberal/conservative scale, considering the economic,
            social, and safety dimensions of ideology.
          </p>
          <Box className={classes.productBenefitsResponsive}>
            {productBenefitsData.map((item, idx) => {
              return (
                <Box className={classes.rangeChartWrapper} key={idx}>
                  <RangeChart
                    mainLineColor="#E5E6E6"
                    middleLineColor="#D9D9D9"
                    leftText={{
                      text: item.leftText,
                      color: '#6C757D',
                    }}
                    rightText={{
                      text: item.rightText,
                      color: '#6C757D',
                    }}
                    valueColor="gray"
                    body={{
                      text: item.body,
                      color: '#6C757D',
                    }}
                    value1={item.personaValue}
                    value2={item.consumerValue}
                    value3={item.socialValue}
                    type="brand"
                  />
                  <span className={classes.consistencyBenefitsText}>
                    Consumer Consistency -{' '}
                    <span
                      style={{
                        color: getProductBenefitsSpanColor(item.ConsumerConsistencyText),
                      }}>
                      {item.ConsumerConsistencyText}
                    </span>
                  </span>
                  <span className={classes.consistencyBenefitsText}>
                    Brand Image Consistency -{' '}
                    <span
                      style={{
                        color: getProductBenefitsSpanColor(item.BrandImageConsistencyText),
                      }}>
                      {item.BrandImageConsistencyText}
                    </span>
                  </span>
                </Box>
              );
            })}
          </Box>
          <div className={classes.positioiningLegends}>
            <div className={classes.productBenefitsLegend}>
              <div style={{ background: '#D9B14A' }}></div>
              {socialPost.Post_Title}
            </div>
            <div className={classes.productBenefitsLegend}>
              <div style={{ background: '#CD225C' }}></div>SELECTED SAMPLE
            </div>
            <div className={classes.productBenefitsLegend}>
              <div style={{ background: '#23A9A9' }}></div>
              {persona.Name}
            </div>
          </div>
        </div>
      </DashDetailsWrapper>

      {/* Social Causes Analysis - Consumer Consistency */}
      <DashDetailsWrapper
        backgroundColor="#254157"
        expandColor="white"
        heading1={{
          text: 'Social Causes Analysis - Consumer Consistency',
          color: '#fff',
        }}>
        <Box>
          <Box className={classes.consumerConsistencyContent}>
            {issuesImpact.map(item => {
              return (
                <ConsumerConsistencyCard
                  key={item.id}
                  id={item.id}
                  subject={item.subject}
                  text={item.text}
                  value={item.value}
                  textColor={item.textColor}
                />
              );
            })}
          </Box>
          <Watermark lightText={true} />
        </Box>
      </DashDetailsWrapper>
    </Box>
  );
};

export default ChartCalculations;
