import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { Box } from '@material-ui/core';
import { ToastContainer, toast } from 'react-toastify';

import { competitiveSetBrandsAlgorithmCalculation } from '../../../redux/actions/Firebase';
import { makeURL } from '../../../helperFunctions';

import PageContainer from '../../../myComponents/pageContainer';
import PageLoader from '../../../myComponents/loaders/pageLoader';
import ChartCalculations from './chartCalculations';

import HeaderSelector from '../../../myComponents/headerSelector';
import Filters from '../../../myComponents/filters/Filters';
import PDFExportButton from '../../../myComponents/PDFExportButton';
import UserDropdownHeader from '../../../myComponents/userDropdownHeader';

const CompetitiveSetBrandsDashboard = () => {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const appData = useSelector(({ data }) => data);
  const [brandPickedId, setBrandPickedId] = useState(null);
  const PDFContentRef = useRef(null);

  const [pageIsLoading, setPageIsLoading] = useState(true);
  const [useEffectShouldTrigger, setUseEffectShouldTrigger] = useState(true);

  useEffect(() => {
    if (!appData.loading && useEffectShouldTrigger) {
      setUseEffectShouldTrigger(false);
      if (appData.error) {
        showToastWarn();
      } else {
        if (!id) {
          history.push(makeURL('/competitive-set-brands/all'));
        } else {
          const pickedBrand = !appData.batchBrands ? false : appData.batchBrands.find(e => e.id === id);
          if (pickedBrand) {
            brandChangeHandler(pickedBrand);
            setPageIsLoading(false);
          } else {
            history.push(makeURL('/competitive-set-brands/all'));
          }
        }
      }
    }
  }, [appData.loading, id]);

  useEffect(() => {
    if (brandPickedId) {
      history.push(makeURL(`/competitive-set-brands/${brandPickedId}`));
      setUseEffectShouldTrigger(true);
    }
  }, [brandPickedId]);

  //Calculation state
  const [calculations, setCalculations] = useState({
    loading: false,
    results: null,
  });

  //Input States
  const [pickedBrand, setPickedBrand] = useState(null);

  const calculateHandler = (userPickedBrand, filters) => {
    setCalculations(prevState => ({ ...prevState, loading: true }));
    competitiveSetBrandsAlgorithmCalculation(
      {
        brandName: userPickedBrand.name,
        filters: filters,
      },
      dispatch,
    )
      .then(res => {
        setCalculations({ results: res, loading: false });
      })
      .catch(err => {
        setCalculations({ results: null, loading: false });
        showToastWarn();
      });
  };

  const showToastWarn = () => {
    toast.warn('Something went wrong, please try again later.', {
      position: 'bottom-right',
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const brandChangeHandler = userPickedBrand => {
    setPickedBrand(userPickedBrand);
    calculateHandler(userPickedBrand, null);
  };

  return (
    <PageContainer>
      {pageIsLoading || calculations.loading ? <PageLoader dashboardCalculation /> : null}
      {calculations.results ? (
        <div ref={PDFContentRef}>
          <UserDropdownHeader />
          <Box width="100%" padding="20px 20px 0px 20px" style={{ background: '#fff' }}>
            <HeaderSelector
              selectedId={brandPickedId ? brandPickedId : id}
              setPickedId={setBrandPickedId}
              data={appData}
              sameIndustryData={calculations.results.companies_match}
              //Here we should make new type competitive, so it can use appData competitive and choose from that list
              type="competitive"
              redirect={true}
            />
          </Box>
          <Filters
            calculateHandler={preparedFilterData => calculateHandler(pickedBrand, preparedFilterData)}
            themeColor="green"
          />
          <PDFExportButton contentRef={PDFContentRef} type="green" />
          <ChartCalculations data={calculations.results} name={pickedBrand.name} />
        </div>
      ) : null}
      <ToastContainer
        theme="colored"
        position="bottom-right"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </PageContainer>
  );
};

export default CompetitiveSetBrandsDashboard;
