import React from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Typography,
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    headingText: {
        color: theme.palette.text.primary,
        fontWeight: 600,
        fontSize: "23px"
    },
    bodyText: {
        color: theme.palette.text.secondary,
        fontWeight: 400,
        fontSize: "16px"
    },
    buttons: {
        padding: "8px 30px",
        color: "white",
        fontWeight: 300,
        backgroundColor: "#19989B",
        "&:hover": {
            backgroundColor: "#82C2C3",
        },
        "&.Mui-disabled": {
            background: "#b3b3b3",
            color: "white",
        },
    },
}));

const AlertDialog = (props) => {
    const classes = useStyles();

    return (
        <Dialog
            open={props.isShown}
            onClose={() => props.buttonAction(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                <Typography
                    component="div"
                    variant="h4"
                    className={classes.headingText}
                >
                    {props.heading}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Typography
                    component="div"
                    variant="body2"
                    className={classes.bodyText}
                >
                    {props.body}
                </Typography>
            </DialogContent>
            <DialogActions style={{ marginTop: "30px" }}>
                <Button className={classes.buttons} onClick={() => props.buttonAction(false)}>
                    {props.noButtonText}
                </Button>
                <Button className={classes.buttons} style={{ marginLeft: "20px" }} onClick={() => props.buttonAction(true)} autoFocus>
                    {props.yesButtonText}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AlertDialog;
