import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '23%',

    ['@media (max-width: 1300px)']: {
      width: '30%',
    },
    ['@media (max-width: 1000px)']: {
      width: '45%',
    },
    ['@media (max-width: 800px)']: {
      width: '100%',
    },
  },
  title: {
    fontSize: '20px',
    marginBottom: '12px',
  },
  chartContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',

    '& div:first-child': {
      borderTopRightRadius: '14px',
      borderTopLeftRadius: '14px',
    },
    '& div:last-child': {
      borderBottomRightRadius: '14px',
      borderBottomLeftRadius: '14px',
    },

    '@media (max-width: 768px)': {
      flexDirection: 'column',
    },
  },
  chart: {
    height: '340px',
    width: '150px',
    boxShadow: '4px 4px 12px rgba(0,0,0,0.2)',
    borderRadius: '14px',
    background: '#254157',
    ['@media (max-width: 800px)']: {
      width: '100%',
    },

    '& div': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: '#fff',
    },
  },
  labels: {
    marginLeft: '24px',
    '@media (max-width: 768px)': {
      marginTop: '24px',
    },
    alignSelf: 'flex-start',
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '8px',
    marginBottom: '8px',

    '& div': {
      borderRadius: '0 !important',
      width: '16px',
      height: '16px',
      boxShadow: '0 4px 4px rgba(0,0,0,0.1)',
    },
    '& span': {
      fontSize: '14px',
    },
  },
}));

const ConsumerGroup = ({
  highText,
  highPercentage,
  highColor,
  lowText,
  lowPercentage,
  lowColor,
  balancedText,
  balancedPercentage,
  balancedColor,
}) => {
  const classes = useStyles();

  const title = `${highText} - <br />${lowText}`;

  return (
    <div className={classes.container}>
      <h3
        className={classes.title}
        dangerouslySetInnerHTML={{ __html: title }}
      ></h3>
      <div className={classes.chartContainer}>
        <div className={classes.chart}>
          <div style={{ background: highColor, height: `${highPercentage}%` }}>
            <span>{highPercentage}%</span>
          </div>
          <div
            style={{
              background: balancedColor,
              height: `${balancedPercentage}%`,
            }}
          >
            <span>{balancedPercentage}%</span>
          </div>
          <div style={{ background: lowColor, height: `${lowPercentage}%` }}>
            <span>{lowPercentage}%</span>
          </div>
        </div>

        <div className={classes.labels}>
          <div className={classes.label}>
            <div style={{ background: highColor }}></div>
            <span>{highText}</span>
          </div>
          <div className={classes.label}>
            <div style={{ background: balancedColor }}></div>
            <span>{balancedText}</span>
          </div>
          <div className={classes.label}>
            <div style={{ background: lowColor }}></div>
            <span>{lowText}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConsumerGroup;
