import React, { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { fetchData } from '../redux/actions/Data';
import { makeURL } from '../helperFunctions';
import useCheckAuthStateInterval from '../myHooks/useCheckAuthStateInterval';
import { subscriptionTypeNames } from '../config/subscriptionsConfig';

//pages
import SessionExpired from './Pages/SessionExpired';
import Home from './Pages/Home';

import BrandViewAll from './Pages/BrandPersonas/BrandViewAll';
import BrandDashboard from './Pages/BrandPersonas/BrandDashboard';

import BuyerViewAll from './Pages/BuyerPersonas/BuyerViewAll';
import BuyerDashboard from './Pages/BuyerPersonas/BuyerDashboard';

import CreateNew from './Pages/CreateNew';
import Consumer from './Pages/Consumer';
import ContactUs from './Pages/ContactUs';
import Reports from './Pages/Reports';
import BrandConsumers from './Pages/BrandConsumers';

import AppLoader from '../myComponents/loaders/appLoader';
import SocialMediaViewAll from './Pages/SocialMedia/SocialMediaViewAll';
import SocialMediaDashboard from './Pages/SocialMedia/SocialMediaDashboard';
import CompetitiveSetBrands from './Pages/CompetitiveSetBrands/CompetitiveSetBrandsViewAll';
import CompetitiveSetBrandsDashboard from './Pages/CompetitiveSetBrands/CompetitiveSetBrandsDashboard';

const Routes = () => {
  const location = useLocation();
  const authState = useSelector(({ auth }) => auth);
  const dispatch = useDispatch();

  //Settup authentication check interval
  const checkAuthInterval = 1000 * 30; //30 seconds
  useCheckAuthStateInterval(dispatch, checkAuthInterval);

  useEffect(() => {
    if (authState.user) {
      dispatch(fetchData());
    }
  }, [authState.user]);

  if (authState.initialAppLoadCheckingAuthState) {
    return <AppLoader />;
  }

  if (!authState.user) {
    return <SessionExpired />;
  }

  if (location.pathname === makeURL('') || location.pathname === makeURL('/')) {
    return <Redirect to={makeURL('/home')} />;
  }

  const restrictCompetitiveRouteAccess = authState?.user?.customClaims?.subscriptionType === subscriptionTypeNames.demo;

  return (
    <React.Fragment>
      <Switch>
        <Route path={makeURL('/home')} exact component={Home} />
        <Route path={makeURL('/create-new/:type/:id')} exact component={CreateNew} />

        {/* Brand personas */}
        <Route path={makeURL('/brand-personas/all')} exact component={BrandViewAll} />
        <Route path={makeURL('/brand-personas/:id')} exact component={BrandDashboard} />

        {/* Buyer personas */}
        <Route path={makeURL('/buyer-personas/all')} exact component={BuyerViewAll} />

        <Route path={makeURL('/buyer-personas/:id')} exact component={BuyerDashboard} />

        {/* Social Media */}
        <Route path={makeURL('/social-media/all')} exact component={SocialMediaViewAll} />

        <Route path={makeURL('/social-media/:id')} exact component={SocialMediaDashboard} />

        {/* Competitive Set Brands, demo account doesn't have access */}
        {!restrictCompetitiveRouteAccess && (
          <Route path={makeURL('/competitive-set-brands/all')} exact component={CompetitiveSetBrands} />
        )}
        {!restrictCompetitiveRouteAccess && (
          <Route path={makeURL('/competitive-set-brands/:id')} exact component={CompetitiveSetBrandsDashboard} />
        )}

        <Route path={makeURL('/consumer-dash')} exact component={Consumer} />

        <Route path={makeURL('/brand-consumers')} exact component={BrandConsumers} />
        <Route path={makeURL('/reports')} exact component={Reports} />
        <Route path={makeURL('/contact-us')} exact component={ContactUs} />

        <Redirect to={makeURL('/home')} />
      </Switch>
    </React.Fragment>
  );
};

export default Routes;
