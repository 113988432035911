import { makeStyles } from '@material-ui/core/styles';
import { dataCodeFields } from '../../../config/dataCodes';
import { USA_States } from '../../../config/questions';

const useStyles = makeStyles((theme) => ({
  table: {
    width: '100%',
  },
  tableItem: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '1px solid rgba(0,0,0,0.05)',
    padding: '4px 0px',
  },
  tableKey: {
    flex: 1,
  },
  tableValue: {
    flex: 1,
  },
}));

const ConsumerDataInfoTable = ({ data }) => {
  const classes = useStyles();

  const mapValue = (key, value) => {
    if (key == 'State') {
      let result = '';

      USA_States.forEach((el) => {
        if (el.value == value) {
          result = el.text;
        }
      });

      return result;
    }

    let result = '';

    dataCodeFields[key].forEach((el) => {
      if (el.value == value) {
        result = el.text;
      }
    });

    return result;
  };

  return (
    <div className={classes.table}>
      <div className={classes.tableItem}>
        <span className={classes.tableKey}>Age</span>
        <span className={classes.tableValue}>
          {data.average.Age.toFixed(0)}
        </span>
      </div>
      {Object.keys(data.most_occurring).map((key, idx) => {
        return (
          <div key={idx} className={classes.tableItem}>
            <span className={classes.tableKey}>{key}</span>
            <span className={classes.tableValue}>
              {typeof data.most_occurring[key] == 'number' && key != 'ZIP'
                ? mapValue(key, data.most_occurring[key])
                : data.most_occurring[key]}
            </span>
          </div>
        );
      })}
    </div>
  );
};

export default ConsumerDataInfoTable;
