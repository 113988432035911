import React from 'react';
import { Box, Typography, FormControlLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import {
  uncheckAgeGroupFilters,
  uncheckGenGroupFilters,
  uncheckIncomeBracketFilters,
  uncheckIncomeGroupFilters,
} from '../../../helperFunctions';

const useStyles = makeStyles(theme => ({
  elementLabel: {
    color: theme.palette.text.primary,
    fontSize: '13px',
    marginLeft: '-14px',
    letterSpacing: '0px',
  },
}));

const muiTheme = createTheme({
  overrides: {
    MuiSwitch: {
      switchBase: {
        // Controls default (unchecked) color for the thumb
        color: '#C1C1C1',
      },
      colorSecondary: {
        '&$checked': {
          // Controls checked color for the thumb
          color: '#19989B',
        },
      },
      track: {
        // Controls default (unchecked) color for the track
        backgroundColor: '#D0D0D0',
        '$checked$checked + &': {
          // Controls checked color for the track
          backgroundColor: '#19989B',
        },
      },
    },
  },
});

const switchGroup = ({ switchElements, id, onSliderChangeHandler, themeColor }) => {
  const classes = useStyles();

  const ageGroups = ['Young18-34', 'Middle35-49', 'Older50+'];
  const generationGroups = [
    'GenZ(10-25Years)',
    'GenY(26-41Years)',
    'GenX(42-57Years)',
    'Boomers(58-76Years)',
    'Silent(77+Years)',
  ];

  const incomeGroup = ['Low(under$49,999)', 'Middle($50,000-$99,999)', 'High($100,000+)'];

  const incomeBracket = [
    'LESSTHAN$15,000',
    '$10,000-$24,999',
    '$25,000-$34,999',
    '$35,000–$49,999',
    '$50,000-$74,999',
    '$75,000-$99,999',
    '$100,000-$149,999',
    '$150,000-$199,999',
    '$200,000+',
  ];

  const onSwitchToggle = (e, label) => {
    const ageSlider = document.querySelector('#ageRange input');

    if (ageSlider.value !== '0,0') {
      onSliderChangeHandler(e, [0, 0]);
    }

    const isAgeGroup = ageGroups.indexOf(e.target.id);
    const isGenGroup = generationGroups.indexOf(e.target.id);

    const isIncomeGroup = incomeGroup.indexOf(e.target.id);
    const isIncomeBracket = incomeBracket.indexOf(e.target.id);

    const ageGroupSection = document.getElementById('AGEGROUPS');
    const genGroupSection = document.getElementById('GENERATIONS');
    const ageRangeSection = document.getElementById('AGERANGE(YEARS)');
    const incomeGroupSection = document.getElementById('INCOMEGROUP');
    const incomeBracketSection = document.getElementById('INCOMEBRACKET');

    if (isAgeGroup !== -1) {
      ageGroupSection.classList.remove('opacity-sm');
      genGroupSection.classList.add('opacity-sm');
      ageRangeSection.classList.add('opacity-sm-range');

      uncheckGenGroupFilters();
    }

    if (isGenGroup !== -1) {
      ageGroupSection.classList.add('opacity-sm');
      ageRangeSection.classList.add('opacity-sm-range');
      genGroupSection.classList.remove('opacity-sm');

      uncheckAgeGroupFilters();
    }

    if (isIncomeGroup !== -1) {
      incomeGroupSection.classList.remove('opacity-sm');
      incomeBracketSection.classList.add('opacity-sm');

      uncheckIncomeBracketFilters();
    }

    if (isIncomeBracket !== -1) {
      incomeBracketSection.classList.remove('opacity-sm');
      incomeGroupSection.classList.add('opacity-sm');

      uncheckIncomeGroupFilters();
    }
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="flex-end" marginLeft="0" marginBottom="10px">
      {switchElements.map((element, index) => {
        const elementId = element.label.replace(/ /g, '');

        return (
          <Box
            key={index}
            display="flex"
            alignItems="center"
            justifyContent="flex-start"
            width="100%"
            paddingBottom="8px"
            className="">
            <div className={`custom-toggle-switch-container ${themeColor}`}>
              <div className="custom-toggle-switch-inner-container">
                <ThemeProvider theme={muiTheme}>
                  <FormControlLabel
                    value={element.value}
                    control={<input type="checkbox" size="small" id={elementId} color="transparent" />}
                    name={id}
                    onChange={e => onSwitchToggle(e, element.label)}
                  />
                </ThemeProvider>
                <span className={`custom-toggle-switch-slider ${themeColor}`}></span>
              </div>
            </div>
            <Typography component="div" variant="body1" className={classes.elementLabel}>
              {element.label}
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
};

export default switchGroup;
