import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { Box } from '@material-ui/core';
import { ToastContainer, toast } from 'react-toastify';

import { algorithmCalculation } from '../../../redux/actions/Firebase';
import { makeURL } from '../../../helperFunctions';

import PageContainer from '../../../myComponents/pageContainer';
import PageLoader from '../../../myComponents/loaders/pageLoader';
import ChartCalculations from './chartCalculations';

import HeaderSelector from '../../../myComponents/headerSelector';
import Filters from '../../../myComponents/filters/Filters';
import PDFExportButton from '../../../myComponents/PDFExportButton';
import UserDropdownHeader from '../../../myComponents/userDropdownHeader';

const BuyerDashboard = () => {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const appData = useSelector(({ data }) => data);
  const [buyerPersonaPickedId, setBuyerPersonaPickedId] = useState(null);
  const PDFContentRef = useRef(null);

  const [pageIsLoading, setPageIsLoading] = useState(true);
  const [useEffectShouldTrigger, setUseEffectShouldTrigger] = useState(true);

  useEffect(() => {
    if (!appData.loading && useEffectShouldTrigger) {
      setUseEffectShouldTrigger(false);
      if (appData.error) {
        showToastWarn();
      } else {
        if (!id) {
          history.push(makeURL('/buyer-personas/all'));
        } else {
          const pickedBuyerPersona = !appData.buyerPersonas
            ? false
            : appData.buyerPersonas.find((e) => e.id === id);
          if (pickedBuyerPersona) {
            personaChangeHandler(pickedBuyerPersona);
            setPageIsLoading(false);
          } else {
            history.push(makeURL('/buyer-personas/all'));
          }
        }
      }
    }
  }, [appData.loading, id]);

  useEffect(() => {
    if (buyerPersonaPickedId) {
      history.push(makeURL(`/buyer-personas/${buyerPersonaPickedId}`));
      setUseEffectShouldTrigger(true);
    }
  }, [buyerPersonaPickedId]);

  //Calculation state
  const [calculations, setCalculations] = useState({
    loading: false,
    results: null,
  });

  const [pickedBuyerPersona, setPickedBuyerPersona] = useState(null);

  const calculateHandler = (pickedPersona, filters) => {
    setCalculations((prevState) => ({ ...prevState, loading: true }));
    algorithmCalculation(
      'Buyer',
      {
        persona: pickedPersona,
        filters: filters,
      },
      dispatch
    )
      .then((res) => {
        setCalculations({ results: res, loading: false });
      })
      .catch((err) => {
        setCalculations({ results: null, loading: false });
        showToastWarn();
      });
  };

  const showToastWarn = () => {
    toast.warn('Something went wrong, please try again later.', {
      position: 'bottom-right',
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const personaChangeHandler = (pickedPersona) => {
    setPickedBuyerPersona(pickedPersona);
    calculateHandler(pickedPersona, null);
  };

  return (
    <PageContainer>
      {pageIsLoading || calculations.loading ? (
        <PageLoader dashboardCalculation />
      ) : null}
      {calculations.results ? (
        <div ref={PDFContentRef}>
          <UserDropdownHeader />
          <Box
            width='100%'
            padding='20px 20px 0px 20px'
            style={{ background: '#fff' }}
          >
            <HeaderSelector
              selectedId={buyerPersonaPickedId ? buyerPersonaPickedId : id}
              setPickedId={setBuyerPersonaPickedId}
              data={appData}
              type='buyer'
              redirect={true}
              bgColor='#AB8628'
            />
          </Box>
          <Filters
            calculateHandler={(preparedFilterData) =>
              calculateHandler(pickedBuyerPersona, preparedFilterData)
            }
            themeColor='yellow'
          />
          <PDFExportButton contentRef={PDFContentRef} type="yellow"/>
          <ChartCalculations
            type='brand'
            data={calculations.results}
            persona={pickedBuyerPersona}
          />
        </div>
      ) : null}
      <ToastContainer
        theme='colored'
        position='bottom-right'
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </PageContainer>
  );
};

export default BuyerDashboard;
