import { FIREBASE_INIT_APP } from './actionTypes';

import axios from '../../API/axiosConfig';

// FIREABSE CREDENTIALS
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

// IMPORT CUSTOM HANDLERS
import { updateSurvey } from '../../API/agent';
import { fetchData } from './Data';

const firebaseConfig = {
  apiKey: 'AIzaSyCsbUg28nAV_5KdKZVYdS04NrOm-nLzs-4',
  authDomain: 'survey-app-acdd3.firebaseapp.com',
  projectId: 'survey-app-acdd3',
  storageBucket: 'survey-app-acdd3.appspot.com',
  messagingSenderId: '998259138842',
  appId: '1:998259138842:web:1a7622031a3c37a25f4ca3',
  measurementId: 'G-3LL854WBS6',
};

const firebaseApp = initializeApp(firebaseConfig);
const firebaseAuth = getAuth(firebaseApp);
const firebaseFirestore = getFirestore(firebaseApp);

//FILL UP STORE WITH THE FIREBASE METHODS (app, auth, firestore)
export const initializeFirebaseApp = () => {
  return dispatch => {
    dispatch({
      type: FIREBASE_INIT_APP,
      app: firebaseApp,
      auth: firebaseAuth,
      firestore: firebaseFirestore,
    });
  };
};

/**
 * @param {'Brand' | 'Buyer' | 'Social'} surveyType
 * @param {any} data
 * @description calls axios on calulcation api
 */
export const algorithmCalculation = (surveyType, data, dispatch) => {
  return new Promise((resolve, reject) => {
    const url = surveyType === 'Brand' ? '/calculations/brand-persona' : '/calculations/buyer-persona';

    axios
      .post(url, data)
      .then(result => {
        if (result.status === 200) {
          if (!result.data.error) {
            resolve(result.data.result);

            //Update the survey with calcuated data
            const surveyId = data.persona.id;
            const { Total_Match, Product_Match, Values_Match } = result.data.result.match;
            const totalMatchRounded = Number((Total_Match * 100).toFixed(0));
            const productMatchRounded = Number((Product_Match * 100).toFixed(0));
            const valuesMatchRounded = Number((Values_Match * 100).toFixed(0));
            if (
              data.persona.filter != !!data.filters ||
              data.persona.totalMatch != totalMatchRounded ||
              data.persona.productBenefitsMatch != productMatchRounded ||
              data.persona.socialValueMatch != valuesMatchRounded
            ) {
              const resultData = {
                filter: !!data.filters,
                totalMatch: totalMatchRounded,
                productBenefitsMatch: productMatchRounded,
                socialValueMatch: valuesMatchRounded,
              };
              updateSurvey(surveyType, surveyId, resultData).then(res => {
                if (!res.err) dispatch(fetchData());
              });
            }
          } else {
            reject('An error occured while calulcating the data');
          }
        } else {
          reject('Server didnt respond in a regular status');
        }
      })
      .catch(err => {
        reject(err.message);
      });
  });
};

export const consumerAlgorithmCalculation = (data, dispatch) => {
  return new Promise((resolve, reject) => {
    const url = '/calculations/consumer-data';

    axios
      .post(url, data)
      .then(result => {
        if (result.status === 200) {
          if (!result.data.error) {
            resolve(result.data.result);
          } else {
            reject('An error occured while calulcating the data');
          }
        } else {
          reject('Server didnt respond in a regular status');
        }
      })
      .catch(err => {
        reject(err.message);
      });
  });
};

export const competitiveSetBrandsAlgorithmCalculation = data => {
  return new Promise((resolve, reject) => {
    const url = '/calculations/selected-brand';

    axios
      .post(url, data)
      .then(result => {
        if (result.status === 200) {
          if (!result.data.error) {
            resolve(result.data.result);
          } else {
            reject('An error occured while calulcating the data');
          }
        } else {
          reject('Server didnt respond in a regular status');
        }
      })
      .catch(err => {
        reject(err.message);
      });
  });
};

export const multipleBrandsAlgorithmCalculation = data => {
  return new Promise((resolve, reject) => {
    const url = '/calculations/multiple-brand';

    axios
      .post(url, data)
      .then(result => {
        if (result.status === 200) {
          if (!result.data.error) {
            const rewampedResults = Object.values(result.data.result).map(value => ({
              ...value.info,
              ...value.average,
              ...value.match,
            }));
            resolve(rewampedResults);
          } else {
            reject('An error occured while calulcating the data');
          }
        } else {
          reject('Server didnt respond in a regular status');
        }
      })
      .catch(err => {
        reject(err.message);
      });
  });
};

export const socialMediaAlgorithmCalculation = (data, dispatch) => {
  return new Promise((resolve, reject) => {
    const url = '/calculations/social-post';

    axios
      .post(url, data)
      .then(result => {
        if (result.status === 200) {
          if (!result.data.error) {
            resolve(result.data.result);

            //Update the survey with calcuated data
            const surveyId = data.socialPost.id;
            const { value, mainStatus } = result.data.result.social_post_data.TotalImpact;
            const impactValue = Math.round(Number((value * 100).toFixed(0)));

            if (
              data.socialPost.filter != !!data.filters ||
              data.socialPost.impact != impactValue ||
              data.socialPost.suggestion != mainStatus
            ) {
              const resultData = {
                filter: !!data.filters,
                impact: impactValue,
                suggestion: mainStatus,
              };
              updateSurvey('social', surveyId, resultData).then(res => {
                if (!res.err) dispatch(fetchData());
              });
            }
          } else {
            reject('An error occured while calulcating the data');
          }
        } else {
          reject('Server didnt respond in a regular status');
        }
      })
      .catch(err => {
        reject(err.message);
      });
  });
};
